import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import "./addeditfolder.scss";

const AddFolder = () => {
  const [form, setform] = useState();
  const history = useHistory();

  const handleSave = (e) => {
    e.preventDefault();
    let payload = {
      name: form?.folderName,
    };
    ApiClient.post(`folder`, payload).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res.message);
        }, 100);
        history.push(`/app/folder`);
      }
    });
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSave}>
          <div className="row mx-2">
            <div className="col-md-12">
              <label className="form-label">Folder Name</label>
              <input
                type="text"
                value={form?.folderName}
                onChange={(e) =>
                  setform({ ...form, folderName: e.target.value })
                }
                maxLength="20"
                className="form-control"
                placeholder="Folder Name"
                required
              />
            </div>
            <div className="col-md-12 my-4 text-right">
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={(e) => {
                  history.goBack();
                }}
              >
                Back
              </button>
              <button type="submit" className="btn btn-primary ml-2">
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddFolder;
