import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";

const Frames = ({ frameForm, setframeForm, radiobtn, setradiobtn }) => {
  // Swap Colors
  const swapValues = () => {
    let temp1 = "";
    let temp2 = "";
    temp1 = frameForm?.qrFrameColorGradient1;
    temp2 = frameForm?.qrFrameColorGradient2;
    setframeForm({
      ...frameForm,
      qrFrameColorGradient1: temp2,
      qrFrameColorGradient2: temp1,
    });
  };

  // Dropdown Handler
  const [isOpen, setIsOpen] = useState({
    framecolor: false,
    qrframecolorGradient1: false,
    qrframecolorGradient2: false,
    qrframetextColor: false,
  });
  const dropdownRefFrameColor = useRef(null);
  const dropdownRefFrameColorGradient1 = useRef(null);
  const dropdownRefFrameColorGradient2 = useRef(null);
  const dropdownRefTextColor = useRef(null);
  const handleClickOutside = (event) => {
    if (
      dropdownRefFrameColor.current &&
      !dropdownRefFrameColor.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, framecolor: false }));
    }
    if (
      dropdownRefFrameColorGradient1.current &&
      !dropdownRefFrameColorGradient1.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrframecolorGradient1: false }));
    }
    if (
      dropdownRefFrameColorGradient2.current &&
      !dropdownRefFrameColorGradient2.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrframecolorGradient2: false }));
    }
    if (
      dropdownRefTextColor.current &&
      !dropdownRefTextColor.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrframetextColor: false }));
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {/* Frames */}
      <div className="frames_div row text-center">
        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "",
              width: "250",
              height: "250",
            })
          }
        >
          <img
            src="/assets/img/frame0_img.png"
            className={!frameForm?.frame ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "1",
              width: "200",
              height: "200",
            })
          }
        >
          <img
            src="/assets/img/frame1_img.png"
            className={frameForm?.frame == "1" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "2",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame2_img.png"
            className={frameForm?.frame == "2" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "3",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame3_img.png"
            className={frameForm?.frame == "3" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "4",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame4_img.png"
            className={frameForm?.frame == "4" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "5",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame5_img.png"
            className={frameForm?.frame == "5" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "6",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame6_img.png"
            className={frameForm?.frame == "6" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "7",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame7_img.png"
            className={frameForm?.frame == "7" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "8",
              width: "85",
              height: "85",
            })
          }
        >
          <img
            src="/assets/img/frame8_img.png"
            className={frameForm?.frame == "8" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "9",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame9_img.png"
            className={frameForm?.frame == "9" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "10",
              width: "70",
              height: "70",
            })
          }
        >
          <img
            src="/assets/img/frame10_img.png"
            className={frameForm?.frame == "10" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>

        <div
          className="div_img4 pointer col-6 col-sm-4 col-md-3 mb-3"
          onClick={(e) =>
            setframeForm({
              ...frameForm,
              frame: "11",
              width: "90",
              height: "90",
            })
          }
        >
          <img
            src="/assets/img/frame11_img.png"
            className={frameForm?.frame == "11" ? "active_box" : ""}
            width="140px"
            height="140px"
          />
        </div>
      </div>

      {/* Frames Color */}
      <div className="row mx-1">
        <p className="fs-5 mt-3 mb-0">
          <b>Frame Color</b>
        </p>
        <div className="form-check d-flex mb-3 ps-0">
          <div className="col-md-6 ps-0 d-flex justify-content-between">
            <div className="form-check check-radio_btn mb-0">
              <label className="form-check-label fw-normal">
                <input
                  className="form-check-input"
                  checked={radiobtn?.frameColor}
                  onClick={(e) =>
                    setradiobtn({ ...radiobtn, frameColor: true })
                  }
                  type="radio"
                  name="frameColorRadioButton"
                />
                <span>Solid Color</span>
              </label>
            </div>
            <div className="form-check check-radio_btn mb-0">
              <label className="form-check-label fw-normal">
                <input
                  className="form-check-input"
                  checked={!radiobtn?.frameColor}
                  onClick={(e) =>
                    setradiobtn({ ...radiobtn, frameColor: false })
                  }
                  type="radio"
                  name="frameColorRadioButton"
                />
                Gradient Color
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {radiobtn?.frameColor ? (
            <div className="row">
              <div className="col-md-6">
                <div className="color-code mb-3">
                  <div className="code-btn d-flex gap-3 align-items-center">
                    <div
                      className="dropdown d-flex align-items-center"
                      ref={dropdownRefFrameColor}
                    >
                      <button
                        style={{ backgroundColor: frameForm?.qrFrameColor }}
                        className="color_box"
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            framecolor: !isOpen?.framecolor,
                          })
                        }
                        aria-expanded={isOpen?.framecolor}
                      ></button>
                      <span className="ml-2">{frameForm?.qrFrameColor}</span>
                      {isOpen?.framecolor && (
                        <div className="dropdown-menu p-0 d-block">
                          <SketchPicker
                            color={frameForm?.qrFrameColor}
                            onChange={(color) =>
                              setframeForm({
                                ...frameForm,
                                qrFrameColor: color?.hex,
                                qrFrameColorGradient1: "",
                                qrFrameColorGradient2: "",
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="gradient-div">
              <div className="d-flex align-items-center mr-5">
                <h6 className="fw-bold mb-0">Gradient Type</h6>
              </div>
              <div className="rowDiv align-items-center">
                <div className="gradient_code-btn d-flex gap-3 align-items-center">
                  <div
                    className="dropdown d-flex align-items-center"
                    ref={dropdownRefFrameColorGradient1}
                  >
                    <button
                      style={{
                        backgroundColor:
                          frameForm?.qrFrameColorGradient1 || "#030202",
                      }}
                      className="color_box"
                      onClick={() =>
                        setIsOpen({
                          ...isOpen,
                          qrframecolorGradient1: !isOpen?.qrframecolorGradient1,
                        })
                      }
                      aria-expanded={isOpen?.qrframecolorGradient1}
                    ></button>
                    <span className="ml-2">
                      {frameForm?.qrFrameColorGradient1 || "#030202"}
                    </span>
                    {isOpen?.qrframecolorGradient1 && (
                      <div className="dropdown-menu p-0 d-block">
                        <SketchPicker
                          color={frameForm?.qrFrameColorGradient1 || "#030202"}
                          onChange={(color) =>
                            setframeForm({
                              ...frameForm,
                              qrFrameColorGradient1: color?.hex,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <img
                  src="/assets/img/compare_arrows.png"
                  className="campare_arrow pointer"
                  alt=""
                  onClick={(e) => swapValues()}
                />
                <div className="gradient_code-btn d-flex gap-3 align-items-center">
                  <div
                    className="dropdown d-flex align-items-center"
                    ref={dropdownRefFrameColorGradient2}
                  >
                    <button
                      style={{
                        backgroundColor:
                          frameForm?.qrFrameColorGradient2 || "#030202",
                      }}
                      className="color_box"
                      onClick={() =>
                        setIsOpen({
                          ...isOpen,
                          qrframecolorGradient2: !isOpen?.qrframecolorGradient2,
                        })
                      }
                      aria-expanded={isOpen?.qrframecolorGradient2}
                    ></button>
                    <span className="ml-2">
                      {frameForm?.qrFrameColorGradient2 || "#030202"}
                    </span>
                    {isOpen?.qrframecolorGradient2 && (
                      <div className="dropdown-menu p-0 d-block">
                        <SketchPicker
                          color={frameForm?.qrFrameColorGradient2 || "#030202"}
                          onChange={(color) =>
                            setframeForm({
                              ...frameForm,
                              qrFrameColorGradient2: color?.hex,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Frame Text */}
      <div className="row mx-1 mt-2">
        <div className="col-md-12">
          <p className="fs-5 mt-3 mb-0">
            <b>Frame Text</b>
          </p>
          <div className="mt-1">
            <label className="mb-0">Text Content</label>
            <input
              type="text"
              value={frameForm?.text}
              onChange={(e) =>
                setframeForm({ ...frameForm, text: e.target.value })
              }
              className="form-control"
              maxLength="20"
              placeholder="SCAN ME"
            />
            <label className="font_color">20 characters max</label>
          </div>
        </div>

        <div className="mt-2 col-md-12">
          <div className="row">
            <div className="col-md-6">
              <label className="mb-0">Font</label>
              <select
                class="form-select"
                value={frameForm?.fontStyle}
                onChange={(e) =>
                  setframeForm({ ...frameForm, fontStyle: e.target.value })
                }
                aria-label="Default select example"
              >
                <option selected>Arial</option>
                <option value="Font">Font</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Verdana">Verdana</option>
                <option value="cursive">Cursive</option>
                <option value="monospace">Monospace</option>
                <option value="italic">Italic</option>
              </select>
            </div>
            <div className="col-md-6">
              <label className="mb-0">Text Color</label>
              <div className="code-btn d-flex gap-3 align-items-center">
                <div
                  className={`dropdown d-flex align-items-center ${
                    isOpen?.qrframetextColor ? "dropup" : ""
                  }`}
                  ref={dropdownRefTextColor}
                >
                  <button
                    style={{
                      backgroundColor: frameForm?.textColor || "#3c61e4",
                    }}
                    className="color_box"
                    onClick={() =>
                      setIsOpen({
                        ...isOpen,
                        qrframetextColor: !isOpen?.qrframetextColor,
                      })
                    }
                    aria-expanded={isOpen?.qrframetextColor}
                  ></button>
                  <span className="ml-2">
                    {frameForm?.textColor || "#3c61e4"}
                  </span>
                  {isOpen?.qrframetextColor && (
                    <div className="dropdown-menu p-0 d-block">
                      <SketchPicker
                        color={frameForm?.textColor || "#3c61e4"}
                        onChange={(color) =>
                          setframeForm({ ...frameForm, textColor: color?.hex })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Frames;
