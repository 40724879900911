import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import environment from "../../environment";
import "./style.scss";
import { useHistory, useParams } from "react-router-dom";
import loader from "../../methods/loader";
import Forms from "./Forms";
import QrCode from ".";
import HomeLogo from "../Logo/HomeLogo";
import PatternColors from "../PatternColors";
import DynamicQrCode from "../DynamicQrCode";
import { useSelector } from "react-redux";
import TemplatesDesign from "../Templates/TemplatesDesign";
import Select from "react-select";
import Frames from "../Frames";
import moment from "moment";
import { toast } from "react-toastify";
// import UAParser from 'ua-parser-js';

const QrCodes = () => {
  const { slug, qrCodeSlug } = useParams();
  let user = useSelector((state) => state.user);
  let activePlan = useSelector((state) => state.activePlan);
  const [tab, settab] = useState("first");
  const urlPath = window.location.pathname;
  const [slugData, setslugData] = useState('');
  const [activeDataPlan, setactivePlan] = useState();
  let qr = localStorage.getItem("qr");
  // const [uaInfo, setUaInfo] = useState(null);

  const [typeDropdown, settypeDropdown] = useState();
  // For View More Search
  const [selectSlug, setselectSlug] = useState();
  const [qrTypesViewMore, setqrTypesViewMore] = useState();
  const [searchViewMore, setsearchViewMore] = useState();
  const [ViewMoreSuggestions, setViewMoreSuggestions] = useState([]);
  const history = useHistory();
  const [qrCodeImage, setqrCodeImage] = useState();
  const [folder, setfolder] = useState([]);
  const [totalFOlder, setTotalFolder] = useState(0);
  const [slugDataImage,setSlugDataImage] = useState('')
  const [form, setform] = useState({
    value: "",
    staticQrCode:null,
    logoimg: "",
    logosize: "4",
    qrOuterEye: "square",
    qrInnerEye: "square",
    qrBodyPattern: "square",
    qrOuterEyeColor: "#030202",
    qrInnerEyeColor: "#030202",
    qrBodyPatternColor: "#030202",
    backgroundColor: "#ffffff",
    qrOuterEyeColorGradient1: "",
    qrOuterEyeColorGradient2: "",
    qrOuterEyeColorGradientType: "linear",
    qrInnerEyeColorGradient1: "",
    qrInnerEyeColorGradient2: "",
    qrInnerEyeColorGradientType: "linear",
    qrBodyPatternGradientColor1: "",
    qrBodyPatternGradientColor2: "",
    qrBodyPatternGradientColorType: "linear",
    backgroundGradientColor1: "",
    backgroundGradientColor2: "",
    backgroundGradientColorType: "linear",
    errorCorrection: "Q",
  });
  const [common, setcommon] = useState({
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    value6: "",
    value7: "",
    value8: "",
  });
  // For vCard
  const [vcard, setvcard] = useState();
  //Add More
  const [smartURL, setsmartURL] = useState({
    ruleType: "location",
    defaultUrl: "",
  });
  const [location, setlocation] = useState([
    { country: "", region: "", city: "", url: "" },
  ]);
  const [numberOfScans, setnumberOfScans] = useState([{ scans: "", url: "" }]);
  const [time, settime] = useState([
    { timezone: "", startTime: "", endTime: "", url: "" },
  ]);
  const [language, setlanguage] = useState([{ language: "", url: "" }]);
  // For Frames
  const [frameForm, setframeForm] = useState({
    width: "250",
    height: "250",
    frame: "",
    qrFrameColor: "#030202",
    qrFrameColorGradient1: "",
    qrFrameColorGradient2: "",
    text: "SCAN ME",
    fontStyle: "",
    textColor: "#3c61e4",
  });
  //For Select Download Format
  const [selectFormat, setselectFormat] = useState("");
  const [downloadConfirm, setdownloadConfirm] = useState(false);
  // For Design Qr Code
  const [radiobtn, setradiobtn] = useState({
    qrOuterEyeType: true,
    qrInnerEyeType: true,
    backgroundColorType: true,
    qrBodyPatternType: true,
    frameColor: true,
  });

  // For Dynamic Qr Code
  const [settingForm, setsettingForm] = useState({
    qrCodeName: "",
    folderName: "",
    folderId: "",
    flyer: "",
    printRun: "",
    campaignStart: "",
    campaignEnd: "",
    numberOfScansStatus: false,
    numberOfScans: "",
    dateRangeStatus: false,
    dateRangeStart: "",
    dateRangeEnd: "",
    expiryURL: "",
    externalTrackerFacebook: false,
    externalTrackerGoogle: false,
    passwordStatus: false,
    password: "",
    exactGPSLocation: false,
    limitScanLocation: false,
    longitude: "",
    latitude: "",
    radiusInKM: "",
  });

  const getPurchasePlan = () => {
    loader(true);
    ApiClient.get(`active-subscription?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setactivePlan(res?.data);
      } else {
        setactivePlan("");
      }
      loader(false);
    });
  };

  const urlsQrCodeOnly = () => {
    if (
      qrTypesViewMore?.find((item) => item?.slug == slug.replaceAll("-", " "))
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getViewMoreQrCodeTypes();
    getPurchasePlan();
    getTypeDropdown();
    if (qrCodeSlug) {
      ApiClient.get(`qrcode/download?slug=${qrCodeSlug}`).then((res) => {
        if (res.success) {
          let step = localStorage.getItem("step");
          if (step == "2") {
            settab("second");
            localStorage.removeItem("step");
          }
          setform({
            ...res?.data?.design,
            value: res?.data?.qrCode_data,
            id: res?.data?.id,
            image: res?.data?.image,
            logotoggle: res?.data?.logotoggle,
            staticQrCode:!res?.data?.dynamicQrcode ? true : false
          });
          setframeForm(
            res?.data?.frames || {
              width: "250",
              height: "250",
              frame: "",
              qrFrameColor: "#030202",
              qrFrameColorGradient1: "",
              qrFrameColorGradient2: "",
              text: "SCAN ME",
              fontStyle: "",
              textColor: "#3c61e4",
            }
          );
          setsettingForm({
            ...res?.data?.setting,
            campaignStart: res?.data?.setting?.campaignStart
              ? new Date(res?.data?.setting?.campaignStart)
              : "",
            campaignEnd: res?.data?.setting?.campaignEnd
              ? new Date(res?.data?.setting?.campaignEnd)
              : "",
            dateRangeStart: res?.data?.setting?.dateRangeStart
              ? new Date(res?.data?.setting?.dateRangeStart)
              : "",
            dateRangeEnd: res?.data?.setting?.dateRangeEnd
              ? new Date(res?.data?.setting?.dateRangeEnd)
              : "",
          });
          if (
            slug == "urls" ||
            slug == "facebook" ||
            slug == "instagram" ||
            slug == "youtube" ||
            slug == "google-forms" ||
            slug == "docs" ||
            slug == "sheets" ||
            slug == "spotify" ||
            slug == "vimeo" ||
            slug == "apple-music" ||
            slug == "tiktok"
          ) {
            setcommon({ ...common, value1: res?.data?.urlData });
          } else if (slug == "whatsapp" || slug == "sms") {
            setcommon({
              ...common,
              value1: res?.data?.mobileNumber,
              value2: res?.data?.message,
            });
          } else if (slug == "wifi") {
            setcommon({
              ...common,
              value1: res?.data?.networkName,
              value2: res?.data?.encryptionType,
              value3: res?.data?.password,
              value4: res?.data?.networkHidden,
            });
          } else if (slug == "events") {
            setcommon({
              ...common,
              value1: res?.data?.title,
              value2: res?.data?.location,
              value3: res?.data?.startDate,
              value4: res?.data?.endDate,
            });
          } else if (slug == "call") {
            setcommon({ ...common, value1: res?.data?.mobileNumber });
          } else if (slug == "email") {
            setcommon({
              ...common,
              value1: res?.data?.emailAddress,
              value2: res?.data?.cc,
              value3: res?.data?.bcc,
              value4: res?.data?.subject,
              value5: res?.data?.message,
            });
          } else if (slug == "text") {
            setcommon({ ...common, value1: res?.data?.message });
          } else if (slug == "location" || slug == "maps") {
            setcommon({
              ...common,
              value1: res?.data?.location,
              value2: res?.data?.urlData,
              value3: res?.data?.latitude,
              value4: res?.data?.longitude,
            });
          } else if (slug == "telegram") {
            setcommon({ ...common, value1: res?.data?.userName });
          } else if (slug == "bitcoin") {
            setcommon({
              ...common,
              value1: res?.data?.address,
              value2: res?.data?.uri,
              value3: res?.data?.amount,
              value4: res?.data?.message,
            });
          } else if (slug == "vcard") {
            setvcard({
              ...vcard,
              firstName: res?.data?.firstName,
              lastName: res?.data?.lastName,
              personalMobile: res?.data?.personalMobile,
              personalHome: res?.data?.personalHome,
              personalFax: res?.data?.personalFax,
              workMobile: res?.data?.workMobile,
              workOffice: res?.data?.workOffice,
              workFax: res?.data?.workFax,
              personalEmail: res?.data?.personalEmail,
              workEmail: res?.data?.workEmail,
              website: res?.data?.website,
              company: res?.data?.company,
              jobPosition: res?.data?.jobPosition,
              street: res?.data?.street,
              city: res?.data?.city,
              zipCode: res?.data?.zipCode,
              state: res?.data?.state,
              country: res?.data?.country,
            });
          } else if (slug == "multiple-apps") {
            setcommon({
              ...common,
              value1: res?.data?.apple_app_store_ios,
              value2: res?.data?.google_play_store_android,
              value3: res?.data?.apple_app_store_for_iPad_only_ios,
              value4: res?.data?.galaxy_store_samsung,
              value5: res?.data?.microsoft_store_window_mobile,
              value6: res?.data?.app_gallery_huawei,
              value7: res?.data?.backup_link_for_other_device_browser,
            });
          } else if (slug == "smart-url") {
            setsmartURL({
              ...smartURL,
              ruleType: res?.data?.ruleType,
              defaultUrl: res?.data?.defaultUrl,
            });
            if (res?.data?.ruleType == "location") {
              setlocation(res?.data?.smartURLLocation);
            } else if (res?.data?.ruleType == "number_of_scans") {
              setnumberOfScans(res?.data?.smartURLNumberOfScans);
            } else if (res?.data?.ruleType == "time") {
              settime(
                res?.data?.smartURLTime.map((item) => {
                  return {
                    ...item,
                    startTime: item?.startTime ? new Date(item?.startTime) : "",
                    endTime: item?.endTime
                      ? new Date(item?.endTime || null)
                      : "",
                  };
                })
              );
            } else {
              setlanguage(res?.data?.smartURLLanguage);
            }
          } else if (slug == "paynow") {
            setcommon({
              ...common,
              value1: res?.data?.paynow?.type,
              value2: res?.data?.paynow?.uen_mobile,
              value3: res?.data?.paynow?.amount,
              value4: res?.data?.paynow?.edit_amount,
              value5: res?.data?.paynow?.reference,
              value6: res?.data?.paynow?.expiry_date,
              value7: res?.data?.paynow?.expiry_date_status,
              value8: res?.data?.paynow?.company,
            });
          } else if (urlsQrCodeOnly()) {
            setcommon({ ...common, value1: res?.data?.urlData });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (slug) {
      setform({
        value: "",
        staticQrCode:null,
        logoimg: "",
        logosize: "4",
        qrOuterEye: "square",
        qrInnerEye: "square",
        qrBodyPattern: "square",
        qrOuterEyeColor: "#030202",
        qrInnerEyeColor: "#030202",
        qrBodyPatternColor: "#030202",
        backgroundColor: "#ffffff",
        qrOuterEyeColorGradient1: "",
        qrOuterEyeColorGradient2: "",
        qrOuterEyeColorGradientType: "linear",
        qrInnerEyeColorGradient1: "",
        qrInnerEyeColorGradient2: "",
        qrInnerEyeColorGradientType: "linear",
        qrBodyPatternGradientColor1: "",
        qrBodyPatternGradientColor2: "",
        qrBodyPatternGradientColorType: "linear",
        backgroundGradientColor1: "",
        backgroundGradientColor2: "",
        backgroundGradientColorType: "linear",
        errorCorrection: "Q",
      });
      setsettingForm({
        qrCodeName: "",
        folderName: "",
        folderId: "",
        flyer: "",
        printRun: "",
        campaignStart: "",
        campaignEnd: "",
        numberOfScansStatus: false,
        numberOfScans: "",
        dateRangeStatus: false,
        dateRangeStart: "",
        dateRangeEnd: "",
        expiryURL: "",
        externalTrackerFacebook: false,
        externalTrackerGoogle: false,
        passwordStatus: false,
        password: "",
        exactGPSLocation: false,
        limitScanLocation: false,
        longitude: "",
        latitude: "",
        radiusInKM: "",
      });
      settab("first");
      setcommon({
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
        value8: "",
      });
      getSlugQrCodeDetail(slug?.replaceAll("-", " "));
    }
    // if (step == '1') {
    //     settab('first')

    // }
    if (slug == "multiple-apps" || slug == "smart-url" || slug == "file") {
      setform({ ...form, staticQrCode: false });
    }
    if (slug == "paynow") {
      setcommon({
        value1: true,
        value2: "",
        value3: "",
        value4: true,
        value5: "",
        value6: true,
        value7: "",
        value8: false,
        value9: "",
      });
    }
    if (slug == "sepa") {
      setcommon({
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
        value8: "",
      });
    }
    let formData = JSON.parse(localStorage.getItem("QrCodeDesign"));
    if (formData) {
      setform(formData);
      localStorage.removeItem("QrCodeDesign");
    }
    let commonData = JSON.parse(localStorage.getItem("commonState"));
    if (commonData) {
      setcommon(commonData);
      localStorage.removeItem("commonState");
    }
    let frameFormData = JSON.parse(localStorage.getItem("frameForm"));
    if (frameFormData) {
      setframeForm(frameFormData);
      localStorage.removeItem("frameForm");
    }
  }, [slug]);

  const getTypeDropdown = () => {
    let payload = { status: "active", sortBy: "type asc" };
    loader(true);
    ApiClient.get(`qrtype/all`, payload).then((res) => {
      if (res.success) {
        settypeDropdown(
          res.data.map((item) => {
            return { value: item?.slug, label: item?.type };
          })
        );
      }
      loader(false);
    });
  };

  const getSlugQrCodeDetail = (e) => {
    if (e) {
      loader(true);
      ApiClient.get(`qrtype?id=${e.replaceAll("-", " ")}`).then((res) => {
        if (res.success) {
          if (slug && slug != 'events' && slug != 'email' && slug != 'text' && slug != 'call' && slug != 'sms' && slug != "urls" && slug != "wifi" && slug != "smart-url" && slug != "file" && slug != "vcard" && slug != "multiple-apps") {
              setSlugDataImage(res?.data?.image)
          }else{
            setSlugDataImage('')
          }
          if(slug == "wifi" ){
            setform({...form,staticQrCode:true})
          }
          if(slug == "vcard" ){
            setform({...form,staticQrCode:false})
          }
          setslugData(res?.data)
        }
        loader(false);
      });
    }
  };

  const handleTypeDropdown = (e) => {
    if (e == "multiple apps" || e == "smart url" || e == "file") {
      if (activePlan?.planActive) {
        getSlugQrCodeDetail(e);
        history.push(`/app/qrcodes/${e.replaceAll(" ", "-")}`);
        setform({
          value: "",
          staticQrCode: true,
          logoimg: "",
          logosize: "4",
          qrOuterEye: "square",
          qrInnerEye: "square",
          qrBodyPattern: "square",
          qrOuterEyeColor: "#030202",
          qrInnerEyeColor: "#030202",
          qrBodyPatternColor: "#030202",
          backgroundColor: "#FFFFFF",
          qrOuterEyeColorGradient1: "",
          qrOuterEyeColorGradient2: "",
          qrOuterEyeColorGradientType: "linear",
          qrInnerEyeColorGradient1: "",
          qrInnerEyeColorGradient2: "",
          qrInnerEyeColorGradientType: "linear",
          qrBodyPatternGradientColor1: "",
          qrBodyPatternGradientColor2: "",
          qrBodyPatternGradientColorType: "linear",
          backgroundGradientColor1: "",
          backgroundGradientColor2: "",
          backgroundGradientColorType: "linear",
          errorCorrection: "H",
        });
        setcommon({
          value1: "",
          value2: "",
          value3: "",
          value4: "",
          value5: "",
          value6: "",
          value7: "",
          value8: "",
        });
        setvcard();
        setsmartURL({ ruleType: "location", defaultUrl: "" });
        setlocation([{ country: "", region: "", city: "", url: "" }]);
        setnumberOfScans([{ scans: "", url: "" }]);
        settime([{ timezone: "", startTime: "", endTime: "", url: "" }]);
        setlanguage([{ language: "", url: "" }]);
        if (e == "multiple apps" || e == "smart url" || e == "file") {
          setform({ ...form, staticQrCode: false });
        }
        return;
      } else {
        handleQrCode(false, "No");
        return;
      }
    } else {
      getSlugQrCodeDetail(e);
      history.push(`/app/qrcodes/${e.replaceAll(" ", "-")}`);
      setform({
        value: "",
        staticQrCode: true,
        logoimg: "",
        logosize: "4",
        qrOuterEye: "square",
        qrInnerEye: "square",
        qrBodyPattern: "square",
        qrOuterEyeColor: "#030202",
        qrInnerEyeColor: "#030202",
        qrBodyPatternColor: "#030202",
        backgroundColor: "#FFFFFF",
        qrOuterEyeColorGradient1: "",
        qrOuterEyeColorGradient2: "",
        qrOuterEyeColorGradientType: "linear",
        qrInnerEyeColorGradient1: "",
        qrInnerEyeColorGradient2: "",
        qrInnerEyeColorGradientType: "linear",
        qrBodyPatternGradientColor1: "",
        qrBodyPatternGradientColor2: "",
        qrBodyPatternGradientColorType: "linear",
        backgroundGradientColor1: "",
        backgroundGradientColor2: "",
        backgroundGradientColorType: "linear",
        errorCorrection: "H",
      });
      setcommon({
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
        value8: "",
      });
      setvcard();
      setsmartURL({ ruleType: "location", defaultUrl: "" });
      setlocation([{ country: "", region: "", city: "", url: "" }]);
      setnumberOfScans([{ scans: "", url: "" }]);
      settime([{ timezone: "", startTime: "", endTime: "", url: "" }]);
      setlanguage([{ language: "", url: "" }]);
    }
  };

  // View More Search Functions
  const handleQrType = (slugType, type) => {
    localStorage.setItem("qr", false);
    if (
      slugType == "multiple apps" ||
      slugType == "smart url" ||
      slugType == "file"
    ) {
      if (activePlan?.planActive && type == "viewMore") {
        setselectSlug(slugType);
        setform({ ...form, staticQrCode: false });
      } else {
        handleQrCode(false, "No");
      }
    } else {
      if (type == "viewMore") {
        setform({ ...form, staticQrCode: true });
        setselectSlug(slugType);
      } else {
        window.open(`/${slugType.replace(" ", "-")}`);
      }
    }
  };
  const getViewMoreQrCodeTypes = (p = {}) => {
    let payload = { ...p, status: "active" };
    loader(true);
    ApiClient.get(`qrtype/all`, payload).then((res) => {
      if (res.success) {
        setqrTypesViewMore(res.data);
      }
      loader(false);
    });
  };
  const handleViewMoreSearch = (e) => {
    setsearchViewMore(e);
    ApiClient.get(`qrtype/all?search=${e || "zzzz"}&status=active`).then(
      (res) => {
        if (res.success) {
          setViewMoreSuggestions(res?.data);
        }
      }
    );
    if (!e) getViewMoreQrCodeTypes({});
  };
  const selectViewMoreSearch = (type) => {
    getViewMoreQrCodeTypes({ search: type });
    setViewMoreSuggestions([]);
  };

  // Design Functions
  const resetDesign = () => {
    setform({
      ...form,
      logoimg: "",
      logosize: "4",
      qrOuterEye: "square",
      qrInnerEye: "square",
      qrBodyPattern: "square",
      qrOuterEyeColor: "#030202",
      qrInnerEyeColor: "#030202",
      qrBodyPatternColor: "#030202",
      backgroundColor: "#FFFFFF",
      qrOuterEyeColorGradient1: "",
      qrOuterEyeColorGradient2: "",
      qrOuterEyeColorGradientType: "linear",
      qrInnerEyeColorGradient1: "",
      qrInnerEyeColorGradient2: "",
      qrInnerEyeColorGradientType: "linear",
      qrBodyPatternGradientColor1: "",
      qrBodyPatternGradientColor2: "",
      qrBodyPatternGradientColorType: "linear",
      backgroundGradientColor1: "",
      backgroundGradientColor2: "",
      backgroundGradientColorType: "linear",
      errorCorrection: "M",
    });
    setframeForm({
      width: "250",
      height: "250",
      frame: "",
      qrFrameColor: "#030202",
      qrFrameColorGradient1: "",
      qrFrameColorGradient2: "",
      text: "SCAN ME",
      fontStyle: "",
      textColor: "#3c61e4",
    });
    setradiobtn({
      qrOuterEyeType: true,
      qrInnerEyeType: true,
      backgroundColorType: true,
      qrBodyPatternType: true,
      frameColor: true,
    });
  };

  // Tabs Function
  const handlePreviousTab = () => {
    if (tab == "first") {
      history.push(`/app/qrcodes`);
    } else if (tab == "second") {
      settab("first");
    } else {
      settab("second");
    }
  };

  const getFolders = () => {
    ApiClient.get(`folder/all?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setfolder(res?.data?.data);
        setTotalFolder(res?.data?.total);
      }
    });
  };

  useEffect(() => {
    getFolders();
  }, []);

  const handleCreateFolder = (e) => {
    e.preventDefault();
    let payload = {
      name: settingForm?.folderName,
    };
    loader(true);
    ApiClient.post(`folder`, payload).then((res) => {
      if (res.success) {
        HandleNewfolderclosemodal();
        toast.success(res.message);
        getFolders();
        setsettingForm({
          ...settingForm,
          folderId: res?.data?.id,
          folderName: res?.data?.name,
        });
      }
      loader(false);
    });
    document.getElementById("CloseFolderModal").click();
  };

  const handleFolderSelect = (e) => {
    let folderDetail = folder?.find((item) => item?._id == e);
    setsettingForm({
      ...settingForm,
      folderName: folderDetail?.name,
      folderId: e,
    });
  };

  const handleNextTab = () => {
    if (tab == "first") {
      console.log("innn")
      settab("second");
    } else if (tab == "second") {
      console.log("innn222222")
      if (form?.staticQrCode) {
        console.log("innn111")
        document.getElementById("OpenSelectFOlderMOdal").click();
        // saveQrCode('second')
      } else {
        console.log("innn2222")
        settab("third");
      }
    } else {
      if (!form?.staticQrCode && !settingForm?.qrCodeName) {
        toast.error("QR Code name is required");
        return;
      } if (settingForm?.limitScanLocation == true) {
        if (
          !settingForm?.radiusInKM ||
          !settingForm?.latitude ||
          !settingForm?.longitude
        ) {
          toast.error("All Geo-fencing fields is required");
        } 
      } if (settingForm?.dateRangeStatus || settingForm?.numberOfScansStatus ) {
        if (!settingForm?.expiryURL) {
          toast.error("Expiry URL fields is required");
        }else{
          setdownloadConfirm(true)
        }
      }
      // document.getElementById('OpenDownloadModal').click()
      else setdownloadConfirm(true);
      // saveQrCode('third')
    }
  };

  const handleQrCode = (check, modal) => {
    if (!check) {
      if (modal == "No") {
        if (activePlan?.planActive) {
          setform({ ...form, staticQrCode: check });
        } else {
          document.getElementById("OpenDynamicQrCode").click();
        }
      } else {
        setform({ ...form, staticQrCode: check });
        localStorage.setItem(
          "QrCodeDesign",
          JSON.stringify({ ...form, staticQrCode: check })
        );
        localStorage.setItem("commonState", JSON.stringify(common));
        localStorage.setItem("DynamicQrCode", urlPath);
        history.push(`/pricing`);
        document.getElementById("ClodeDynamicQrCode").click();
      }
    } else {
      setform({ ...form, staticQrCode: check });
    }
  };

  const validateForm = () => {
    let value = false;
    if (slug == "multiple-apps") {
      if (common?.value7) {
        value = false;
      } else {
        value = true;
      }
    } else if (slug == "smart-url") {
      if (smartURL?.defaultUrl) {
        value = false;
      } else {
        value = true;
      }
    } else {
      if (!form?.value || form?.staticQrCode == null) {
        value = true;
      } else {
        value = false;
      }
    }
    return value;
  };

  const HandleModalSubmit = (e) => {
    e.preventDefault();
    setdownloadConfirm(true);
  };
  useEffect(() => {
    if (!qr) {
      setform({
        logoimg: "",
        logosize: "4",
        qrOuterEye: "square",
        qrInnerEye: "square",
        qrBodyPattern: "square",
        qrOuterEyeColor: "#030202",
        qrInnerEyeColor: "#030202",
        qrBodyPatternColor: "#030202",
        backgroundColor: "#FFFFFF",
        qrOuterEyeColorGradient1: "",
        qrOuterEyeColorGradient2: "",
        qrOuterEyeColorGradientType: "linear",
        qrInnerEyeColorGradient1: "",
        qrInnerEyeColorGradient2: "",
        qrInnerEyeColorGradientType: "linear",
        qrBodyPatternGradientColor1: "",
        qrBodyPatternGradientColor2: "",
        qrBodyPatternGradientColorType: "linear",
        backgroundGradientColor1: "",
        backgroundGradientColor2: "",
        backgroundGradientColorType: "linear",
        errorCorrection: "M",
      });
    }
  }, [qr]);

  const HandleNewfolderopenmodal = () => {
    document.getElementById("clodefoldermodel").click();
    document.getElementById("openstaticmodal").click();
  };
  const HandleNewfolderclosemodal = () => {
    document.getElementById("OpenSelectFOlderMOdal").click();
  };
  useEffect(() => {
    const showPosition = (position) => {
      if (settingForm?.exactGPSLocation == true) {
        setsettingForm({
          ...settingForm,
          exactGPSLocation:true,
          limitScanLocation:false,
          // exactGPSLocationLat: String(position?.coords?.latitude),
          // exactGPSLocationLng: String(position?.coords?.longitude),
          latitude:"",
          longitude:"",
          radiusInKM:""
        });
      }
    };
    // if (settingForm?.exactGPSLocation) {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(showPosition);
    //   }
    // }
  }, [settingForm?.exactGPSLocation]);

  useEffect(() => {
    if (settingForm?.limitScanLocation == true) {
      setsettingForm({
        ...settingForm,
          limitScanLocation: true,
          exactGPSLocation: false,
        
      
      });
    }
  }, [settingForm?.limitScanLocation]);

  return (
    <>
      <Layout>
        <div className="qr-code_apps">
          <div className="container-fluid">
            {!slug ? (
              <div className="">
                <h4 className="fw-bold select-type mb-0  pb-3">
                  Select your QR Code Type
                </h4>
                <div className="bg-white border white-radius mb-3">
                  <div className="p-3 border-bottom">
                    <div className="search-or-code bg-white  p-0">
                      <div className="rowSearch">
                        <div className="col-md-6 div_9row px-0">
                          <div className="search-qr-code position-relative">
                            <input
                              value={searchViewMore}
                              onChange={(e) =>
                                handleViewMoreSearch(e.target.value)
                              }
                              className="form-control "
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                            />
                            <i className="fa fa-search search_pos"></i>
                            <div
                              className={`bg-white ${
                                ViewMoreSuggestions?.length != 0
                                  ? "search_barlist"
                                  : ""
                              }`}
                            >
                              {ViewMoreSuggestions &&
                                ViewMoreSuggestions.map((item) => {
                                  return (
                                    <div className="white_bx_bg">
                                      <div className="search_view_more">
                                        <p
                                          onClick={(e) =>
                                            selectViewMoreSearch(item?.type)
                                          }
                                          className="pointer p-2 mb-0"
                                        >
                                          <img
                                            src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                            className="setimg"
                                          />{" "}
                                          <span className="mb-0 textp text-capitalize ms-2">
                                            {" "}
                                            {item?.type}{" "}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`div9row2 ml-3 ${
                            !selectSlug ? "disable" : ""
                          }`}
                        >
                          <button
                            onClick={(e) => {
                              history.push(
                                `qrcodes/${selectSlug.replace(" ", "-")}`
                              ),
                                getSlugQrCodeDetail(selectSlug);
                            }}
                            disabled={!selectSlug}
                            className="btn btn-primary w-100"
                          >
                            Proceed{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_317_5936)">
                                <path
                                  d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_317_5936">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="padding_box">
                    {/* <!-- popular  and app-stories --> */}
                    <div className="popular-box pt-3 pl-3">
                      <div className="row">
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) => item?.category_details?.name == "popular"
                        ) ? (
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-12 col-lg-10">
                                <p className="mb-2 headp">Popular</p>
                                <div className="rowQr">
                                  {qrTypesViewMore &&
                                    qrTypesViewMore.map((item) => {
                                      if (
                                        item?.category_details?.name ==
                                        "popular"
                                      ) {
                                        return (
                                          <div
                                            className={`max_div py-2  ${
                                              selectSlug == item?.slug
                                                ? "scanner-active py-2"
                                                : ""
                                            }`}
                                          >
                                            <div
                                              onClick={(e) =>
                                                handleQrType(
                                                  item?.slug,
                                                  "viewMore"
                                                )
                                              }
                                              className={`link-item`}
                                            >
                                              <img
                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                className="setimg pointer mx-auto"
                                                alt="..."
                                              />
                                              <p className="mb-0 textp pointer text-capitalize">
                                                {item?.type}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) => item?.category_details?.name == "app stores"
                        ) ? (
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-12 col-lg-10">
                                <p className="mb-2 headp">App Stores</p>
                                <div className="rowQr">
                                  {qrTypesViewMore &&
                                    qrTypesViewMore.map((item) => {
                                      if (
                                        item?.category_details?.name ==
                                        "app stores"
                                      ) {
                                        return (
                                          <div
                                            className={`max_div py-2 ${
                                              selectSlug == item?.slug
                                                ? "scanner-active "
                                                : ""
                                            }`}
                                          >
                                            <div
                                              onClick={(e) =>
                                                handleQrType(
                                                  item?.slug,
                                                  "viewMore"
                                                )
                                              }
                                              className={`link-item`}
                                            >
                                              <img
                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                className="setimg pointer mx-auto"
                                                alt="..."
                                              />
                                              <p className="mb-0 textp pointer text-capitalize">
                                                {item?.type}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <!-- Social Media and Chat Apps --> */}
                    <div className="social pt-3 pl-3">
                      <div className="row">
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) =>
                            item?.category_details?.name == "social media"
                        ) ? (
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-12 col-lg-10">
                                <div className="social-app">
                                  <p className="mb-2 headp">Social Media</p>
                                  <div className="rowQr">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.map((item) => {
                                        if (
                                          item?.category_details?.name ==
                                          "social media"
                                        ) {
                                          return (
                                            <div
                                              className={`max_div py-2 ${
                                                selectSlug == item?.slug
                                                  ? "scanner-active "
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                onClick={(e) =>
                                                  handleQrType(
                                                    item?.slug,
                                                    "viewMore"
                                                  ) &
                                                  localStorage.removeItem("qr")
                                                }
                                                className={`link-item`}
                                              >
                                                <img
                                                  src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                  className="setimg pointer mx-auto"
                                                  alt="..."
                                                />
                                                <p className="mb-0 textp pointer text-capitalize">
                                                  {item?.type}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="col-2"></div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-6 col-lg-6">
                          <div className="row">
                            {qrTypesViewMore &&
                            qrTypesViewMore.find(
                              (item) =>
                                item?.category_details?.name == "chat apps"
                            ) ? (
                              <>
                                <div className="col-md-12 col-lg-10">
                                  <div className="social-app">
                                    <p className="mb-2 headp">Chat Apps</p>
                                    <div className="rowQr">
                                      {qrTypesViewMore &&
                                        qrTypesViewMore.map((item) => {
                                          if (
                                            item?.category_details?.name ==
                                            "chat apps"
                                          ) {
                                            return (
                                              <div
                                                className={`max_div py-2 ${
                                                  selectSlug == item?.slug
                                                    ? "scanner-active py-2"
                                                    : ""
                                                }`}
                                              >
                                                <div
                                                  onClick={(e) =>
                                                    handleQrType(
                                                      item?.slug,
                                                      "viewMore"
                                                    )
                                                  }
                                                  className={`link-item`}
                                                >
                                                  <img
                                                    src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                    className="setimg pointer mx-auto"
                                                    alt="..."
                                                  />
                                                  <p className="mb-0 textp pointer text-capitalize">
                                                    {item?.type}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-2"></div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Media and Google  --> */}
                    <div className="social pt-3 pl-3">
                      <div className="row">
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) => item?.category_details?.name == "media"
                        ) ? (
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-12 col-lg-10">
                                <div className="social-app">
                                  <p className="mb-2 headp">Media</p>
                                  <div className="rowQr">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.map((item) => {
                                        if (
                                          item?.category_details?.name ==
                                          "media"
                                        ) {
                                          return (
                                            <div
                                              className={`max_div py-2 ${
                                                selectSlug == item?.slug
                                                  ? "scanner-active "
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                onClick={(e) =>
                                                  handleQrType(
                                                    item?.slug,
                                                    "viewMore"
                                                  )
                                                }
                                                className={`link-item`}
                                              >
                                                <img
                                                  src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                  className="setimg pointer mx-auto"
                                                  alt="..."
                                                />
                                                <p className="mb-0 textp pointer text-capitalize">
                                                  {item?.type}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="col-2"></div>
                            </div>
                          </div>
                        ) : null}
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) => item?.category_details?.name == "google"
                        ) ? (
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-12 col-lg-10">
                                <div className="social-app">
                                  <p className="mb-2 headp">Google</p>
                                  <div className="rowQr">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.map((item) => {
                                        if (
                                          item?.category_details?.name ==
                                          "google"
                                        ) {
                                          return (
                                            <div
                                              className={`max_div py-2 ${
                                                selectSlug == item?.slug
                                                  ? "scanner-active "
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                onClick={(e) =>
                                                  handleQrType(
                                                    item?.slug,
                                                    "viewMore"
                                                  )
                                                }
                                                className={`link-item`}
                                              >
                                                <img
                                                  src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                  className="setimg pointer mx-auto"
                                                  alt="..."
                                                />
                                                <p className="mb-0 textp pointer text-capitalize">
                                                  {item?.type}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="col-2"></div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <!-- Phone and Payment --> */}
                    <div className="social pt-3 pl-3">
                      <div className="row">
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) => item?.category_details?.name == "phone"
                        ) ? (
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-12 col-lg-10">
                                <div className="social-app">
                                  <p className="mb-2 headp">Phone</p>
                                  <div className="rowQr">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.map((item) => {
                                        if (
                                          item?.category_details?.name ==
                                          "phone"
                                        ) {
                                          return (
                                            <div
                                              className={`max_div py-2 ${
                                                selectSlug == item?.slug
                                                  ? "scanner-active py-2"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                onClick={(e) =>
                                                  handleQrType(
                                                    item?.slug,
                                                    "viewMore"
                                                  )
                                                }
                                                className={`link-item`}
                                              >
                                                <img
                                                  src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                  className="setimg pointer mx-auto"
                                                  alt="..."
                                                />
                                                <p className="mb-0 textp pointer text-capitalize">
                                                  {item?.type}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="col-2"></div>
                            </div>
                          </div>
                        ) : null}
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) => item?.category_details?.name == "payment"
                        ) ? (
                          <div className="col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-md-12 col-lg-10">
                                <div className="social-app">
                                  <p className="mb-2 headp">Payment</p>
                                  <div className="rowQr">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.map((item) => {
                                        if (
                                          item?.category_details?.name ==
                                          "payment"
                                        ) {
                                          return (
                                            <div
                                              className={`max_div py-2 ${
                                                selectSlug == item?.slug
                                                  ? "scanner-active py-2"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                onClick={(e) =>
                                                  handleQrType(
                                                    item?.slug,
                                                    "viewMore"
                                                  )
                                                }
                                                className={`link-item`}
                                              >
                                                <img
                                                  src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                  className="setimg pointer mx-auto"
                                                  alt="..."
                                                />
                                                <p className="mb-0 textp pointer text-capitalize">
                                                  {item?.type}
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="col-2"></div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="layout_new">
                <div className="row">
                  <div className="col-md-12 col-lg-8">
                    <div className="header_tabs">
                      <div className="select_input mb-3">
                        {/* <p className="type_text">Type:</p> */}
                        {/* className="form-select select_url" */}
                        <Select
                          className="type-dropdown"
                          value={{
                            value: slugData?.slug,
                            label: slugData?.type,
                          }}
                          onChange={(e) => handleTypeDropdown(e?.value || "")}
                          isDisabled={qrCodeSlug}
                          options={typeDropdown}
                        />
                      </div>
                      <ul
                        className="nav nav-pills pills_tabs mb-3 mr-0"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            onClick={(e) => settab("first")}
                            className={`nav-link nav_link ${
                              tab == "first" ? "active" : ""
                            }`}
                            id="pills-1-tab"
                            data-toggle="pill"
                            data-target="#pills-1"
                            type="button"
                            role="tab"
                            aria-controls="pills-1"
                            aria-selected={`${
                              tab == "first" ? "true" : "false"
                            }`}
                          >
                            <span
                              className={`${
                                tab == "first" ? "number_btn" : "number_check"
                              }`}
                            >
                              {tab == "first" ? (
                                "1"
                              ) : (
                                <i className="fa fa-check fa-green"></i>
                              )}
                            </span>{" "}
                            Enter Data{" "}
                            <img
                              src="/assets/img/arrow_img .png"
                              className="arrow_img"
                            ></img>
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            onClick={(e) => settab("second")}
                            className={`nav-link nav_link ${
                              tab == "second" ? "active" : ""
                            }`}
                            id="pills-2-tab"
                            data-toggle="pill"
                            data-target="#pills-2"
                            type="button"
                            role="tab"
                            aria-controls="pills-2"
                            aria-selected={`${
                              tab == "second" ? "true" : "false"
                            }`}
                          >
                            <span
                              className={`${
                                tab != "third" ? "number_btn" : "number_check"
                              }`}
                            >
                              {tab != "third" ? (
                                "2"
                              ) : (
                                <i className="fa fa-check fa-green"></i>
                              )}
                            </span>
                            Design{" "}
                            {form?.staticQrCode ? null : (
                              <img
                                src="/assets/img/arrow_img .png"
                                className="arrow_img"
                              ></img>
                            )}
                          </button>
                        </li>
                        {form?.staticQrCode ? null : (
                          <li className="nav-item" role="presentation">
                            <button
                              onClick={(e) => settab("third")}
                              className={`nav-link nav_link ${
                                tab == "third" ? "active" : ""
                              }`}
                              id="pills-3-tab"
                              data-toggle="pill"
                              data-target="#pills-3"
                              type="button"
                              role="tab"
                              aria-controls="pills-3"
                              aria-selected={`${
                                tab == "third" ? "true" : "false"
                              }`}
                            >
                              <span className="number_btn">3</span>Settings
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 mb-3">
                    <div className="btns_tabs">
                      <button
                        type="button"
                        onClick={(e) => handlePreviousTab()}
                        className="btn btn-light light_btn"
                      >
                        <span className="material-icons mr-2">arrow_back</span>
                        Back
                      </button>
                      <button
                        type="button"
                        disabled={validateForm()}
                        onClick={(e) => handleNextTab()}
                        className={`btn btn-${
                          (form?.staticQrCode && tab != "second") ||
                          (!form?.staticQrCode && tab != "third")
                            ? "primary"
                            : "success"
                        } ml-3`}
                      >
                        {(form?.staticQrCode && tab != "second") ||
                        (!form?.staticQrCode && tab != "third")
                          ? "Next"
                          : "Complete"}
                        <span className="material-icons ml-2">
                          {(form?.staticQrCode && tab != "second") ||
                          (!form?.staticQrCode && tab != "third")
                            ? "arrow_forward"
                            : "check"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="tab_pages">
                  <div className="row">
                    <div className="col-md-12 col-lg-8 mb-3">
                      <div className="tabs_page">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className={`tab-pane fade ${
                              tab == "first" ? "show active" : ""
                            }`}
                            id="pills-1"
                            role="tabpanel"
                            aria-labelledby="pills-1-tab"
                          >
                            <div className="bg-white p-3 rounded-4">
                              <Forms
                                qrTypesViewMore={qrTypesViewMore}
                                slug={slug}
                                setform={setform}
                                form={form}
                                common={common}
                                setcommon={setcommon}
                                slugDataImage={slugDataImage}
                                slugData={slugData}
                                vcard={vcard}
                                setvcard={setvcard}
                                smartURL={smartURL}
                                setsmartURL={setsmartURL}
                                location={location}
                                setlocation={setlocation}
                                numberOfScans={numberOfScans}
                                setnumberOfScans={setnumberOfScans}
                                time={time}
                                settime={settime}
                                language={language}
                                setlanguage={setlanguage}
                              />
                              {slug == "wifi" ||
                              slug == "call" ||
                              slug == "text" ||
                              slug == "sms" ||
                              slug == "email" ||
                              slug == "location" ||
                              slug == "maps" ||
                              slug == "paynow" ? null : (
                                <div className="row">
                                  <div className="col-md-12 col-lg-5 mb-3">
                                    <label
                                      className={`form-check-label fw-bold w-100 ${
                                        slug == "multiple-apps" ||
                                        slug == "smart-url" ||
                                        slug == "file" ||
                                        qrCodeSlug
                                          ? "disable-box"
                                          : "pointer"
                                      }`}
                                    >
                                      <div
                                        className={`${
                                          form?.staticQrCode
                                            ? "activeurl"
                                            : "urlbtn"
                                        } height_box rounded-3 p-3`}
                                      >
                                        <div className="form-check ">
                                          <input
                                            disabled={
                                              slug == "multiple-apps" ||
                                              slug == "smart-url" ||
                                              slug == "file" ||
                                              slug == "vcard" ||
                                              qrCodeSlug || (activePlan?.staticQrcode == activeDataPlan?.static_qr_codes)
                                            }
                                            className="form-check-input"
                                            type="radio"
                                            checked={form?.staticQrCode == true}
                                            onClick={(e) =>
                                              handleQrCode(true, "No")
                                            }
                                            name="flexRadioDefault4"
                                          />
                                          <h6 className="fw-bold">
                                            Static QR Code
                                          </h6>
                                          <ul className="list-group list-gp-btn">
                                            <li className="list-group-item fw-normal li-green">
                                              100% free forever
                                            </li>
                                            <li className="list-group-item fw-normal li-close">
                                              Cannot edit data once created
                                            </li>
                                            <li className="list-group-item fw-normal li-close">
                                              {" "}
                                              Cannot track scans
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </label>
                                    {(activePlan?.staticQrcode == activeDataPlan?.static_qr_codes && activePlan?.dynamicQrcode != activeDataPlan?.dynamic_qr_code) && <div style={{color:'red'}}>Your Static Qr Code Limit Exceed.</div>}
                                  </div>
                                  <div className="col-md-12 col-lg-7 mb-3">
                                    <label className="form-check-label fw-bold w-100">
                                      <div
                                        className={`${
                                          form?.staticQrCode == false
                                            ? "activeurl"
                                            : "urlbtn"
                                        } height_box rounded-3 p-3 pointer`}
                                      >
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            disabled={activePlan?.dynamicQrcode == activeDataPlan?.dynamic_qr_code ? true : false}
                                            checked={form?.staticQrCode == false ? true : false}
                                            onClick={(e) =>
                                              handleQrCode(false, "No")
                                            }
                                            name="flexRadioDefault4"
                                          />
                                          <h6 className="fw-bold  ">
                                            Dynamic QR Code
                                          </h6>
                                          <ul className="list-group list-gp-btn mt-2">
                                            <li className="list-group-item fw-normal li-green">
                                              Edit data or change QR type even
                                              after its printed
                                            </li>
                                            <li className="list-group-item fw-normal li-green">
                                              Track analytics; number of scans,
                                              time, location, device and more
                                            </li>
                                            <li className="list-group-item fw-normal li-green">
                                              {" "}
                                              Redirect users based on your rules
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </label>
    {(activePlan?.staticQrcode != activeDataPlan?.static_qr_codes && activePlan?.dynamicQrcode == activeDataPlan?.dynamic_qr_code) && <div style={{color:'red'}}>Your Dynamic Qr Code Limit Exceed.</div>}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className={`tab-pane fade ${
                              tab == "second" ? "show active" : ""
                            }`}
                            id="pills-2"
                            role="tabpanel"
                            aria-labelledby="pills-2-tab"
                          >
                            <div className="bg-white rounded-4 p-3">
                              <p className="fw-bold">
                                STEP 2: Design your QR Code{" "}
                                <span className="fw-normal"></span>
                                <span
                                  className="text-primary fw-bold float-right pointer"
                                  onClick={(e) => resetDesign()}
                                >
                                  Reset Design{" "}
                                  <img
                                    src="/assets/img/reset.png"
                                    className=" img-fluid ms-3 "
                                    alt="..."
                                  />{" "}
                                </span>
                              </p>
                              {/* tabs-section */}
                              <ul
                                className="nav nav-pills mb-4 tab-pills pills_row"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link active"
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                  >
                                    {" "}
                                    <div className="d-flex flex-column  gap-3 ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_168_400)">
                                          <path
                                            d="M12.0006 10C8.5306 10 5.6406 12.54 5.0906 15.86C4.9906 16.46 5.4806 17 6.0906 17C6.5806 17 6.9906 16.64 7.0706 16.15C7.4806 13.79 9.5306 12 12.0006 12C14.4706 12 16.5206 13.79 16.9306 16.15C17.0106 16.64 17.4206 17 17.9106 17C18.5206 17 19.0006 16.46 18.9006 15.86C18.3606 12.54 15.4706 10 12.0006 10ZM12.0006 6C6.3006 6 1.6106 10.34 1.0506 15.9C1.0006 16.49 1.4606 17 2.0606 17C2.5706 17 3.0006 16.62 3.0506 16.12C3.4906 11.57 7.3406 8 12.0006 8C16.6606 8 20.5106 11.57 20.9606 16.12C21.0106 16.62 21.4406 17 21.9506 17C22.5406 17 23.0106 16.49 22.9506 15.9C22.3906 10.34 17.7006 6 12.0006 6Z"
                                            fill="#212121"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_168_400">
                                            <rect
                                              width="24"
                                              height="24"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      Logo{" "}
                                    </div>
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    <div className="d-flex flex-column  gap-3 ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_168_408)">
                                          <path
                                            d="M12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C12.83 21 13.5 20.33 13.5 19.5C13.5 19.11 13.35 18.76 13.11 18.49C12.88 18.23 12.73 17.88 12.73 17.5C12.73 16.67 13.4 16 14.23 16H16C18.76 16 21 13.76 21 11C21 6.58 16.97 3 12 3ZM6.5 12C5.67 12 5 11.33 5 10.5C5 9.67 5.67 9 6.5 9C7.33 9 8 9.67 8 10.5C8 11.33 7.33 12 6.5 12ZM9.5 8C8.67 8 8 7.33 8 6.5C8 5.67 8.67 5 9.5 5C10.33 5 11 5.67 11 6.5C11 7.33 10.33 8 9.5 8ZM14.5 8C13.67 8 13 7.33 13 6.5C13 5.67 13.67 5 14.5 5C15.33 5 16 5.67 16 6.5C16 7.33 15.33 8 14.5 8ZM17.5 12C16.67 12 16 11.33 16 10.5C16 9.67 16.67 9 17.5 9C18.33 9 19 9.67 19 10.5C19 11.33 18.33 12 17.5 12Z"
                                            fill="#212121"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_168_408">
                                            <rect
                                              width="24"
                                              height="24"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      Pattern & Colors{" "}
                                    </div>
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link"
                                    id="pills-contact-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-contact"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected="false"
                                  >
                                    <div className="d-flex flex-column gap-3">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_168_415)">
                                          <path
                                            d="M20 3.99998H16L12.71 0.70998C12.32 0.31998 11.69 0.31998 11.3 0.70998L8 3.99998H4C2.9 3.99998 2 4.89998 2 5.99998V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V5.99998C22 4.89998 21.1 3.99998 20 3.99998ZM19 20H5C4.45 20 4 19.55 4 19V6.99998C4 6.44998 4.45 5.99998 5 5.99998H8.52L12.04 2.49998L15.52 5.99998H19C19.55 5.99998 20 6.44998 20 6.99998V19C20 19.55 19.55 20 19 20ZM17 7.99998H7C6.45 7.99998 6 8.44998 6 8.99998V17C6 17.55 6.45 18 7 18H17C17.55 18 18 17.55 18 17V8.99998C18 8.44998 17.55 7.99998 17 7.99998Z"
                                            fill="#212121"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_168_415">
                                            <rect
                                              width="24"
                                              height="24"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      Frame{" "}
                                    </div>
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link"
                                    id="pills-disabled-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-disabled"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-disabled"
                                    aria-selected="false"
                                  >
                                    <div className="d-flex flex-column  gap-3">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_168_422)">
                                          <path
                                            d="M19.5803 3.08L3.15031 19.51C3.24031 19.85 3.42031 20.16 3.66031 20.41C3.91031 20.65 4.22031 20.83 4.56031 20.92L21.0003 4.49C20.8103 3.8 20.2703 3.26 19.5803 3.08ZM11.9503 3L3.07031 11.88V14.71L14.7803 3H11.9503ZM5.07031 3C3.97031 3 3.07031 3.9 3.07031 5V7L7.07031 3H5.07031ZM19.0703 21C19.6203 21 20.1203 20.78 20.4803 20.41C20.8503 20.05 21.0703 19.55 21.0703 19V17L17.0703 21H19.0703ZM9.36031 21H12.1903L21.0703 12.12V9.29L9.36031 21Z"
                                            fill="#212121"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_168_422">
                                            <rect
                                              width="24"
                                              height="24"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      Template{" "}
                                    </div>
                                  </button>
                                </li>
                              </ul>
                              <div
                                className="tab-content"
                                id="pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="pills-home"
                                  role="tabpanel"
                                  aria-labelledby="pills-home-tab"
                                  tabindex="0"
                                >
                                  <HomeLogo setform={setform} form={form} />
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="pills-profile"
                                  role="tabpanel"
                                  aria-labelledby="pills-profile-tab"
                                  tabindex="0"
                                >
                                  <PatternColors
                                    setform={setform}
                                    form={form}
                                    setradiobtn={setradiobtn}
                                    radiobtn={radiobtn}
                                  />
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="pills-contact"
                                  role="tabpanel"
                                  aria-labelledby="pills-contact-tab"
                                  tabindex="0"
                                >
                                  <Frames
                                    frameForm={frameForm}
                                    setframeForm={setframeForm}
                                    radiobtn={radiobtn}
                                    setradiobtn={setradiobtn}
                                  />
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="pills-disabled"
                                  role="tabpanel"
                                  aria-labelledby="pills-disabled-tab"
                                  tabindex="0"
                                >
                                  <TemplatesDesign
                                    setform={setform}
                                    form={form}
                                    setframeForm={setframeForm}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`tab-pane fade ${
                              tab == "third" ? "show active" : ""
                            }`}
                            id="pills-3"
                            role="tabpanel"
                            aria-labelledby="pills-3-tab"
                          >
                            <DynamicQrCode
                              settingForm={settingForm}
                              setsettingForm={setsettingForm}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <div className="section_background">
                        <p className="p_sec">
                          Scan your QR code to confirm that it works
                        </p>
                        <QrCode
                          slugData={slugData}
                          slugDataImage={slugDataImage}
                          qrTypesViewMore={qrTypesViewMore}
                          frameForm={frameForm}
                          settingForm={settingForm}
                          smartURL={smartURL}
                          location={location}
                          numberOfScans={numberOfScans}
                          time={time}
                          language={language}
                          setqrCodeImage={setqrCodeImage}
                          qrCodeImage={qrCodeImage}
                          form={form}
                          setform={setform}
                          common={common}
                          slug={slug}
                          urlPath={urlPath}
                          selectFormat={selectFormat}
                          downloadConfirm={downloadConfirm}
                          setdownloadConfirm={setdownloadConfirm}
                          setselectFormat={setselectFormat}
                          vcard={vcard}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Name and folder Modal for static */}
        <button
          id="OpenSelectFOlderMOdal"
          type="button"
          class="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#renamefolder"
        >
          Launch demo modal
        </button>
        <div
          class="modal fade"
          id="renamefolder"
          tabindex="-1"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="exampleModalLabel">Name and Folder</h5>
              </div>
              <div class="modal-body">
                <form onSubmit={(e) => HandleModalSubmit(e)}>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Name of QR Code <span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        value={settingForm?.qrCodeName}
                        onChange={(e) =>
                          setsettingForm({
                            ...settingForm,
                            qrCodeName: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="e.g. Summer campaign"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Folder
                      </label>
                      <select
                        value={settingForm?.folderId}
                        onChange={(e) => handleFolderSelect(e.target.value)}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value={""}>Select Folder</option>
                        {folder &&
                          folder?.map((itm) => {
                            return (
                              <option value={itm?._id}>{itm?.name}</option>
                            );
                          })}
                      </select>
                    </div>

                    <div className="col-md-6 mb-4">
                      <label></label>

                      <h3
                        className="new_folder pointer"
                        onClick={(e) =>
                          totalFOlder < activePlan?.max_folders
                            ? HandleNewfolderopenmodal()
                            : toast.error(
                                "Your Folder Plan Limit has been reached"
                              )
                        }
                      >
                        New Folder{" "}
                        <span className="material-icons file_icon">
                          create_new_folder
                        </span>
                      </h3>
                    </div>
                  </div>
                  <hr />
                  {/* <div className="modal-footer"> */}
                  <div class="btns_tabs">
                    <button
                      type="button"
                      id="clodefoldermodel"
                      class="btn btn-light light_btn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary ml-3">
                      Complete
                      <span className="material-icons  ml-2">check</span>
                    </button>
                  </div>
                  {/* </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* New Folder Modal */}
        <button
          type="button"
          class="btn btn-primary d-none"
          id="openstaticmodal"
          data-bs-toggle="modal"
          data-bs-target="#opennewfoldermodal1"
        >
          Opennewfoldermodal
        </button>
        <div
          className="modal fade"
          id="opennewfoldermodal1"
          tabindex="-1"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <form onSubmit={(e) => handleCreateFolder(e)}>
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Create New Folder
                  </h1>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label">Folder Name</label>
                      <input
                        value={settingForm?.folderName}
                        onChange={(e) =>
                          setsettingForm({
                            ...settingForm,
                            folderName: e.target.value,
                          })
                        }
                        maxLength="20"
                        type="text"
                        className="form-control"
                        placeholder="Folder Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={(e) => HandleNewfolderclosemodal()}
                    className="btn btn-light light_btn"
                    id="CloseFolderModal"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/*  */}
        {/* Select Download Format Modal */}
        <button
          type="button"
          id="OpenDownloadModal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#downloadQrImageModal"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="downloadQrImageModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setselectFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      selectFormat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setselectFormat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setselectFormat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseDownloadModal"
                  onClick={(e) => setselectFormat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    !selectFormat ? "disable" : ""
                  }`}
                  disabled={!selectFormat}
                  onClick={(e) => setdownloadConfirm(true)}
                >
                  Confirm <i className="fa fa-check text-light ml-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Dynamic Qr Code Modal */}
        <button
          type="button"
          className="btn btn-primary d-none"
          id="OpenDynamicQrCode"
          data-bs-toggle="modal"
          data-bs-target="#OpenModalForDynamicQrCode"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="OpenModalForDynamicQrCode"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered dynamic_modal">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <h4>For Dynamic Qr Code you need to purchase plan</h4>
                </div>
                <div className="mt-4 text-center">
                  <button
                    type="button"
                    className="btn btn-secondary mr-2"
                    id="ClodeDynamicQrCode"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={(e) => handleQrCode(false, "Yes")}
                    className="btn btn-primary ml-2"
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default QrCodes;
