import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../methods/api/apiClient";
import loader from "../methods/loader";
import vcardLinkedinParser from "vcard-linkedin-parser";
function VcardLandingPage() {
  const { slug } = useParams();
  const [Data, setData] = useState({});
  const [form, setform] = useState({});
  const GetVcardData = () => {
    loader(true);

    ApiClient.get(`qrcode/download?slug=${slug}`).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
    });
    loader(false);
  };
  
  useEffect(() => {
    GetVcardData();
  }, [slug]);
  return (
    <div>
      <div className="container">
        <div className="vcard_page mt-5 mb-3">
          <div
            className="banner"
            style={{
              backgroundImage: `url("/assets/img/background_banner.jpg")`,
            }}
          >
            <div className="banner_back">
              <img src="/assets/img/dummy-profile.jpg"></img>
              <div className="vcard_name">
                <h2>Alex Smith</h2>
                <p>Web design</p>
              </div>
            </div>
            <p></p>
          </div>
          <div className="layout_vcard">
            <div className="row">
              <div className="col-lg-7 mb-3">
                <h2>
                  About <span className="text_blue">Me</span>
                </h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="col-lg-5">
                <div className="flex_vcard">
                  <p>Age</p>
                  <span>25</span>
                </div>
                <div className="flex_vcard">
                  <p>Residence</p>
                  <span className="text_detail">USA</span>
                </div>
                <div className="flex_vcard">
                  <p>Address</p>
                  <span className="text_detail">USA</span>
                </div>
                <div className="flex_vcard">
                  <p>Email</p>
                  <a href="#">alex123@gmail.com</a>
                </div>
                <div className="flex_vcard">
                  <p>Phone</p>
                  <span className="text_detail">+0123 123 456 789</span>
                </div>
              </div>
              <div className="col-lg-12">
                <a download={Data?.qrCode_data} href={Data?.qrCode_data}>
                  <button
                    onClick={() => {
                      var encodedData = encodeURIComponent(Data?.qrCode_data);
                      var dataUri =
                        "data:text/vcard;charset=utf-8," + encodedData;

                      var newWindow = window.open(dataUri, "_blank");
                    }}
                    className="btn btn_primary"
                  >
                    {" "}
                    Download Vcard
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <a download={Data?.qrCode_data} href={Data?.qrCode_data}>
          <button
            onClick={() => {
              var encodedData = encodeURIComponent(Data?.qrCode_data);
              var dataUri = "data:text/vcard;charset=utf-8," + encodedData;

              var newWindow = window.open(dataUri, "_blank");
            }}
            className="btn btn-primary"
          >
            {" "}
            Download Vcard
          </button>
        </a>
      </div> */}
    </div>
  );
}

export default VcardLandingPage;
