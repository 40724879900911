import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import { ToastContainer, toast } from "react-toastify";
import { active_Plan, deactive_Plan } from "../../actions/activePlan";
import methodModel from "../../methods/methods";

const Login = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [eyes, setEyes] = useState({ password: false });
  const redirectURL = localStorage.getItem("AfterLogin");

  useEffect(() => {
    const id = methodModel.getPrams("id");
    if (localStorage.getItem("token")) {
      history.push("/app/qrcodes");
    }
    if (id) {
      handleAutoLogin(id);
    }
  }, []);

  const handleAutoLogin = (userId) => {
    ApiClient.post(`user/auto-login`, { id: userId }).then((res) => {
      if (res.success) {
        localStorage.setItem("token", res.data.access_token);
        dispatch(login_success(res.data));
        getActivePlanDetail(res?.data);
        setTimeout(() => {
          toast.success("Successfully Login");
        }, 100);
        // if (redirectURL) history.push(redirectURL);
        // else history.push("/app/qrcodes");

        history.push("/app/qrcodes");
      }
    });
  };

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password,
    };
    loader(true);
    ApiClient.post("user/signin", data).then((res) => {
      loader(false);
      if (res.success) {
        localStorage.setItem("token", res.data.access_token);
        dispatch(login_success(res.data));
        getActivePlanDetail(res?.data);
        setTimeout(() => {
          toast.success("Successfully Login");
        }, 100);
        // if (redirectURL) history.push(redirectURL);
        // else history.push("/app/qrcodes");
        if (localStorage.getItem("BulkQR")) {
          history.push("/app/bulkqr");
        } else {
          history.push("/app/qrcodes");
        }
      }
    });
  };

  const getActivePlanDetail = (data) => {
    ApiClient.get(`active-subscription?user_id=${data?.id}`).then((res) => {
      if (res.success) {
        dispatch(active_Plan(res?.data));
      } else {
        dispatch(deactive_Plan());
      }
    });
  };

  return (
    <>
      <div className="m_login">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8 mx-auto">
              <div className="login-bx bg-white">
                <a className="navbar-brand">
                  <img
                    onClick={() => history.push("/urls")}
                    src="./assets/img/favicon_2.png"
                    width="150px"
                    className="mb-2"
                  />
                </a>
                <h5 className="fw-bold text-center mb-3">
                  Log in to your account
                </h5>
                <form onSubmit={hendleSubmit}>
                  <div className="mb-3 text-start">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-left"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="mb-3 text-start inputWrapper">
                    <label for="exampleInputPassword1" className="form-label">
                      Password
                    </label>
                    <input
                      type={eyes.password ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      required
                    />
                    <i
                      className={
                        eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                      onClick={() =>
                        setEyes({ ...eyes, password: !eyes.password })
                      }
                    ></i>
                  </div>
                  <div className="mb-3 text-start ">
                    <Link
                      to="/forgotpassword"
                      className="form-check-label m-sign fw-bold "
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Login
                  </button>
                  {/* <div className="or-bx text-center my-3">Or</div>
                  <button type="button" className="border-1 rounded py-2 bg-transparent w-100">  <img src="./assets/img/gogle.png " className="card-img-top m_img" alt="..." /> Continue with Google</button> */}
                </form>
                <div className="d-flex align-items-center gap-2 mt-4 justify-content-center">
                  <h6 className="h6_login text-center mb-0">
                    Don’t have an account yet?
                  </h6>{" "}
                  <Link className="m-sign fw-bold" to={`/signup`} role="button">
                    Sign Up Free
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default Login;
