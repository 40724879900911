import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./style.scss";

const HomeLogo = ({ setform, form }) => {
  const { slug } = useParams();
  const urlPath = window.location.pathname;
  const [myLogos, setmyLogos] = useState([]);
  const [allLogos, setallLogos] = useState([]);
  const [imageName, setimageName] = useState();
  const [logotoggle, setlogotoggle] = useState(false);

  useEffect(() => {
    getMyLogos();
    getAllLogogs();
  }, []);

  const getMyLogos = () => {
    ApiClient.get(`logo/all?status=active&slug=${slug || "urls"}`).then(
      (res) => {
        if (res.success) {
          setmyLogos(res?.data?.data);
        }
      }
    );
  };

  const getAllLogogs = () => {
    ApiClient.get(`logo/all?status=active`).then((res) => {
      if (res.success) {
        setallLogos(res?.data?.data);
      }
    });
  };

  const uploadImage = (e, type) => {
    let file = "";
    if (type == "dragDrop") {
      file = e;
    } else {
      setform({ ...form, baseImg: e.target.value });
      let files = e.target.files;
      file = files.item(0);
    }
    loader(true);
    ApiClient.postFormData("upload/image?modelName=logo", {
      file: file,
      modelName: "logo",
    }).then((res) => {
      if (res.success) {
        let image = res.data.fullpath;
        setform({ ...form, logoimg: image, baseImg: "" });
        if (res?.data?.originalFileName) {
          setimageName(res?.data?.originalFileName);
        }
      } else {
        setform({ ...form, baseImg: "" });
      }
      loader(false);
    });
  };

  // Drop Image Function
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    const file = files[0];
    uploadImage(file, "dragDrop");
  };

  return (
    <>
      <p className="text-dark fw-normal my-3">
        <img
          src="/assets/img/lightbulb_.png"
          className="img-fluid me-2"
          alt="..."
        />
        Add your logo to build brand recognition
      </p>
      <div className="row ">
        <div
          className={`col-${form?.logoimg ? "12" : "12"}`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label className="w-100 pointer">
            <div className="browser-div text-center p-2 rounded-3 d-flex flex-column justify-content-center gap-2">
              <p className="text-secondary fw-normal mb-0">
                Drag and drop to upload or
              </p>
              <p className="text-primary fw-bold mb-0">
                {" "}
                Browse{" "}
                <img
                  src="/assets/img/browser.png"
                  className=" img-fluid ms-3"
                  alt="..."
                />
              </p>
            </div>
            <input
              type="file"
              className="d-none"
              accept="image/*"
              value={form?.baseImg || ""}
              onChange={(e) => {
                uploadImage(e, "uploadFile");
              }}
            />
          </label>
          <label className="fw-normal">
            Upload formats:<span className="fw-bold"> PNG, JPG, SVG</span>
          </label>
        </div>
        {form?.logoimg ? (
          <div className="col-md-12">
            <div className="upload-img-div text-centerrounded-3 d-flex flex-column justify-content-center alex-text">
              {/* <img src="/assets/img/images.png" className="img-bg-set  " alt="..." /> */}
              <img
                src={`${environment?.api}images/logo/${form?.logoimg}`}
                className="img-bg-set"
              />
              <p className="text-dark fw-bold mb-0 mt-2 text-center d-flex">
                {" "}
                {imageName || ""}{" "}
                <img
                  src="/assets/img/delete_forever.png"
                  onClick={(e) => setform({ ...form, logoimg: "" })}
                  className="img-fluid pointer"
                  alt="..."
                />
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="row">
        <div className="col-9">
          <div className="slidecontainer" class="">
            <h6 className="">
              Logo size{" "}
              <input
                type="range"
                min="1"
                max="5"
                value={form?.logosize}
                onChange={(e) => setform({ ...form, logosize: e.target.value })}
                className=" ms-3 slider-input"
                id="myRange"
              />
            </h6>
          </div>
        </div>
        <div class=" form-switch col-3">
          <input
            class="form-check-input"
            type="checkbox"
            id="backgroundToggle"
            checked={form?.logotoggle}
            onChange={(e) => setform({ ...form, logotoggle: e.target.checked })}
          />
          <label class="form-check-label" for="backgroundToggle">
            {" "}
            Logo Background
          </label>
        </div>
        <h6 className="mt-4">Or use any of these free logos</h6>
        <div className="row_to">
          {allLogos &&
            allLogos.map((item) => {
              return (
                <div className="col_box">
                  <div
                    className={`grid-box ${form?.logoimg == item?.image ? "active" : ""
                      }`}
                  >
                    <img
                      src={`${environment?.api}images/logo/${item?.image}`}
                      width="50px"
                      onClick={(e) =>
                        setform({ ...form, logoimg: item?.image })
                      }
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <h6 className="mt-4">Recommended Logos</h6>
        <div className="row">
          {myLogos &&
            myLogos.map((item) => {
              return (
                <div className="col_box">
                  <div
                    className={`grid-box ${form?.logoimg == item?.image ? "active" : ""
                      }`}
                  >
                    <img
                      src={`${environment?.api}images/logo/${item?.image}`}
                      width="50px"
                      onClick={(e) =>
                        setform({ ...form, logoimg: item?.image })
                      }
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default HomeLogo;
