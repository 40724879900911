import React, { useEffect, useState } from "react";
import Header from "../../components/global/header";
import "./blogstyle.scss";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import environment from "../../environment";
import moment from "moment";

const BlogDetail = () => {
  const Navigate=useHistory();
  const {id}=useParams();
  const [BlogData,setBlogData]=useState({});

  useEffect(()=>{
if(id){
  loader(true)
  ApiClient.get(`blog?id=${id}`).then(Res=>{
    if(Res.success){
    setBlogData(Res.data)
  }
  loader(false);
})
}
  },[])
  const BlogImageHandler=(image)=>{
    return image==""?"/assets/img/qr-images/blog.jpeg":environment.api+"images/blog/"+image
  }
  return (
    <>
      <Header />
      <section className="blogs-detailpage">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="qr-code-generate">
                <div className="free-qr-code">
                  <h6 className="sixth-head">
                    Create static QR Codes for FREE with QR BUBBLE
                  </h6>
                  <a href="#" className="btn light-btn">
                    Generate QR Code
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="blog-content-page">
                <div className="back-btn">
                  <a onClick={e=>Navigate.goBack()}>
                    <img src="/assets/img/qr-images/arrow.svg" />
                    Back
                  </a>
                </div>
                <div className="blog-title">
                  <h3 className="third-head">
                    {BlogData.heading}
                  </h3>
                  <div className="blog-author">
                    <p>Written by {BlogData.author}</p>
                    <p>{moment(BlogData.createdAt).format('MMM Do, YYYY')}</p>
                  </div>
                  <img
                    src={`${BlogImageHandler(BlogData?.image)}`}
                    alt="blog"
                    className="img-fluid"
                  />
                </div>
                <div className="blog-pointers">
                  <p className="mb-3" dangerouslySetInnerHTML={{ __html: BlogData.description }}></p>
                  {/* <p>
                    Distance should not hinder presence. Use a QR code for
                    virtual tours and allow everyone to reach places they’ve
                    never been—even if they can’t be there physically.
                  </p>
                  <p>
                    This QR code solution lets users visit and explore an
                    establishment or a building virtually. It’s highly suitable
                    and efficient for real estate agents looking to sell
                    properties.
                  </p>
                  <p>
                    People don’t have to be in the actual location to have a
                    closer look at the place; they can now check it out without
                    going through traffic or messing with schedule conflicts.
                  </p>
                  <p>
                    The best thing about this solution is its flexibility for
                    other uses apart from real estate viewing—it’s also a good
                    choice for entertainment, tourism, and even education.
                  </p>
                  <p>
                    You can create this using the best QR code generator in only
                    seven simple steps. Keep reading below to learn how.
                  </p>
                  <h3 className="third-head">
                    Virtual tour QR code: What is it, and how does it work?
                  </h3>
                  <p>
                    A virtual tour or video QR code is an innovative tool that
                    provides clients and customers with a tour of a specific
                    location or establishment without being physically there.
                  </p>
                  <p>
                    Photos may fail to provide a comprehensive view of the
                    property, leaving clients desiring more details.
                  </p>
                  <p>
                    But with a QR code for virtual tours, they’ll be able to
                    have a closer look, especially at the property’s structure
                    and other intricate details.
                  </p>
                  <p>
                    There are two solutions that you can use in sharing a
                    virtual tour:
                  </p>
                  <h3 className="third-head">URL QR code</h3>
                  <p>
                    The URL QR code solution can hold a link leading to a
                    website with a virtual tour. You may also use it for
                    real-time virtual tours via video conferencing like Google
                    Meet or Zoom.
                  </p>
                  <p>
                    You can create a URL QR code so participants can instantly
                    join the call. This eliminates the struggle with manually
                    inputting meeting IDs and passwords.
                  </p>
                  <p>
                    Sharing a dynamic URL QR code for Zoom or Google Meet that
                    your guests can quickly scan is also much more convenient
                    than sharing the link itself, which is a hassle as
                    participants still need to do the copy-paste method
                  </p> */}
                  {/* <div className="box"></div> */}
                  <div className="blogs-right-content">
                    <h6 className="sixth-head">Subscribe now</h6>
                    <div className="d-flex align-items-center bottom-subscribe">
                      <div className="subscribe-option">
                        <p className="common-parah">
                          Sign up for our newsletter and be the first to hear
                          about promos, updates, and tips
                        </p>
                      </div>
                      <div className="form-wrapper bar_div">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your email"
                          className="form-control inputbar"
                        />
                        <button type="submit" className="btn   blue-btn">
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="qr-code-generate bg-yellow">
                <div className="free-qr-code mb-3">
                  <h6 className="sixth-head">
                    Create static QR Codes for FREE with QR BUBBLE
                  </h6>
                  <a href="#" className="btn light-btn">
                    Generate QR Code
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetail;
