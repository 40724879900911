import React from "react";
import Footer from "../../components/global/Footer";

const HomeDetail = () => {
  return (
    <>
      <div className="home_detail">
        <div className="container">
          <div className="sections_1">
            <h1 className="main_heading">
              Trusted by top brands around the world
            </h1>
            {/* <div className="image_rowdiv">
                            <img src="/assets/img/1amazon-logo.png"></img>
                            <img src="/assets/img/2starbucks-logo.png"></img>
                            <img src="/assets/img/3g12.png"></img>
                            <img src="/assets/img/4Hyatt-Logo.png"></img>
                            <img src="/assets/img/5Samsung_wordmark.png"></img>
                            <img src="/assets/img/6Toyota_Motor.png"></img>
                        </div> */}
            <div className="row text-center">
              <div className="col-md-2 col-4 mb-3 ">
                {" "}
                <img
                  src="/assets/img/1amazon-logo.png"
                  className="row_images"
                ></img>
              </div>
              <div className="col-md-2 col-4 mb-3 ">
                {" "}
                <img
                  src="/assets/img/2starbucks-logo.png"
                  className="row_images"
                ></img>
              </div>
              <div className="col-md-2 col-4 mb-3 ">
                {" "}
                <img src="/assets/img/3g12.png" className="row_images"></img>
              </div>
              <div className="col-md-2 col-4 mb-3 ">
                {" "}
                <img
                  src="/assets/img/4Hyatt-Logo.png"
                  className="row_images"
                ></img>
              </div>
              <div className="col-md-2 col-4 mb-3 ">
                {" "}
                <img
                  src="/assets/img/5Samsung_wordmark.png"
                  className="row_images"
                ></img>
              </div>
              <div className="col-md-2 col-4 mb-3 ">
                {" "}
                <img
                  src="/assets/img/6Toyota_Motor.png"
                  className="row_images"
                ></img>
              </div>
            </div>
          </div>

          <div className="section_2">
            <div className="row">
              <div className="col-md-6">
                <div className="track_row">
                  <h1 className="main_heading mb-md-5 mb-3">
                    Trackable QR Code with your logo and brand colors in under 2
                    minutes
                  </h1>
                  <div className="list_code line_1">
                    <span className="number_list">1</span>
                    <div>
                      <h1>Select QR type and content</h1>
                      <p className="list_desc">
                        Choose from a wide range of options from URL, vCard,
                        PDF, App Store, WiFi, and more
                      </p>
                    </div>
                  </div>

                  <div className="list_code line_1">
                    <span className="number_list">2</span>
                    <div>
                      <h1>Design your QR Code</h1>
                      <p className="list_desc">
                        Use your corporate logo and colors to improve brand
                        recall and scan rates by 60%
                      </p>
                    </div>
                  </div>

                  <div className="list_code">
                    <span className="number_list">3</span>
                    <div>
                      <h1>Optimise settings</h1>
                      <p className="list_desc">
                        Integrate with Google Analytics, set expiry or
                        passwords, redirect users based on rules, all up to you
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="image_detail">
                  <img
                    src="/assets/img/Rectangle.png"
                    className="image_detail_img"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="section_3">
            <div className="qr_layout">
              <div className="row">
                <div className="col-md-4">
                  <div className="img_qrDiv">
                    <img src="/assets/img/1qr-browser.png"></img>
                    <h3>Users scan your QR Code</h3>
                    <span class="material-icons arrow_next">arrow_forward</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img_qrDiv">
                    <img src="/assets/img/2app-content.png"></img>
                    <h3>They are directed to your website</h3>
                    <span class="material-icons arrow_next">arrow_forward</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="img_qrDiv">
                    <img src="/assets/img/3analyze-files.png"></img>
                    <h3>You get tracking insights</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section_4">
            <div className="edit_qr">
              <h1 className="main_heading mb-md-5 mb-3">
                The most advanced enterprise QR Code solution
              </h1>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/1Group.png"></img>
                    <h1>Edit QR Code Anytime</h1>
                    <p>
                      Never worry about outdated or broken links; update your
                      URL or QR Code type even after its printed
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/2Group 299.png"></img>
                    <h1>Track Analytics </h1>
                    <p>
                      Optimize your marketing campaigns with insights of your QR
                      code scans; no coding required
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/3Group 299.png"></img>
                    <h1>Google Analytics</h1>
                    <p>
                      Deepen tracking by integrating with Google Analytics,
                      Facebook Pixel, or LinkedIn Insight Tag
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/4Group 299.png"></img>
                    <h1>Bulk QR Codes</h1>
                    <p>
                      Generate thousands of QR codes in seconds; we scale with
                      you!
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/5Group 299.png"></img>
                    <h1>SMART URL</h1>
                    <p>
                      Redirect users to different URLs based on your rules such
                      as language, time of day, and more 
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/6Group 299.png"></img>
                    <h1>Password Protection</h1>
                    <p>
                      Secure sensitive content with passwords to control access
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/7Group 299.png"></img>
                    <h1>Set Expiry</h1>
                    <p>
                      Create exclusive offers by setting QR Codes to expire
                      after a number of scans or by date
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/8Group 299.png"></img>
                    <h1>Folders</h1>
                    <p>
                      Organize and sort thousands of QR Codes into customizable
                      folders 
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="edit_box">
                    <img src="/assets/img/9Group 299.png"></img>
                    <h1>Templates</h1>
                    <p>
                      Save your QR Code designs as templates to reuse them for
                      other campaigns
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section_5">
            <div className="edit_qr">
              <h1 className="main_heading mb-md-5 mb-3">Why QR Bubble</h1>
              <div className="qr_bubble">
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <div className="edit_box">
                      <img src="/assets/img/bubble_1.png"></img>
                      <h1>Transparent</h1>
                      <p>
                        Static QR codes remain free forever, and we will never
                        force you to use dynamic QR codes
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="edit_box">
                      <img src="/assets/img/bubble_2.png"></img>
                      <h1>No Ads </h1>
                      <p>
                        Your users will never see any ads, so your brand
                        identity always stays professional
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="edit_box">
                      <img src="/assets/img/bubble_3.png"></img>
                      <h1>Unlimited Scans</h1>
                      <p>
                        Never worry about QR Codes failing from unexpected
                        spikes in scans
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="edit_box">
                      <img src="/assets/img/bubble_4.png"></img>
                      <h1>Enterprise Ready</h1>
                      <p>
                        Easily manage thousands of QR codes with folders,
                        multiple user accounts, and bulk upload features.  
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div className="edit_box">
                      <img src="/assets/img/bubble_5.png"></img>
                      <h1>Reliable</h1>
                      <p>
                        We run multiple servers across different countries to
                        guarantee a 99.9% uptime 
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div className="edit_box">
                      <img src="/assets/img/bubble_6.png"></img>
                      <h1>Compliant</h1>
                      <p>
                        We are fully compliant with data privacy laws, including
                        GDPR and CCPA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomeDetail;
