import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/global/layout";
import EMAIL_template from "../BulkTemplates/EMAIL_template.csv";
import Papa from "papaparse";
// import EMAIL_template from '../BulkTemplates/EMAIL_template.csv'
import PatternColors from "../PatternColors";
import Frames from "../Frames";

import PHONE_template from "../BulkTemplates/PHONE_template.csv";

import SMS_template from "../BulkTemplates/SMS_template.csv";

import TEXT_template from "../BulkTemplates/TEXT_template.csv";

import URL_template from "../BulkTemplates/URL_template (2).csv";
import TemplatesDesign from "../Templates/TemplatesDesign";

import VCARD_template from "../BulkTemplates/VCARD_template.csv";
import HomeLogo from "../Logo/HomeLogo";
import environment from "../../environment";

import WHATSAPP_template from "../BulkTemplates/WHATSAPP_template.csv";
import Logo from "./Logo";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import QrCode from "./QrCode";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import methodModel from "../../methods/methods";
import { active_Plan, deactive_Plan } from "../../actions/activePlan";
import SettingQR from "./SettingQr";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import loader from "../../methods/loader";
import { use } from "echarts";
const UserBulkQrCode = () => {
  const reffile = useRef()
  const [folder, setfolder] = useState([]);
  const user = useSelector((state) => state.user);
  const [file, setFile] = useState([]);
  const [eye, seteye] = useState(false);

  const token = localStorage.getItem("token");
  const [totalFolder, setTotalFolder] = useState(0);

  const activePlan = useSelector((state) => state.activePlan);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [TabName, setTabName] = useState("upload");
  const code = methodModel.getPrams("code");
  const urlPath = window.location.pathname;
  const [Cordinates, setCordinates] = useState({});
  const history = useHistory();
  const [slugData, setslugData] = useState();

  const [form, setform] = useState({
    value: "",
    staticQrCode: true,
    logoimg: "",
    logosize: "4",
    qrOuterEye: "square",
    qrInnerEye: "square",
    qrBodyPattern: "square",
    qrOuterEyeColor: "#030202",
    qrInnerEyeColor: "#030202",
    qrBodyPatternColor: "#030202",
    backgroundColor: "#ffffff",
    qrOuterEyeColorGradient1: "",
    qrOuterEyeColorGradient2: "",
    qrOuterEyeColorGradientType: "linear",
    qrInnerEyeColorGradient1: "",
    qrInnerEyeColorGradient2: "",
    qrInnerEyeColorGradientType: "linear",
    qrBodyPatternGradientColor1: "",
    qrBodyPatternGradientColor2: "",
    qrBodyPatternGradientColorType: "linear",
    backgroundGradientColor1: "",
    backgroundGradientColor2: "",
    backgroundGradientColorType: "linear",
    errorCorrection: "Q",
    file: [],
  });
  useEffect(() => { }, [file]);
  const [qrCodeImage, setqrCodeImage] = useState();
  const [filter, setfilter] = useState({
    page: 1,
    count: 12,
    sortBy: "srNumber asc",
    status: "active",
  });
  const [radiobtn, setradiobtn] = useState({
    qrOuterEyeType: true,
    qrInnerEyeType: true,
    backgroundColorType: true,
    qrBodyPatternType: true,
    frameColor: true,
  });
  const [qrTypes, setqrTypes] = useState();
  const [common, setcommon] = useState({
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    value6: "",
    value7: "",
    value8: "",
  });
  const [selectSlug, setselectSlug] = useState();
  const [qrTypesViewMore, setqrTypesViewMore] = useState();
  const [searchViewMore, setsearchViewMore] = useState();
  const [ViewMoreSuggestions, setViewMoreSuggestions] = useState();
  const [selectFormat, setselectFormat] = useState("");
  const [downloadConfirm, setdownloadConfirm] = useState(false);
  // For Settings tab
  const [settingForm, setsettingForm] = useState({
    qrCodeName: "",
    folderName: "",
    folderId: "",
    flyer: "",
    printRun: "",
    campaignStart: "",
    campaignEnd: "",
    numberOfScansStatus: false,
    numberOfScans: "",
    dateRangeStatus: false,
    dateRangeStart: "",
    dateRangeEnd: "",
    expiryURL: "",
    externalTrackerFacebook: false,
    externalTrackerGoogle: false,
    passwordStatus: false,
    password: "",
    exactGPSLocation: false,
    limitScanLocation: false,
    longitude: "",
    latitude: "",
    radiusInKM: "",
  });
  // For vCard
  const [vcard, setvcard] = useState();
  //Add More
  const [smartURL, setsmartURL] = useState({
    ruleType: "location",
    defaultUrl: "",
  });
  const [location, setlocation] = useState([
    { country: "", region: "", city: "", url: "" },
  ]);
  const [numberOfScans, setnumberOfScans] = useState([{ scans: "", url: "" }]);
  const [time, settime] = useState([
    { timezone: "", startTime: "", endTime: "", url: "" },
  ]);
  const [language, setlanguage] = useState([{ language: "", url: "" }]);
  // For Frames
  const [frameForm, setframeForm] = useState({
    width: "250",
    height: "250",
    frame: "",
    qrFrameColor: "#030202",
    qrFrameColorGradient1: "",
    qrFrameColorGradient2: "",
    text: "SCAN ME",
    fontStyle: "",
    textColor: "#3c61e4",
  });

  useEffect(() => {
    const showPosition = (position) => {
      if (settingForm?.exactGPSLocation == true) {
        setsettingForm({
          ...settingForm,
          exactGPSLocation:true,
          limitScanLocation: false,
          // limitScanLocation: true,
          // latitude: position?.coords?.latitude,
          // longitude: position?.coords?.longitude,
          // exactGPSLocationLat: String(position?.coords?.latitude),
          // exactGPSLocationLng: String(position?.coords?.longitude),
          latitude:"",
          longitude:"",
          radiusInKM:""
        });
      }
    };
    // if (settingForm?.exactGPSLocation) {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(showPosition);
    //   }
    // }
  }, [settingForm?.exactGPSLocation]);

  useEffect(() => {
    if (settingForm?.limitScanLocation == true) {
      setsettingForm({
        ...settingForm,
        limitScanLocation: true,
        exactGPSLocation: false,
       
        // exactGPSLocationLat: "",
        // exactGPSLocationLng: "",
      });
    }
  }, [settingForm?.limitScanLocation]);

  useEffect(() => {
    // getSlugQrCodeDetail()
    getQrCodeTypes();
    getViewMoreQrCodeTypes();
    getActivePlanDetail();
    if (slug == "multiple-apps" || slug == "smart-url" || slug == "file") {
      setform({ ...form, staticQrCode: false });
    }
    if (slug == "paynow") {
      setcommon({
        value1: true,
        value2: "",
        value3: "",
        value4: true,
        value5: "",
        value6: true,
        value7: "",
        value8: false,
        value9: "",
      });
    }
    if (slug == "sepa") {
      setcommon({
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: true,
        value6: "",
        value7: "",
        value8: "",
      });
    }
    // if (!activePlan?.planActive) {
    //   setform({ ...form, staticQrCode: true })
    // }
    let formData = JSON.parse(localStorage.getItem("QrCodeDesign"));
    if (formData) {
      setform(formData);
      localStorage.removeItem("QrCodeDesign");
    }
    let commonData = JSON.parse(localStorage.getItem("commonState"));
    if (commonData) {
      setcommon(commonData);
      localStorage.removeItem("commonState");
    }
    localStorage.removeItem("AfterLogin");
    localStorage.removeItem("DynamicQrCode");
  }, []);

  const getActivePlanDetail = () => {
    if (user?.id && token) {
      ApiClient.get(`active-subscription?user_id=${user?.id}`).then((res) => {
        if (res.success) {
          dispatch(active_Plan(res?.data));
        } else {
          dispatch(deactive_Plan());
        }
      });
    } else {
      dispatch(deactive_Plan());
    }
  };

  const getSlugQrCodeDetail = () => {
    if (slug || urlPath == "/urls") {
      ApiClient.get(
        `qrtype?id=${slug ? slug.replaceAll("-", " ") : "urls"}`
      ).then((res) => {
        if (res.success) {
          setslugData(res?.data);
        }
      });
    }
  };

  const getQrCodeTypes = (p = {}) => {
    let fil = { ...filter, ...p };
    ApiClient.get(`qrtype/all`, fil).then((res) => {
      if (res.success) {
        setqrTypes(res.data);
      }
    });
  };

  const handleCount = () => {
    if (filter?.count == 12) {
      setfilter({ ...filter, count: 100 });
      getQrCodeTypes({ count: 100 });
    } else {
      setfilter({ ...filter, count: 12 });
      getQrCodeTypes({ count: 12 });
    }
  };

  const resetDesign = () => {
    setform({
      ...form,
      logoimg: "",
      logosize: "4",
      qrOuterEye: "square",
      qrInnerEye: "square",
      qrBodyPattern: "square",
      qrOuterEyeColor: "#030202",
      qrInnerEyeColor: "#030202",
      qrBodyPatternColor: "#030202",
      backgroundColor: "#FFFFFF",
      qrOuterEyeColorGradient1: "",
      qrOuterEyeColorGradient2: "",
      qrOuterEyeColorGradientType: "linear",
      qrInnerEyeColorGradient1: "",
      qrInnerEyeColorGradient2: "",
      qrInnerEyeColorGradientType: "linear",
      qrBodyPatternGradientColor1: "",
      qrBodyPatternGradientColor2: "",
      qrBodyPatternGradientColorType: "linear",
      backgroundGradientColor1: "",
      backgroundGradientColor2: "",
      backgroundGradientColorType: "linear",
      errorCorrection: "M",
    });
    setframeForm({
      width: "250",
      height: "250",
      frame: "",
      qrFrameColor: "#030202",
      qrFrameColorGradient1: "",
      qrFrameColorGradient2: "",
      text: "SCAN ME",
      fontStyle: "",
      textColor: "#3c61e4",
    });
    setradiobtn({
      qrOuterEyeType: true,
      qrInnerEyeType: true,
      backgroundColorType: true,
      qrBodyPatternType: true,
    });
  };

  const handleQrType = (slugType, type) => {
    if (
      slugType == "multiple apps" ||
      slugType == "smart url" ||
      slugType == "file"
    ) {
      if (type == "direct") {
        window.open(`/${slugType.replace(" ", "-")}`);
      } else if (type == "viewMore") {
        setselectSlug(slugType);
      } else {
        handleQrCode(false, "No");
      }
    } else {
      if (
        slugType == slug?.replace("-", " ") ||
        urlPath.replace("/", "") == slugType
      ) {
        return;
      } else if (type == "viewMore") {
        setselectSlug(slugType);
      } else {
        window.open(`/${slugType.replace(" ", "-")}`);
      }
    }
  };

  const urlsQrCodeOnly = () => {
    if (
      qrTypesViewMore?.find((item) => item?.slug == slug.replaceAll("-", " "))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const headerText = () => {
    // if (slug == 'url' || urlPath == '/urls') {
    //   return 'Free QR Code Generator with Logo and Brand Colors'
    // }
    // else if (slug == 'whatsapp') {
    //   return 'WhatsApp QR Code Generator'
    // }
    // else if (slug == 'google-forms') {
    //   return 'Google Forms QR Code Generator'
    // }
    // else if (slug == 'sheets') {
    //   return 'Google Sheets QR Code Generator'
    // }
    // else if (slug == 'docs') {
    //   return 'Google Docs QR Code Generator'
    // }
    // else if (slug == 'wifi') {
    //   return 'Free WiFi QR Code Generator'
    // }
    // else if (slug == 'events') {
    //   return 'Calendar QR Code Generator'
    // }
    // else if (slug == 'call') {
    //   return 'Phone Number QR Code Generator'
    // }
    // else if (slug == 'sms') {
    //   return 'SMS QR Code Generator'
    // }
    // else if (slug == 'location' || slug == 'maps') {
    //   return 'Location QR Code Generator'
    // }
    // else if (slug == 'vcard') {
    //   return 'vCard QR Code Generator'
    // }
    // else if (slug == 'paynow') {
    //   return 'PayNow QR Code Generator'
    // }
    // else if (slug == 'sepa') {
    //   return 'SEPA QR Code Generator'
    // }
    // else if (urlsQrCodeOnly()) {
    //   return <span className="text-capitalize">{slug.replaceAll('-', ' ')} QR Code Generator</span>
    // }
    // else {
    //   return ''
    // }
    return " ";
  };

  // View More Search Functions
  const getViewMoreQrCodeTypes = (p = {}) => {
    let payload = { ...p, status: "active" };
    ApiClient.get(`qrtype/all`, payload).then((res) => {
      if (res.success) {
        setqrTypesViewMore(res.data);
      }
    });
  };

  const handleViewMoreSearch = (e) => {
    setsearchViewMore(e);
    ApiClient.get(`qrtype/all?search=${e || "zzzz"}&status=active`).then(
      (res) => {
        if (res.success) {
          setViewMoreSuggestions(res?.data);
        }
      }
    );
    if (!e) getViewMoreQrCodeTypes({});
  };

  const selectViewMoreSearch = (type) => {
    getViewMoreQrCodeTypes({ search: type });
    setViewMoreSuggestions([]);
  };

  const ViewMoreModal = () => {
    setsearchViewMore("");
    setViewMoreSuggestions([]);
    getViewMoreQrCodeTypes({});
    setselectSlug("");
  };

  const staticQrCode = () => {
    if (
      slug == "sepa" ||
      slug == "paynow" ||
      slug == "wifi" ||
      slug == "call" ||
      slug == "text" ||
      slug == "sms" ||
      slug == "email" ||
      slug == "location" ||
      slug == "maps" ||
      slug == "vcard"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleQrCode = (check, modal) => {
    if (!check) {
      if (modal == "No") {
        if (activePlan?.planActive) {
          setform({ ...form, staticQrCode: check });
        } else {
          if (user?.id) {
            document.getElementById("OpenDynamicQrCode").click();
          } else {
            document.getElementById("OpenSignUpModal").click();
          }
        }
      } else {
        setform({ ...form, staticQrCode: check });
        localStorage.setItem(
          "QrCodeDesign",
          JSON.stringify({ ...form, staticQrCode: check })
        );
        localStorage.setItem("commonState", JSON.stringify(common));
        localStorage.setItem("DynamicQrCode", urlPath);
        history.push(`/pricing`);
        document.getElementById("ClodeDynamicQrCode").click();
      }
    } else {
      setform({ ...form, staticQrCode: check });
    }
  };
  const getFolders = () => {
    loader(true);
    ApiClient.get(`folder/all?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setfolder(res?.data?.data);
        setTotalFolder(res?.data?.total)
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user?.id) {
      getFolders();
    }
  }, [user]);

  const Handlenexttab = (e) => {
    e.preventDefault();
    if (TabName == "upload") {
      setTabName("design");
      document.getElementById("pills-user_2-tab").click();
    } else if (TabName == "design") {
      setTabName("setting");
      document.getElementById("pills-user_3-tab").click();
    }else if (settingForm?.limitScanLocation == true) {
      if (
        !settingForm?.radiusInKM ||
        !settingForm?.latitude ||
        !settingForm?.longitude
      ) {
        toast.error("All Geo-fencing fields is required");
      }else setdownloadConfirm(true);
    }else{
      setdownloadConfirm(true);
    }
    
  };

  const Preveiostab = (e) => {
    e.preventDefault();
    if (TabName == "setting") {
      setTabName("design");
      document.getElementById("pills-user_2-tab").click();
    } else {
      setTabName("upload");
      document.getElementById("pills-user_1-tab").click();
    }
  };

  const handleFolderSelect = (e) => {
    let folderDetail = folder.find((item) => item?._id == e);
    setsettingForm({
      ...settingForm,
      folderName: folderDetail?.name,
      folderId: e,
    });
  };

  const handleCreateFolder = (e) => {
    e.preventDefault();
    let payload = {
      name: settingForm?.folderName,
    };
    loader(true);
    ApiClient.post(`folder`, payload).then((res) => {
      if (res.success) {
        // HandleNewfolderclosemodal()
        toast.success(res.message);
        getFolders();
        setsettingForm({
          ...settingForm,
          folderId: res?.data?.id,
          folderName: res?.data?.name,
        });
      }
      loader(false);
    });
    document.getElementById("CloseFolderModal").click();
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Check if any data field is empty
        // const isEmptyField = results.data.some((item) =>
        //   Object.values(item).some((value) => !value.trim())
        function validateObjectsInArray(arr, expectedKeys, index, total) {
          arr.forEach((obj) => {
            for (let key in obj) {
              if (!expectedKeys.includes(key)) {
                if (index == total - 1)
                  throw new Error(`Unexpected key '${key}' found in Your file`);
              }
            }
          });
        }

        results?.data?.map((itm, index) => {
          if (itm?.QR_Type == "email") {
            const expectedKeys = [
              "QR_Code",
              "CC",
              "BCC",
              "To",
              "Message",
              "QR_Type",
              "QR_Name",
            ];
            try {
              validateObjectsInArray(
                results?.data,
                expectedKeys,
                index,
                results?.data?.length
              );
              if (index == results?.data?.length - 1) {
                const qrTypes = results.data.map((item) => item.QR_Code);
                const isAllDynamic = qrTypes.every(
                  (type) => type === "dynamic"
                );
                const isAllStatic = qrTypes.every((type) => type === "static");
                const hasMixedValues = !isAllDynamic && !isAllStatic;

                if (hasMixedValues) {
                  toast.error(
                    "QR_Type should have all dynamic or all static values."
                  );
                } else {
                  toast.success("Your file is processed");
                  setFile(results.data);
                  // document.getElementById('pills-user_2-tab').click();
                }
              }
            } catch (error) {
              toast.error(error.message);
            }
          } else if (itm?.QR_Type == "sms") {
            const expectedKeys = [
              "QR_Code",
              "Country_Code_Phone_Number",
              "Message",
              "QR_Type",
              "QR_Name",
            ];
            try {
              validateObjectsInArray(
                results?.data,
                expectedKeys,
                index,
                results?.data?.length
              );
              if (index == results?.data?.length - 1) {
                const qrTypes = results.data.map((item) => item.QR_Code);
                const isAllDynamic = qrTypes.every(
                  (type) => type === "dynamic"
                );
                const isAllStatic = qrTypes.every((type) => type === "static");
                const hasMixedValues = !isAllDynamic && !isAllStatic;

                if (hasMixedValues) {
                  toast.error(
                    "QR_Type should have all dynamic or all static values."
                  );
                } else {
                  toast.success("Your file is processed");
                  setFile(results.data);
                  // document.getElementById('pills-user_2-tab').click();
                }
              }
            } catch (error) {
              toast.error(error.message);
            }
          } else if (itm?.QR_Type == "call") {
            const expectedKeys = [
              "QR_Code",
              "Country_Code_Phone_Number",
              "QR_Type",
              "QR_Name",
            ];
            try {
              validateObjectsInArray(
                results?.data,
                expectedKeys,
                index,
                results?.data?.length
              );
              if (index == results?.data?.length - 1) {
                const qrTypes = results.data.map((item) => item.QR_Code);
                const isAllDynamic = qrTypes.every(
                  (type) => type === "dynamic"
                );
                const isAllStatic = qrTypes.every((type) => type === "static");
                const hasMixedValues = !isAllDynamic && !isAllStatic;

                if (hasMixedValues) {
                  toast.error(
                    "QR_Type should have all dynamic or all static values."
                  );
                } else {
                  toast.success("Your file is processed");
                  setFile(results.data);
                  // document.getElementById('pills-user_2-tab').click();
                }
              }
            } catch (error) {
              toast.error(error.message);
            }
          } else if (itm?.QR_Type == "whatsapp") {
            const expectedKeys = [
              "QR_Code",
              "Country_Code_Phone_Number",
              "Message",
              "QR_Type",
              "QR_Name",
            ];
            try {
              validateObjectsInArray(
                results?.data,
                expectedKeys,
                index,
                results?.data?.length
              );
              if (index == results?.data?.length - 1) {
                const qrTypes = results.data.map((item) => item.QR_Code);
                const isAllDynamic = qrTypes.every(
                  (type) => type === "dynamic"
                );
                const isAllStatic = qrTypes.every((type) => type === "static");
                const hasMixedValues = !isAllDynamic && !isAllStatic;

                if (hasMixedValues) {
                  toast.error(
                    "QR_Type should have all dynamic or all static values."
                  );
                } else {
                  toast.success("Your file is processed");
                  setFile(results.data);
                  // document.getElementById('pills-user_2-tab').click();
                }
              }
            } catch (error) {
              toast.error(error.message);
            }
          } else if (itm?.QR_Type == "text") {
            const expectedKeys = [
              "QR_Code",
              "Frame_Text",
              "TEXT",
              "QR_Type",
              "QR_Name",
            ];
            try {
              validateObjectsInArray(
                results?.data,
                expectedKeys,
                index,
                results?.data?.length
              );
              if (index == results?.data?.length - 1) {
                const qrTypes = results.data.map((item) => item.QR_Code);
                const isAllDynamic = qrTypes.every(
                  (type) => type === "dynamic"
                );
                const isAllStatic = qrTypes.every((type) => type === "static");
                const hasMixedValues = !isAllDynamic && !isAllStatic;

                if (hasMixedValues) {
                  toast.error(
                    "QR_Type should have all dynamic or all static values."
                  );
                } else {
                  toast.success("Your file is processed");
                  setFile(results.data);
                  // document.getElementById('pills-user_2-tab').click();
                }
              }
            } catch (error) {
              toast.error(error.message);
            }
          } else if (itm?.QR_Type == "urls") {
            const expectedKeys = [
              "QR_Code",
              "Frame_Text",
              "URL",
              "QR_Type",
              "QR_Name",
            ];
            try {
              validateObjectsInArray(
                results?.data,
                expectedKeys,
                index,
                results?.data?.length
              );
              if (index == results?.data?.length - 1) {
                const qrTypes = results.data.map((item) => item.QR_Code);
                const isAllDynamic = qrTypes.every(
                  (type) => type === "dynamic"
                );
                const isAllStatic = qrTypes.every((type) => type === "static");
                const hasMixedValues = !isAllDynamic && !isAllStatic;

                if (hasMixedValues) {
                  toast.error(
                    "QR_Type should have all dynamic or all static values."
                  );
                } else {
                  toast.success("Your file is processed");
                  setFile(results.data);
                  // document.getElementById('pills-user_2-tab').click();
                }
              }
            } catch (error) {
              toast.error(error.message);
            }
          } else if (itm?.QR_Type == "vcard") {
            const expectedKeys = [
              "QR_Type",
              "QR_Name",
              "First_Name",
              "Last_Name",
              "Personal_Mobile",
              "Personal_Home",
              "Personal_Fax",
              "Work_Mobile",
              "Work_Office",
              "Work_Fax",
              "Email_Personal",
              "Email_Work",
              "Website",
              "Company_Name",
              "Company_Position",
              "Street",
              "City",
              "State",
              "Zipcode",
              "Country",
              "QR_Code",
            ];
            try {
              validateObjectsInArray(
                results?.data,
                expectedKeys,
                index,
                results?.data?.length
              );
              if (index == results?.data?.length - 1) {
                const qrTypes = results.data.map((item) => item.QR_Code);
                const isAllDynamic = qrTypes.every(
                  (type) => type === "dynamic"
                );
                const isAllStatic = qrTypes.every((type) => type === "static");
                const hasMixedValues = !isAllDynamic && !isAllStatic;

                if (hasMixedValues) {
                  toast.error(
                    "QR_Type should have all dynamic or all static values."
                  );
                } else {
                  toast.success("Your file is processed");
                  setFile(results.data);
                  // document.getElementById('pills-user_2-tab').click();
                }
              }
            } catch (error) {
              toast.error(error.message);
            }
          }
        });
        // Check if QR_Type has inconsistent values
      },
    });
  };
  const [isRowVisible1, setisRowVisible1] = useState(false);
  const [isRowVisible2, setisRowVisible2] = useState(false);
  const [isRowVisible3, setisRowVisible3] = useState(false);
  const [isRowVisible4, setisRowVisible4] = useState(false);


  const handleArrowClick1 = () => {
    setisRowVisible1(!isRowVisible1);
  };
  const handleArrowClick2 = () => {
    setisRowVisible2(!isRowVisible2);
  };
  const handleArrowClick3 = () => {
    setisRowVisible3(!isRowVisible3);
  };
  const handleArrowClick4 = () => {
    setisRowVisible4(!isRowVisible4);
  };
  
  useEffect(() => {
    if (localStorage.getItem("BulkQR")) {
      let QRDATA = JSON.parse(localStorage.getItem("BulkQR"));
      setform(QRDATA);
      setFile(QRDATA?.file);
    }
    setTimeout(() => {
      localStorage.removeItem("BulkQR");
    }, 2000);
  }, []);

  return (
    <>
      <Layout>
        <div className="user_bulk">
          <div className="container-fluid">
            <div className="main_user">
              <div className="row">
                <div className="col-md-12 col-lg-8 mb-md-3">
                  <div class="header_tabs">
                    <ul
                      class="nav nav-pills pills_tabs mb-3 mr-0"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          onClick={() => setTabName("upload")}
                          class="nav-link nav_link active"
                          id="pills-user_1-tab"
                          data-toggle="pill"
                          data-target="#pills-user_1"
                          type="button"
                          role="tab"
                          aria-controls="pills-user_1"
                          aria-selected="true"
                        >
                          <span class="number_btn">1</span>Upload{" "}
                          <img
                            src="/assets/img/arrow_img .png"
                            class="arrow_img"
                          ></img>
                        </button>
                      </li>
                      <li id="DesignTab" class="nav-item" role="presentation">
                        <button
                          onClick={() => setTabName("design")}
                          class="nav-link nav_link"
                          id="pills-user_2-tab"
                          data-toggle="pill"
                          data-target="#pills-user_2"
                          type="button"
                          role="tab"
                          aria-controls="pills-user_2"
                          aria-selected="false"
                        >
                          <span class="number_btn">2</span>Design{" "}
                          <img
                            src="/assets/img/arrow_img .png"
                            class="arrow_img"
                          ></img>
                        </button>
                      </li>

                      <li id="setting" class="nav-item" role="presentation">
                        <button
                          onClick={() => setTabName("setting")}
                          class="nav-link nav_link"
                          id="pills-user_3-tab"
                          data-toggle="pill"
                          data-target="#pills-user_3"
                          type="button"
                          role="tab"
                          aria-controls="pills-user_3"
                          aria-selected="false"
                        >
                          <span class="number_btn">3</span>Settings{" "}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 mb-3">
                  <mdiv class="btns_tabs">
                    {/* <button class="btn btn-light light_btn">
                      <span class="material-icons mr-md-2 mr-1">
                        arrow_back
                      </span>
                      Back
                    </button> */}
                    {/* {  file?.length == 0 || TabName != "setting" ? (
                      <button
                        onClick={() => {
                          if (file?.length != 0 && TabName == "upload") {
                           setTabName("design")
                          }
                        }}
                        class="btn btn-primary ml-3 "
                        // disabled={file?.length == 0}
                      >
                        Next
                        <span class="material-icons ml-md-2 ml-1">
                          arrow_forward
                        </span>
                      </button>
                    ) : (
                      <button
                      onClick={()=>{
                        setdownloadConfirm(true)
                      }}
                        class="btn btn-success ml-3 "
                        disabled={file?.length == 0}
                      >
                        Complete
                        <span class="material-icons ml-md-2 ml-1">
                          arrow_forward
                        </span>
                      </button>
                    )} */}
                    <button
                      type="button"
                      onClick={(e) => Preveiostab(e)}
                      className="btn btn-light light_btn"
                    >
                      <span className="material-icons mr-2">arrow_back</span>
                      Back
                    </button>
                    <button
                      type="button"
                      disabled={file.length == 0}
                      onClick={(e) => Handlenexttab(e)}
                      className={`btn btn-${TabName != "setting" ? "primary" : "success"
                        } ml-3`}
                    >
                      {TabName != "setting" ? "Next" : "Complete"}
                      <span className="material-icons ml-2">
                        {TabName != "setting" ? "arrow_forward" : "check"}
                      </span>
                    </button>
                  </mdiv>
                </div>
              </div>

              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-user_1"
                  role="tabpanel"
                  aria-labelledby="pills-user_1-tab"
                >
                  <div class="row">
                    {/* <input
                      type="file"
                      name=""
                      accept=".csv"
                      id="BulkUploader"
                      onChange={(e) => {
                        Papa.parse(e.target.files[0], {
                          header: true,
                          skipEmptyLines: true,
                          complete: function (results) {
                            
                              toast.success("Your file is processed");
                              setFile(results?.data);
                              // document.getElementById("pills-user_2-tab").click();
                            
                          },
                        });
                      }}
                      className="d-none"
                    /> */}
                    <input
                      type="file"
                      name=""
                      accept=".csv"
                      id="BulkUploader"
                      onChange={(e) => handleFileUpload(e)}
                      ref={reffile}
                      className="d-none"
                    />
                    <div class="col-md-7 mb-md-3 mb-3">
                      <div class="section_background">
                        <h1 class="heading_row">STEP 1: Upload</h1>
                        {file?.length == 0 ? (
                          <div
                            className="dot_div"
                            onClick={() => {
                              if (file?.length == 0) {
                                document.getElementById("BulkUploader").click();
                              }
                            }}
                          >
                            <div className="pointer">
                              <p>Drag and drop to upload or</p>
                              <h5>
                                Browse{" "}
                                <span class="material-icons ml-2">
                                  drive_folder_upload
                                </span>
                              </h5>
                            </div>
                          </div>
                        ) : (
                          <div
                            // style={{ flexDirection: "column" }}
                            className="d-flex dot_div justify-content-center align-items-center"
                          >
                            <img
                              style={{ width: "120px", textAlign: "center" }}
                              src="https://www.creativefabrica.com/wp-content/uploads/2020/12/01/Line-Style-Selected-File-Icon-Office-Graphics-6934835-1.jpg"
                              alt=""
                            />

                            {/* <p>{form?.file}</p> */}
                            {file.length == 0 ? "" : <a className="fa fa-trash text-danger" onClick={() => { setFile(""); reffile.current.value = "" }}></a>}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="col-md-5 mb-md-3 mb-3">
                      <div class="section_background">
                        <h1 class="heading_row">
                          How to generate bulk QR Codes
                        </h1>
                        <div class="ul_row">
                          <ul>
                            <li> Download the relevant CSV template below</li>
                            <li>Fill up the spreadsheet</li>
                            <li>Upload the CSV file</li>
                          </ul>
                        </div>
                        <h1 class="heading_row">Templates</h1>
                        <div className="div_download">
                          {/* <a
                            href={URL_template}
                            download="Example-URL-document"
                            class="material-icons mr-2"
                          >
                            <button class="btn_download mb-2 mr-md-3">
                              download URL
                            </button>
                          </a> */}
                          <button className="btn-outline-primary bg-white my-1 ml-2 rounded">
                            <a
                              href={URL_template}
                              download="Example-URL-document"
                              className="fa fa-download text-primary"
                            >
                              <span className="text-primary ml-1">URL</span>
                            </a>
                          </button>
                          {/* <button class="btn_download mb-2 mr-md-3">
                            <a
                              href={TEXT_template}
                              download="Example-TEXT-document"
                              class="material-icons mr-2"
                            >
                              download
                            </a>
                            Text
                          </button> */}
                          <button className="btn-outline-primary rounded bg-white my-1 ml-2">
                            <a
                              href={TEXT_template}
                              download="Example-TEXT-document"
                              className="fa fa-download text-primary"
                            >
                              <span className="text-primary ml-1">Text</span>
                            </a>
                          </button>
                          {/* <button class="btn_download mb-2 mr-md-3">
                            <a
                              href={VCARD_template}
                              download="Example-VCARD-document"
                              class="material-icons mr-2"
                            >
                              download
                            </a>
                            vCard
                          </button> */}
                          <button className="btn-outline-primary rounded bg-white my-1 ml-2">
                            <a
                              href={VCARD_template}
                              download="Example-VCARD-document"
                              className="fa fa-download text-primary"
                            >
                              <span className="text-primary ml-1">vCARD</span>
                            </a>
                          </button>
                          {/* <button class="btn_download mb-2 mr-md-3">
                            <a
                              href={EMAIL_template}
                              download="Example-EMAIL-document"
                              class="material-icons mr-2"
                            >
                              download
                            </a>
                            Email
                          </button> */}
                          <button className="btn-outline-primary rounded bg-white my-1 ml-2">
                            <a
                              href={EMAIL_template}
                              download="Example-EMAIL-document"
                              className="fa fa-download text-primary"
                            >
                              <span className="text-primary ml-1">Email</span>
                            </a>
                          </button>
                          {/* <button class="btn_download mb-2 mr-md-3">
                            <a
                              href={PHONE_template}
                              download="Example-PHONE-document"
                              class="material-icons mr-2"
                            >
                              download
                            </a>
                            Phone
                          </button> */}

                          <button className="btn-outline-primary rounded bg-white ml-2 my-1 ">
                            <a
                              href={PHONE_template}
                              download="Example-PHONE-document"
                              className="fa fa-download text-primary"
                            >
                              <span className="text-primary ml-1">Phone</span>
                            </a>
                          </button>

                          {/* <a
                              href={SMS_template}
                              download="Example-SMS-document"
                              class="material-icons mr-2"
                            >
                               <button class="btn_download mb-2 mr-md-3">
                              download
                              SMS
                          </button>
                            </a> */}

                          <button className="btn-outline-primary rounded bg-white my-1 ml-2">
                            <a
                              href={SMS_template}
                              download="Example-SMS-document"
                              className="fa fa-download text-primary"
                            >
                              <span className="text-primary ml-1">SMS</span>
                            </a>
                          </button>

                          {/* <button class="btn_download mb-2 mr-md-3">
                            <a
                              href={WHATSAPP_template}
                              download="Example-WHATSAPP-document"
                              class="material-icons mr-2"
                            >
                              download
                            </a>
                            WhatsApp
                          </button> */}
                          <button className="btn-outline-primary rounded bg-white my-1 ml-2">
                            <a
                              href={WHATSAPP_template}
                              download="Example-WHATSAPP-document"
                              className="fa fa-download text-primary"
                            >
                              <span className="text-primary ml-1">
                                WhatsApp
                              </span>
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="section_background">
                        <h1 class="heading_row">
                          Guide to filling up spreadsheet
                        </h1>

                        <ul
                          class="nav nav-pills user_bulkDiv mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="pills-guide_1-tab"
                              data-toggle="pill"
                              data-target="#pills-guide_1"
                              type="button"
                              role="tab"
                              aria-controls="pills-guide_1"
                              aria-selected="true"
                            >
                              URL
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-guide_2-tab"
                              data-toggle="pill"
                              data-target="#pills-guide_2"
                              type="button"
                              role="tab"
                              aria-controls="pills-guide_2"
                              aria-selected="false"
                            >
                              Text
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-guide_3-tab"
                              data-toggle="pill"
                              data-target="#pills-guide_3"
                              type="button"
                              role="tab"
                              aria-controls="pills-guide_3"
                              aria-selected="false"
                            >
                              vCard
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-guide_4-tab"
                              data-toggle="pill"
                              data-target="#pills-guide_4"
                              type="button"
                              role="tab"
                              aria-controls="pills-guide_4"
                              aria-selected="false"
                            >
                              Email
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-guide_5-tab"
                              data-toggle="pill"
                              data-target="#pills-guide_5"
                              type="button"
                              role="tab"
                              aria-controls="pills-guide_5"
                              aria-selected="false"
                            >
                              Phone
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-guide_6-tab"
                              data-toggle="pill"
                              data-target="#pills-guide_6"
                              type="button"
                              role="tab"
                              aria-controls="pills-guide_6"
                              aria-selected="false"
                            >
                              SMS
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-guide_7-tab"
                              data-toggle="pill"
                              data-target="#pills-guide_7"
                              type="button"
                              role="tab"
                              aria-controls="pills-guide_7"
                              aria-selected="false"
                            >
                              WhatsApp
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-guide_1"
                            role="tabpanel"
                            aria-labelledby="pills-guide_1-tab"
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="teb_user">
                                  <ul>
                                    <li>
                                      <span className="span_heading">
                                        QR_Type:
                                      </span>{" "}
                                      1 type per CSV upload (URL)
                                    </li>
                                    <li>
                                      <span className="span_heading">
                                        QR_Code:
                                      </span>{" "}
                                      Dynamic or static
                                    </li>
                                    <li>
                                      <span className="span_heading">
                                        QR_Name:
                                      </span>{" "}
                                      Name of each QR code for reference
                                    </li>
                                    <li>
                                      <span className="span_heading">
                                        Frame_Text:
                                      </span>{" "}
                                      Label at the frame of the QR Code (only if
                                      you add a frame)
                                    </li>
                                    <li>
                                      <span className="span_heading">URL:</span>{" "}
                                      Website to be loaded when the QR Code is
                                      scanned
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="img_rowRight">
                                  <img src="/assets/img/screen.png"></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-guide_2"
                            role="tabpanel"
                            aria-labelledby="pills-guide_2-tab"
                          >
                            guide_2
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-guide_3"
                            role="tabpanel"
                            aria-labelledby="pills-guide_3-tab"
                          >
                            guide_3
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-guide_4"
                            role="tabpanel"
                            aria-labelledby="pills-guide_4-tab"
                          >
                            guide_4
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-guide_5"
                            role="tabpanel"
                            aria-labelledby="pills-guide_5-tab"
                          >
                            guide_5
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-guide_6"
                            role="tabpanel"
                            aria-labelledby="pills-guide_6-tab"
                          >
                            guide_6
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-guide_7"
                            role="tabpanel"
                            aria-labelledby="pills-guide_7-tab"
                          >
                            guide_7
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-user_2"
                  role="tabpanel"
                  aria-labelledby="pills-user_1-tab"
                >
                  {TabName == "design" && (
                    <>
                      <div className="bgback back-gradient py-4">
                        <div className=" enter-url-detail">
                          <div className="container-fluid position-relative top-0">
                            <div className="row">
                              <div className="col-md-12 col-lg-8 pr-md-1 mb-3">
                                <div className="bg-white p-3 mt-md-4 mt-2 rounded-4">
                                  <div className="row ">
                                    <div className="col-12">
                                      <div className="bg-white rounded-4  ">
                                        <p className="fw-bold">
                                          STEP 2: Design your QR Code{" "}
                                          <span className="fw-normal">
                                            (Optional)
                                          </span>
                                          <span
                                            className="text-primary fw-bold float-right pointer"
                                            onClick={(e) => resetDesign()}
                                          >
                                            Reset Design{" "}
                                            <img
                                              src="/assets/img/reset.png"
                                              className=" img-fluid ms-3 "
                                              alt="..."
                                            />{" "}
                                          </span>
                                        </p>

                                        <ul
                                          className="nav nav-pills mb-4 tab-pills pills_row"
                                          id="pills-tab"
                                          role="tablist"
                                        >
                                          <li
                                            className="nav-item"
                                            role="presentation"
                                          >
                                            <button
                                              className="nav-link active"
                                              id="pills-home-tab"
                                              data-bs-toggle="pill"
                                              data-bs-target="#pills-home"
                                              type="button"
                                              role="tab"
                                              aria-controls="pills-home"
                                              aria-selected="true"
                                            >
                                              {" "}
                                              <div className="d-flex flex-column  gap-3 ">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <g clip-path="url(#clip0_168_400)">
                                                    <path
                                                      d="M12.0006 10C8.5306 10 5.6406 12.54 5.0906 15.86C4.9906 16.46 5.4806 17 6.0906 17C6.5806 17 6.9906 16.64 7.0706 16.15C7.4806 13.79 9.5306 12 12.0006 12C14.4706 12 16.5206 13.79 16.9306 16.15C17.0106 16.64 17.4206 17 17.9106 17C18.5206 17 19.0006 16.46 18.9006 15.86C18.3606 12.54 15.4706 10 12.0006 10ZM12.0006 6C6.3006 6 1.6106 10.34 1.0506 15.9C1.0006 16.49 1.4606 17 2.0606 17C2.5706 17 3.0006 16.62 3.0506 16.12C3.4906 11.57 7.3406 8 12.0006 8C16.6606 8 20.5106 11.57 20.9606 16.12C21.0106 16.62 21.4406 17 21.9506 17C22.5406 17 23.0106 16.49 22.9506 15.9C22.3906 10.34 17.7006 6 12.0006 6Z"
                                                      fill="#212121"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_168_400">
                                                      <rect
                                                        width="24"
                                                        height="24"
                                                        fill="white"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                                Logo{" "}
                                              </div>
                                            </button>
                                          </li>
                                          <li
                                            className="nav-item"
                                            role="presentation"
                                          >
                                            <button
                                              className="nav-link"
                                              id="pills-profile-tab"
                                              data-bs-toggle="pill"
                                              data-bs-target="#pills-profile"
                                              type="button"
                                              role="tab"
                                              aria-controls="pills-profile"
                                              aria-selected="false"
                                            >
                                              <div className="d-flex flex-column  gap-3 ">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <g clip-path="url(#clip0_168_408)">
                                                    <path
                                                      d="M12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C12.83 21 13.5 20.33 13.5 19.5C13.5 19.11 13.35 18.76 13.11 18.49C12.88 18.23 12.73 17.88 12.73 17.5C12.73 16.67 13.4 16 14.23 16H16C18.76 16 21 13.76 21 11C21 6.58 16.97 3 12 3ZM6.5 12C5.67 12 5 11.33 5 10.5C5 9.67 5.67 9 6.5 9C7.33 9 8 9.67 8 10.5C8 11.33 7.33 12 6.5 12ZM9.5 8C8.67 8 8 7.33 8 6.5C8 5.67 8.67 5 9.5 5C10.33 5 11 5.67 11 6.5C11 7.33 10.33 8 9.5 8ZM14.5 8C13.67 8 13 7.33 13 6.5C13 5.67 13.67 5 14.5 5C15.33 5 16 5.67 16 6.5C16 7.33 15.33 8 14.5 8ZM17.5 12C16.67 12 16 11.33 16 10.5C16 9.67 16.67 9 17.5 9C18.33 9 19 9.67 19 10.5C19 11.33 18.33 12 17.5 12Z"
                                                      fill="#212121"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_168_408">
                                                      <rect
                                                        width="24"
                                                        height="24"
                                                        fill="white"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                                Pattern & Colors{" "}
                                              </div>
                                            </button>
                                          </li>
                                          <li
                                            className="nav-item"
                                            role="presentation"
                                          >
                                            <button
                                              className="nav-link"
                                              id="pills-contact-tab"
                                              data-bs-toggle="pill"
                                              data-bs-target="#pills-contact"
                                              type="button"
                                              role="tab"
                                              aria-controls="pills-contact"
                                              aria-selected="false"
                                            >
                                              <div className="d-flex flex-column gap-3">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <g clip-path="url(#clip0_168_415)">
                                                    <path
                                                      d="M20 3.99998H16L12.71 0.70998C12.32 0.31998 11.69 0.31998 11.3 0.70998L8 3.99998H4C2.9 3.99998 2 4.89998 2 5.99998V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V5.99998C22 4.89998 21.1 3.99998 20 3.99998ZM19 20H5C4.45 20 4 19.55 4 19V6.99998C4 6.44998 4.45 5.99998 5 5.99998H8.52L12.04 2.49998L15.52 5.99998H19C19.55 5.99998 20 6.44998 20 6.99998V19C20 19.55 19.55 20 19 20ZM17 7.99998H7C6.45 7.99998 6 8.44998 6 8.99998V17C6 17.55 6.45 18 7 18H17C17.55 18 18 17.55 18 17V8.99998C18 8.44998 17.55 7.99998 17 7.99998Z"
                                                      fill="#212121"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_168_415">
                                                      <rect
                                                        width="24"
                                                        height="24"
                                                        fill="white"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                                Frame{" "}
                                              </div>
                                            </button>
                                          </li>
                                          <li
                                            className="nav-item"
                                            role="presentation"
                                          >
                                            <button
                                              className="nav-link"
                                              id="pills-disabled-tab"
                                              data-bs-toggle="pill"
                                              data-bs-target="#pills-disabled"
                                              type="button"
                                              role="tab"
                                              aria-controls="pills-disabled"
                                              aria-selected="false"
                                            >
                                              <div className="d-flex flex-column  gap-3">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <g clip-path="url(#clip0_168_422)">
                                                    <path
                                                      d="M19.5803 3.08L3.15031 19.51C3.24031 19.85 3.42031 20.16 3.66031 20.41C3.91031 20.65 4.22031 20.83 4.56031 20.92L21.0003 4.49C20.8103 3.8 20.2703 3.26 19.5803 3.08ZM11.9503 3L3.07031 11.88V14.71L14.7803 3H11.9503ZM5.07031 3C3.97031 3 3.07031 3.9 3.07031 5V7L7.07031 3H5.07031ZM19.0703 21C19.6203 21 20.1203 20.78 20.4803 20.41C20.8503 20.05 21.0703 19.55 21.0703 19V17L17.0703 21H19.0703ZM9.36031 21H12.1903L21.0703 12.12V9.29L9.36031 21Z"
                                                      fill="#212121"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_168_422">
                                                      <rect
                                                        width="24"
                                                        height="24"
                                                        fill="white"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                                Template{" "}
                                              </div>
                                            </button>
                                          </li>
                                        </ul>
                                        <div
                                          className="tab-content"
                                          id="pills-tabContent"
                                        >
                                          <div
                                            className="tab-pane fade show active"
                                            id="pills-home"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab"
                                            tabindex="0"
                                          >
                                            <HomeLogo
                                              setform={setform}
                                              form={form}
                                            />
                                          </div>
                                          <div
                                            className="tab-pane fade"
                                            id="pills-profile"
                                            role="tabpanel"
                                            aria-labelledby="pills-profile-tab"
                                            tabindex="0"
                                          >
                                            <PatternColors
                                              setform={setform}
                                              form={form}
                                              setradiobtn={setradiobtn}
                                              radiobtn={radiobtn}
                                            />
                                          </div>
                                          <div
                                            className="tab-pane fade"
                                            id="pills-contact"
                                            role="tabpanel"
                                            aria-labelledby="pills-contact-tab"
                                            tabindex="0"
                                          >
                                            <Frames
                                              frameForm={frameForm}
                                              setframeForm={setframeForm}
                                              radiobtn={radiobtn}
                                              setradiobtn={setradiobtn}
                                            />
                                          </div>
                                          <div
                                            className="tab-pane fade"
                                            id="pills-disabled"
                                            role="tabpanel"
                                            aria-labelledby="pills-disabled-tab"
                                            tabindex="0"
                                          >
                                            <TemplatesDesign
                                              setform={setform}
                                              form={form}
                                              setframeForm={setframeForm}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 col-lg-4 mt-md-0 mt-2 mb-3">
                                <div className="bg-white rounded-4 p-4 position-sticky top-0">
                                  <p className="text-center">
                                    Scan your QR code to confirm that it works
                                  </p>
                                  <div className="qr_multi">
                                    <QrCode
                                      qrTypesViewMore={qrTypesViewMore}
                                      frameForm={frameForm}
                                      settingForm={settingForm}
                                      smartURL={smartURL}
                                      location={location}
                                      numberOfScans={numberOfScans}
                                      time={time}
                                      language={language}
                                      qrCodeImage={qrCodeImage}
                                      setqrCodeImage={setqrCodeImage}
                                      form={{ ...form, file: file }}
                                      setform={setform}
                                      common={common}
                                      urlPath={urlPath}
                                      selectFormat={selectFormat}
                                      downloadConfirm={downloadConfirm}
                                      setdownloadConfirm={setdownloadConfirm}
                                      setselectFormat={setselectFormat}
                                      slug={slug}
                                      vcard={vcard}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="modal fade qr-modal-code"
                        id="exampleModalToggle"
                        aria-hidden="true"
                        aria-labelledby="exampleModalToggleLabel"
                        tabindex="-1"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-body qr-code-body p-0 rounded-3 ">
                              <div className="p-3 border-bottom">
                                <div className="search-or-code bg-white  p-0">
                                  <div className="row">
                                    <div className="col-9">
                                      <div className="search-qr-code position-relative">
                                        <input
                                          value={searchViewMore}
                                          onChange={(e) =>
                                            handleViewMoreSearch(e.target.value)
                                          }
                                          className="form-control me-2"
                                          type="search"
                                          placeholder="Search"
                                          aria-label="Search"
                                        />
                                        <i
                                          className="fa fa-search search-qr-svg"
                                          width="24"
                                        ></i>
                                        <div
                                          className={`bg-white ${ViewMoreSuggestions?.length != 0
                                            ? "search_barlist"
                                            : ""
                                            }`}
                                        >
                                          {ViewMoreSuggestions &&
                                            ViewMoreSuggestions.map((item) => {
                                              return (
                                                <div className="white_bx_bg">
                                                  <div className="search_view_more">
                                                    <p
                                                      onClick={(e) =>
                                                        selectViewMoreSearch(
                                                          item?.type
                                                        )
                                                      }
                                                      className="pointer p-2 mb-0"
                                                    >
                                                      <img
                                                        src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                        className="setimg"
                                                      />{" "}
                                                      <span className="mb-0 textp text-capitalize ms-2">
                                                        {" "}
                                                        {item?.type}{" "}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`col-3 ${!selectSlug ? "disable" : ""
                                        }`}
                                    >
                                      <button
                                        onClick={(e) => {
                                          window.open(
                                            `/${selectSlug.replace(" ", "-")}`
                                          );
                                        }}
                                        disabled={!selectSlug}
                                        className="btn btn-primary w-100"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        Proceed{" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <g clip-path="url(#clip0_317_5936)">
                                            <path
                                              d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
                                              fill="white"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_317_5936">
                                              <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="padding_box">
                                <div className="popular-box pt-3 pl-md-3">
                                  <div className="row">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.find(
                                        (item) =>
                                          item?.category_details?.name ==
                                          "popular"
                                      ) ? (
                                      <div className="col-6">
                                        <p className="mb-2 headp">Popular</p>
                                        <div className="row mb-0">
                                          {qrTypesViewMore &&
                                            qrTypesViewMore.map((item) => {
                                              if (
                                                item?.category_details?.name ==
                                                "popular"
                                              ) {
                                                return (
                                                  <div
                                                    className={`col-lg-2 p-2 mx-1 ${selectSlug == item?.slug
                                                      ? "scanner-active py-2"
                                                      : ""
                                                      }`}
                                                  >
                                                    <div
                                                      onClick={(e) =>
                                                        handleQrType(
                                                          item?.slug,
                                                          "viewMore"
                                                        ) &
                                                        localStorage.removeItem(
                                                          "qr"
                                                        )
                                                      }
                                                      className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                                    >
                                                      <img
                                                        src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                        className="setimg mx-auto"
                                                        alt="..."
                                                      />
                                                      <p className="mb-0 textp text-capitalize">
                                                        {item?.type}
                                                      </p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })}
                                        </div>
                                      </div>
                                    ) : null}
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.find(
                                        (item) =>
                                          item?.category_details?.name ==
                                          "app stores"
                                      ) ? (
                                      <div className="col-6">
                                        <p className="mb-2 headp">App Stores</p>
                                        <div className="row">
                                          {qrTypesViewMore &&
                                            qrTypesViewMore.map((item) => {
                                              if (
                                                item?.category_details?.name ==
                                                "app stores"
                                              ) {
                                                return (
                                                  <div
                                                    className={`col-lg-2 p-2 mx-1 ${selectSlug == item?.slug
                                                      ? "scanner-active "
                                                      : ""
                                                      }`}
                                                  >
                                                    <div
                                                      onClick={(e) =>
                                                        handleQrType(
                                                          item?.slug,
                                                          "viewMore"
                                                        )
                                                      }
                                                      className={`link-item d-flex flex-column text-center align-items-center gap-2`}
                                                    >
                                                      <img
                                                        src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                        className="setimg mx-auto"
                                                        alt="..."
                                                      />
                                                      <p className="mb-0 textp text-capitalize">
                                                        {item?.type}
                                                      </p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="social pt-3 pl-3">
                                  <div className="row">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.find(
                                        (item) =>
                                          item?.category_details?.name ==
                                          "social media"
                                      ) ? (
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-10">
                                            <div className="social-app">
                                              <p className="mb-2 headp">
                                                Social Media
                                              </p>
                                              <div className="row">
                                                {qrTypesViewMore &&
                                                  qrTypesViewMore.map(
                                                    (item) => {
                                                      if (
                                                        item?.category_details
                                                          ?.name ==
                                                        "social media"
                                                      ) {
                                                        return (
                                                          <div
                                                            className={`col-lg-2 p-2 mx-1 ${selectSlug ==
                                                              item?.slug
                                                              ? "scanner-active "
                                                              : ""
                                                              }`}
                                                          >
                                                            <div
                                                              onClick={(e) =>
                                                                handleQrType(
                                                                  item?.slug,
                                                                  "viewMore"
                                                                )
                                                              }
                                                              className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                                            >
                                                              <img
                                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                                className="setimg mx-auto"
                                                                alt="..."
                                                              />
                                                              <p className="mb-0 textp text-capitalize">
                                                                {item?.type}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-2"></div>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="col-6">
                                      <div className="row">
                                        {qrTypesViewMore &&
                                          qrTypesViewMore.find(
                                            (item) =>
                                              item?.category_details?.name ==
                                              "chat apps"
                                          ) ? (
                                          <>
                                            <div className="col-10">
                                              <div className="social-app">
                                                <p className="mb-2 headp">
                                                  Chat Apps
                                                </p>
                                                <div className="row">
                                                  {qrTypesViewMore &&
                                                    qrTypesViewMore.map(
                                                      (item) => {
                                                        if (
                                                          item?.category_details
                                                            ?.name ==
                                                          "chat apps"
                                                        ) {
                                                          return (
                                                            <div
                                                              className={`col-lg-2 p-2 mx-1 ${selectSlug ==
                                                                item?.slug
                                                                ? "scanner-active py-2"
                                                                : ""
                                                                }`}
                                                            >
                                                              <div
                                                                onClick={(e) =>
                                                                  handleQrType(
                                                                    item?.slug,
                                                                    "viewMore"
                                                                  )
                                                                }
                                                                className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                                              >
                                                                <img
                                                                  src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                                  className="setimg mx-auto"
                                                                  alt="..."
                                                                />
                                                                <p className="mb-0 textp text-capitalize">
                                                                  {item?.type}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-2"></div>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="social pt-3 pl-3">
                                  <div className="row">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.find(
                                        (item) =>
                                          item?.category_details?.name == "media"
                                      ) ? (
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-10">
                                            <div className="social-app">
                                              <p className="mb-2 headp">
                                                Media
                                              </p>
                                              <div className="row">
                                                {qrTypesViewMore &&
                                                  qrTypesViewMore.map(
                                                    (item) => {
                                                      if (
                                                        item?.category_details
                                                          ?.name == "media"
                                                      ) {
                                                        return (
                                                          <div
                                                            className={`col-lg-2 p-2 mx-1 ${selectSlug ==
                                                              item?.slug
                                                              ? "scanner-active "
                                                              : ""
                                                              }`}
                                                          >
                                                            <div
                                                              onClick={(e) =>
                                                                handleQrType(
                                                                  item?.slug,
                                                                  "viewMore"
                                                                )
                                                              }
                                                              className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                                            >
                                                              <img
                                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                                className="setimg mx-auto"
                                                                alt="..."
                                                              />
                                                              <p className="mb-0 textp text-capitalize">
                                                                {item?.type}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-2"></div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.find(
                                        (item) =>
                                          item?.category_details?.name == "google"
                                      ) ? (
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-10">
                                            <div className="social-app">
                                              <p className="mb-2 headp">
                                                Google
                                              </p>
                                              <div className="row">
                                                {qrTypesViewMore &&
                                                  qrTypesViewMore.map(
                                                    (item) => {
                                                      if (
                                                        item?.category_details
                                                          ?.name == "google"
                                                      ) {
                                                        return (
                                                          <div
                                                            className={`col-lg-2 p-2 mx-1 ${selectSlug ==
                                                              item?.slug
                                                              ? "scanner-active "
                                                              : ""
                                                              }`}
                                                          >
                                                            <div
                                                              onClick={(e) =>
                                                                handleQrType(
                                                                  item?.slug,
                                                                  "viewMore"
                                                                )
                                                              }
                                                              className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                                            >
                                                              <img
                                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                                className="setimg mx-auto"
                                                                alt="..."
                                                              />
                                                              <p className="mb-0 textp text-capitalize">
                                                                {item?.type}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-2"></div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="social pt-3 pl-3">
                                  <div className="row">
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.find(
                                        (item) =>
                                          item?.category_details?.name == "phone"
                                      ) ? (
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-10">
                                            <div className="social-app">
                                              <p className="mb-2 headp">
                                                Phone
                                              </p>
                                              <div className="row">
                                                {qrTypesViewMore &&
                                                  qrTypesViewMore.map(
                                                    (item) => {
                                                      if (
                                                        item?.category_details
                                                          ?.name == "phone"
                                                      ) {
                                                        return (
                                                          <div
                                                            className={`col-lg-2 p-2 mx-1 ${selectSlug ==
                                                              item?.slug
                                                              ? "scanner-active py-2"
                                                              : ""
                                                              }`}
                                                          >
                                                            <div
                                                              onClick={(e) =>
                                                                handleQrType(
                                                                  item?.slug,
                                                                  "viewMore"
                                                                )
                                                              }
                                                              className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                                            >
                                                              <img
                                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                                className="setimg mx-auto"
                                                                alt="..."
                                                              />
                                                              <p className="mb-0 textp text-capitalize">
                                                                {item?.type}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-2"></div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {qrTypesViewMore &&
                                      qrTypesViewMore.find(
                                        (item) =>
                                          item?.category_details?.name ==
                                          "payment"
                                      ) ? (
                                      <div className="col-6">
                                        <div className="row">
                                          <div className="col-10">
                                            <div className="social-app">
                                              <p className="mb-2 headp">
                                                Payment
                                              </p>
                                              <div className="row">
                                                {qrTypesViewMore &&
                                                  qrTypesViewMore.map(
                                                    (item) => {
                                                      if (
                                                        item?.category_details
                                                          ?.name == "payment"
                                                      ) {
                                                        return (
                                                          <div
                                                            className={`col-lg-2 p-2 mx-1 ${selectSlug ==
                                                              item?.slug
                                                              ? "scanner-active py-2"
                                                              : ""
                                                              }`}
                                                          >
                                                            <div
                                                              onClick={(e) =>
                                                                handleQrType(
                                                                  item?.slug,
                                                                  "viewMore"
                                                                )
                                                              }
                                                              className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                                            >
                                                              <img
                                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                                className="setimg mx-auto"
                                                                alt="..."
                                                              />
                                                              <p className="mb-0 textp text-capitalize">
                                                                {item?.type}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-2"></div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        id="OpenDownloadModal"
                        className="btn btn-primary d-none"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Launch demo modal
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        data-bs-backdrop="static"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Select Download Format
                              </h1>
                            </div>
                            <div className="modal-body d-flex justify-content-center gap-5 my-2">
                              <div>
                                <p
                                  className={`svg_box pointer ${selectFormat == "svg"
                                    ? "download_active"
                                    : ""
                                    }`}
                                  onClick={(e) => setselectFormat("svg")}
                                >
                                  SVG
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`png_box pointer ${selectFormat == "png"
                                    ? "download_active"
                                    : ""
                                    }`}
                                  onClick={(e) => setselectFormat("png")}
                                >
                                  PNG
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`jpeg_box pointer ${selectFormat == "jpeg"
                                    ? "download_active"
                                    : ""
                                    }`}
                                  onClick={(e) => setselectFormat("jpeg")}
                                >
                                  JPEG
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`webp_box pointer ${selectFormat == "webp"
                                    ? "download_active"
                                    : ""
                                    }`}
                                  onClick={(e) => setselectFormat("webp")}
                                >
                                  WEBP
                                </p>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                id="CloseDownloadModal"
                                onClick={(e) => setselectFormat("")}
                                className="btn btn-clear"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className={`btn btn-primary ${!selectFormat ? "disable" : ""
                                  }`}
                                disabled={!selectFormat}
                                onClick={(e) => setdownloadConfirm(true)}
                              >
                                Confirm{" "}
                                <i className="fa fa-check text-light ml-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary d-none"
                        id="OpenDynamicQrCode"
                        data-bs-toggle="modal"
                        data-bs-target="#OpenModalForDynamicQrCode"
                      >
                        Launch demo modal
                      </button>
                      <div
                        className="modal fade"
                        id="OpenModalForDynamicQrCode"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered dynamic_modal">
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="text-center">
                                <h4>
                                  For Dynamic Qr Code you need to purchase plan
                                </h4>
                              </div>
                              <div className="mt-4 text-center">
                                <button
                                  type="button"
                                  className="btn btn-secondary mr-2"
                                  id="ClodeDynamicQrCode"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={(e) => handleQrCode(false, "Yes")}
                                  className="btn btn-primary ml-2"
                                >
                                  Buy
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {TabName == "setting" && (
                    <div className="row">
                      <div className="col-md-8 mb-3">
                        <div className="section_background">
                          <h1 className="heading_row">
                            STEP 3: Settings{" "}
                            <span class="font_style">(Optional)</span>
                          </h1>
                          <ul>
                            <li>Save this batch of QR Codes in a folder </li>
                            <li>
                              {" "}
                              The settings will apply to all QR Codes in this
                              batch
                            </li>
                            <li>
                              You can update the settings for each individual QR
                              Code after it is created{" "}
                            </li>
                          </ul>

                          {/* <div className="row">
                            <div className="col-md-12 mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Name of QR Code<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                value={settingForm?.qrCodeName}
                                onChange={(e) =>
                                  setsettingForm({
                                    ...settingForm,
                                    qrCodeName: e.target.value,
                                  })

                                }
                                className="form-control"
                                placeholder="Name of QR Code"
                              />
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-md-6">
                              <label>Folder</label>
                              <select
                                value={settingForm?.folderId}
                                onChange={(e) =>
                                  handleFolderSelect(e.target.value)
                                }
                                className="form-select pointer border"
                                aria-label="Default select example"
                              >
                                <option value={""}>Select Folder</option>
                                {folder?.map((itm) => {
                                  return (
                                    <option value={itm?._id}>
                                      {itm?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            {/* This */}
                            <div className="col-md-6 mb-3">
                              <label></label>
                              <h3 class="new_folder">
                                {" "}
                                New Folder
                                <span
                                  class="material-icons file_icon pointer"
                                  onClick={e => {
                                    totalFolder < activePlan?.max_folders ? document.getElementById("openstaticmodal").click() :
                                    toast.error("Your Folder Plan Limit has been reached")
                                  }}
                                >
                                  create_new_folder
                                </span>
                              </h3>
                            </div>

                          </div>

                          <div className="row_form">
                            <div
                              className="dropdown_arrow pointer"
                              onClick={handleArrowClick1}
                            >
                              <h1 class="heading_row pointer">
                                Campaign <span class="font_style"></span>
                              </h1>
                              <span class="material-icons arrow_down">
                                keyboard_arrow_down
                              </span>
                            </div>
                            {isRowVisible1 && (
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <label>Medium</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={settingForm?.flyer}
                                    onChange={(e) =>
                                      setsettingForm({
                                        ...settingForm,
                                        flyer: e.target.value,
                                      })
                                    }
                                    placeholder="e.g. email, banner, bus stop"
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label>Print Run</label>
                                  <input
                                    type="text"
                                    value={settingForm?.printRun}
                                    onChange={(e) =>
                                      setsettingForm({
                                        ...settingForm,
                                        printRun: methodModel.isNumber(e),
                                      })
                                    }
                                    maxLength="7"
                                    className="form-control"
                                    placeholder="e.g. 500"
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label>Campaign Start</label>
                                  {/* <DatePicker
                                    selected={settingForm?.campaignStart}
                                    minDate={new Date()}
                                    onChange={(date) =>
                                      setsettingForm({
                                        ...settingForm,
                                        campaignStart: date,
                                      })
                                    }
                                    dateFormat="dd-MMM-yyyy"
                                    className="form-control email-input"
                                    placeholderText="Campaign Start"
                                  /> */}
                                  <input
                                    type="date"
                                    value={settingForm?.campaignStart ? settingForm.campaignStart.toISOString().split('T')[0] : ''}
                                    min={new Date().toISOString().split('T')[0]}
                                    onChange={(e) => {
                                      const selectedDate = new Date(e.target.value);
                                      setsettingForm({ ...settingForm, campaignStart: selectedDate });
                                    }}
                                    className="form-control email-input"
                                    placeholder="Campaign Start"
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label>Campaign End</label>
                                  {/* <DatePicker
                                    selected={settingForm?.campaignEnd}
                                    minDate={
                                      settingForm?.campaignStart || new Date()
                                    }
                                    onChange={(date) =>
                                      setsettingForm({
                                        ...settingForm,
                                        campaignEnd: date,
                                      })
                                    }
                                    dateFormat="dd-MMM-yyyy"
                                    className="form-control email-input"
                                    placeholderText="Campaign End"
                                  /> */}
                                  <input
                                    type="date"
                                    value={settingForm?.campaignEnd ? settingForm.campaignEnd.toISOString().split('T')[0] : ''}
                                    min={settingForm?.campaignStart ? settingForm.campaignStart.toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                                    onChange={(e) => {
                                      const selectedDate = new Date(e.target.value);
                                      setsettingForm({ ...settingForm, campaignEnd: selectedDate });
                                    }}
                                    className="form-control email-input"
                                    placeholder="Campaign End"
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row_form">
                            <div className="heading_flex">
                              <div
                                className="dropdown_arrow pointer"
                                onClick={handleArrowClick2}
                              >
                                <h1 className="heading_row ">
                                  Expiry <span className="font_style"></span>
                                </h1>
                                <span className="material-icons arrow_down pointer">
                                  keyboard_arrow_down
                                </span>
                              </div>
                              {isRowVisible2&& (
                                <div>
                                  <p>
                                    You can set the QR Code to expire after a
                                    number of scans or by date. This is useful
                                    for scenarios like limiting the number of
                                    voucher redemptions.
                                  </p>
                                  <label> Number of scans</label>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-check form-switch switch_button">
                                        <input
                                          checked={
                                            settingForm.numberOfScansStatus
                                          }
                                          onChange={(e) =>
                                            setsettingForm({
                                              ...settingForm,
                                              numberOfScansStatus:
                                                e.target.checked,
                                            })
                                          }
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="numberOfScans"
                                        />
                                        <label
                                          className="form-check-label label_text"
                                          htmlFor="numberOfScans"
                                        >
                                          Enabled
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        value={settingForm.numberOfScans}
                                        onChange={(e) =>
                                          setsettingForm({
                                            ...settingForm,
                                            numberOfScans: e.target.value,
                                          })
                                        }
                                        disabled={
                                          !settingForm.numberOfScansStatus
                                        }
                                        maxLength="5"
                                        className="form-control"
                                        placeholder="Number of scans"
                                      />
                                    </div>
                                  </div>
                                  <label>Date Range</label>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-check form-switch switch_button">
                                        <input
                                          checked={settingForm.dateRangeStatus}
                                          onChange={(e) =>
                                            setsettingForm({
                                              ...settingForm,
                                              dateRangeStatus: e.target.checked,
                                            })
                                          }
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="dateRange"
                                        />
                                        <label
                                          className="form-check-label label_text"
                                          htmlFor="dateRange"
                                        >
                                          Enabled
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <DatePicker
                                        selected={settingForm.dateRangeStart}
                                        minDate={new Date()}
                                        onChange={(date) =>
                                          setsettingForm({
                                            ...settingForm,
                                            dateRangeStart: date,
                                          })
                                        }
                                        dateFormat="dd-MMM-yyyy"
                                        disabled={!settingForm.dateRangeStatus}
                                        className="form-control email-input"
                                        placeholderText="Start"
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <DatePicker
                                        selected={settingForm.dateRangeEnd}
                                        minDate={
                                          settingForm.dateRangeStart ||
                                          new Date()
                                        }
                                        onChange={(date) =>
                                          setsettingForm({
                                            ...settingForm,
                                            dateRangeEnd: date,
                                          })
                                        }
                                        dateFormat="dd-MMM-yyyy"
                                        disabled={!settingForm.dateRangeStatus}
                                        className="form-control email-input"
                                        placeholderText="End"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label>Expiry URL</label>
                                    <input
                                      type="text"
                                      value={settingForm.expiryURL}
                                      onChange={(e) =>
                                        setsettingForm({
                                          ...settingForm,
                                          expiryURL: e.target.value,
                                        })
                                      }
                                      disabled={!settingForm.numberOfScansStatus && !settingForm.dateRangeStatus}

                                      className="form-control"
                                      placeholder="https://"
                                    />
                                    <p>
                                      Visitors will be redirected to this URL
                                      after the main link expires.
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div class="row_form">
                            <div class="heading_flex">
                              <div
                                class="dropdown_arrow pointer"
                                onClick={handleArrowClick3}
                              >
                                <h1 class="heading_row">
                                  Password <span class="font_style"></span>
                                </h1>
                                <span class="material-icons arrow_down ">
                                  keyboard_arrow_down
                                </span>
                              </div>
                              {isRowVisible3 && (
                                <div class="row">
                                  <div class="col-md-12">
                                    <div className="an_div mb-3">
                                      <div class="form-check form-switch switch_button mr-2">
                                        <input
                                          checked={settingForm?.passwordStatus}
                                          onClick={(e) =>
                                            setsettingForm({
                                              ...settingForm,
                                              passwordStatus: e.target.checked,
                                            })
                                          }
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckDefault"
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexSwitchCheckDefault"
                                        >
                                          Enabled
                                        </label>
                                      </div>
                                      <span class="text_span">
                                        Users will be required to enter your
                                        password to access your URL
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="password_div">
                                      <input
                                        type={`${eye ? "text" : "password"}`}
                                        value={settingForm?.password}
                                        onChange={(e) =>
                                          setsettingForm({
                                            ...settingForm,
                                            password: e.target.value,
                                          })
                                        }
                                        minLength={0}
                                        maxLength={10}
                                        disabled={!settingForm?.passwordStatus}
                                        className="form-control"
                                        placeholder="Set your password"
                                      />
                                      <span
                                        className={`material-icons ${eye ? "eye_view" : "eye_off"
                                          }`}
                                        onClick={(e) => seteye(!eye)}
                                      >
                                        {eye ? "visibility" : "visibility_off"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="row_form">
                            <div class="heading_flex">
                              <div
                                class="dropdown_arrow pointer"
                                onClick={handleArrowClick4}
                              >
                                <h1 class="heading_row">
                                  GPS Tracking <span class="font_style"></span>
                                </h1>
                                <span class="material-icons arrow_down ">
                                  keyboard_arrow_down
                                </span>
                              </div>
                              {isRowVisible4 && (
                                <div>
                                  <p>
                                    Note: Users need to grant permission to
                                    share their current location
                                  </p>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-check form-switch switch_button">
                                        <input
                                          checked={
                                            settingForm?.exactGPSLocation
                                          }
                                          onClick={(e) => {
                                            setsettingForm({
                                              ...settingForm,
                                              exactGPSLocation:
                                                e.target.checked,
                                            });
                                            // const showPosition = (position) => {

                                            //   setsettingForm({
                                            //     ...settingForm,

                                            //     exactGPSLocationLat:
                                            //       position?.coords?.latitude,
                                            //     exactGPSLocationLng:
                                            //       position?.coords?.longitude,
                                            //   });
                                            //   // setCordinates({
                                            //   //   ...Cordinates,
                                            //   //   exactGPSLocationLat:
                                            //   //     position?.coords?.latitude,
                                            //   //   exactGPSLocationLng:
                                            //   //     position?.coords?.longitude,
                                            //   // });
                                            // };
                                            // if (navigator.geolocation) {
                                            //   navigator.geolocation.getCurrentPosition(
                                            //     showPosition
                                            //   );
                                            // }
                                          }}
                                          disabled={
                                            settingForm?.limitScanLocation
                                          }
                                          className="form-check-input border pointer"
                                          type="checkbox"
                                          role="switch"
                                          id="exactGPSLocation"
                                        />
                                        <label
                                          class="form-check-label pointer"
                                          for="exactGPSLocation"
                                        >
                                          Exact GPS Location
                                        </label>
                                      </div>
                                      {/* <div className="d-flex">
                                        <div class="col-md-4 mb-3">
                                          <label>Longitude</label>
                                          <input
                                            type="text"
                                            value={
                                              settingForm?.exactGPSLocationLng
                                            }
                                            onChange={(e) =>
                                              setsettingForm({
                                                ...settingForm,
                                                exactGPSLocationLng:
                                                  e.target.value,
                                              })
                                            }
                                            disabled={
                                              !settingForm?.exactGPSLocation
                                            }
                                            maxLength="15"
                                            className="form-control"
                                            placeholder="Longitude"
                                          />
                                        </div>
                                        <div class="col-md-4 mb-3">
                                          <label>Latitude</label>
                                          <input
                                            type="text"
                                            value={
                                              settingForm?.exactGPSLocationLat
                                            }
                                            onChange={(e) =>
                                              setsettingForm({
                                                ...settingForm,
                                                exactGPSLocationLat:
                                                  e.target.value,
                                              })
                                            }
                                            disabled={
                                              !settingForm?.exactGPSLocation
                                            }
                                            maxLength="15"
                                            className="form-control"
                                            placeholder="Latitude"
                                          />
                                        </div>
                                      </div> */}
                                    </div>
                                    <div class="col-md-12">
                                      <div class="form-check form-switch switch_button">
                                        <input
                                          checked={
                                            settingForm?.limitScanLocation
                                          }
                                          onClick={(e) =>
                                            setsettingForm({
                                              ...settingForm,
                                              limitScanLocation:
                                                e.target.checked,
                                            })
                                          }
                                          disabled={
                                            settingForm?.exactGPSLocation
                                          }
                                          className="form-check-input border pointer"
                                          type="checkbox"
                                          role="switch"
                                          id="limitScanLocation"
                                        />
                                        <label
                                          class="form-check-label pointer"
                                          for="limitScanLocation"
                                        >
                                          Geo-fencing: Limit scan location
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                      <label>Longitude</label>
                                      <input
                                      max={9}
                                      min={0}
                                        type="number"
                                        value={settingForm?.longitude}
                                        onChange={(e) =>
                                          setsettingForm({
                                            ...settingForm,
                                            longitude: e.target.value,
                                          })
                                        }
                                        disabled={
                                          !settingForm?.limitScanLocation
                                        }
                                       maxLength="15"
                                         className="form-control"
                                        placeholder="Longitude"
                                      />
                                    </div>
                                    <div class="col-md-4 mb-3">
                                      <label>Latitude</label>
                                      <input
                                      max={9}
                                      min={0}
                                        type="number"
                                        value={settingForm?.latitude}
                                        onChange={(e) =>
                                          setsettingForm({
                                            ...settingForm,
                                            latitude: e.target.value,
                                          })
                                        }
                                        disabled={
                                          !settingForm?.limitScanLocation
                                        }
                                        maxLength="15"
                                        className="form-control"
                                        placeholder="Latitude"
                                      />
                                    </div>
                                    <div class="col-md-4 mb-3">
                                      <label>
                                        Radius in Km{" "}
                                        <span class="label_text">
                                          {" "}
                                          (1 Km = 0,62 Mile)
                                        </span>
                                      </label>
                                      {/* <input
                                        type="text"
                                        value={settingForm?.radiusInKM}
                                        onChange={(e) =>
                                          setsettingForm({
                                            ...settingForm,
                                            radiusInKM: e.target.value,
                                          })
                                        }
                                        disabled={
                                          !settingForm?.limitScanLocation
                                        }
                                        maxLength="10"
                                        className="form-control"
                                        placeholder="Radius in Km"
                                      /> */}
                                      <input
                                        type="number"
                                        value={settingForm?.radiusInKM}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          if (/^\d*\.?\d*$/.test(newValue)) {
                                            setsettingForm({
                                              ...settingForm,
                                              radiusInKM: newValue,
                                            });
                                          }
                                        }}
                                        onKeyPress={(e) => {
                                          const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Delete'];
                                          if (!allowedKeys.includes(e.key)) {
                                            e.preventDefault();
                                          }
                                        }}
                                        disabled={!settingForm?.limitScanLocation}
                                        maxLength="10"
                                        className="form-control"
                                        placeholder="Radius in Km"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>

                      <div className="col-md-4 mb-3">
                        <div className={`${downloadConfirm ? "columns" : ""}`}>
                          {" "}
                          {downloadConfirm && (
                            <p class="download_text">Downloading...</p>
                          )}{" "}
                        </div>
                        <div className="section_background">
                          <h1 className="text-center">QR CODE</h1>
                          <div class="qr_multi">
                            <SettingQR
                              qrTypesViewMore={qrTypesViewMore}
                              frameForm={frameForm}
                              settingForm={settingForm}
                              smartURL={smartURL}
                              location={location}
                              numberOfScans={numberOfScans}
                              time={time}
                              language={language}
                              qrCodeImage={qrCodeImage}
                              setqrCodeImage={setqrCodeImage}
                              form={{ ...form, file: file }}
                              setform={setform}
                              common={common}
                              urlPath={urlPath}
                              selectFormat={selectFormat}
                              downloadConfirm={downloadConfirm}
                              setdownloadConfirm={setdownloadConfirm}
                              setselectFormat={setselectFormat}
                              slug={slug}
                              vcard={vcard}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* New Folder Modal */}
        <button
          type="button"
          class="btn btn-primary d-none"
          id="openstaticmodal"
          data-bs-toggle="modal"
          data-bs-target="#opennewfoldermodal1"
        >
          Opennewfoldermodal
        </button>
        <div
          className="modal fade"
          id="opennewfoldermodal1"
          tabindex="-1"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <form onSubmit={(e) => handleCreateFolder(e)}>
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Create New Folder
                  </h1>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label">Folder Name</label>
                      <input
                        value={settingForm?.folderName}
                        onChange={(e) =>
                          setsettingForm({
                            ...settingForm,
                            folderName: e.target.value,
                          })
                        }
                        maxLength="20"
                        type="text"
                        className="form-control"
                        placeholder="Folder Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light light_btn"
                    id="CloseFolderModal"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UserBulkQrCode;
