
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import './style.scss';

const Resetpassword = () => {
    const user = useSelector(state => state.user)
    const history = useHistory();
    const [form, setForm] = useState({ confirmPassword: '', newPassword: '', code: '', id: '' });
    const [submitted, setSubmitted] = useState(false)
    const [eyes, setEyes] = useState({ password: false, confirmPassword: false });

    const formValidation = [
        { key: 'confirmPassword', minLength: 8, confirmMatch: ['confirmPassword', 'newPassword'] },
        { key: 'newPassword', minLength: 8 },
    ]

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/urls')
        }
    }, [])

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation)
    }

    useEffect(() => {
        let prm = {
            code: methodModel.getPrams('code'),
        }
        setForm({ ...form, ...prm })
    }, [])

    const hendleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        loader(true)
        let payload = {
            newPassword: form.newPassword,
            confirmPassword: form.confirmPassword,
            code: form.code,
        }
        ApiClient.put('reset/password', payload).then(res => {
            if (res.success) {
                setTimeout(() => {
                    toast.success(res.message)
                }, 100);
                history.push(`/login`)
            }
            loader(false)
        })
    };

    return (
        <>
            <div className='m_login'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 mx-auto px-0 ">
                            <div className='login-bx bg-white '>
                                <form className="centerLogin" onSubmit={hendleSubmit} >
                                    <img src='./assets/img/favicon_2.png' width='150px' className='mb-2' />
                                    {/* <button className="btn btn-primary mb-2" type="submit"></button> */}
                                    <div className="text-center mb-2">
                                        <h4 className=" lgtext fw-bold">Update Password</h4>
                                    </div>
                                    <div className="mb-3">
                                        <div className="mb-3 ">
                                            <div className=" text-start position-relative set-pass">
                                                <label for="exampleInputEmail1" className="form-label text-left">New Password</label>
                                                <input type={eyes.password ? 'text' : 'password'} className="form-control mb-0 bginput" value={form.newPassword} min="12" onChange={e => setForm({ ...form, newPassword: e.target.value })} placeholder="Create new password" required />
                                                <i className={eyes.password ? 'fa fa-eye setfa' : 'fa fa-eye-slash setfa'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
                                            </div>
                                            {submitted && getError('newPassword').invalid ? <div className="invalid-feedback d-block">Min Length must be 8 characters long</div> : <></>}
                                        </div>
                                        <div className="text-start position-relative set-pass">
                                            <label for="exampleInputEmail1" className="form-label text-left">Confirm New Password</label>
                                            <div className="inputWrapper">
                                                <input type={eyes.confirmPassword ? 'text' : 'password'} className="form-control mb-0 bginput" value={form.confirmPassword} maxLength={50} onChange={e => setForm({ ...form, confirmPassword: e.target.value })} placeholder="Confirm new password" required />
                                                <i className={eyes.confirmPassword ? 'fa fa-eye setfa' : 'fa fa-eye-slash setfa'} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })}></i>
                                            </div>
                                            {submitted && getError('confirmPassword').err.confirmMatch ? <div className="invalid-feedback d-block">Confirm Password is not matched with New Password</div> : <></>}
                                        </div>
                                    </div>
                                    <div className="buttons">
                                        <button type="submit" className="btn btn-primary w-100">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </>
    );
};

export default Resetpassword;
