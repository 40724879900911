import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useParams } from "react-router-dom";

const PatternColors = ({ setform, form, setradiobtn, radiobtn }) => {
  const urlPath = window.location.pathname;
  const { slug } = useParams();

  const swapValues = (type) => {
    let temp1 = "";
    let temp2 = "";
    if (type == "QrOuterEye") {
      temp1 = form?.qrOuterEyeColorGradient1;
      temp2 = form?.qrOuterEyeColorGradient2;
      setform({
        ...form,
        qrOuterEyeColorGradient1: temp2,
        qrOuterEyeColorGradient2: temp1,
      });
    } else if (type == "QrInnerEye") {
      temp1 = form?.qrInnerEyeColorGradient1;
      temp2 = form?.qrInnerEyeColorGradient2;
      setform({
        ...form,
        qrInnerEyeColorGradient1: temp2,
        qrInnerEyeColorGradient2: temp1,
      });
    } else if (type == "QrBodyPattern") {
      temp1 = form?.qrBodyPatternGradientColor1;
      temp2 = form?.qrBodyPatternGradientColor2;
      setform({
        ...form,
        qrBodyPatternGradientColor1: temp2,
        qrBodyPatternGradientColor2: temp1,
      });
    } else {
      temp1 = form?.backgroundGradientColor1;
      temp2 = form?.backgroundGradientColor2;
      setform({
        ...form,
        backgroundGradientColor1: temp2,
        backgroundGradientColor2: temp1,
      });
    }
  };

  //DropDown Functions
  const [isOpen, setIsOpen] = useState({
    qrOuterEye: false,
    qrOuterEyeGradient1: false,
    qrOuterEyeGradient2: false,
    qrInnerEye: false,
    qrInnerEyeGradient1: false,
    qrInnerEyeGradient2: false,
    qrBodyPattern: false,
    qrBodyPatternGradient1: false,
    qrBodyPatternGradient2: false,
    backgroundColor: false,
    backgroundGradientColor1: false,
    backgroundGradientColor2: false,
  });
  const dropdownRefOuterEye = useRef(null);
  const dropdownRefOuterEyeGradient1 = useRef(null);
  const dropdownRefOuterEyeGradient2 = useRef(null);
  const dropdownRefInnerEye = useRef(null);
  const dropdownRefInnerEyeGradient1 = useRef(null);
  const dropdownRefInnerEyeGradient2 = useRef(null);
  const dropdownRefBodyPattern = useRef(null);
  const dropdownRefBodyPatternGradient1 = useRef(null);
  const dropdownRefBodyPatternGradient2 = useRef(null);
  const dropdownRefbackgroundColor = useRef(null);
  const dropdownRefbackgroundGradientColor1 = useRef(null);
  const dropdownRefbackgroundGradientColor2 = useRef(null);
  const handleClickOutside = (event) => {
    if (
      dropdownRefOuterEye.current &&
      !dropdownRefOuterEye.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrOuterEye: false }));
    }
    if (
      dropdownRefOuterEyeGradient1.current &&
      !dropdownRefOuterEyeGradient1.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrOuterEyeGradient1: false }));
    }
    if (
      dropdownRefOuterEyeGradient2.current &&
      !dropdownRefOuterEyeGradient2.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrOuterEyeGradient2: false }));
    }
    if (
      dropdownRefInnerEye.current &&
      !dropdownRefInnerEye.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrInnerEye: false }));
    }
    if (
      dropdownRefInnerEyeGradient1.current &&
      !dropdownRefInnerEyeGradient1.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrInnerEyeGradient1: false }));
    }
    if (
      dropdownRefInnerEyeGradient2.current &&
      !dropdownRefInnerEyeGradient2.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrInnerEyeGradient2: false }));
    }
    if (
      dropdownRefBodyPattern.current &&
      !dropdownRefBodyPattern.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrBodyPattern: false }));
    }
    if (
      dropdownRefBodyPatternGradient1.current &&
      !dropdownRefBodyPatternGradient1.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrBodyPatternGradient1: false }));
    }
    if (
      dropdownRefBodyPatternGradient2.current &&
      !dropdownRefBodyPatternGradient2.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, qrBodyPatternGradient2: false }));
    }
    if (
      dropdownRefbackgroundColor.current &&
      !dropdownRefbackgroundColor.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({ ...previous, backgroundColor: false }));
    }
    if (
      dropdownRefbackgroundGradientColor1.current &&
      !dropdownRefbackgroundGradientColor1.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({
        ...previous,
        backgroundGradientColor1: false,
      }));
    }
    if (
      dropdownRefbackgroundGradientColor2.current &&
      !dropdownRefbackgroundGradientColor2.current.contains(event.target)
    ) {
      setIsOpen((previous) => ({
        ...previous,
        backgroundGradientColor2: false,
      }));
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <p className="text-darl fw-normal my-3">
        <img
          src="/assets/img/lightbulb_.png"
          className="img-fluid me-2"
          alt="..."
        />
        Add your corporate colors to improve brand recall
      </p>
      {urlPath == "/bulk-qr-code" ? null : (
        <div className="row">
          <div
            className={`col-md-${
              urlPath == `/app/qrcodes/${slug}` ? "8" : "6"
            }`}
          >
            <div className="outer-eye">
              <>
                {urlPath == "/bulk-qr-code" ? null : (
                  <>
                    <h6 className="fw-bold">QR Outer Eye</h6>
                    <div className="row">
                      <div className="col-md-3 col-3">
                        <div
                          className={`box-link ${
                            form?.qrOuterEye == "square" ? "link-active" : ""
                          }`}
                          onClick={(e) =>
                            setform({ ...form, qrOuterEye: "square" })
                          }
                        >
                          <img
                            src="/assets/img/outer1.png"
                            className="img-fluid w-100"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div
                          className={`box-link ${
                            form?.qrOuterEye == "rounded" ? "link-active" : ""
                          }`}
                          onClick={(e) =>
                            setform({ ...form, qrOuterEye: "rounded" })
                          }
                        >
                          <img
                            src="/assets/img/outer2.png"
                            className="img-fluid w-100"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div
                          className={`box-link ${
                            form?.qrOuterEye == "extra-rounded"
                              ? "link-active"
                              : ""
                          }`}
                          onClick={(e) =>
                            setform({ ...form, qrOuterEye: "extra-rounded" })
                          }
                        >
                          <img
                            src="/assets/img/outer3.png"
                            className="img-fluid w-100"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
              <div className="form-check d-flex my-3 ps-0">
                <div className="col-6 ps-0">
                  <div className="form-check check-radio_btn mb-0">
                    <label className="form-check-label fw-normal">
                      <input
                        className="form-check-input"
                        checked={radiobtn?.qrOuterEyeType}
                        onClick={(e) =>
                          setradiobtn({ ...radiobtn, qrOuterEyeType: true })
                        }
                        type="radio"
                        name="flexRadioDefault2"
                      />
                      <span>Solid Color</span>
                    </label>
                  </div>
                </div>
                <div className="col-md-6 ps-0">
                  <div className="form-check check-radio_btn mb-0">
                    <label className="form-check-label fw-normal">
                      <input
                        className="form-check-input"
                        checked={!radiobtn?.qrOuterEyeType}
                        onClick={(e) =>
                          setradiobtn({ ...radiobtn, qrOuterEyeType: false })
                        }
                        type="radio"
                        name="flexRadioDefault2"
                      />
                      Gradient Color
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {radiobtn?.qrOuterEyeType ? (
              <div className="row">
                <div className="col-md-6">
                  <div className="color-code mb-3">
                    <div className="code-btn d-flex gap-3 align-items-center">
                      <div
                        className="dropdown d-flex align-items-center"
                        ref={dropdownRefOuterEye}
                      >
                        <button
                          style={{ backgroundColor: form?.qrOuterEyeColor }}
                          className="color_box"
                          onClick={() =>
                            setIsOpen({
                              ...isOpen,
                              qrOuterEye: !isOpen?.qrOuterEye,
                            })
                          }
                          aria-expanded={isOpen?.qrOuterEye}
                        ></button>
                        <span className="ml-2">{form?.qrOuterEyeColor}</span>
                        {isOpen?.qrOuterEye && (
                          <div className="dropdown-menu p-0 d-block">
                            <SketchPicker
                              color={form?.qrOuterEyeColor}
                              onChange={(color) =>
                                setform({
                                  ...form,
                                  qrOuterEyeColor: color?.hex,
                                  qrOuterEyeColorGradient1: "",
                                  qrOuterEyeColorGradient2: "",
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrOuterEyeColor} onChange={e => setform({ ...form, qrOuterEyeColor: e.target.value, qrOuterEyeColorGradient1: '', qrOuterEyeColorGradient2: '' })} />{form?.qrOuterEyeColor} */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="gradient-div">
                <div className="rowDiv align-items-center">
                  <h6 className="fw-bold mb-0">Gradient Type</h6>
                  <div className="rowDiv">
                    <div
                      className="gradient-link gradient-active pointer"
                      onClick={(e) =>
                        setform({
                          ...form,
                          qrOuterEyeColorGradientType: "linear",
                        })
                      }
                    >
                      <img src="/assets/img/gradient1.png" />
                    </div>
                    <div
                      className="gradient-link pointer"
                      onClick={(e) =>
                        setform({
                          ...form,
                          qrOuterEyeColorGradientType: "radial",
                        })
                      }
                    >
                      <img src="/assets/img/gradient2.png" />
                    </div>
                    {/* <div className="col-3 px-2">
                                                <div className="gradient-link ">
                                                    <img src="/assets/img/gradient3.png" />
                                                </div>
                                            </div>
                                            <div className="col-3 px-2">
                                                <div className="gradient-link ">
                                                    <img src="/assets/img/gradient4.png" />
                                                </div>
                                            </div> */}
                  </div>
                </div>
                <div className="rowDiv align-items-center">
                  <div className="gradient_code-btn d-flex gap-3 align-items-center">
                    <div
                      className="dropdown d-flex align-items-center"
                      ref={dropdownRefOuterEyeGradient1}
                    >
                      <button
                        style={{
                          backgroundColor:
                            form?.qrOuterEyeColorGradient1 || "#030202",
                        }}
                        className="color_box"
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            qrOuterEyeGradient1: !isOpen?.qrOuterEyeGradient1,
                          })
                        }
                        aria-expanded={isOpen?.qrOuterEyeGradient1}
                      ></button>
                      <span className="ml-2">
                        {form?.qrOuterEyeColorGradient1 || "#030202"}
                      </span>
                      {isOpen?.qrOuterEyeGradient1 && (
                        <div className="dropdown-menu p-0 d-block">
                          <SketchPicker
                            color={form?.qrOuterEyeColorGradient1 || "#030202"}
                            onChange={(color) =>
                              setform({
                                ...form,
                                qrOuterEyeColorGradient1: color?.hex,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                    {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrOuterEyeColorGradient1 || '#030202'} onChange={e => setform({ ...form, qrOuterEyeColorGradient1: e.target.value })} />{form?.qrOuterEyeColorGradient1 || '#030202'} */}
                  </div>

                  <img
                    src="/assets/img/compare_arrows.png"
                    className="campare_arrow pointer"
                    alt=""
                    onClick={(e) => swapValues("QrOuterEye")}
                  />

                  <div className="gradient_code-btn d-flex gap-3 align-items-center">
                    <div
                      className="dropdown d-flex align-items-center"
                      ref={dropdownRefOuterEyeGradient2}
                    >
                      <button
                        style={{
                          backgroundColor:
                            form?.qrOuterEyeColorGradient2 || "#030202",
                        }}
                        className="color_box"
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            qrOuterEyeGradient2: !isOpen?.qrOuterEyeGradient2,
                          })
                        }
                        aria-expanded={isOpen?.qrOuterEyeGradient2}
                      ></button>
                      <span className="ml-2">
                        {form?.qrOuterEyeColorGradient2 || "#030202"}
                      </span>
                      {isOpen?.qrOuterEyeGradient2 && (
                        <div className="dropdown-menu p-0 d-block">
                          <SketchPicker
                            color={form?.qrOuterEyeColorGradient2 || "#030202"}
                            onChange={(color) =>
                              setform({
                                ...form,
                                qrOuterEyeColorGradient2: color?.hex,
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                    {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrOuterEyeColorGradient2 || '#030202'} onChange={e => setform({ ...form, qrOuterEyeColorGradient2: e.target.value })} />{form?.qrOuterEyeColorGradient2 || '#030202'} */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="form-row">
        <div
          className={`col-${urlPath == `/app/qrcodes/${slug}` ? "12" : "12"}`}
        >
          {urlPath == "/bulk-qr-code" ? null : (
            <div className="outer-eye border_top">
              <h6 className="fw-bold">QR Inner Eye</h6>
              <div className="row">
                <div className="col-3">
                  <div
                    className={`box-link ${
                      form?.qrInnerEye == "square" ? "link-active" : ""
                    }`}
                    onClick={(e) => setform({ ...form, qrInnerEye: "square" })}
                  >
                    <img src="/assets/img/inner1.png" />
                  </div>
                </div>

                <div className="col-3">
                  <div
                    className={`box-link ${
                      form?.qrInnerEye == "rounded" ? "link-active" : ""
                    }`}
                    onClick={(e) => setform({ ...form, qrInnerEye: "rounded" })}
                  >
                    <img src="/assets/img/inner3.png" />
                  </div>
                </div>
              </div>
              <div className="row form-check d-flex my-3">
                <div className="col-sm-3 col-md-3 ps-0">
                  <div className="form-check check-radio_btn  mb-0">
                    <label className="form-check-label fw-normal">
                      <input
                        className="form-check-input"
                        checked={radiobtn?.qrInnerEyeType}
                        onClick={(e) =>
                          setradiobtn({ ...radiobtn, qrInnerEyeType: true })
                        }
                        type="radio"
                        name="flexRadioDefault1"
                      />
                      Solid Color
                    </label>
                  </div>
                </div>
                <div className="col-sm-3 col-md-3 ps-0">
                  <div className="form-check check-radio_btn  mb-0">
                    <label className="form-check-label fw-normal">
                      <input
                        className="form-check-input"
                        checked={!radiobtn?.qrInnerEyeType}
                        onClick={(e) =>
                          setradiobtn({ ...radiobtn, qrInnerEyeType: false })
                        }
                        type="radio"
                        name="flexRadioDefault1"
                      />
                      Gradient Color
                    </label>
                  </div>
                </div>
              </div>
              {radiobtn?.qrInnerEyeType ? (
                <div className="row">
                  <div className="col-md-6">
                    <div className="color-code mb-3">
                      <div className="code-btn d-flex gap-3 align-items-center">
                        <div
                          className="dropdown d-flex align-items-center"
                          ref={dropdownRefInnerEye}
                        >
                          <button
                            style={{ backgroundColor: form?.qrInnerEyeColor }}
                            className="color_box"
                            onClick={() =>
                              setIsOpen({
                                ...isOpen,
                                qrInnerEye: !isOpen?.qrInnerEye,
                              })
                            }
                            aria-expanded={isOpen?.qrInnerEye}
                          ></button>
                          <span className="ml-2">{form?.qrInnerEyeColor}</span>
                          {isOpen?.qrInnerEye && (
                            <div className="dropdown-menu p-0 d-block">
                              <SketchPicker
                                color={form?.qrInnerEyeColor}
                                onChange={(color) =>
                                  setform({
                                    ...form,
                                    qrInnerEyeColor: color?.hex,
                                    qrInnerEyeColorGradient1: "",
                                    qrInnerEyeColorGradient2: "",
                                  })
                                }
                              />
                            </div>
                          )}
                        </div>
                        {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrInnerEyeColor} onChange={e => setform({ ...form, qrInnerEyeColor: e.target.value, qrInnerEyeColorGradient1: '', qrInnerEyeColorGradient2: '' })} />{form?.qrInnerEyeColor} */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="gradient-div">
                  <div className="rowDiv align-items-center">
                    <h6 className="fw-bold mb-0">Gradient Type</h6>

                    <div className="rowDiv">
                      <div
                        className="gradient-link gradient-active pointer"
                        onClick={(e) =>
                          setform({
                            ...form,
                            qrInnerEyeColorGradientType: "linear",
                          })
                        }
                      >
                        <img src="/assets/img/gradient1.png" />
                      </div>

                      <div
                        className="gradient-link pointer"
                        onClick={(e) =>
                          setform({
                            ...form,
                            qrInnerEyeColorGradientType: "radial",
                          })
                        }
                      >
                        <img src="/assets/img/gradient2.png" />
                      </div>

                      {/* <div className="col-3 px-2">
                                                <div className="gradient-link ">
                                                    <img src="/assets/img/gradient3.png" />
                                                </div>
                                            </div>
                                            <div className="col-3 px-2">
                                                <div className="gradient-link ">
                                                    <img src="/assets/img/gradient4.png" />
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                  <div className="rowDiv align-items-center ">
                    <div className="gradient_code-btn d-flex gap-3 align-items-center">
                      <div
                        className="dropdown d-flex align-items-center"
                        ref={dropdownRefInnerEyeGradient1}
                      >
                        <button
                          style={{
                            backgroundColor:
                              form?.qrInnerEyeColorGradient1 || "#030202",
                          }}
                          className="color_box"
                          onClick={() =>
                            setIsOpen({
                              ...isOpen,
                              qrInnerEyeGradient1: !isOpen?.qrInnerEyeGradient1,
                            })
                          }
                          aria-expanded={isOpen?.qrInnerEyeGradient1}
                        ></button>
                        <span className="ml-2">
                          {form?.qrInnerEyeColorGradient1 || "#030202"}
                        </span>
                        {isOpen?.qrInnerEyeGradient1 && (
                          <div className="dropdown-menu p-0 d-block">
                            <SketchPicker
                              color={
                                form?.qrInnerEyeColorGradient1 || "#030202"
                              }
                              onChange={(color) =>
                                setform({
                                  ...form,
                                  qrInnerEyeColorGradient1: color?.hex,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrInnerEyeColorGradient1 || '#030202'} onChange={e => setform({ ...form, qrInnerEyeColorGradient1: e.target.value })} />{form?.qrInnerEyeColorGradient1 || '#030202'} */}
                    </div>

                    <img
                      src="/assets/img/compare_arrows.png"
                      className="campare_arrow pointer"
                      onClick={(e) => swapValues("QrInnerEye")}
                    />

                    <div className="gradient_code-btn d-flex gap-3 align-items-center">
                      <div
                        className="dropdown d-flex align-items-center"
                        ref={dropdownRefInnerEyeGradient2}
                      >
                        <button
                          style={{
                            backgroundColor:
                              form?.qrInnerEyeColorGradient2 || "#030202",
                          }}
                          className="color_box"
                          onClick={() =>
                            setIsOpen({
                              ...isOpen,
                              qrInnerEyeGradient2: !isOpen?.qrInnerEyeGradient2,
                            })
                          }
                          aria-expanded={isOpen?.qrInnerEyeGradient2}
                        ></button>
                        <span className="ml-2">
                          {form?.qrInnerEyeColorGradient2 || "#030202"}
                        </span>
                        {isOpen?.qrInnerEyeGradient2 && (
                          <div className="dropdown-menu p-0 d-block">
                            <SketchPicker
                              color={
                                form?.qrInnerEyeColorGradient2 || "#030202"
                              }
                              onChange={(color) =>
                                setform({
                                  ...form,
                                  qrInnerEyeColorGradient2: color?.hex,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrInnerEyeColorGradient2 || '#030202'} onChange={e => setform({ ...form, qrInnerEyeColorGradient2: e.target.value })} />{form?.qrInnerEyeColorGradient2 || '#030202'} */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="qr-body border_top">
        {urlPath == "/bulk-qr-code" ? null : (
          <h6 className="fw-bold">QR Body Pattern</h6>
        )}
        <div className="row mx-0">
          <div className="qr-body-link">
            {/* qr-body-actve */}
            {urlPath == "/bulk-qr-code" ? null : (
              <div className="row gap-4">
                <button
                  className={`grid-button-bx1 ${
                    form?.qrBodyPattern == "square" ? "qr-body-actve" : ""
                  }`}
                  onClick={(e) => setform({ ...form, qrBodyPattern: "square" })}
                ></button>
                <button
                  className={`grid-button-bx2 ${
                    form?.qrBodyPattern == "rounded" ? "qr-body-actve" : ""
                  }`}
                  onClick={(e) =>
                    setform({ ...form, qrBodyPattern: "rounded" })
                  }
                ></button>
                <button
                  className={`grid-button-bx3 ${
                    form?.qrBodyPattern == "extra-rounded"
                      ? "qr-body-actve"
                      : ""
                  }`}
                  onClick={(e) =>
                    setform({ ...form, qrBodyPattern: "extra-rounded" })
                  }
                ></button>
                <button
                  className={`grid-button-bx4 ${
                    form?.qrBodyPattern == "classy-rounded"
                      ? "qr-body-actve"
                      : ""
                  }`}
                  onClick={(e) =>
                    setform({ ...form, qrBodyPattern: "classy-rounded" })
                  }
                ></button>
                <button
                  className={`grid-button-bx5 ${
                    form?.qrBodyPattern == "dots" ? "qr-body-actve" : ""
                  }`}
                  onClick={(e) => setform({ ...form, qrBodyPattern: "dots" })}
                >
                  <img
                    src="/assets/img/dotted_img.png"
                    className="dotted_img"
                  />
                </button>
                <button
                  className={`grid-button-bx6 ${
                    form?.qrBodyPattern == "classy" ? "qr-body-actve" : ""
                  }`}
                  onClick={(e) => setform({ ...form, qrBodyPattern: "classy" })}
                ></button>
              </div>
            )}
          </div>

          <div className="row form-check d-flex my-3">
            <div className="col-sm-3 col-md-3 ps-0">
              <div className="form-check check-radio_btn  mb-0">
                <label className="form-check-label fw-normal">
                  <input
                    className="form-check-input"
                    checked={radiobtn?.qrBodyPatternType}
                    onClick={(e) =>
                      setradiobtn({ ...radiobtn, qrBodyPatternType: true })
                    }
                    type="radio"
                    name="flexRadioDefault3"
                  />
                  Solid Color
                </label>
              </div>
            </div>
            {urlPath == "/bulk-qr-code" ? null : (
              <div className="col-sm-3 col-md-3 ps-0">
                <div className="form-check check-radio_btn  mb-0">
                  <label className="form-check-label fw-normal">
                    <input
                      className="form-check-input"
                      checked={!radiobtn?.qrBodyPatternType}
                      onClick={(e) =>
                        setradiobtn({ ...radiobtn, qrBodyPatternType: false })
                      }
                      type="radio"
                      name="flexRadioDefault3"
                    />
                    Gradient Color
                  </label>
                </div>
              </div>
            )}
          </div>
          {radiobtn?.qrBodyPatternType ? (
            <div className="row">
              <div className="col-md-6">
                <div className="color-code mb-3">
                  <div className="code-btn d-flex gap-3 align-items-center">
                    <div
                      className="dropdown d-flex align-items-center"
                      ref={dropdownRefBodyPattern}
                    >
                      <button
                        style={{ backgroundColor: form?.qrBodyPatternColor }}
                        className="color_box"
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            qrBodyPattern: !isOpen?.qrBodyPattern,
                          })
                        }
                        aria-expanded={isOpen?.qrBodyPattern}
                      ></button>
                      <span className="ml-2">{form?.qrBodyPatternColor}</span>
                      {isOpen?.qrBodyPattern && (
                        <div className="dropdown-menu p-0 d-block">
                          <SketchPicker
                            color={form?.qrBodyPatternColor}
                            onChange={(color) =>
                              setform({
                                ...form,
                                qrBodyPatternColor: color?.hex,
                                qrBodyPatternGradientColor1: "",
                                qrBodyPatternGradientColor2: "",
                              })
                            }
                          />
                        </div>
                      )}
                    </div>
                    {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrBodyPatternColor} onChange={e => setform({ ...form, qrBodyPatternColor: e.target.value, qrBodyPatternGradientColor1: '', qrBodyPatternGradientColor2: '' })} />{form?.qrBodyPatternColor} */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {urlPath == "/bulk-qr-code" ? null : (
                <div className="gradient-div">
                  <div className="rowDiv align-items-center">
                    <h6 className="fw-bold mb-0">Gradient Type</h6>

                    <div className="rowDiv">
                      <div
                        className="gradient-link gradient-active pointer"
                        onClick={(e) =>
                          setform({
                            ...form,
                            qrBodyPatternGradientColorType: "linear",
                          })
                        }
                      >
                        <img src="/assets/img/gradient1.png" />
                      </div>

                      <div className="gradient-link pointer">
                        <img
                          src="/assets/img/gradient2.png"
                          onClick={(e) =>
                            setform({
                              ...form,
                              qrBodyPatternGradientColorType: "radial",
                            })
                          }
                        />
                      </div>

                      {/* <div className="col-3 px-2">
                                            <div className="gradient-link">
                                                <img src="/assets/img/gradient3.png" />
                                            </div>
                                        </div>
                                        <div className="col-3 px-2">
                                            <div className="gradient-link">
                                                <img src="/assets/img/gradient4.png" />
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                  <div className="rowDiv align-items-center">
                    <div className="gradient_code-btn d-flex gap-3 align-items-center">
                      <div
                        className="dropdown d-flex align-items-center"
                        ref={dropdownRefBodyPatternGradient1}
                      >
                        <button
                          style={{
                            backgroundColor:
                              form?.qrBodyPatternGradientColor1 || "#030202",
                          }}
                          className="color_box"
                          onClick={() =>
                            setIsOpen({
                              ...isOpen,
                              qrBodyPatternGradient1:
                                !isOpen?.qrBodyPatternGradient1,
                            })
                          }
                          aria-expanded={isOpen?.qrBodyPatternGradient1}
                        ></button>
                        <span className="ml-2">
                          {form?.qrBodyPatternGradientColor1 || "#030202"}
                        </span>
                        {isOpen?.qrBodyPatternGradient1 && (
                          <div className="dropdown-menu p-0 d-block">
                            <SketchPicker
                              color={
                                form?.qrBodyPatternGradientColor1 || "#030202"
                              }
                              onChange={(color) =>
                                setform({
                                  ...form,
                                  qrBodyPatternGradientColor1: color?.hex,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      {/* <input className="color-picker" type="color" id="color" name="color" value={form?.qrBodyPatternGradientColor1 || '#030202'} onChange={e => setform({ ...form, qrBodyPatternGradientColor1: e.target.value })} />{form?.qrBodyPatternGradientColor1 || '#030202'} */}
                    </div>

                    <img
                      src="/assets/img/compare_arrows.png"
                      className="campare_arrow pointer"
                      onClick={(e) => swapValues("QrBodyPattern")}
                    />

                    <div className="gradient_code-btn d-flex gap-3 align-items-center">
                      <div
                        className="dropdown d-flex align-items-center"
                        ref={dropdownRefBodyPatternGradient2}
                      >
                        <button
                          style={{
                            backgroundColor:
                              form?.qrBodyPatternGradientColor2 || "#030202",
                          }}
                          className="color_box"
                          onClick={() =>
                            setIsOpen({
                              ...isOpen,
                              qrBodyPatternGradient2:
                                !isOpen?.qrBodyPatternGradient2,
                            })
                          }
                          aria-expanded={isOpen?.qrBodyPatternGradient2}
                        ></button>
                        <span className="ml-2">
                          {form?.qrBodyPatternGradientColor2 || "#030202"}
                        </span>
                        {isOpen?.qrBodyPatternGradient2 && (
                          <div className="dropdown-menu p-0 d-block">
                            <SketchPicker
                              color={
                                form?.qrBodyPatternGradientColor2 || "#030202"
                              }
                              onChange={(color) =>
                                setform({
                                  ...form,
                                  qrBodyPatternGradientColor2: color?.hex,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="info_detail mb-3">
            <label for="" className="fw-bold">
              Scannability <span className="fw-normal">(Error Correction)</span>{" "}
              <img
                src="/assets/img/error-correction.png"
                className="img-fluid ms-2"
                alt=""
              />
            </label>
            <select
              className="form-select col-md-6"
              value={slug=='vcard'?'L':form?.errorCorrection}
              onChange={(e) =>
                setform({ ...form, errorCorrection: e.target.value })
              }
              aria-label="Default select example"
            >
              <option value="L">Level L (Low) - 7%</option>
              <option value="M">Level M (Medium) - 15%</option>
              <option value="Q">Level Q (Quartile) - 25%</option>
              <option value="H">Level H (High) - 30%</option>
            </select>
          </div>

          {urlPath == "/bulk-qr-code" ? null : (
            <label for="" className="my-3 fw-bold">
              <h6 className="fw-bold">Background</h6>
            </label>
          )}
          {urlPath == "/bulk-qr-code" ? null : (
            <>
              <div className="col-sm-3 col-md-3 ps-0">
                <div className="form-check check-radio_btn mb-0">
                  <label className="form-check-label fw-normal" for="button313">
                    <input
                      className="form-check-input"
                      checked={radiobtn?.backgroundColorType}
                      onClick={(e) =>
                        setradiobtn({ ...radiobtn, backgroundColorType: true })
                      }
                      type="radio"
                      name="flexRadioDefault"
                      id="button313"
                    />
                    Solid Color
                  </label>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 ps-0">
                <div className="form-check check-radio_btn mb-0">
                  <label className="form-check-label fw-normal" for="check1213">
                    <input
                      className="form-check-input"
                      checked={!radiobtn?.backgroundColorType}
                      onClick={(e) =>
                        setradiobtn({ ...radiobtn, backgroundColorType: false })
                      }
                      type="radio"
                      name="flexRadioDefault"
                      id="check1213"
                    />
                    Gradient Color
                  </label>
                </div>
              </div>
              <div className="row form-check d-flex my-3"></div>
              {radiobtn?.backgroundColorType ? (
                <div className="col-md-6">
                  <div className="color-code mb-3">
                    <div className="code-btn d-flex gap-3 align-items-center">
                      <div
                        className={`dropdown d-flex align-items-center ${
                          isOpen?.backgroundColor ? "dropup" : ""
                        }`}
                        ref={dropdownRefbackgroundColor}
                      >



                        {/* We need */}
                        <button
                          style={{ backgroundColor:  form?.backgroundGradientColor1 }}
                          className="color_box"
                          
                          onClick={() =>
                            {setIsOpen({
                              ...isOpen,
                              backgroundColor: !isOpen?.backgroundGradientColor1,
                              
                            });}
                          }
                          
                          aria-expanded={isOpen?.backgroundGradientColor1}
                        ></button>
                        <span className="ml-2">{form?.backgroundColor}</span>
                        {isOpen?.backgroundColor && (
                          <div className="dropdown-menu p-0 d-block">
                            <SketchPicker
                              color={form?.backgroundGradientColor1}
                              onChange={(color) =>
                                setform({
                                  ...form,
                                  backgroundGradientColor1: color?.hex,
                                  backgroundGradientColor2: color?.hex,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      {/* <input className="color-picker" type="color" id="color" name="color" value={form?.backgroundColor} onChange={e => setform({ ...form, backgroundColor: e.target.value, backgroundGradientColor1: '', backgroundGradientColor2: '' })} />{form?.backgroundColor} */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="gradient-div">
                    <div className="rowDiv align-items-center">
                      <h6 className="fw-bold mb-0">Gradient Type</h6>

                      <div className="rowDiv">
                        <div
                          className="gradient-link gradient-active pointer"
                          onClick={(e) =>
                            setform({
                              ...form,
                              backgroundGradientColorType: "linear",
                            })
                          }
                        >
                          <img src="/assets/img/gradient1.png" />
                        </div>

                        <div
                          className="gradient-link pointer"
                          onClick={(e) =>
                            setform({
                              ...form,
                              backgroundGradientColorType: "radial",
                            })
                          }
                        >
                          <img src="/assets/img/gradient2.png" />
                        </div>
                      </div>
                    </div>
                    <div className="rowDiv align-items-center">
                      <div className="gradient_code-btn d-flex gap-3 align-items-center">
                        <div
                          className={`dropdown d-flex align-items-center ${
                            isOpen?.backgroundGradientColor1 ? "dropup" : ""
                          }`}
                          ref={dropdownRefbackgroundGradientColor1}
                        >
                          <button
                            style={{
                              backgroundColor:
                                form?.backgroundGradientColor1 || "#FFFFFF",
                            }}
                            className="color_box"
                            onClick={() =>
                             { setIsOpen({
                                ...isOpen,
                                backgroundGradientColor1:
                                  !isOpen?.backgroundGradientColor1,
                              })}
                            }
                            aria-expanded={isOpen?.backgroundGradientColor1}
                          ></button>
                          <span className="ml-2">
                            {form?.backgroundGradientColor1 || "#FFFFFF"}
                          </span>
                          {isOpen?.backgroundGradientColor1 && (
                            <div className="dropdown-menu p-0 d-block">
                              <SketchPicker
                                color={
                                  form?.backgroundGradientColor1 || "#FFFFFF"
                                }
                                onChange={(color) =>
                                  setform({
                                    ...form,
                                    backgroundGradientColor1: color?.hex,
                                  })
                                }
                              />
                            </div>
                          )}
                        </div>
                        {/* <input className="color-picker" type="color" id="color" name="color" value={form?.backgroundGradientColor1 || '#FFFFFF'} onChange={e => setform({ ...form, backgroundGradientColor1: e.target.value })} />{form?.backgroundGradientColor1 || '#FFFFFF'} */}
                      </div>

                      <img
                        src="/assets/img/compare_arrows.png"
                        className="campare_arrow pointer"
                        onClick={(e) => swapValues("BackgroundColor")}
                      />

                      <div className="gradient_code-btn d-flex gap-3 align-items-center">
                        <div
                          className={`dropdown d-flex align-items-center ${
                            isOpen?.backgroundGradientColor2 ? "dropup" : ""
                          }`}
                          ref={dropdownRefbackgroundGradientColor2}
                        >
                          <button
                            style={{
                              backgroundColor:
                                form?.backgroundGradientColor2 || "#FFFFFF",
                            }}
                            className="color_box"
                            onClick={() =>
                              setIsOpen({
                                ...isOpen,
                                backgroundGradientColor2:
                                  !isOpen?.backgroundGradientColor2,
                              })
                            }
                            aria-expanded={isOpen?.backgroundGradientColor2}
                          ></button>
                          <span className="ml-2">
                            {form?.backgroundGradientColor2 || "#FFFFFF"}
                          </span>
                          {isOpen?.backgroundGradientColor2 && (
                            <div className="dropdown-menu p-0 d-block">
                              <SketchPicker
                                color={
                                  form?.backgroundGradientColor2 || "#FFFFFF"
                                }
                                onChange={(color) =>
                                  setform({
                                    ...form,
                                    backgroundGradientColor2: color?.hex,
                                  })
                                }
                              />
                            </div>
                          )}
                        </div>
                        {/* <input className="color-picker" type="color" id="color" name="color" value={form?.backgroundGradientColor2 || '#FFFFFF'} onChange={e => setform({ ...form, backgroundGradientColor2: e.target.value })} />{form?.backgroundGradientColor2 || '#FFFFFF'} */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {/* <label for="" className="my-3 fw-bold">
                        CMYK Color
                    </label>
                    <h6 className="d-flex align-items-center gap-3 mb-0">  <img src="/assets/img/cmyk.png" className=" img-fluid me-4 " alt="" />   <div className="form-check form-switch switch-btn">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                        <label className="form-check-label " for="flexSwitchCheckChecked">Enable</label>
                    </div></h6> */}
        </div>
      </div>
    </>
  );
};

export default PatternColors;
