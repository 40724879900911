import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import methodModal from "../../methods/methods";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import ApiClient from "../../methods/api/apiClient";
import Select from "react-select";
import environment from "../../environment";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { use } from "echarts";

const Forms = ({
  form,
  setform,
  common,
  setcommon,
  vcard,
  setvcard,
  slugData,
  smartURL,
  setsmartURL,
  location,
  setlocation,
  numberOfScans,
  setnumberOfScans,
  time,
  settime,
  language,
  setlanguage,
  qrTypesViewMore,
}) => {
  const { slug } = useParams();
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state);
  useEffect(() => {}, []);
  const [eye, seteye] = useState(false);
  const [enterUrl,setEnterUrl] = useState(false);
  const urlPath = window.location.pathname;
  // For Whatsapp
  const whatsappNum = useRef();
  const whatsappMsg = useRef();
  // For Email
  const emailaddress = useRef();
  const emailcc = useRef();
  const emailbcc = useRef();
  const emailsubject = useRef();
  const emailMsg = useRef();
  // For Text
  const textmes = useRef();
  // For Telegram
  const telegramNum = useRef();
  const telegramMsg = useRef();

  // For Device Information
  const userAgent =
    navigator.userAgent ||
    navigator.vendor ||
    window.opera ||
    window.navigator.userAgent;
  // For All Timezones
  const [timezone, settimezone] = useState();

  // PayNow Qr Code Functions
  const generateString = (
    uenOrMobile,
    isMobile,
    expiryDate,
    companyName,
    isAmountEditable,
    amount,
    reference
  ) => {
    let uenormobile_1;
    if (isMobile) {
      uenormobile_1 = expiryDate ? "650" : "638";
    } else {
      uenormobile_1 = expiryDate ? "649" : "637";
    }
    const uenormobile_2 = isMobile ? "0" : "2";
    const if_expiry = expiryDate
      ? `04${getLength(expiryDate)}${expiryDate}`
      : "";
    const if_companyName = companyName
      ? `59${getLength(companyName)}${companyName}`
      : "";
    const editable_amount = isAmountEditable || !amount ? "1" : "0";
    const if_amount = amount ? `54${getLength(amount)}${amount}` : "";
    let refPart = "";
    if (reference) {
      const referenceLength = getLength(reference);
      const totalLength = getLength("01" + referenceLength + reference);
      refPart = `62${totalLength}01${referenceLength}${reference}`;
    }
    let str = `0002010102122${uenormobile_1}0009SG.PAYNOW0101${uenormobile_2}02${getLength(
      uenOrMobile
    )}${uenOrMobile}0301${editable_amount}${if_expiry}${if_amount}5802SG${if_companyName}6009Singapore${refPart}6304`;
    function getLength(value) {
      return value?.toString()?.length?.toString()?.padStart(2, "0");
    }
    let checksum = crc16(str);
    return str + checksum;
  };
  const crc16 = (s) => {
    var crcTable = [
      0x0000, 0x1021, 0x2042, 0x3063, 0x4084, 0x50a5, 0x60c6, 0x70e7, 0x8108,
      0x9129, 0xa14a, 0xb16b, 0xc18c, 0xd1ad, 0xe1ce, 0xf1ef, 0x1231, 0x0210,
      0x3273, 0x2252, 0x52b5, 0x4294, 0x72f7, 0x62d6, 0x9339, 0x8318, 0xb37b,
      0xa35a, 0xd3bd, 0xc39c, 0xf3ff, 0xe3de, 0x2462, 0x3443, 0x0420, 0x1401,
      0x64e6, 0x74c7, 0x44a4, 0x5485, 0xa56a, 0xb54b, 0x8528, 0x9509, 0xe5ee,
      0xf5cf, 0xc5ac, 0xd58d, 0x3653, 0x2672, 0x1611, 0x0630, 0x76d7, 0x66f6,
      0x5695, 0x46b4, 0xb75b, 0xa77a, 0x9719, 0x8738, 0xf7df, 0xe7fe, 0xd79d,
      0xc7bc, 0x48c4, 0x58e5, 0x6886, 0x78a7, 0x0840, 0x1861, 0x2802, 0x3823,
      0xc9cc, 0xd9ed, 0xe98e, 0xf9af, 0x8948, 0x9969, 0xa90a, 0xb92b, 0x5af5,
      0x4ad4, 0x7ab7, 0x6a96, 0x1a71, 0x0a50, 0x3a33, 0x2a12, 0xdbfd, 0xcbdc,
      0xfbbf, 0xeb9e, 0x9b79, 0x8b58, 0xbb3b, 0xab1a, 0x6ca6, 0x7c87, 0x4ce4,
      0x5cc5, 0x2c22, 0x3c03, 0x0c60, 0x1c41, 0xedae, 0xfd8f, 0xcdec, 0xddcd,
      0xad2a, 0xbd0b, 0x8d68, 0x9d49, 0x7e97, 0x6eb6, 0x5ed5, 0x4ef4, 0x3e13,
      0x2e32, 0x1e51, 0x0e70, 0xff9f, 0xefbe, 0xdfdd, 0xcffc, 0xbf1b, 0xaf3a,
      0x9f59, 0x8f78, 0x9188, 0x81a9, 0xb1ca, 0xa1eb, 0xd10c, 0xc12d, 0xf14e,
      0xe16f, 0x1080, 0x00a1, 0x30c2, 0x20e3, 0x5004, 0x4025, 0x7046, 0x6067,
      0x83b9, 0x9398, 0xa3fb, 0xb3da, 0xc33d, 0xd31c, 0xe37f, 0xf35e, 0x02b1,
      0x1290, 0x22f3, 0x32d2, 0x4235, 0x5214, 0x6277, 0x7256, 0xb5ea, 0xa5cb,
      0x95a8, 0x8589, 0xf56e, 0xe54f, 0xd52c, 0xc50d, 0x34e2, 0x24c3, 0x14a0,
      0x0481, 0x7466, 0x6447, 0x5424, 0x4405, 0xa7db, 0xb7fa, 0x8799, 0x97b8,
      0xe75f, 0xf77e, 0xc71d, 0xd73c, 0x26d3, 0x36f2, 0x0691, 0x16b0, 0x6657,
      0x7676, 0x4615, 0x5634, 0xd94c, 0xc96d, 0xf90e, 0xe92f, 0x99c8, 0x89e9,
      0xb98a, 0xa9ab, 0x5844, 0x4865, 0x7806, 0x6827, 0x18c0, 0x08e1, 0x3882,
      0x28a3, 0xcb7d, 0xdb5c, 0xeb3f, 0xfb1e, 0x8bf9, 0x9bd8, 0xabbb, 0xbb9a,
      0x4a75, 0x5a54, 0x6a37, 0x7a16, 0x0af1, 0x1ad0, 0x2ab3, 0x3a92, 0xfd2e,
      0xed0f, 0xdd6c, 0xcd4d, 0xbdaa, 0xad8b, 0x9de8, 0x8dc9, 0x7c26, 0x6c07,
      0x5c64, 0x4c45, 0x3ca2, 0x2c83, 0x1ce0, 0x0cc1, 0xef1f, 0xff3e, 0xcf5d,
      0xdf7c, 0xaf9b, 0xbfba, 0x8fd9, 0x9ff8, 0x6e17, 0x7e36, 0x4e55, 0x5e74,
      0x2e93, 0x3eb2, 0x0ed1, 0x1ef0,
    ];
    var crc = 0xffff; // Initial value for CRC-CCITT
    for (var i = 0; i < s.length; i++) {
      let c = s.charCodeAt(i);
      if (c > 255) {
        throw new RangeError();
      }
      let j = (c ^ (crc >> 8)) & 0xff;
      crc = crcTable[j] ^ (crc << 8);
    }
    // XOR with 0 and mask with 0xFFFF, then convert to hexadecimal string, ensuring uppercase and 4 characters
    return ((crc ^ 0) & 0xffff).toString(16).toUpperCase().padStart(4, "0");
  };

  useEffect(() => {
    getAllTimeZones();
  }, []);

  const getAllTimeZones = () => {
    ApiClient.get(`timezones?page=1&sortBy=GMT_Offset`).then((res) => {
      if (res.success) {
        settimezone(
          res?.data.map((item) => {
            return {
              value: item?.TimeZone,
              label: `${item?.TimeZone} ${item?.GMT_Offset}`,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    if (slug == "vcard" && vcard) {
      let value = utf8Encode(
        `BEGIN:VCARD\nVERSION: 3.0\nN:${vcard?.lastName || ""};${
          vcard?.firstName || ""
        }\nFN:${vcard?.firstName || ""} ${vcard?.lastName || ""}\n${
          vcard?.company ? `ORG:${vcard?.company}\n` : ""
        }${vcard?.jobPosition ? `TITLE:${vcard?.jobPosition}\n` : ""}${
          vcard?.website ? `URL:${vcard?.website}\n` : ""
        }${vcard?.personalEmail ? `EMAIL;HOME:${vcard?.personalEmail}\n` : ""}${
          vcard?.workEmail ? `EMAIL;WORK:${vcard?.workEmail}\n` : ""
        }${vcard?.workOffice ? `TEL;WORK;VOICE:${vcard?.workOffice}\n` : ""}${
          vcard?.personalHome ? `TEL;HOME;VOICE:${vcard?.personalHome}\n` : ""
        }${
          vcard?.personalMobile
            ? `TEL;CELL;VOICE:${vcard?.personalMobile}\n`
            : ""
        }${vcard?.workMobile ? `TEL;WORK;CELL:${vcard?.workMobile}\n` : ""}${
          vcard?.workFax ? `TEL;TYPE=work, FAX:${vcard?.workFax}\n` : ""
        }${vcard?.personalFax ? `c:${vcard?.personalFax}\n` : ""}ADR;WORK:;;${
          vcard?.street || ""
        };${vcard?.city || ""};${vcard?.state || ""};${vcard?.zipCode || ""};${
          vcard?.country || ""
        }\nEND:VCARD`
      );
      setform({
        ...form,
        value: value,
        errorCorrection: slug == "vcard" ? "L" : form?.errorCorrection,
      });
    }
  }, [vcard]);

  const convertFormat = (dateString) => {
    return moment(dateString).format("YYYYMMDDTHHmmss");
  };

  const handleRuleType = (e) => {
    setsmartURL({ ruleType: e, defaultUrl: "" });
    setlocation([{ country: "", region: "", city: "", url: "" }]);
    setnumberOfScans([{ scans: "", url: "" }]);
    settime([{ timezone: "", startTime: "", endTime: "", url: "" }]);
    setlanguage([{ language: "", url: "" }]);
  };

  const urlsQrCodeOnly = () => {
    if (
      qrTypesViewMore?.find((item) => item?.slug == slug.replaceAll("-", " "))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const utf8Encode = (str) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    return String.fromCharCode(...data);
  };

  const hanldeForms = (
    value1 = "",
    value2 = "",
    value3 = "",
    value4 = "",
    value5 = "",
    value6 = "",
    value7 = "",
    value8 = "",
    value9 = ""
  ) => {
    if (
      !slug ||
      slug == "urls" ||
      slug == "facebook" ||
      slug == "instagram" ||
      slug == "youtube" ||
      slug == "google-forms" ||
      slug == "docs" ||
      slug == "sheets" ||
      slug == "spotify" ||
      slug == "vimeo" ||
      slug == "apple-music" ||
      slug == "tiktok" ||
      slug == "stripe"
    ) {
      if (
        (value1.length >= 4 && !slug) ||
        (value1.length >= 4 && slug == "urls")
      ) {
        if (!value1.includes("http")) {
          setcommon({ ...common, value1: `http://${value1}` });
          value1 = `http://${value1}`;
        }
      }
      setcommon({ ...common, value1: value1 });
      setform({ ...form, value: value1 });
    } else if (slug == "whatsapp") {
      if (value1) whatsappNum.current = value1;
      if (value2) whatsappMsg.current = value2;
      setcommon((previous) => ({
        ...previous,
        value1: value1,
        value2: value2,
      }));
      setform({
        ...form,
        value: `https://wa.me/${value1}?text=${
          encodeURIComponent(value2) || ""
        }`,
      });
    } else if (slug == "wifi") {
      setcommon({
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
      });
      setform({
        ...form,
        value: `WIFI:S:${value1};T:${value2};P:${value3};H:${value4};;`,
      });
    } else if (slug == "events") {
      setcommon({
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
      });
      let value = utf8Encode(
        `BEGIN:VEVENT\nSUMMARY:${value1}\nDTSTART:${convertFormat(
          value3
        )}\nDTEND:${convertFormat(value4)}\nLOCATION:${value2}\nEND:VEVENT`
      );
      setform({ ...form, value: value });
    } else if (slug == "paypal") {
      setcommon({
        ...common,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
      });
      setform({
        ...form,
        value: `https://www.paypal.com/checkout/pay?currency=${value6}&amount=${value5}&item_name=${value3}&item_number=${value4}&shipping=${value7}&tax=${
          value5 * (value8 / 100)
        }&custom=${value2}`,
      });
    } else if (slug == "call") {
      setcommon({ ...common, value1: value1 });
      setform({ ...form, value: `tel:${value1}` });
    } else if (slug == "sms") {
      setcommon({ ...common, value1: value1, value2: value2 });
      setform({
        ...form,
        value: `sms:${value1}?body=${encodeURIComponent(utf8Encode(value2))}`,
      });
    } else if (slug == "email") {
      if (value1) emailaddress.current = value1;
      if (value2) emailcc.current = value2;
      if (value3) emailbcc.current = value3;
      if (value4) emailsubject.current = value4;
      if (value5) emailMsg.current = value5;
      setcommon((previous) => ({
        ...previous,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
        value5: value5,
      }));
      setform({
        ...form,
        value: `mailto:${encodeURIComponent(
          value1
        )}?subject=${encodeURIComponent(value4)}&body=${encodeURIComponent(
          utf8Encode(value5)
        )}&cc=${encodeURIComponent(value2)}&bcc=${encodeURIComponent(value3)}`,
      });
    } else if (slug == "text") {
      if (value1) textmes.current = value1;
      setcommon({ ...common, value1: value1 });
      setform({ ...form, value: utf8Encode(value1) });
    } else if (slug == "location" || slug == "maps") {
      setcommon({
        ...common,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
      });
      setform({
        ...form,
        value: `https://www.google.com/maps/search/?api=1&query=${value3},${value4}`,
      });
    } else if (slug == "telegram") {
      if (value1) telegramNum.current = value1;
      if (value2) telegramMsg.current = value2;
      setcommon((previous) => ({
        ...previous,
        value1: value1,
        value2: value2,
      }));
      setform({
        ...form,
        value: `tg://resolve?domain=${encodeURIComponent(value1)}`,
      });
    } else if (slug == "bitcoin") {
      setcommon({
        ...common,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
      });
      let value = utf8Encode(
        `${value1}?amount=${value3}${
          !value2 ? "URI" : ""
        }&message=${encodeURIComponent(value4)}`
      );

      setform({
        ...form,
        value: value,
        // value: `${utf8Encode(value1)}?amount=${utf8Encode(value3)}${!value2 ? "URI" : ""}&message=${encodeURIComponent((value4))}`,
      });
    } else if (slug == "multiple-apps") {
      setcommon({
        ...common,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
        value5: value5,
        value6: value6,
        value7: value7,
      });
    } else if (slug == "paynow") {
      setcommon({
        ...common,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
        value5: value5,
        value6: value6,
        value7: value8 ? value7 : "",
        value8: value8,
        value9: value9,
      });

      let uenOrMobile = !value1 ? `+65${value2}` : value2;
      let isMobile = !value1;
      let isAmountEditable = value4;
      let expiryDate = value7 ? `${moment(value7).format("YYYYMMDD")}` : "";
      let companyName = value9;
      let amount = value3;
      let reference = value5;

      let result = generateString(
        uenOrMobile,
        isMobile,
        expiryDate,
        companyName,
        isAmountEditable,
        amount,
        reference
      );

      setform({ ...form, value: result });
    } else if (slug == "sepa") {
      setcommon({
        ...common,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
        value5: value5,
        value6: value6,
        value7: value7,
        value8: value8,
        value9: value9,
      });
      setform({
        ...form,
        value: `BCD\n${
          value7 == false ? "001" : "002"
        }\n1\nSCT\n${value3}\n${value1}\n${value2}\nEUR${value4}\n${value5}\n${value6}\n${value7}\n${value8}`,
      });
    } else if (urlsQrCodeOnly()) {
      setcommon({ ...common, value1: value1 });
      setform({ ...form, value: value1 });
    } else {
      return;
    }
  };

  const getLength = (value) => {
    return value?.toString()?.length?.toString()?.padStart(2, "0");
  };

  const names = () => {
    if (urlPath == "/urls" || slug == "urls") return "URL";
    else if (slug == "whatsapp") return "your WhatsApp number and message";
    else if (slug == "facebook")
      return "your Facebook page URL or Facebook Messenger URL";
    else if (slug == "instagram") return "your Instagram Handle or Link";
    else if (slug == "youtube")
      return "the YouTube video URL or YouTube Channel";
    else if (slug == "google-forms") return "your Google Forms URL";
    else if (slug == "wifi") return "your WiFi details";
    else if (slug == "events") return "your calendar details";
    else if (slug == "paypal") return "your Paypal details";
    else if (slug == "spotify") return "your Spotify URL";
    else if (slug == "apple-music") return "your Apple Music URL";
    else if (slug == "docs") return "your Google Docs URL";
    else if (slug == "sheets") return "your Google Sheets URL";
    else if (slug == "call") return "your phone number";
    else if (slug == "sms") return "your phone number and message";
    else if (slug == "email") return "your email details";
    else if (slug == "text") return "your text";
    else if (slug == "location" || slug == "maps")
      return "your location details";
    else if (slug == "telegram") return "your Telegram number and message";
    else if (slug == "tiktok") return "your TikTok username";
    else if (slug == "bitcoin") return "your Bitcoin address details";
    else if (slug == "vcard") return "your contact details";
    else if (slug == "multiple-apps") return "at least 1 URL";
    else if (slug == "paynow") return "your PayNow details";
    else if (slug == "sepa") return "your SEPA details";
    else if (slug == "stripe") return "your Stripe payment link";
    else if (slug == "smart-url") return "each URL and its redirect rule";
    else if (slug == "file") return "your file";
    else if (urlsQrCodeOnly())
      return (
        <span>
          your{" "}
          <span className="text-capitalize">{slug.replaceAll("-", " ")}</span>{" "}
          URL
        </span>
      );
    else return "URL";
  };

  const formImages = () => {
    return slugData?.headerImg
      ? `${environment?.api}/images/qrcodetypes/${slugData?.headerImg}`
      : "";
    // if (slug == 'whatsapp') {
    //     return slugData?.headerImg?`${environment?.api}/images/qrcodetypes/${slugData?.headerImg}`:""
    // } else if (slug == 'facebook') {
    //     return "/assets/img/facebook_img.png"
    // } else if (slug == 'instagram') {
    //     return "/assets/img/instagram_img.png"
    // } else if (slug == 'youtube') {
    //     return "/assets/img/youtube_img.png"
    // } else if (slug == 'google-forms') {
    //     return "/assets/img/google_form_img.png"
    // } else if (slug == 'spotify'){
    //     return "/assets/img/spotify_icon.png"
    // } else if (slug == 'apple-music'){
    //     return "/assets/img/apple_music_img.png"
    // } else if (slug == 'vimeo'){
    //     return "/assets/img/vimeo_img.png"
    // } else if (slug == 'docs'){
    //     return "/assets/img/google_docs_img.png"
    // } else if (slug == 'sheets'){
    //     return "/assets/img/google_sheets_img.png"
    // } else if (slug == 'call'){
    //     return "/assets/img/call_img.png"
    // } else if (slug == 'sms'){
    //     return "/assets/img/sms_img.png"
    // } else if (slug == 'email'){
    //     return '/assets/img/email_img.png'
    // } else if (slug == 'text'){
    //     return '/assets/img/text_img.png'
    // } else if (slug == 'location' || slug == 'maps'){
    //     return '/assets/img/location_img.png'
    // } else if (slug == 'telegram'){
    //     return '/assets/img/telegram_img.png'
    // } else if (slug == 'tiktok'){
    //     return '/assets/img/tiktok_img.png'
    // } else if (slug == 'bitcoin'){
    //     return "/assets/img/bitcoin_img.png"
    // } else if (slug == 'multiple-apps'){
    //     return "/assets/img/multiple_qr_img.png"
    // } else if (slug == 'paynow'){
    //     return "/assets/img/paynow_img.png"
    // } else if (slug == 'sepa'){
    //     return "/assets/img/sepa_img.png"
    // } else if (slug == 'stripe'){
    //     return "/assets/img/stripe_img.png"
    // } else if (slug == 'smart-url'){
    //     return "/assets/img/smart_url_img.png"
    // } else if (slug == 'file'){
    //     return "assets/img/file_img.png"
    // }
  };

  const formLabel = () => {
    if (slug == "whatsapp") {
      return "Whatsapp Number";
    } else if (slug == "facebook") {
      return "Facebook Page URL or Messenger URL";
    } else if (slug == "instagram") {
      return "Instagram Handle or Link";
    } else if (slug == "youtube") {
      return "YouTube URL";
    } else if (slug == "google-forms") {
      return "Google Forms URL";
    } else if (slug == "docs") {
      return "Google Docs URL";
    } else if (slug == "sheets") {
      return "Google Sheets URL";
    } else if (slug == "spotify") {
      return "Spotify URL";
    } else if (slug == "apple-music") {
      return "Apple Music URL";
    } else if (slug == "vimeo") {
      return "Vimeo URL";
    } else if (slug == "stripe") {
      return "Stripe Payment Link";
    }
  };

  const formPlaceholders = () => {
    if (slug == "facebook") {
      return "https://www.facebook.com/yourpage or https://m.me/yourpage";
    } else if (slug == "instagram") {
      return "https://www.instagram.com/";
    } else if (slug == "youtube") {
      return "https://www.youtube.com/";
    } else if (slug == "google-forms" || slug == "docs" || slug == "sheets") {
      return "https://docs.google.com/";
    } else if (slug == "spotify") {
      return "https://open.spotify.com/";
    } else if (slug == "apple-music") {
      return "https://music.apple.com/";
    } else if (slug == "vimeo") {
      return "https://vimeo.com/";
    } else if (slug == "stripe") {
      return "https://";
    }
  };

  const handleEmoji = (e) => {
    if (slug == "whatsapp") {
      let message = whatsappMsg.current + e?.emoji;
      whatsappMsg.current = message;
      setcommon((previous) => ({
        ...previous,
        value2: previous?.value2 + e.emoji,
      }));
      setform((previous) => ({
        ...previous,
        value: `https://wa.me/${whatsappNum?.current}?text=${
          encodeURIComponent(message) || ""
        }`,
      }));
    } else if (slug == "email") {
      let message = emailMsg.current + e?.emoji;
      emailMsg.current = message;
      setcommon((previous) => ({
        ...previous,
        value5: previous?.value5 + e.emoji,
      }));
      setform((previous) => ({
        ...previous,
        value: `mailto:${encodeURIComponent(
          emailaddress.current
        )}?cc=${encodeURIComponent(emailcc.current)}&bcc=${encodeURIComponent(
          emailbcc.current
        )}&subject=${encodeURIComponent(
          emailsubject.current
        )}&body=${encodeURIComponent(message)}`,
      }));
    } else if (slug == "text") {
      let message = textmes.current + e?.emoji;
      textmes.current = message;
      setcommon((previous) => ({
        ...previous,
        value1: previous?.value1 + e.emoji,
      }));
      setform({ ...form, value: utf8Encode(message) });
    } else if (slug == "telegram") {
      let message = telegramMsg.current + e?.emoji;
      telegramMsg.current = message;
      setcommon((previous) => ({
        ...previous,
        value2: previous?.value2 + e.emoji,
      }));
      setform({
        ...form,
        value: `https://t.me/${telegramNum.current}}?start=${
          encodeURIComponent(telegramMsg.current) || ""
        }`,
      });
    }
  };

  const addressResult = (e) => {
    setcommon({
      ...common,
      value2: e?.value,
      value3: e?.address?.lat,
      value4: e?.address?.lng,
    });
    setform({
      ...form,
      value: `https://www.google.com/maps/search/?api=1&query=${e?.address?.lat},${e?.address?.lng}`,
    });
  };

  //Add More
  const addMore = () => {
    if (smartURL?.ruleType == "location")
      setlocation([
        ...location,
        { country: "", region: "", city: "", url: "" },
      ]);
    else if (smartURL?.ruleType == "number_of_scans")
      setnumberOfScans([...numberOfScans, { scans: "", url: "" }]);
    else if (smartURL?.ruleType == "time")
      settime([...time, { timezone: "", startTime: "", endTime: "", url: "" }]);
    else setlanguage([...language, { language: "", url: "" }]);
  };
  const removeAddMore = (index) => {
    if (smartURL?.ruleType == "location") {
      let array = location;
      array = array.filter((item, i) => i != index);
      setlocation([...array]);
    } else if (smartURL?.ruleType == "number_of_scans") {
      let array = numberOfScans;
      array = array.filter((item, i) => i != index);
      setnumberOfScans([...array]);
    } else if (smartURL?.ruleType == "time") {
      let array = time;
      array = array.filter((item, i) => i != index);
      settime([...array]);
    } else {
      let array = language;
      array = array.filter((item, i) => i != index);
      setlanguage([...array]);
    }
  };
  const handleAddMoreFields = (index, keyName, value) => {
    
    if (smartURL?.ruleType == "location") {
      let array = location;
      array[index][keyName] = value;
      setlocation([...array]);
    } else if (smartURL?.ruleType == "number_of_scans") {
      let array = numberOfScans;
      array[index][keyName] = value;
      if (keyName == "scans") {
        array[index]["totalScans"] = value;
      }
      setnumberOfScans([...array]);
    } else if (smartURL?.ruleType == "time") {
      let array = time;
      array[index][keyName] = value;
      settime([...array]);
    } else {
      let array = language;
      array[index][keyName] = value;
      setlanguage([...array]);
    }
  };

  const SaveQRCode = () => {
    ApiClient.post(`qrcode/download`, payload).then((res) => {
      if (res.success) {
        // qrCode.update({ data: `https://qrcode.jcsoftwaresolution.in/redirecting/${res?.data?.slug}` })
        // qrCode.download({ name: "QR Code", extension: selectFormat || "jpeg", quality: '1' });
        var link = document.createElement("a");
        link.href = qrImage;
        if (selectFormat == "png") {
          link.download = `QrCode.png`;
        } else {
          link.download = `QrCode.jpeg`;
        }
        link.click();
        if (urlPath.includes("/app/qrcodes")) {
          history.push("/app/qrcodelisting");
        }
      }
      loader(false);
    });
  };

  const HandleIploadDocuments = async (e) => {
    let formData = new FormData();
    let file = e.target.files[0];
    let size = file?.size / 1048576;
    let BypteToMb = Math.round(size);

    const ActiveSizeLimit = user?.activePlan?.name;
    if (ActiveSizeLimit == "free" && BypteToMb > 2) {
      return toast.error("File size should be same as your plan");
    }
    if (ActiveSizeLimit == "starter" && BypteToMb > 10) {
      return toast.error("File size should be same as your plan");
    }
    if (ActiveSizeLimit == "premium" && BypteToMb > 20) {
      return toast.error("File size should be same as your plan");
    }
    if (ActiveSizeLimit == "buisness" && BypteToMb > 30) {
      return toast.error("File size should be same as your plan");
    }
    formData.append("file", file);

    ApiClient.postFormData("upload/document", { file: file }).then((res) => {
      setform({ ...form, value: res?.data?.imagePath, fileName: file?.name });
    });
  };

  useEffect(() => {
    formImages();
  }, [slug]);

  return (
    <>
      <p className="fw-bold">STEP 1: Enter {names()}</p>
      <div className="mb-3">
        {!slug || slug == "urls" ? (
          <>
            <label for="exampleInputEmail1" className="form-label">
              Enter your URL
            </label>
            <input
              type="text"
              value={form?.value}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 500) {
                  hanldeForms(inputValue);
                  setEnterUrl(false)
                } else {
                  setEnterUrl(true)
                  hanldeForms(inputValue.slice(0, 500));
                }
              }}
              className="form-control email-input"
              id="urlInputField"
              aria-describedby="emailHelp"
              placeholder="https://"
            />
           {enterUrl && <div style={{color:'red'}}>You Exceed the limit max 500</div>}
          </>
        ) : slug == "whatsapp" ? (
          <>
            <div className="mb-1">
              <img src="/assets/img/lightbulb_.png" />
              <label>
                By scanning this QR code, your customers can send a pre-loaded
                message to you on WhatsApp
              </label>
            </div>
            <img src={formImages()} width="180px" className="mb-3" />
            <br />
            <label className="form-label">{formLabel()}</label>
            <PhoneInput
              country={"us"}
              enableSearch={true}
              disableSearchIcon={true}
              value={common?.value1}
              onChange={(phone) => hanldeForms(phone || "", common?.value2)}
              placeholder="Whatsapp Number"
            />
            <div className="d-flex justify-content-between mt-3">
              <label className="form-label">Your Message</label>
              <lable className="form-label">Optional</lable>
            </div>
            <textarea
              className="form-control email-input"
              id="message"
              value={common?.value2}
              onChange={(e) => {
                hanldeForms(common?.value1, e.target.value);
              }}
              maxLength="150"
              rows="3"
              placeholder="Your Message"
            ></textarea>
            <div className="mt-2">
              <label>150 characters max</label>
              <br />
              <div className="dropdown">
                <button
                  className="btn btn-primary emoji_btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Emoji <i className="fa fa-smile ml-2"></i>
                </button>
                <div
                  className="dropdown-menu p-0"
                  aria-labelledby="dropdownMenuButton"
                >
                  <EmojiPicker
                    onEmojiClick={handleEmoji}
                    autoFocusSearch={false}
                    skinTonesDisabled={true}
                  />
                </div>
              </div>
            </div>
          </>
        ) : slug == "facebook" ||
          slug == "instagram" ||
          slug == "youtube" ||
          slug == "google-forms" ||
          slug == "docs" ||
          slug == "sheets" ||
          slug == "spotify" ||
          slug == "apple-music" ||
          slug == "vimeo" ||
          slug == "stripe" ? (
          <>
            <img
              src={formImages()}
              width="180px"
              className={slug == "vimeo" ? "" : "mb-3"}
            />
            <br />
            <label className="form-label">{formLabel()}</label>
            <input
              type="text"
              value={form?.value}
              onChange={(e) => hanldeForms(e.target.value)}
              className="form-control email-input"
              placeholder={formPlaceholders()}
            />
          </>
        ) : slug == "wifi" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              By scanning this QR code, your users can easily join your WiFi
              network without selecting from a list or entering the password
              manually
            </div>
            <img src={formImages()} className="my-3" />
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="form-label">Network Name (SSID)</label>
                <input
                  type="text"
                  value={common?.value1}
                  onChange={(e) =>
                    hanldeForms(e.target.value, common?.value2, common?.value3)
                  }
                  className="form-control email-input"
                  placeholder="Network Name (SSID)"
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label">Encryption Type</label>
                <div className="form-check d-flex">
                  <label className="form-check-label mr-4">
                    <input
                      checked={common?.value2 == "WPA"}
                      onClick={(e) =>
                        hanldeForms(common?.value1, "WPA", common?.value3)
                      }
                      className="form-check-input"
                      type="radio"
                      name="encryptionType"
                    />
                    WPA/WPA2/WPA3
                  </label>
                  <label className="form-check-label mx-4">
                    <input
                      checked={common?.value2 == "WEP"}
                      onClick={(e) =>
                        hanldeForms(common?.value1, "WEP", common?.value3)
                      }
                      className="form-check-input"
                      type="radio"
                      name="encryptionType"
                    />
                    WEP (older models)
                  </label>
                  <label className="form-check-label ml-4">
                    <input
                      checked={common?.value2 == "none"}
                      onClick={(e) =>
                        hanldeForms(common?.value1, "none", common?.value3)
                      }
                      className="form-check-input"
                      type="radio"
                      name="encryptionType"
                    />
                    None
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3 position-relative">
                <label className="form-label">Password</label>
                <input
                  type={`${eye ? "text" : "password"}`}
                  value={common?.value3}
                  onChange={(e) =>
                    hanldeForms(common?.value1, common?.value2, e.target.value)
                  }
                  className="form-control email-input"
                  placeholder="Password"
                />
                <i
                  className={`fa fa-eye${
                    eye ? "" : "-slash"
                  } fs-5 position-absolute`}
                  onClick={(e) => seteye(eye ? false : true)}
                ></i>
              </div>
              <div className="col-md-12 mb-3">
                <label className="form=label">Is your network hidden?</label>
                <div className="form-check d-flex">
                  <label className="form-check-label mr-4">
                    <input
                      checked={!common?.value4}
                      onClick={(e) =>
                        hanldeForms(
                          common?.value1,
                          common?.value2,
                          common?.value3,
                          false
                        )
                      }
                      className="form-check-input"
                      type="radio"
                      name="networlHidden"
                    />
                    No
                  </label>
                  <label className="form-check-label ml-4">
                    <input
                      checked={common?.value4}
                      onClick={(e) =>
                        hanldeForms(
                          common?.value1,
                          common?.value2,
                          common?.value3,
                          true
                        )
                      }
                      className="form-check-input"
                      type="radio"
                      name="networlHidden"
                    />
                    Yes
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : slug == "events" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              By scanning this QR code, your users can add your event to their
              calendar with all details pre-filled
            </div>
            <img src={formImages()} width="160px" className="my-3" />
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="form-label">Title of Event</label>
                <input
                  type="text"
                  value={common?.value1}
                  onChange={(e) =>
                    hanldeForms(
                      e.target.value,
                      common?.value2,
                      common?.value3,
                      common?.value4
                    )
                  }
                  className="form-control email-input"
                  placeholder="Title of Event"
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  value={common?.value2}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      e.target.value,
                      common?.value3,
                      common?.value4
                    )
                  }
                  className="form-control email-input"
                  placeholder="Location"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Event starts on</label>
                <br />
                <DatePicker
                  selected={common?.value3}
                  minDate={new Date()}
                  onChange={(date) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      date,
                      common?.value4
                    )
                  }
                  dateFormat="dd-MMM-yyyy | hh:mm aa"
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat={"HH:mm"}
                  className="form-control email-input"
                  placeholderText="Event starts on"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Event ends on</label>
                <DatePicker
                  selected={common?.value4}
                  minDate={common?.value3 || new Date()}
                  onChange={(date) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      date
                    )
                  }
                  dateFormat="dd-MMM-yyyy | hh:mm aa"
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat={"HH:mm"}
                  className="form-control email-input"
                  placeholderText="Event ends on"
                />
              </div>
            </div>
          </>
        ) : slug == "paypal" ? (
          <>
            <img src={formImages()} width="160px" className="my-3" />
            <div>
              <label className="form-label">PayPal URL</label>
              <input
                type="text"
                value={form?.value}
                onChange={(e) => setform({ ...form, value: e.target.value })}
                className="form-control email-input"
                placeholder="https://paypal.me"
              />
              <label className="mt-2">
                Visit{" "}
                <a
                  target="_blank"
                  style={{ textDecoration: "underline", color: "blue" }}
                  onClick={(e) => {
                    window.open(
                      "https://www.paypal.com/in/webapps/mpp/paypal-me"
                    );
                  }}
                >
                  PayPal.Me
                </a>{" "}
                to get your free PayPal URL
              </label>
              <div className="d-flex my-1">
                {/* <hr className="bold"/> */}
                {/* <p>Or</p>
                            <hr className="bold"/> */}
              </div>
              {/* <div className="row">
                            <h5>PayPal Details</h5>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Payment Type</label>
                                <input type='text' value={common?.value1} onChange={e=> hanldeForms(e.target.value,common?.value2,common?.value3,common?.value4,common?.value5,common?.value6,common?.value7,common?.value8)} className="form-control email-input" placeholder="Buy Now" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Email</label>
                                <input type='email' value={common?.value2} onChange={e=> hanldeForms(common?.value1,e.target.value,common?.value3,common?.value4,common?.value5,common?.value6,common?.value7,common?.value8)} className="form-control email-input" placeholder="Email" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Item Name</label>
                                <input type='text' value={common?.value3} onChange={e=> hanldeForms(common?.value1,common?.value2,e.target.value,common?.value4,common?.value5,common?.value6,common?.value7,common?.value8)} className="form-control email-input" placeholder="Item Name" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Item Id</label>
                                <input type='text' value={common?.value4} onChange={e=> hanldeForms(common?.value1,common?.value2,common?.value3,e.target.value,common?.value5,common?.value6,common?.value7,common?.value8)} className="form-control email-input" placeholder="Item Id" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Price</label>
                                <input type='text' maxLength='10' value={common?.value5} onChange={e=> hanldeForms(common?.value1,common?.value2,common?.value3,common?.value4,methodModal.isNumber(e),common?.value6,common?.value7,common?.value8)} className="form-control email-input" placeholder="Price" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Currency</label>
                                <select value={common?.value6} onChange={e=> hanldeForms(common?.value1,common?.value2,common?.value3,common?.value4,common?.value5,e.target.value,common?.value7,common?.value8)} className="form-select email-input" aria-label="Default select example">
                                    <option value="">Select Currency</option>
                                    <option value="usd">USD</option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Shipping</label>
                                <input type='text' value={common?.value7} onChange={e=> hanldeForms(common?.value1,common?.value2,common?.value3,common?.value4,common?.value5,common?.value6,e.target.value,common?.value8)} className="form-control email-input" placeholder="Shipping" />
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="form-kabel">Tax Rate</label>
                                <div className="position-relative">
                                <input type='text' maxLength='4' value={common?.value8} onChange={e=> hanldeForms(common?.value1,common?.value2,common?.value3,common?.value4,common?.value5,common?.value6,common?.value7,methodModal.isNumber(e))} className="form-control email-input" placeholder="Tax Rate" /><span className="percent-symbol">%</span>
                                </div>
                            </div>
                        </div> */}
            </div>
          </>
        ) : slug == "call" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              Your phone number will be loaded at the call screen when users
              scan this QR code.
            </div>
            <img src={formImages()} width="120px" className="my-2" />
            <br />
            <div>
              <label className="form-label">Phone Number</label>
              <PhoneInput
                country={"us"}
                enableSearch={true}
                disableSearchIcon={true}
                value={common?.value1}
                onChange={(phone) => hanldeForms(phone || "")}
                placeholder="Phone Number"
              />
            </div>
          </>
        ) : slug == "sms" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              By scanning this QR code, your customers can send a pre-loaded
              message to you via SMS
            </div>
            <img src={formImages()} width="100px" className="my-2" />
            <br />
            <div className="row">
              <div className="col-md-12 my-2">
                <label className="form-label">Phone Number</label>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  disableSearchIcon={true}
                  value={common?.value1}
                  onChange={(phone) => hanldeForms(phone || "", common?.value2)}
                  placeholder="Phone Number"
                />
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label">Your Message</label>
                <textarea
                  value={common?.value2}
                  onChange={(e) => hanldeForms(common?.value1, e.target.value)}
                  maxLength="1000"
                  className="form-control"
                  id="exampleFormControlTextareaMessage"
                  placeholder="Your Message"
                  rows="3"
                ></textarea>
              </div>
              <div className="col-md-12">
                <p>1,000 characters max</p>
              </div>
            </div>
          </>
        ) : slug == "email" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              By scanning this QR code, your users can send an email to you with
              the details below pre-filled
            </div>
            <img src={formImages()} width="100px" className="my-2" />
            <br />
            <div className="row">
              <div className="col-md-12 my-2">
                <label className="form-label">Email address</label>
                <input
                  type="email"
                  value={common?.value1}
                  onChange={(e) =>
                    hanldeForms(
                      e.target.value,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5
                    )
                  }
                  className="form-control email-input"
                  placeholder="Email address"
                />
              </div>
              <div className="col-md-6 my-2">
                <label className="form-label">CC</label>
                <input
                  type="text"
                  value={common?.value2}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      e.target.value,
                      common?.value3,
                      common?.value4,
                      common?.value5
                    )
                  }
                  className="form-control email-input"
                  placeholder="CC"
                />
              </div>
              <div className="col-md-6 my-2">
                <label className="form-label">BCC</label>
                <input
                  type="text"
                  value={common?.value3}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      e.target.value,
                      common?.value4,
                      common?.value5
                    )
                  }
                  className="form-control email-input"
                  placeholder="BCC"
                />
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label">Subject</label>
                <input
                  type="text"
                  value={common?.value4}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      e.target.value,
                      common?.value5
                    )
                  }
                  className="form-control email-input"
                  placeholder="Subject"
                />
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label">Message</label>
                <textarea
                  value={common?.value5}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      e.target.value
                    )
                  }
                  maxLength="1000"
                  className="form-control"
                  id="exampleFormControlTextareaEmailMessage"
                  placeholder="Message"
                  rows="3"
                ></textarea>
              </div>
              <div className="col-md-12">
                <label>1,000 characters max</label>
                <br />
                <div className="dropdown">
                  <button
                    className="btn btn-primary emoji_btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Emoji <i className="fa fa-smile ml-2"></i>
                  </button>
                  <div
                    className="dropdown-menu p-0"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <EmojiPicker
                      onEmojiClick={handleEmoji}
                      autoFocusSearch={false}
                      skinTonesDisabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : slug == "text" ? (
          <>
            <img src={formImages()} width="100px" className="my-2" />
            <br />
            <label>Text</label>
            <textarea
              value={common?.value1}
              onChange={(e) => hanldeForms(e.target.value)}
              maxLength="1000"
              className="form-control"
              id="textmessage"
              placeholder="Enter your text here"
              rows="3"
            ></textarea>
            <div className="col-md-12 my-2 pl-0">
              <label>1,000 characters max</label>
              <br />
              <div className="dropdown">
                <button
                  className="btn btn-primary emoji_btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Emoji <i className="fa fa-smile ml-2"></i>
                </button>
                <div
                  className="dropdown-menu p-0"
                  aria-labelledby="dropdownMenuButton"
                >
                  <EmojiPicker
                    onEmojiClick={handleEmoji}
                    autoFocusSearch={false}
                    skinTonesDisabled={true}
                  />
                </div>
              </div>
            </div>
          </>
        ) : slug == "location" || slug == "maps" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              By scanning this QR code, your users can load the location on
              Google maps directly
            </div>
            <div className="row">
              <div className="col-md-6">
                <img src={formImages()} width="100px" className="my-2" />
                <br />
                <div className="row">
                  <div className="col-md-12 my-2">
                    <label className="form-label">Google Maps Search</label>
                    <GooglePlaceAutoComplete
                      value={common?.value1}
                      result={addressResult}
                      id="address"
                      placeholder="Google Maps Search"
                    />
                  </div>
                  <div className="col-md-12 my-2">
                    <label className="form-label">Google Maps URL</label>
                    <input
                      type="text"
                      value={`http://maps.google.com/maps?z=12&t=m&q=loc:${common?.value3}+${common?.value4}
`}
                      onChange={(e) =>
                        hanldeForms(
                          common?.value1,
                          e.target.value,
                          common?.value3,
                          common?.value4
                        )
                      }
                      className="form-control email-input"
                      placeholder="Google Maps URL"
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <label className="form-label">Latitude</label>
                    <input
                      type="text"
                      value={common?.value3}
                      onChange={(e) =>
                        hanldeForms(
                          common?.value1,
                          common?.value2,
                          methodModal.isNumber(e),
                          common?.value4
                        )
                      }
                      maxLength="15"
                      className="form-control email-input"
                      placeholder="Latitude"
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <label className="form-label">Longitude</label>
                    <input
                      type="text"
                      value={common?.value4}
                      onChange={(e) =>
                        hanldeForms(
                          common?.value1,
                          common?.value2,
                          common?.value3,
                          methodModal.isNumber(e)
                        )
                      }
                      maxLength="15"
                      className="form-control email-input"
                      placeholder="Longitude"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <iframe
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDXcaa81DeBgUyniq30GpZ5pYI7oDneKlE&q=${
                    common?.value2 || "India"
                  }`}
                  width="100%"
                  height="315"
                  style={{ border: 0, borderRadius: "10px" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </>
        ) : slug == "telegram" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              By scanning this QR code, your customers can send a pre-loaded
              message to you on Telegram
            </div>
            <img src={formImages()} width="150px" className="mt-3" />
            <br />
            <div className="row">
              <div className="col-md-12 mt-3">
                <label className="form-label">Telegram Username</label>
                <input
                  type="text"
                  value={common?.value1}
                  onChange={(e) => hanldeForms(e.target.value)}
                  className="form-control email-input"
                  placeholder="https://t.me/Telegram Username"
                />
                {/* <PhoneInput country={'us'} enableSearch={true} disableSearchIcon={true} value={common?.value1} onChange={phone => hanldeForms(phone || '', common?.value2)} placeholder="Telegram Number" /> */}
              </div>
              {/* <div className="col-md-12 mb-2">
                                <div className="d-flex justify-content-between mt-3">
                                    <label className="form-label">Your Message</label>
                                    <lable className="form-label">Optional</lable>
                                </div>
                                <textarea className="form-control email-input" id="message" value={common?.value2} onChange={e => {hanldeForms(common?.value1, e.target.value)}} maxLength="1000" rows="3" placeholder="Your Message"></textarea>
                                <div className="mt-2">
                                    <label>1,000 characters max</label><br />
                                    <div className="dropdown">
                                        <button className="btn btn-primary emoji_btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Emoji <i className="fa fa-smile ml-2"></i>
                                        </button>
                                        <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                                        <EmojiPicker onEmojiClick={handleEmoji} autoFocusSearch={false} skinTonesDisabled={true} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </>
        ) : slug == "tiktok" ? (
          <>
            <img src={formImages()} width="140px" className="mb-3" />
            <br />
            <label className="form-label">TikTok URL</label>
            <input
              type="text"
              value={form?.value}
              onChange={(e) => hanldeForms(e.target.value)}
              className="form-control email-input"
              placeholder="https://www.tiktok.com/@"
            />
          </>
        ) : slug == "bitcoin" ? (
          <>
            <img src={formImages()} width="120px" className="my-2" />
            <br />
            <div className="row">
              <div className="col-md-12 my-2">
                <label className="form-label">Bitcoin Address</label>
                <input
                  type="text"
                  value={common?.value1}
                  onChange={(e) =>
                    hanldeForms(
                      e.target.value,
                      common?.value2,
                      common?.value3,
                      common?.value4
                    )
                  }
                  className="form-control email-input"
                  placeholder="Bitcoin Address"
                />
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label d-flex align-items-center">
                  URI <i className="material-icons mr-2 text-blue ml-2">info</i>
                </label>
                <div className="form-check form-switch ml-3 d-flex align-items-center">
                  <input
                    checked={common?.value2}
                    onChange={(e) => {
                      if (
                        e.target.checked &&
                        !common?.value1?.match("bitcoin:")
                      ) {
                        hanldeForms(
                          "bitcoin:" + "" + common?.value1,
                          e.target.checked,
                          common?.value3,
                          common?.value4
                        );
                      } else {
                        let Extract = common?.value1?.split(":");
                        hanldeForms(
                          Extract[1],
                          e.target.checked,
                          common?.value3,
                          common?.value4
                        );
                      }
                    }}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    for="flexSwitchCheckChecked"
                  >
                    Enabled
                  </label>
                </div>
              </div>
              <div className="col-md-12 my-2 position-relative">
                <label className="form-label">Amount</label>
                <input
                  type="text"
                  value={common?.value3}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      methodModal.isNumber(e),
                      common?.value4
                    )
                  }
                  maxLength="12"
                  className="form-control email-input"
                  placeholder="Amount"
                />
                <p className="btc-align">BTC</p>
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label">Message</label>
                <textarea
                  className="form-control email-input"
                  value={common?.value4}
                  onChange={(e) => {
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      e.target.value
                    );
                  }}
                  maxLength="100"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="col-md-12">
                <p>100 characters max</p>
              </div>
            </div>
          </>
        ) : slug == "vcard" ? (
          <>
            <div className="d-flex align-items-center mb-3">
              <img src="/assets/img/lightbulb_.png" />
              When someone scans this QR code, your contact details below will
              be saved in their mobile phone
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Name</label>
              </div>
              <div className="col-md-6 col-lg-5 mb-3">
                <input
                  type="text"
                  value={vcard?.firstName}
                  onChange={(e) =>
                    setvcard({ ...vcard, firstName: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="First Name"
                />
              </div>
              <div className="col-md-6 col-lg-5 mb-3">
                <input
                  type="text"
                  value={vcard?.lastName}
                  onChange={(e) =>
                    setvcard({ ...vcard, lastName: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Personal Numbers</label>
              </div>
              <div className="col-md-6 col-lg-4 mb-3">
                <input
                  type="text"
                  value={vcard?.personalMobile}
                  onChange={(e) =>
                    setvcard({
                      ...vcard,
                      personalMobile: methodModal.isNumber(e),
                    })
                  }
                  maxLength="12"
                  className="form-control email-input"
                  placeholder="Mobile"
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <input
                  type="text"
                  value={vcard?.personalHome}
                  onChange={(e) =>
                    setvcard({
                      ...vcard,
                      personalHome: methodModal.isNumber(e),
                    })
                  }
                  maxLength="12"
                  className="form-control email-input"
                  placeholder="Home"
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <input
                  type="text"
                  value={vcard?.personalFax}
                  onChange={(e) =>
                    setvcard({ ...vcard, personalFax: methodModal.isNumber(e) })
                  }
                  maxLength="12"
                  className="form-control email-input"
                  placeholder="Fax"
                />
              </div>
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Work Numbers</label>
              </div>
              <div className="col-md-6 col-lg-4 mb-3">
                <input
                  type="text"
                  value={vcard?.workMobile}
                  onChange={(e) =>
                    setvcard({ ...vcard, workMobile: methodModal.isNumber(e) })
                  }
                  maxLength="12"
                  className="form-control email-input"
                  placeholder="Mobile"
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <input
                  type="text"
                  value={vcard?.workOffice}
                  onChange={(e) =>
                    setvcard({ ...vcard, workOffice: methodModal.isNumber(e) })
                  }
                  maxLength="12"
                  className="form-control email-input"
                  placeholder="Office"
                />
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <input
                  type="text"
                  value={vcard?.workFax}
                  onChange={(e) =>
                    setvcard({ ...vcard, workFax: methodModal.isNumber(e) })
                  }
                  maxLength="12"
                  className="form-control email-input"
                  placeholder="Fax"
                />
              </div>
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Email (Personal)</label>
              </div>
              <div className="col-md-12 col-lg-10 mb-3">
                <input
                  type="email"
                  value={vcard?.personalEmail}
                  onChange={(e) =>
                    setvcard({ ...vcard, personalEmail: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="email@gmail.com"
                />
              </div>
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Email (Work)</label>
              </div>
              <div className="col-md-12 col-lg-10 mb-3">
                <input
                  type="email"
                  value={vcard?.workEmail}
                  onChange={(e) =>
                    setvcard({ ...vcard, workEmail: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="email@company.com"
                />
              </div>
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Website</label>
              </div>
              <div className="col-md-12 col-lg-10 mb-3">
                <input
                  type="text"
                  value={vcard?.website}
                  onChange={(e) =>
                    setvcard({ ...vcard, website: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Company</label>
              </div>
              <div className="col-md-6 col-lg-5 mb-3">
                <input
                  type="text"
                  value={vcard?.company}
                  onChange={(e) =>
                    setvcard({ ...vcard, company: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="Company name"
                />
              </div>
              <div className="col-md-6 col-lg-5 mb-3">
                <input
                  type="text"
                  value={vcard?.jobPosition}
                  onChange={(e) =>
                    setvcard({ ...vcard, jobPosition: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="Job position"
                />
              </div>
            </div>
            <div className="form-row d-flex align-items-center">
              <div className="col-md-12 col-lg-2">
                <label className="form-label fw-bold">Address</label>
              </div>
              <div className="col-md-12 col-lg-10 mb-3">
                <input
                  type="text"
                  value={vcard?.street}
                  onChange={(e) =>
                    setvcard({ ...vcard, street: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="Street"
                />
              </div>
              <div className="col-md-12 col-lg-2 "></div>
              <div className="col-md-6 col-lg-5 mb-3">
                <input
                  type="text"
                  value={vcard?.city}
                  onChange={(e) => setvcard({ ...vcard, city: e.target.value })}
                  className="form-control email-input"
                  placeholder="City"
                />
              </div>
              <div className="col-md-6 col-lg-5 mb-3 ">
                <input
                  type="text"
                  value={vcard?.zipCode}
                  onChange={(e) =>
                    setvcard({ ...vcard, zipCode: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="Zipcode / Postal code"
                />
              </div>
              <div className="col-md-12 col-lg-2 mt-3"></div>
              <div className="col-md-6 col-lg-5 mb-3 ">
                <input
                  type="text"
                  value={vcard?.state}
                  onChange={(e) =>
                    setvcard({ ...vcard, state: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="State"
                />
              </div>
              <div className="col-md-6 col-lg-5 mb-3">
                <input
                  type="text"
                  value={vcard?.country}
                  onChange={(e) =>
                    setvcard({ ...vcard, country: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="Country"
                />
              </div>
            </div>
          </>
        ) : slug == "multiple-apps" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              This single QR code detects the device used to scan it and links
              the user to the correct app store. For example, Android phones
              open the Google Play Store, and iPhones open the Apple App Store.
            </div>
            <img src={formImages()} width="150px" className="my-2" />
            <br />
            <div className="row mb-2">
              <div className="col-md-6 mt-2">
                <label className="form-label">Apple App Store (iOS)</label>
                <input
                  type="text"
                  value={common?.value1}
                  onChange={(e) =>
                    hanldeForms(
                      e.target.value,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
              <div className="col-md-6 mt-2">
                <label className="form-label">
                  Google Play Store (Android)
                </label>
                <input
                  type="text"
                  value={common?.value2}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      e.target.value,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
              <div className="col-md-6 mt-2">
                <label className="form-label">
                  Apple App Store for iPad only (iOS)
                </label>
                <input
                  type="text"
                  value={common?.value3}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      e.target.value,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
              <div className="col-md-6 mt-2">
                <label className="form-label">Galaxy Store (Samsung)</label>
                <input
                  type="text"
                  value={common?.value4}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      e.target.value,
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
              <div className="col-md-6 mt-2">
                <label className="form-label">
                  Microsoft Store (Windows Mobile)
                </label>
                <input
                  type="text"
                  value={common?.value5}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      e.target.value,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
              {/* <div className="col-md-6 mt-2">
                <label className="form-label">App Gallery (Huawei)</label>
                <input
                  type="text"
                  value={common?.value6}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      e.target.value,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div> */}
              <div className="col-md-6 mt-2">
                <label className="form-label">
                  Backup Link (any other device or browser)
                </label>
                <input
                  type="text"
                  value={common?.value7}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      e.target.value
                    )
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <img src="/assets/img/info.png" width="22px" className="mr-1" />
              Detecting multiple apps is a hosted service so it only works with
              a Dynamic QR Code
            </div>
          </>
        ) : slug == "paynow" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              <span>
                To automate the PayNow QR code generation in bulk with custom
                details for multiple transactions, you can use the API or Bulk
                QR feature.
              </span>
            </div>
            <img src={formImages()} width="170px" className="my-3" />
            <br />
            <div className="row mb-2">
              <div className="col-md-6">
                <label className="form-label">Type</label>
                <div className="d-flex justify-content-between">
                  <div className="form-check">
                    <label className="form-check-label fs-6 pointer">
                      <input
                        checked={common?.value1}
                        onClick={(e) =>
                          hanldeForms(
                            true,
                            "",
                            "",
                            true,
                            "",
                            true,
                            "",
                            false,
                            ""
                          )
                        }
                        className="form-check-input fs-6"
                        type="radio"
                        name="typeradio"
                      />
                      UEN (Company)
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label fs-6 pointer">
                      <input
                        checked={!common?.value1}
                        onClick={(e) =>
                          hanldeForms(
                            false,
                            "",
                            "",
                            true,
                            "",
                            true,
                            "",
                            false,
                            ""
                          )
                        }
                        className="form-check-input fs-6"
                        type="radio"
                        name="typeradio"
                      />
                      Mobile (Personal)
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-12 my-2">
                {common?.value1 ? (
                  <>
                    <label>UEN</label>
                    <input
                      type="text"
                      value={common?.value2}
                      onChange={(e) =>
                        hanldeForms(
                          common?.value1,
                          e.target.value,
                          common?.value3,
                          common?.value4,
                          common?.value5,
                          common?.value6,
                          common?.value7,
                          common?.value8,
                          common?.value9
                        )
                      }
                      className="form-control email-input"
                      maxLength="12"
                      placeholder="12 characters max"
                    />
                  </>
                ) : (
                  <>
                    <label>Mobile Number</label>
                    <div className="row">
                      <div className="col-md-2">
                        <input
                          type="text"
                          className="form-control w-50"
                          value="+65"
                          disabled
                        />
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          value={common?.value2}
                          onChange={(e) =>
                            hanldeForms(
                              common?.value1,
                              methodModal.isNumber(e),
                              common?.value3,
                              common?.value4,
                              common?.value5,
                              common?.value6,
                              common?.value7,
                              common?.value8,
                              common?.value9
                            )
                          }
                          maxLength="8"
                          minLength="8"
                          className="form-control"
                          placeholder="Mobile Number"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-6 my-2">
                <label>Amount (SGD)</label>
                <input
                  type="text"
                  value={common?.value3}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      methodModal.isNumber(e),
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7,
                      common?.value8,
                      common?.value9
                    )
                  }
                  maxLength="15"
                  className="form-control email-input"
                  placeholder="Amount (SGD)"
                />
              </div>
              <div className="col-md-6 my-2">
                <label>
                  Edit Amount <br /> (Whether to allow the user to edit the
                  payment amount)
                </label>
                <div className="row ml-1">
                  <div className="form-check col-md-6">
                    <label className="form-check-label fs-6 pointer">
                      <input
                        checked={common?.value4}
                        onClick={(e) =>
                          hanldeForms(
                            common?.value1,
                            common?.value2,
                            common?.value3,
                            true,
                            common?.value5,
                            common?.value6,
                            common?.value7,
                            common?.value8,
                            common?.value9
                          )
                        }
                        className="form-check-input fs-6"
                        type="radio"
                        name="editamountradio"
                      />
                      Yes
                    </label>
                  </div>
                  <div className="form-check col-md-6">
                    <label className="form-check-label fs-6 pointer">
                      <input
                        checked={!common?.value4}
                        onClick={(e) =>
                          hanldeForms(
                            common?.value1,
                            common?.value2,
                            common?.value3,
                            false,
                            common?.value5,
                            common?.value6,
                            common?.value7,
                            common?.value8,
                            common?.value9
                          )
                        }
                        className="form-check-input fs-6"
                        type="radio"
                        name="editamountradio"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <label>Reference</label>
                <input
                  type="text"
                  value={common?.value5}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      e.target.value,
                      common?.value6,
                      common?.value7,
                      common?.value8,
                      common?.value9
                    )
                  }
                  maxLength="25"
                  className="form-control email-input"
                  placeholder="25 characters max"
                />
              </div>
              <div className="col-md-6 my-2"></div>
              {common?.value8 ? (
                <div className="col-md-6">
                  <label className="form-label">Expiry Date</label>
                  <DatePicker
                    selected={common?.value7}
                    onChange={(date) =>
                      hanldeForms(
                        common?.value1,
                        common?.value2,
                        common?.value3,
                        common?.value4,
                        common?.value5,
                        common?.value6,
                        date,
                        common?.value8,
                        common?.value9
                      )
                    }
                    minDate={new Date()}
                    dateFormat="dd-MMM-yyyy"
                    className="form-control email-input"
                    placeholderText="DD/MM/YYYY"
                  />
                </div>
              ) : null}
              <div className="col-md-6">
                <label className="form-label mt-3"></label>
                <br />
                <button
                  onClick={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7,
                      !common?.value8,
                      common?.value9
                    )
                  }
                  className="btn expiry-date-btn d-flex align-items-center"
                >
                  {common?.value8 ? "Remove" : "Add"} Expiry Date{" "}
                  <i className="material-icons ml-3">
                    {common?.value8
                      ? "remove_circle_outline"
                      : "add_circle_outline"}
                  </i>
                </button>
              </div>
              {common?.value1 ? (
                <div className="col-md-12 mt-2">
                  <label className="form-label">Company Name (optional)</label>
                  <input
                    type="text"
                    value={common?.value9}
                    onChange={(e) =>
                      hanldeForms(
                        common?.value1,
                        common?.value2,
                        common?.value3,
                        common?.value4,
                        common?.value5,
                        common?.value6,
                        common?.value7,
                        common?.value8,
                        e.target.value
                      )
                    }
                    className="form-control email-input"
                    maxLength="50"
                    placeholder="50 characters max"
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : slug == "sepa" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              <span>
                To automate the QR code generation in bulk with custom details
                for each unique transaction, you can use the <Link>API</Link> or{" "}
                <Link>Bulk QR</Link> feature.
              </span>
            </div>
            <img src={formImages()} width="170px" className="my-3" />
            <br />
            <div className="row">
              <div className="col-md-12 my-2">
                <label className="form-label">Name of receiver</label>
                <input
                  type="text"
                  value={utf8Encode(common?.value1)}
                  onChange={(e) =>
                    hanldeForms(
                      e.target.value,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  maxLength="70"
                  placeholder="70 characters max"
                />
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label">IBAN (Account)</label>
                <input
                  type="text"
                  value={utf8Encode(common?.value2)}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      e.target.value,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="e.g. NL23020328"
                />
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label">BIC / SWIFT</label>
                <input
                  type="text"
                  value={utf8Encode(common?.value3)}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      e.target.value,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  placeholder="e.g. BFASHSAFF"
                />
              </div>
              <div className="col-md-12 my-2">
                <label className="form-label">Amount (EUR)</label>
                <input
                  type="text"
                  value={utf8Encode(common?.value4)}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      methodModal.isNumber(e),
                      common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  maxLength="15"
                  className="form-control email-input"
                  placeholder="€"
                />
              </div>
              {/* <div className="col-md-12 my-2 d-flex">
                <div className="form-check">
                  <label className="form-check-label fs-6 pointer">
                    <input
                      checked={common?.value5}
                      onClick={(e) =>
                        hanldeForms(
                          common?.value1,
                          common?.value2,
                          common?.value3,
                          common?.value4,
                          true,
                          "",
                          common?.value7
                        )
                      }
                      className="form-check-input fs-6"
                      type="radio"
                      name="sepainforadio"
                    />
                    Payment Reference
                  </label>
                </div>
                <div className="form-check ml-5">
                  <label className="form-check-label fs-6 pointer">
                    <input
                      checked={!common?.value5}
                      onClick={(e) =>
                        hanldeForms(
                          common?.value1,
                          common?.value2,
                          common?.value3,
                          common?.value4,
                          false,
                          "",
                          common?.value7
                        )
                      }
                      className="form-check-input fs-6"
                      type="radio"
                      name="sepainforadio"
                    />
                    Remittance Information
                  </label>
                </div>
              </div> */}
              <div className="col-md-12 my-2">
                Payment Reference
                <input
                  type="text"
                  value={utf8Encode(common?.value5)}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      e.target.value,
                      // common?.value5,
                      common?.value6,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  maxLength={35}
                  placeholder="35 characters max"
                />
              </div>
              <div className="col-md-12 my-2">
                <label>Remittance Information</label>
                <input
                  type="text"
                  value={utf8Encode(common?.value6)}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      e.target.value,
                      common?.value7
                    )
                  }
                  className="form-control email-input"
                  maxLength={140}
                  placeholder="
                     140
                   characters max"
                />
              </div>
              <div className="col-md-6 my-2">
                <label>Version</label>
                <div className="row ml-1">
                  <div className="form-check col-md-6">
                    <label className="form-check-label fs-6 pointer">
                      <input
                        checked={!common?.value7}
                        onClick={(e) =>
                          hanldeForms(
                            common?.value1,
                            common?.value2,
                            common?.value3,
                            common?.value4,
                            common?.value5,
                            common?.value6,
                            false,
                            common?.value8,
                            common?.value9
                          )
                        }
                        className="form-check-input fs-6"
                        type="radio"
                        name="editamountradio"
                      />
                      001
                    </label>
                  </div>
                  <div className="form-check col-md-6">
                    <label className="form-check-label fs-6 pointer">
                      <input
                        checked={common?.value7}
                        onClick={(e) =>
                          hanldeForms(
                            common?.value1,
                            common?.value2,
                            common?.value3,
                            common?.value4,
                            common?.value5,
                            common?.value6,
                            true,
                            common?.value8,
                            common?.value9
                          )
                        }
                        className="form-check-input fs-6"
                        type="radio"
                        name="editamountradio"
                      />
                      002
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-12 my-2">
                <label className="form-label">Purpose</label>
                <input
                  type="text"
                  value={utf8Encode(common?.value8)}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7,
                      e.target.value,
                      common?.value9
                    )
                  }
                  className="form-control email-input"
                  maxLength="4"
                  placeholder="4 character purpose code (e.g. CHAR)"
                />
              </div>

              <div className="col-md-12 my-2">
                <label className="form-label">
                  {" "}
                  Beneficiary to originator information
                </label>
                <input
                  type="text"
                  value={utf8Encode(common?.value9)}
                  onChange={(e) =>
                    hanldeForms(
                      common?.value1,
                      common?.value2,
                      common?.value3,
                      common?.value4,
                      common?.value5,
                      common?.value6,
                      common?.value7,
                      common?.value8,
                      e.target.value,
                      common?.value10
                    )
                  }
                  className="form-control email-input"
                  maxLength="70"
                  placeholder="70 characters max"
                />
              </div>
            </div>
          </>
        ) : slug == "smart-url" ? (
          <>
            <div className="d-flex align-items-center">
              <img src="/assets/img/lightbulb_.png" />
              <span>
                To link multiple app stores in a single QR code, use the{" "}
                <Link>App Store</Link> dynamic QR Code instead
              </span>
            </div>
            <img src={formImages()} width="170px" className="my-3" />
            <br />
            <div className="row">
              <div className="col-md-4 mb-2">
                <label>Rule Type</label>
                <select
                  className="form-select"
                  value={smartURL?.ruleType}
                  onChange={(e) => handleRuleType(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="location">Location</option>
                  <option value="number_of_scans">Number of scans</option>
                  <option value="time">Time</option>
                  <option value="language">Language</option>
                </select>
              </div>
              <div className="col-md-8 mb-2">
                <label>Default URL (if no rules are met) </label>
                <input
                  type="text"
                  value={smartURL?.defaultUrl}
                  onChange={(e) =>
                    setsmartURL({ ...smartURL, defaultUrl: e.target.value })
                  }
                  className="form-control email-input"
                  placeholder="https://"
                />
              </div>
            </div>
            <div className="mb-2">
              {smartURL?.ruleType == "location"
                ? "Users in the specified location will be redirected to the targeted URL"
                : smartURL?.ruleType == "number_of_scans"
                ? "Once the number of scans are reached, the user will be redirected to the next URL"
                : smartURL?.ruleType == "time"
                ? "Users in the specified timezone will be redirected to the targeted URL"
                : "Users with a device in the specified language will be redirected to the targeted URL"}
            </div>
            {/* Add More Code */}
            {smartURL?.ruleType == "location" ? (
              <>
                {location &&
                  location.map((item, index) => {
                    return (
                      <>
                        <div className="addmore-box my-2">
                          <div className="d-flex justify-content-between">
                            <p>
                              <b>Location #{index + 1}</b>
                            </p>
                            {location?.length > 1 ? (
                              <p>
                                <i
                                  className="material-icons text-danger fs-4 pointer"
                                  onClick={(e) => removeAddMore(index)}
                                >
                                  delete_forever
                                </i>
                              </p>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <label className="form-label">Country</label>
                              <CountrySelect
                                defaultValue={item?.country}
                                onChange={(e) =>
                                  handleAddMoreFields(index, "country", e)
                                }
                                placeHolder="Country"
                              />
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="d-flex justify-content-between">
                                <label className="form-label">Region</label>
                                <div className="option-color">Optional</div>
                              </div>
                              <StateSelect
                                defaultValue={item?.region}
                                countryid={item?.country?.id}
                                onChange={(e) =>
                                  handleAddMoreFields(index, "region", e)
                                }
                                placeHolder="Region"
                              />
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="d-flex justify-content-between">
                                <label className="form-label">City</label>
                                <div className="option-color">Optional</div>
                              </div>
                              <CitySelect
                                defaultValue={item?.city}
                                countryid={item?.country?.id}
                                stateid={item?.region?.id}
                                onChange={(e) =>{
                                  handleAddMoreFields(index, "city", e)
                                  
                                }
                                }
                                placeHolder="City"
                              />
                            </div>
                            <div className="col-md-12 my-2">
                              <label className="form-label">Targeted URL</label>
                              <input
                                type="text"
                                value={item?.url}
                                onChange={(e) =>
                                  handleAddMoreFields(
                                    index,
                                    "url",
                                    e.target.value
                                  )
                                }
                                className="form-control email-input"
                                placeholder="https://"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            ) : smartURL?.ruleType == "number_of_scans" ? (
              <>
                {numberOfScans &&
                  numberOfScans.map((item, index) => {
                    return (
                      <>
                        <div className="addmore-box my-2">
                          <div className="d-flex justify-content-between">
                            <p>
                              <b>Number of scan #{index + 1}</b>
                            </p>
                            {numberOfScans?.length > 1 ? (
                              <p>
                                <i
                                  className="material-icons text-danger fs-4 pointer"
                                  onClick={(e) => removeAddMore(index)}
                                >
                                  delete_forever
                                </i>
                              </p>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-4 my-2">
                              <label className="form-label">
                                Number of scan
                              </label>
                              <input
                                type="text"
                                value={item?.scans}
                                onChange={(e) =>
                                  handleAddMoreFields(
                                    index,
                                    "scans",
                                    e.target.value
                                  )
                                }
                                className="form-control email-input"
                                placeholder="Number of scan"
                              />
                            </div>
                            <div className="col-md-8 my-2">
                              <label className="form-label">Targeted URL</label>
                              <input
                                type="text"
                                value={item?.url}
                                onChange={(e) =>
                                  handleAddMoreFields(
                                    index,
                                    "url",
                                    e.target.value
                                  )
                                }
                                className="form-control email-input"
                                placeholder="https://"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            ) : smartURL?.ruleType == "time" ? (
              <>
                {time &&
                  time.map((item, index) => {
                    return (
                      <>
                        <div className="addmore-box my-2">
                          <div className="d-flex justify-content-between">
                            <p>
                              <b>Timezone #{index + 1}</b>
                            </p>
                            {time?.length > 1 ? (
                              <p>
                                <i
                                  className="material-icons text-danger fs-4 pointer"
                                  onClick={(e) => removeAddMore(index)}
                                >
                                  delete_forever
                                </i>
                              </p>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-4 my-2">
                              <label className="form-label">Timezone</label>
                              <Select
                                value={item?.timezone}
                                onChange={(e) =>
                                  handleAddMoreFields(index, "timezone", e)
                                }
                                options={timezone}
                                placeholder="Timezone"
                              />
                            </div>
                            <div className="col-md-4 my-2">
                              <label className="form-label">
                                Start Time (24 hour format)
                              </label>
                              <DatePicker
                                selected={item?.startTime}
                                className="form-control email-input"
                                onChange={(date) =>
                                  handleAddMoreFields(index, "startTime", date)
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeFormat={"HH:mm"}
                                dateFormat="HH:mm "
                                placeholderText="HH:MM"
                              />
                            </div>
                            <div className="col-md-4 my-2">
                              <label className="form-label">
                                End Time (24 hour format)
                              </label>
                              <DatePicker
                                selected={item?.endTime}
                                className="form-control email-input"
                                onChange={(date) =>
                                  handleAddMoreFields(index, "endTime", date)
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeFormat={"HH:mm"}
                                dateFormat="HH:mm"
                                placeholderText="HH:MM"
                              />
                            </div>
                            <div className="col-md-12 my-2">
                              <label className="form-label">Targeted URL</label>
                              <input
                                type="text"
                                value={item?.url}
                                onChange={(e) =>
                                  handleAddMoreFields(
                                    index,
                                    "url",
                                    e.target.value
                                  )
                                }
                                className="form-control email-input"
                                placeholder="https://"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                {language &&
                  language.map((item, index) => {
                    return (
                      <>
                        <div className="addmore-box my-2">
                          <div className="d-flex justify-content-between">
                            <p>
                              <b>Language #{index + 1}</b>
                            </p>
                            {language?.length > 1 ? (
                              <p>
                                <i
                                  className="material-icons text-danger fs-4 pointer"
                                  onClick={(e) => removeAddMore(index)}
                                >
                                  delete_forever
                                </i>
                              </p>
                            ) : null}
                          </div>
                          <div className="row">
                            <div className="col-md-4 my-2">
                              <label className="form-label">Language</label>
                              <LanguageSelect
                                defaultValue={item?.language}
                                onChange={(e) =>
                                  handleAddMoreFields(index, "language", e)
                                }
                                placeHolder="Language"
                              />
                            </div>
                            <div className="col-md-8 my-2">
                              <label className="form-label">Targeted URL</label>
                              <input
                                type="text"
                                value={item?.url}
                                onChange={(e) =>
                                  handleAddMoreFields(
                                    index,
                                    "url",
                                    e.target.value
                                  )
                                }
                                className="form-control email-input"
                                placeholder="https://"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            )}

            <div className="my-3">
              <button
                className="btn btn-primary w-100"
                onClick={(e) => addMore()}
              >
                Add New Rule{" "}
                <i className="material-icons ml-3 text-white">add_circle</i>
              </button>
            </div>
            <div className="d-flex align-items-center">
              <img src="/assets/img/info.png" width="22px" className="mr-1" />
              Redirecting URLs is a hosted service so it only works with a
              Dynamic QR Code
            </div>
          </>
        ) : slug == "file" ? (
          <>
            <img src={formImages()} width="80px" className="my-1" />
            <br />
            <div className="row">
              <div className="col-md-12 col-lg-5 my-2 ">
                <label class="w-100 pointer">
                  {!form?.value ? (
                    <div class="browser-span text-center p-2 rounded-3 d-flex flex-column justify-content-center gap-2">
                      <p class="text-secondary fw-normal mb-0">
                        Drag and drop to upload or
                      </p>
                      <p class="text-primary fw-bold mb-0">
                        {" "}
                        Browse{" "}
                        <img
                          src="/assets/img/browser.png"
                          class=" img-fluid ms-3  "
                          alt="..."
                        />
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{ flexDirection: "column" }}
                      className="d-flex  justify-content-center align-items-center"
                    >
                      <img
                        style={{ width: "120px", textAlign: "center" }}
                        src="https://www.creativefabrica.com/wp-content/uploads/2020/12/01/Line-Style-Selected-File-Icon-Office-Graphics-6934835-1.jpg"
                        alt=""
                      />
                      <p>{form?.fileName}</p>
                    </div>
                  )}
                  <input
                    onChange={HandleIploadDocuments}
                    type="file"
                    class="d-none"
                    accept=".pdf,.jpeg,.jpg,.png,.mp4,.xls,.xlsx,.doc,.docx"
                    value=""
                  />
                </label>
              </div>
              <div className="col-md-12 col-lg-7 my-2">
                <p class="sup mb-1"> Supported Format </p>
                <div className="list-format d-flex">
                  <div className="mr-3">
                    <p className="format_box">PDF</p>
                  </div>
                  <div className="mr-3">
                    <p className="format_box">JPEG</p>
                  </div>
                  <div className="mr-3">
                    <p className="format_box">PNG</p>
                  </div>
                  <div className="mr-3">
                    <p className="format_box">MP4</p>
                  </div>
                  <div className="mr-3">
                    <p className="format_box">Excel</p>
                  </div>
                  <div className="mr-3">
                    <p className="format_box">Word</p>
                  </div>
                </div>
                <div className="list-format d-flex">
                  <div className="d-inline-block mr-3 mb-2">
                    <h4>2MB</h4>
                    <button
                      className={`${
                        user?.activePlan?.name === "free"
                          ? "megabite"
                          : "megabite_deactive"
                      }  d-flex align-items-center`}
                    >
                      <span
                        className={`material-icons mr-2 ${
                          user?.activePlan?.name === "free"
                            ? "text-success"
                            : ""
                        }`}
                      >
                        check
                      </span>{" "}
                      Free
                    </button>
                  </div>
                  <div className="d-inline-block mr-3 mb-2">
                    <h4>10MB</h4>
                    <button
                      className={`${
                        user?.activePlan?.name === "starter"
                          ? "megabite"
                          : "megabite_deactive"
                      }  d-flex align-items-center`}
                    >
                      <span className="material-icons mr-2 ">check</span>{" "}
                      Starter
                    </button>
                  </div>
                  <div className="d-inline-block mr-3 mb-2">
                    <h4>20MB</h4>
                    <button
                      className={`${
                        user?.activePlan?.name === "premium"
                          ? "megabite"
                          : "megabite_deactive"
                      }  d-flex align-items-center`}
                    >
                      <span className="material-icons mr-2 ">check</span>{" "}
                      Premium
                    </button>
                  </div>
                  <div className="d-inline-block mr-3 mb-2">
                    <h4>30MB</h4>
                    <button
                      className={`${
                        user?.activePlan?.name === "business"
                          ? "megabite"
                          : "megabite_deactive"
                      }  d-flex align-items-center`}
                    >
                      <span className="material-icons mr-2 ">check</span>{" "}
                      Business
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <img src="/assets/img/info.png" width="22px" className="mr-1" />
                Uploading files is a hosted service so it only works with a
                Dynamic QR Code
              </div>
            </div>
          </>
        ) : urlsQrCodeOnly() ? (
          <>
            <img src={formImages()} width="170px" className="my-3" />
            <br />
            <label for="exampleInputEmail1" className="form-label">
              <span className="text-capitalize">
                {slug.replaceAll("-", " ")}
              </span>{" "}
              URL
            </label>
            <input
              type="text"
              value={form?.value}
              onChange={(e) => hanldeForms(e.target.value)}
              className="form-control email-input"
              id="urlInputField"
              aria-describedby="emailHelp"
              placeholder="https://"
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default Forms;
