import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import moment from "moment";

const DynamicQrCode = ({ settingForm, setsettingForm }) => {
  const user = useSelector((state) => state.user);
  const [eye, seteye] = useState(false);
  const activePlan = useSelector((state) => state.activePlan);
  const [totalFolder, setTotalFOlder] = useState(0);
  const [folder, setfolder] = useState();
  useEffect(() => {
    getFolders();
  }, []);

  const getFolders = () => {
    ApiClient.get(`folder/all?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setfolder(res?.data?.data);
        setTotalFOlder(res?.data?.total);
      }
    });
  };

  const handleCreateFolder = (e) => {
    e.preventDefault();
    let payload = {
      name: settingForm?.folderName,
    };
    ApiClient.post(`folder`, payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getFolders();
        setsettingForm({
          ...settingForm,
          folderId: res?.data?.id,
          folderName: res?.data?.name,
        });
      }
    });
    document.getElementById("CloseFolderModal").click();
  };

  const handleFolderSelect = (e) => {
    let folderDetail = folder.find((item) => item?._id == e);
    setsettingForm({
      ...settingForm,
      folderName: folderDetail?.name,
      folderId: e,
    });
  };
  const [isRowVisible, setisRowVisible] = useState(false);
  const [iRowVisible, setiRowVisible] = useState(false);
  const [isowVisible, setisowVisible] = useState(false);
  const [isRRowVisible, setisRRowVisible] = useState(false);

  const handleArrowClick = () => {
    setisRowVisible(!isRowVisible);
  };
  const changeArrowClick = () => {
    setiRowVisible(!iRowVisible);
  };
  const clickArrowClick = () => {
    setisowVisible(!isowVisible);
  };  const ArrowClick = () => {
    setisRRowVisible(!isRRowVisible);
  };

  return (
    <>
      <div className="section_background">
        <div className="dropdown_arrow">
          <h1 className="heading_row">
            STEP 3: Settings <span className="font_style">(Optional)</span>
          </h1>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label for="exampleInputEmail1" className="form-label">
              Name of QR Code <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              value={settingForm?.qrCodeName}
              onChange={(e) =>
                setsettingForm({ ...settingForm, qrCodeName: e.target.value })
              }
              className="form-control"
              placeholder="Name of QR Code"
            />
          </div>
          <div className="col-md-6 mb-3">
            <label for="exampleInputEmail1" className="form-label">
              Folder
            </label>
            <select
              value={settingForm?.folderId}
              onChange={(e) => handleFolderSelect(e.target.value)}
              className="form-select pointer border "
              aria-label="Default select example"
            >
              <option value="">Select Folder</option>
              {folder &&
                folder.map((item, index) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label></label>
            <h3
              className="new_folder"
             
            >
              {" "}
              New Folder{" "}
              <span className="material-icons file_icon pointer" 
               onClick={(e) => {
                setsettingForm({ ...settingForm, folderName: "" });
                totalFolder < activePlan?.max_folders
                  ? document.getElementById("openfolder").click()
                  : toast.error("Your Folder Plan Limit has been reached");
              }}>
                create_new_folder
              </span>
            </h3>
          </div>
        </div>
        <div className="row_form ">
          <div className="dropdown_arrow pointer" onClick={changeArrowClick}>
            <h1 className="heading_row qrCodeName pointer">
              Campaign <span className="font_style"></span>
            </h1>
            <span className="material-icons arrow_down qrCodeName pointer">
              keyboard_arrow_down
            </span>
          </div>
          {iRowVisible && (
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Medium
                </label>
                <input
                  type="text"
                  value={settingForm?.flyer}
                  onChange={(e) =>
                    setsettingForm({
                      ...settingForm,
                      flyer: e.target.value,
                    })
                  }
                  // maxLength="7"

                  className="form-control"
                  placeholder="e.g. email,banner,bus stop"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Print Run
                </label>
                <input
                  type="text"
                  value={settingForm?.printRun}
                  onChange={(e) =>
                    setsettingForm({
                      ...settingForm,
                      printRun: methodModel.isNumber(e),
                    })
                  }
                  maxLength="7"
                  className="form-control"
                  placeholder="e.g. 500"
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>Campaign Start</label>
                {/* <DatePicker
                  selected={settingForm?.campaignStart}
                  minDate={new Date()}
                  onChange={(date) =>
                    setsettingForm({ ...settingForm, campaignStart: date })
                  }
                 
                  dateFormat="dd-MMM-yyyy"
                  className="form-control email-input"
                  placeholderText="Campaign Start"
                  inputMode="numeric"
                /> */}
                <input
                  type="date"
                  value={
                    settingForm?.campaignStart
                      ? settingForm.campaignStart.toISOString().split("T")[0]
                      : ""
                  }
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    setsettingForm({
                      ...settingForm,
                      campaignStart: selectedDate,
                    });
                  }}
                  className="form-control email-input"
                  placeholder="Campaign Start"
                />
              </div>

              <div className="col-md-6 mb-3">
                <label>Campaign End</label>
                <input
                  type="date"
                  value={
                    settingForm?.campaignEnd
                      ? settingForm.campaignEnd.toISOString().split("T")[0]
                      : ""
                  }
                  min={
                    settingForm?.campaignStart
                      ? settingForm.campaignStart.toISOString().split("T")[0]
                      : new Date().toISOString().split("T")[0]
                  }
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    setsettingForm({
                      ...settingForm,
                      campaignEnd: selectedDate,
                    });
                  }}
                  className="form-control email-input"
                  placeholder="Campaign End"
                />
              </div>
            </div>
          )}
        </div>

        <div className="row_form">
          <div className="heading_flex">
            <div className="dropdown_arrow pointer" onClick={clickArrowClick}>
              <h1 className="heading_row qrCodeName pointer">
                Expiry <span className="font_style "></span>
              </h1>
              <span className="material-icons arrow_down qrCodeName pointer">
                keyboard_arrow_down
              </span>
            </div>
          
            {isowVisible && (
              <div>
                <p>
                  You can set the QR Code to expire after a number of scans or
                  by date. This is useful for scenarios like limiting the number
                  of voucher redemptions.
                </p>
                <label> Number of scans</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={settingForm.numberOfScansStatus}
                        // disabled={activePlan?.name=='free'}
                        onChange={(e) =>
                          setsettingForm({
                            ...settingForm,
                            numberOfScansStatus: e.target.checked,
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="numberOfScans"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="numberOfScans"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="text"
                      value={settingForm.numberOfScans}
                      onChange={(e) =>
                        setsettingForm({
                          ...settingForm,
                          numberOfScans: e.target.value,
                        })
                      }
                      disabled={!settingForm.numberOfScansStatus}
                      maxLength="5"
                      className="form-control"
                      placeholder="Number of scans"
                    />
                  </div>
                </div>
                <label>Date Range</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={settingForm.dateRangeStatus}
                        // disabled={activePlan?.name=='free'}
                        onChange={(e) =>
                          setsettingForm({
                            ...settingForm,
                            dateRangeStatus: e.target.checked,
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="dateRange"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="dateRange"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <DatePicker
                      selected={settingForm.dateRangeStart}
                      minDate={new Date()}
                      onChange={(date) =>
                        setsettingForm({ ...settingForm, dateRangeStart: date })
                      }
                      dateFormat="dd-MMM-yyyy"
                      disabled={!settingForm.dateRangeStatus}
                      className="form-control email-input"
                      placeholderText="Start"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <DatePicker
                      selected={settingForm.dateRangeEnd}
                      minDate={settingForm.dateRangeStart || new Date()}
                      onChange={(date) =>
                        setsettingForm({ ...settingForm, dateRangeEnd: date })
                      }
                      dateFormat="dd-MMM-yyyy"
                      disabled={!settingForm.dateRangeStatus}
                      className="form-control email-input"
                      placeholderText="End"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label>Expiry URL</label>
                  <input
                    type="text"
                    value={settingForm.expiryURL}
                    onChange={(e) =>
                      setsettingForm({
                        ...settingForm,
                        expiryURL: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="https://"
                    disabled={
                      !settingForm.numberOfScansStatus &&
                      !settingForm.dateRangeStatus
                    }
                  />
                  <p>
                    Visitors will be redirected to this URL after the main link
                    expires.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row_form">
          <div className="heading_flex">
            <div className="dropdown_arrow pointer" onClick={ArrowClick}>
              <h1 className="heading_row qrCodeName pointer">
                Password <span className="font_style"></span>
              </h1>
              <span className="material-icons arrow_down qrCodeName pointer">
                keyboard_arrow_down
              </span>
            </div>
            {isRRowVisible && (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-check form-switch switch_button">
                    <input
                      checked={settingForm?.passwordStatus}
                      // disabled={activePlan?.name=='free'}
                      onClick={(e) =>
                        setsettingForm({
                          ...settingForm,
                          passwordStatus: e.target.checked,
                        })
                      }
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      Enabled
                    </label>
                  </div>
                  <span className="text_span">
                    Users will be required to enter your password to access your
                    URL
                  </span>
                </div>
                <div className="col-md-12">
                  <div className="password_div">
                    <input
                      type={`${eye ? "text" : "password"}`}
                      value={settingForm?.password}
                      onChange={(e) =>
                        setsettingForm({
                          ...settingForm,
                          password: e.target.value,
                        })
                      }
                      minLength={0}
                      maxLength={10}
                      disabled={!settingForm?.passwordStatus}
                      className="form-control"
                      placeholder="Set your password"
                    />
                    <span
                      className={`material-icons ${
                        eye ? "eye_view" : "eye_off"
                      }`}
                      onClick={(e) => seteye(!eye)}
                    >
                      {eye ? "visibility" : "visibility_off"}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row_form">
          <div className="heading_flex">
            <div className="dropdown_arrow pointer" onClick={handleArrowClick}>
              <h1 className="heading_row qrCodeName pointer">
                GPS Tracking <span className="font_style "></span>
              </h1>
              <span className="material-icons arrow_down qrCodeName pointer">
                keyboard_arrow_down
              </span>
            </div>
            {isRowVisible && (
              // <p>
              //   Note: Users need to grant permission to share their current
              //   location
              // </p>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-check form-switch switch_button">
                    <input
                      checked={settingForm?.exactGPSLocation}
                      onClick={(e) =>
                        setsettingForm({
                          ...settingForm,
                          exactGPSLocation: e.target.checked,
                        })
                      }
                      disabled={
                        settingForm?.limitScanLocation ||
                        activePlan?.name == "free"
                      }
                      className="form-check-input border pointer"
                      type="checkbox"
                      role="switch"
                      id="exactGPSLocation"
                    />
                    <label className="form-check-label pointer" for="exactGPSLocation">
                      Exact GPS Location
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check form-switch switch_button">
                    <input
                      checked={settingForm?.limitScanLocation}
                      onClick={(e) =>
                        setsettingForm({
                          ...settingForm,
                          limitScanLocation: e.target.checked,
                        })
                      }
                      disabled={
                        settingForm?.exactGPSLocation ||
                        activePlan?.name == "free"
                      }
                      className="form-check-input border pointer"
                      type="checkbox"
                      role="switch"
                      id="limitScanLocation"
                    />
                    <label className="form-check-label pointer" for="limitScanLocation">
                      Geo-fencing: Limit scan location
                    </label>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <label>Longitude</label>
                  <input
                    type="number"
                    value={
                      settingForm?.longitude
                    }
                    onChange={(e) => {
                      setsettingForm({
                        ...settingForm,
                        longitude: e.target.value,
                      });
                    }}
                    disabled={!settingForm?.limitScanLocation}
                    maxLength="15"
                    className="form-control"
                    placeholder="Longitude"
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>Latitude</label>
                  <input
                    type="number"
                    value={
                      settingForm?.latitude 
                    }
                    onChange={(e) =>
                      setsettingForm({
                        ...settingForm,
                        latitude: e.target.value,
                      })
                    }
                    disabled={!settingForm?.limitScanLocation}
                    maxLength="15"
                    className="form-control"
                    placeholder="Latitude"
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>
                    Radius in Km{" "}
                    <span className="label_text"> (1 Km = 0,62 Mile)</span>
                  </label>
                  {/* <input
                    type="number"
                    value={settingForm?.radiusInKM}
                    onChange={(e) =>
                      setsettingForm({
                        ...settingForm,
                        radiusInKM: e.target.value,
                      })
                    }
                    disabled={!settingForm?.limitScanLocation}
                    maxLength="10"
                    className="form-control"
                    placeholder="Radius in Km"
                  /> */}
                  <input
                    type="number"
                    value={settingForm?.radiusInKM}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      // Validate if newValue is numeric
                      if (/^\d*\.?\d*$/.test(newValue)) {
                        setsettingForm({
                          ...settingForm,
                          radiusInKM: newValue,
                        });
                      }
                    }}
                    onKeyPress={(e) => {
                      // Allow only numbers, backspace, and delete keys
                      const allowedKeys = [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        ".",
                        "Backspace",
                        "Delete",
                      ];
                      if (!allowedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    disabled={!settingForm?.limitScanLocation}
                    maxLength="10"
                    className="form-control"
                    placeholder="Radius in Km"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* New Folder Modal */}
      <button
        className="d-none"
        id="openfolder"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {" "}
        open
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form onSubmit={handleCreateFolder}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Create New Folder
                </h1>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">Folder Name</label>
                    <input
                      value={settingForm?.folderName}
                      onChange={(e) =>
                        setsettingForm({
                          ...settingForm,
                          folderName: e.target.value,
                        })
                      }
                      maxLength="20"
                      type="text"
                      className="form-control"
                      placeholder="Folder Name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light light_btn"
                  id="CloseFolderModal"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DynamicQrCode;
