import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Duplicate from "../Duplicate";
import html2canvas from "html2canvas";
import ReactECharts from "echarts-for-react";
// import { crmType } from "./../../models/type.model";
import QRCodeStyling from "styled-qr-code";

const EditQrCodes = () => {
  const { slug } = useParams();
  const user = useSelector((state) => state.user);
  const [detail, setdetail] = useState();
  const history = useHistory();
  let host = document.location.host;
  let RedirectURL = environment?.liveURL;
  // if (host?.includes("https://qrcode.jcsoftwaresolution.in/")) {
  //   RedirectURL = "https://qrcode.jcsoftwaresolution.in/";
  // } else {
  //   RedirectURL = "http://66.179.251.9:8056/";
  // }
  const [folder, setfolder] = useState();
  const [QRCODES, setQrCodes] = useState([]);
  const [selectedFolder, setselectedFolder] = useState({ id: "", payload: {} });
  const [duplicateQrCode, setduplicateQrCode] = useState();
  const [createDuplicate, setcreateDuplicate] = useState(false);
  const [DownloadImg, setDownloadImg] = useState(null);
  const [rename, setrename] = useState({ active: false, name: "" });
  const ActivePlan = useSelector((state) => state.activePlan);
  const [password, setpassword] = useState();
  // For Analytics
  const [ScansOverTime, setScansOverTime] = useState([]);
  const [ScansByTime, setScabsByTime] = useState([]);
  const [handleDateFilter, sethandleDateFilter] = useState(false);
  const [dateFilter, setdateFilter] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectFormat, setSelectedFormat] = useState("");
  const canvasRef = useRef(null);
  const [ScanByDevices, setScanByDevices] = useState([]);
  const [ScanByOS, setScanByOS] = useState([]);
  const [ScansByTopCountries, setScansByTopCountries] = useState([]);
  const [ScansByTopCities, setScansByTopCities] = useState([]);
  const ref = useRef(null);
  const [peakScansByDay, setpeakScansByDay] = useState([]);
  const [Arr, setArr] = useState([]);
  const [totalScans, setTotalScans] = useState(0);
  const [Expiry, setExpiry] = useState({});
  const [EditData, setEditData] = useState({
    flyer: "",
    printRun: "",
    campaignStart: "",
    campaignEnd: "",
  });

  const [Gps, setGps] = useState();

  const GPStracing = (e, type, checked = false) => {
    e.preventDefault();
    if (type == "button") {
      if (checked) {
        setGps({ ...detail });
        setGps({ setting: { radiusInKM: "", latitude: "", longitude: "" } });
        document.getElementById("Opengpsmodal").click();
      } else {
        let payload = {
          id: detail?.id,
          setting: {
            ...detail.setting,
            exactGPSLocation: false,
            limitScanLocation: false,
            Gps: "",
          },
        };
        loader(true);
        ApiClient.put(`qrcode/download`, payload).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getDetail();
            setGps("");
            loader(false);
          }
        });
      }
    } else {
      let payload = {
        id: detail?.id,
        setting: {
          ...detail.setting,
          ...Gps.setting,
        },
      };
      loader(true);
      ApiClient.put(`qrcode/download`, payload).then((res) => {
        if (res.success) {
          toast.success(res.message);
          getDetail();
          document.getElementById("GPSclose").click();
          setGps("");
          loader(false);
        }
      });
    }
  };

  // useEffect(()=>{
  // setExpiry({...Expiry,numberOfScans:Expiry?.totalScans})
  // },[])

  useEffect(() => {
    const showPosition = (position) => {
      if (Gps?.setting?.exactGPSLocation == true) {
        setGps({
          ...Gps,
          setting: {
            ...Gps.setting,
            exactGPSLocation: true,
            limitScanLocation: false,
            // exactGPSLocationLat: String(position?.coords?.latitude),
            // exactGPSLocationLng: String(position?.coords?.longitude),
            latitude: "",
            longitude: "",
            radiusInKM: "",
          },
        });
      }
    };
    // if (Gps?.setting?.exactGPSLocation) {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(showPosition);
    //   }
    // }
  }, [Gps?.setting?.exactGPSLocation]);

  useEffect(() => {
    if (Gps?.setting?.limitScanLocation == true) {
      setGps({
        ...Gps,
        setting: {
          ...Gps.setting,
          limitScanLocation: true,
          exactGPSLocation: false,
        },
      });
    }
  }, [Gps?.setting?.limitScanLocation]);

  const HandleEditchange = (e) => {
    const value = e.target.value;
    const data = EditData;
    data["setting"][e.target.name] = value;
    setEditData({ ...data });
  };

  const UpdateEditdata = (e) => {
    e.preventDefault();
    let payload = { ...EditData };
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    loader(true);
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;

    ApiClient.put("qrcode/download", payload).then((res) => {
      if (res.success) {
        toast.success(res.message);

        document.getElementById("btnclose").click();
        loader(false);
      }
    });
  };

  // For Duplicate
  const [form, setform] = useState({
    value: "",
    staticQrCode: true,
    logoimg: "",
    logosize: "4",
    qrOuterEye: "square",
    qrInnerEye: "square",
    qrBodyPattern: "square",
    qrOuterEyeColor: "#030202",
    qrInnerEyeColor: "#030202",
    qrBodyPatternColor: "#030202",
    backgroundColor: "#FFFFFF",
    qrOuterEyeColorGradient1: "",
    qrOuterEyeColorGradient2: "",
    qrOuterEyeColorGradientType: "linear",
    qrInnerEyeColorGradient1: "",
    qrInnerEyeColorGradient2: "",
    qrInnerEyeColorGradientType: "linear",
    qrBodyPatternGradientColor1: "",
    qrBodyPatternGradientColor2: "",
    qrBodyPatternGradientColorType: "linear",
    backgroundGradientColor1: "",
    backgroundGradientColor2: "",
    backgroundGradientColorType: "linear",
    errorCorrection: "M",
  });
  const [frameForm, setframeForm] = useState({
    width: "250",
    height: "250",
    frame: "",
    qrFrameColor: "#030202",
    qrFrameColorGradient1: "",
    qrFrameColorGradient2: "",
    text: "SCAN ME",
    fontStyle: "",
    textColor: "#3c61e4",
  });

  const [Type, setType] = useState([]);

  const GetType = () => {
    ApiClient.get("qrtype/all").then((res) => {
      setType(res.data);
    });
  };

  useEffect(() => {
    GetType();
  }, []);

  const FindType = (data) => {
    let value = "";
    if (data == "url") {
      data = "urls";
    }
    value = Type && Type.filter((itm) => itm?.slug == data?.replace("-", " "));
    return value[0]?.type ? value[0]?.type : "--";
  };

  useEffect(() => {
    if (slug) {
      getDetail();
    }
    getFolders();

    // For Analytics
    getScansOverTimeData();
    getScansByTime();
  }, []);

  const getFolders = () => {
    ApiClient.get(`folder/all?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setfolder(res?.data?.data);
      }
    });
  };

  const getDetail = () => {
    loader(true);
    ApiClient.get(`qrcode/download?slug=${slug}`).then((res) => {
      if (res.success) {
        setdetail(res?.data);
        setGps(res?.data?.setting);
        setduplicateQrCode(`Copy of ${res?.data?.setting?.qrCodeName}`);

        const qrCode = new QRCodeStyling({
          width: 500,
          height: 500,
          type: "svg",
          margin: 5,
          data: res?.data?.dynamicQrcode
            ? `${RedirectURL}redirecting/${res?.data?.slug}`
            : res?.data?.qrCode_data || RedirectURL,
          image: res?.data?.design?.logoimg
            ? `${environment.api}images/logo/${res?.data?.design?.logoimg}`
            : "",
          crossOrigin: "anonymous",
          qrOptions: {
            typeNumber: 0,
            mode: "Byte",
            errorCorrectionLevel: res?.data?.design?.errorCorrection,
          },
          dotsOptions: {
            color: res?.data?.design?.qrBodyPatternColor,
            type: res?.data?.design?.qrBodyPattern,
            gradient:
              res?.data?.design?.qrBodyPatternGradientColor1 ||
              res?.data?.design?.qrBodyPatternGradientColor2
                ? {
                    type: res?.data?.design?.qrBodyPatternGradientColorType,
                    colorStops: [
                      {
                        offset: 0,
                        color:
                          res?.data?.design?.qrBodyPatternGradientColor1 ||
                          "#030202",
                      },
                      {
                        offset: 1,
                        color:
                          res?.data?.design?.qrBodyPatternGradientColor2 ||
                          "#030202",
                      },
                    ],
                  }
                : "",
          },
          imageOptions: {
            crossOrigin: "anonymous",
            hideBackgroundDots: res?.data?.design.logotoggle,
            margin: 0,
            imageSize: `0.${res?.data?.design?.logosize}`,
          },

          cornersSquareOptions: {
            type: res?.data?.design?.qrOuterEye,
            color: res?.data?.design?.qrOuterEyeColor,
            gradient:
              res?.data?.design?.qrOuterEyeColorGradient1 ||
              res?.data?.design?.qrOuterEyeColorGradient2
                ? {
                    type: res?.data?.design?.qrOuterEyeColorGradientType,
                    colorStops: [
                      {
                        offset: 0,
                        color:
                          res?.data?.design?.qrOuterEyeColorGradient1 ||
                          "#030202",
                      },
                      {
                        offset: 1,
                        color:
                          res?.data?.design?.qrOuterEyeColorGradient2 ||
                          "#030202",
                      },
                    ],
                  }
                : "",
          },
          cornersDotOptions: {
            type: res?.data?.design?.qrInnerEye,
            color: res?.data?.design?.qrInnerEyeColor,
            gradient:
              res?.data?.design?.qrInnerEyeColorGradient1 ||
              res?.data?.design?.qrInnerEyeColorGradient2
                ? {
                    type: res?.data?.design?.qrInnerEyeColorGradientType,
                    colorStops: [
                      {
                        offset: 0,
                        color:
                          res?.data?.design?.qrInnerEyeColorGradient1 ||
                          "#030202",
                      },
                      {
                        offset: 1,
                        color:
                          res?.data?.design?.qrInnerEyeColorGradient2 ||
                          "#030202",
                      },
                    ],
                  }
                : "",
          },
          backgroundOptions: {
            color: res?.data?.design?.backgroundGradientColor1
              ? res?.data?.design?.backgroundGradientColor1
              : res?.data?.design?.backgroundColor || "#ffffff",
            gradient:
              res?.data?.design?.backgroundGradientColor1 ||
              res?.data?.design?.backgroundGradientColor2
                ? {
                    type: res?.data?.design?.backgroundGradientColorType,
                    colorStops: [
                      {
                        offset: 0,
                        color:
                          res?.data?.design?.backgroundGradientColor1 ||
                          "#FFFFFF",
                      },
                      {
                        offset: 1,
                        color:
                          res?.data?.design?.backgroundGradientColor2 ||
                          "#FFFFFF",
                      },
                    ],
                  }
                : "",
          },
        });
        QRCODES.push(qrCode);
        setrename({
          active: false,
          name: res?.data?.setting?.qrCodeName || "",
        });
        if (res?.data?.staticQrCode) {
          setform({ ...res?.data?.design, value: res?.data?.qrCode_data });
        } else {
          setform({
            ...res?.data?.design,
            value: `${environment?.liveURL}redirecting/${res?.data?.slug}`,
          });
        }
        setframeForm(
          res?.data?.frames
            ? res?.data?.frames
            : {
                width: "250",
                height: "250",
                frame: "",
                qrFrameColor: "#030202",
                qrFrameColorGradient1: "",
                qrFrameColorGradient2: "",
                text: "SCAN ME",
                fontStyle: "",
                textColor: "#3c61e4",
              }
        );
      }
      loader(false);
    });
  };

  const handleChangePassword = (type, checked = false) => {
    if (type == "toggle") {
      if (checked) {
        document.getElementById("OpenPasswordChangeModal").click();
      } else {
        let payload = {
          id: detail?.id,
          setting: {
            ...detail?.setting,
            passwordStatus: checked,
            password: "",
          },
        };
        ApiClient.put(`qrcode/download`, payload).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            getDetail();
            setpassword("");
          }
        });
      }
    } else {
      let payload = {
        id: detail?.id,
        setting: {
          ...detail?.setting,
          password: password,
          passwordStatus: true,
        },
      };
      ApiClient.put(`qrcode/download`, payload).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          getDetail();
          document.getElementById("ClosePasswordChangeModal").click();
          setpassword("");
        }
      });
    }
  };

  const totalScansCount = (data) => {
    let value = 0;
    if (data?.length != 0) {
      data?.map((item) => {
        value += item?.count;
      });
      return value;
    } else {
      return value;
    }
  };

  const handleStatus = (status) => {
    if (detail?.staticQrcode) return;
    if (status == "paused") {
      if (detail?.status == "pause") {
        return;
      } else {
        let payload = {
          id: detail?.id,
          status: "pause",
        };
        if (window.confirm("Do you really want to change this status")) {
          ApiClient.put(`updateStatus`, { data: [payload] }).then((res) => {
            if (res.success) {
              toast.success(res?.message);
              getDetail();
            }
          });
        }
      }
    } else {
      let payload = {
        id: detail?.id,
        status: detail?.status == "pause" ? "active" : "pause",
      };
      if (window.confirm("Do you really want to change this status")) {
        ApiClient.put(`updateStatus`, { data: [payload] }).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            getDetail();
          }
        });
      }
    }
  };

  const handleDelete = () => {
    if (window.confirm(`Do you really want to delete this qr code`)) {
      ApiClient.delete(`qrcode/download?id=${detail?.id}`).then((res) => {
        if (res.success) {
          setTimeout(() => {
            toast.success(res.message);
          }, 100);
          history.push(`/app/qrcodelisting`);
        }
      });
    }
  };

  const handleDuplicate = (type) => {
    console.log(type,"---------")
    if (type == "modal") {
      document.getElementById("OpenDuplicateModal").click();
    } else {
      setcreateDuplicate(true);
    }
  };

  // Move Folder
  const moveFolder = (type) => {
    if (type == "modal") {
      setselectedFolder({ id: "", payload: {} });
      document.getElementById("OpenMoveFolderModal").click();
    } else {
      ApiClient.put(`updateMultipleFolder`, {
        data: [selectedFolder?.payload],
      }).then((res) => {
        if (res.success) {
          getDetail();
          setselectedFolder({ id: "", payload: {} });
          document.getElementById("CloseMoveFolderModal").click();
          toast.success(res?.message);
        }
      });
    }
  };

  const handleFolderSelect = (e) => {
    let folderDetail = folder.find((item) => item?._id == e);
    let values = {
      id: detail?.id,
      setting: {
        ...detail?.setting,
        folderName: folderDetail?.name,
        folderId: e,
      },
    };
    setselectedFolder({ ...selectedFolder, id: e, payload: values });
  };

  const handleEditDesign = () => {
    history.push(`/app/qrcodes/${detail?.type}/${detail?.slug}`);
    localStorage.setItem("step", "2");
  };

  const handleResetScans = () => {
    if (window.confirm("Do you really want to reset scans of this qr code")) {
      ApiClient.delete(`resetScan?slug=${slug}`).then((res) => {
        if (res.success) {
          toast.success(res.message);
          getDetail();
          getFolders();
          getScansOverTimeData();
          getScansByTime();
          getScansByDevices();
          getScansByOS();
          getScansByTopCountries();
          getScansByTopCities();
          getPeakScansByDay();
        }
      });
    }
  };

  const handleRename = (e) => {
    e.preventDefault();
    let payload = {
      id: detail?.id,
      setting: { ...detail?.setting, qrCodeName: rename?.name },
    };
    ApiClient.put(`qrcode/download`, payload).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        getDetail();
        setrename({ ...rename, active: false });
      }
    });
  };

  const handleRedirectURL = (url) => {
    window.open(url);
  };

  // Scan Over Time Chart
  const getScansOverTimeData = () => {
    ApiClient.get(
      `analytics/scan/over-time?qrCode=${slug}&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        if (res?.data?.data?.length > 0) {
          setScansOverTime(res?.data?.data);
        } else {
          setScansOverTime(null);
        }
      }
    });
  };

  const scansOverTimeData = [
    ["", "Unique Scans", "Total Scans"],
    ...((ScansOverTime?.length > 0 &&
      ScansOverTime?.map((item) => {
        return [`${item?.monthName}`, item?.uniqueScan, item?.total_scans];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];

  const scansOverTimeOption = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };
  // Scans By Time
  const getScansByTime = () => {
    ApiClient.get(
      `analytics/scan/by-time?qrCode=${slug}&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        if (res?.data?.data?.length > 0) {
          setScabsByTime(res?.data?.data);
        } else {
          setScabsByTime([]);
        }
      }
    });
  };
  const scansByTimeData = [
    ["", "Unique Scans", "Total Scans"],
    ...((ScansByTime?.length > 0 &&
      ScansByTime?.map((item) => {
        const label = `${item?.monthName} Week ${item?.weekOfMonth}`;
        return [label, item?.uniqueScan, item?.total_scans];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const scansByTimeOption = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };

  const clearAnalyticsDateFilter = () => {
    setdateFilter([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
  };

  const confirmAnalyticsDateFilter = () => {
    sethandleDateFilter(false);
  };

  const DownloadQR = (index) => {
    document.getElementById("OpenFormatModel").click();
    // convertHTMLToImage()
    // setDownloadimageindex(index)
  };

  const convertHTMLToImage1 = (e) => {
    e.preventDefault();
    if (canvasRef.current) {
      html2canvas(canvasRef.current).then((canvas) => {
        const image = new Image();

        if (selectFormat == "svg") {
          const svgString = new XMLSerializer().serializeToString(canvas);

          // Create a Blob from the SVG string
          const blob = new Blob([svgString], { type: "image/svg+xml" });

          // Create a download link
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "color:black ";
          a.href = url;
          a.download = "QrCode.svg";

          // Trigger the download
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        } else {
          if (selectFormat == "png") {
            image.src = canvas.toDataURL("image/png", 1.0);
          } else {
            image.src = canvas.toDataURL("image/jpeg", 1.0);
          }
          var link = document.createElement("a");
          link.href = image.src;

          if (selectFormat == "png") {
            link.download = `QrCode.png`;
          } else {
            link.download = `QrCode.jpeg`;
          }
          link.click();
        }
        document.getElementById("CloseFormatModal").click();
        // saveQrCode(image.src)
      });
    }
  };

  const convertHTMLToImage = (e) => {
    e.preventDefault();
    if (detail?.design?.isFrame) {
      if (selectFormat != "svg") {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var img = new Image();
        img.onload = function () {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // Convert the image to a different format (e.g., PNG)
          if (selectFormat === "png") {
            var newImage = canvas.toDataURL("image/png", 1.0);
          } else {
            var newImage = canvas.toDataURL("image/jpeg", 1.0);
          }
          // var newImage = canvas.toDataURL("image/jpeg");

          var a = document.createElement("a");
          a.href = newImage;
          if (selectFormat === "png") {
            a.download = `QrCode.png`;
          } else if (selectFormat === "jpeg") {
            a.download = `QrCode.jpeg`;
          }
          // a.download = `QrCode.jpeg`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
        img.src = DownloadImg;
        document.getElementById("CloseFormatModal").click();
      } else {
        ConvertQRtoSVG();
      }
    } else {
      if (selectFormat == "png") {
        QRCODES[0].download({ name: "QRCode", extension: "png" });
      }
      if (selectFormat == "jpeg") {
        QRCODES[0].download({ name: "QRCode", extension: "jpeg" });
      }
      if (selectFormat == "svg") {
        QRCODES[0].download({ name: "QRCode", extension: "svg" });
      }
    }
    document.getElementById("CloseFormatModal").click();
  };

  const ConvertQRtoSVG = () => {
    var img = new Image();
    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");

      // Set background color to white
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Convert canvas content to SVG
      var svg =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
        img.width +
        " " +
        img.height +
        '" width="' +
        img.width +
        '" height="' +
        img.height +
        '"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml"><img src="' +
        canvas.toDataURL("image/png") +
        '"/></div></foreignObject></svg>';

      // Download the SVG file
      var blob = new Blob([svg], { type: "image/svg+xml" });
      var url = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "image.svg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };
    img.src = DownloadImg;
    document.getElementById("CloseFormatModal").click();
  };

  const templateImageConvert = (e) => {
    if (e) {
      if (canvasRef.current) {
        html2canvas(canvasRef.current).then((canvas) => {
          let image = new Image();
          if (selectFormat == "jpeg") {
            image.src = canvas.toDataURL("image/jpeg", 1.0);
          } else {
            image.src = canvas.toDataURL("image/png", 1.0);
          }
          handleTemplate(image.src);
        });
      }
    }
  };
  const handleTemplate = (img) => {
    let payload = {
      name: "New Template",
      image: img,
      design: {
        logoimg: form?.logoimg,
        logosize: form?.logosize,
        qrOuterEye: form?.qrOuterEye,
        qrInnerEye: form?.qrInnerEye,
        qrBodyPattern: form?.qrBodyPattern,
        qrOuterEyeColor: form?.qrOuterEyeColor,
        qrInnerEyeColor: form?.qrInnerEyeColor,
        qrBodyPatternColor: form?.qrBodyPatternColor,
        backgroundColor: form?.backgroundColor,
        qrOuterEyeColorGradient1: form?.qrOuterEyeColorGradient1,
        qrOuterEyeColorGradient2: form?.qrOuterEyeColorGradient2,
        qrOuterEyeColorGradientType: form?.qrOuterEyeColorGradientType,
        qrInnerEyeColorGradient1: form?.qrInnerEyeColorGradient1,
        qrInnerEyeColorGradient2: form?.qrInnerEyeColorGradient2,
        qrInnerEyeColorGradientType: form?.qrInnerEyeColorGradientType,
        qrBodyPatternGradientColor1: form?.qrBodyPatternGradientColor1,
        qrBodyPatternGradientColor2: form?.qrBodyPatternGradientColor2,
        qrBodyPatternGradientColorType: form?.qrBodyPatternGradientColorType,
        backgroundGradientColor1: form?.backgroundGradientColor1,
        backgroundGradientColor2: form?.backgroundGradientColor2,
        backgroundGradientColorType: form?.backgroundGradientColorType,
        errorCorrection: form?.errorCorrection,
      },
      frames: frameForm,
      user_id: user?.id,
    };
    loader(true);
    ApiClient.post(`template`, payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
      }
      loader(false);
    });
  };

  // const scansOverTimeOption = {
  //     colors: ['#42a2eb', '#4bc0bf'],
  //     annotations: {
  //         alwaysOutside: true,
  //     },
  //     legend: { position: 'top' },
  //     vAxis: {
  //         format: '0'  // Display integers only on the vertical axis
  //     }
  // };

  const getScansByDevices = (p = {}) => {
    // const newfilter = { ...Filter, ...p, userId: user?.id }
    ApiClient.get(
      `analytics/scan/by-device?qrSlug=${slug}&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        setScanByDevices(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getScansByDevices();
    getScansByOS();
    getScansByTopCountries();
    getScansByTopCities();
    getPeakScansByDay();
  }, []);

  const findDevice = (device) => {
    console.log(device,"devicedevice")
    const iphoneRegex = /iPhone/i;
    const ipadRegex = /iPad/i;
    const samsungRegex = /Samsung/i;
    const motoRegex = /Moto/i;
    const motorolaRegex = /Motorola/i;
    const huaweiRegex = /Huawei/i;
    const realmeRegex = /Realme/i;
    const vivoRegex = /Vivo/i;
    const oppoRegex = /Oppo/i;
    const pocoRegex = /Poco/i;
    const linuxRegex = /Linux/i;
    const windowsRegex = /Windows/i;
    const macRegex = /Macintosh|Mac OS X/i;

    if (iphoneRegex.test(userAgent)) {
      return "iPhone";
    } else if (samsungRegex.test(userAgent)) {
      return "Samsung";
    } else if (ipadRegex.test(userAgent)) {
      return "iPad";
    } else if (motoRegex.test(userAgent) || motorolaRegex.test(userAgent)) {
      return "Motorola";
    } else if (huaweiRegex.test(userAgent)) {
      return "Huawei";
    } else if (realmeRegex.test(userAgent)) {
      return "Realme";
    } else if (vivoRegex.test(userAgent)) {
      return "Vivo";
    } else if (oppoRegex.test(userAgent)) {
      return "Oppo";
    } else if (pocoRegex.test(userAgent)) {
      return "Poco";
    } else if (linuxRegex.test(userAgent)) {
      return "Linux";
    } else if (windowsRegex.test(userAgent)) {
      return "Windows";
    } else if (macRegex.test(userAgent)) {
      return "macOS";
    } else {
      return "Unknown Device";
    }
  };

  const getScansByOS = (p = {}) => {
    // const newfilter = { ...Filter, ...p, userId: user?.id }
    ApiClient.get(
      `analytics/scan/by-os?qrSlug=${slug}&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        setScanByOS(res?.data?.data);
      }
    });
  };

  const geoChartData = [
    ["Country", "Total Scans"],
    ...(ScansByTopCountries &&
      ScansByTopCountries?.map((item) => {
        return [`${item?.country}`, item?.total_scans];
      })),
  ];

  const getScansByTopCountries = (p = {}) => {
    // const newfilter = { ...Filter, ...p, userId: user?.id }
    ApiClient.get(
      `analytics/scan/by-country?qrSlug=${slug}&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        setScansByTopCountries(res?.data?.data);
      }
    });
  };

  const getScansByTopCities = (p = {}) => {
    // const newfilter = { ...Filter, ...p, userId: user?.id }
    ApiClient.get(
      `analytics/scan/by-city?qrSlug=${slug}&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        setScansByTopCities(res?.data?.data);
      }
    });
  };

  let arr = [];
  // Peak hour scans by day
  const getPeakScansByDay = (p = {}) => {
    // const newfilter = { ...Filter, ...p, userId: user?.id }
    ApiClient.get(
      `analytics/scan/by-day?qrSlug=${slug}&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        setpeakScansByDay(res?.data?.data);

        // Assuming your total scans data is in res.data.total
        setTotalScans(res?.data?.total || 0);
        res?.data?.data?.map((itm) => {
          arr.push([itm?.dayOfWeek, itm?.hour, itm?.total_scans]);
        });
        setArr(arr);
      }
    });
  };
  let array = [];
  peakScansByDay &&
    peakScansByDay?.map((itm) => {
      array.push(itm?.hour, itm?.total_scans, itm?.dayOfWeek);
    });
  let Elements = [];
  peakScansByDay?.map((itm) =>
    Elements?.push([itm?.dayOfWeek, itm?.hour, itm?.total_scans || "-"])
  );

  useEffect(() => {}, []);
  const generateHeatmapData = (xAxisData, yAxisData, minValue, maxValue) => {
    const data = [];

    xAxisData.forEach((xValue, xIndex, value) => {
      yAxisData.forEach((yValue, yIndex) => {
        const value =
          Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
        data.push([xIndex, yIndex, value]);
      });
    });
    return data;
  };

  const data = Arr.map(function (item) {
    return [item[1] > 9 ? `${item[1]}:00` : `0${item[1]}:00`,item[0] == 0 ? 1 : item[0] == 2 ? 6 : item[0] == 1 ? 7 :item[0] == 3 ? 5 :item[0] == 4 ? 4 :item[0] == 5 ? 3 : item[0] == 6 ? 2 : ''  ,item[2] || "-"];
  });

  console.log(data,"daatatatat")

  const peakScansOption = {
    tooltip: {
      position: "top",
      // formatter: params => {
      //     return `Total Scans: ${totalScans}`;
      // },
    },
    xAxis: {
      type: "category",
      data: [
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
      ], // Assuming hours of the day
    },
    yAxis: {
      type: "category",
      data: ["Sun", "Sat", "Fri", "Thu", "Wed", "Tue", "Mon"],
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: 50,
      calculable: false,
      orient: "horizontal",
      left: "center",
      bottom: "0%",
    },
    series: [
      {
        name: "Total Scans",
        type: "heatmap",
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "#ffffff",
          },
        },
      },
    ],
  };

  const UpdateExpiry = (e) => {
    e.preventDefault();
    let payload = { ...Expiry };
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    loader(true);
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    ApiClient.put("qrcode/download", payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
        // getData();
        document.getElementById("CloseExpiry").click();
      }
      loader(false);
    });
  };

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="card_div mb-3">
                <div className="list-group-item d-flex justify-content-between">
                  <div className="active_h1">
                    <span
                      onClick={(e) => handleStatus("active")}
                      className={`mx-2 ${
                        detail?.staticQrcode
                          ? "static"
                          : detail?.status == "pause"
                          ? "paused pointer"
                          : "status pointer"
                      } text-capitalize`}
                    >
                      {detail?.staticQrcode
                        ? "Static"
                        : detail?.status == "pause"
                        ? "Paused"
                        : "Active"}
                    </span>
                    {rename?.active ? (
                      <form className="d-flex" onSubmit={handleRename}>
                        <input
                          type="text"
                          value={rename?.name}
                          onChange={(e) =>
                            setrename({ ...rename, name: e.target.value })
                          }
                          className="form-control ml-2"
                          placeholder="Campaign Name"
                          required
                        />
                        <button type="submit" className="btn btn-primary ml-3">
                          Save
                        </button>
                      </form>
                    ) : (
                      <h1 className="name_heading">
                        {detail?.setting?.qrCodeName || ""}{" "}
                      </h1>
                    )}
                  </div>
                  <div className="btns_blue">
                    <span
                      className="material-icons more_horizIcon dropdown-toggle ml-2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      more_horiz
                    </span>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) =>
                            setrename({ ...rename, active: true })
                          }
                        >
                          <span className="material-icons drop_icon mr-2">
                            title
                          </span>{" "}
                          Rename
                        </a>
                      </li>
                      {!detail?.staticQrcode ? (
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={(e) => handleStatus("paused")}
                          >
                            <span className="material-icons drop_icon mr-2">
                              pause_circle_outline
                            </span>{" "}
                            Pause QR Code
                          </a>
                        </li>
                      ) : null}
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => moveFolder("modal")}
                        >
                          <span className="material-icons drop_icon mr-2">
                            folder_open
                          </span>{" "}
                          Move Folder
                        </a>
                      </li>
                      {!detail?.staticQrcode ? (
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={(e) => handleDuplicate("modal")}
                          >
                            <span className="material-icons drop_icon mr-2">
                              control_point_duplicate
                            </span>{" "}
                            Duplicate
                          </a>
                        </li>
                      ) : null}
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => handleDelete()}
                        >
                          <span className="material-icons drop_icon mr-2">
                            delete
                          </span>{" "}
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row_22 border_22">
                        <div className="left_list">
                          <div className="row_qr">
                            {!detail?.staticQrcode ? (
                              <div className="layout_qr mb-3">
                                <span className="material-icons ico mr-2">
                                  folder
                                </span>{" "}
                                {detail?.setting?.folderName || "--"}
                              </div>
                            ) : null}
                            {/* <div className="layout_qr mb-3">
                                                                <span className="material-icons ico mr-2">event</span>  Data
                                                            </div> */}
                            <div className="layout_qr mb-3">
                              <span className="material-icons ico mr-2">
                                link
                              </span>
                              {detail?.type == "text" ||
                              detail?.type == "vcard" ||
                              detail?.type == "email" ||
                              detail?.type == "events" ||
                              detail?.type == "sms" ||
                              detail?.type == "call" ||
                              detail?.staticQrcode ? (
                                <span className="ellipes">
                                  {detail?.qrCode_data}
                                </span>
                              ) : (
                                <span
                                  className="pointer link_btn"
                                  onClick={(e) =>
                                    handleRedirectURL(
                                      !detail?.staticQrcode
                                        ? `${environment?.liveURL}redirecting/${detail?.slug}`
                                        : detail?.qrCode_data
                                    )
                                  }
                                >
                                  {!detail?.staticQrcode ? (
                                    `${environment?.liveURL}redirecting/${detail?.slug}`
                                  ) : (
                                    <span className="ellipes">
                                      {detail?.qrCode_data}
                                    </span>
                                  )}
                                </span>
                              )}
                            </div>
                            <div className="list_rowqr">
                              <div className="layout_qr">
                                Created:{" "}
                                <b>
                                  {moment(detail?.createdAt).format(
                                    "MMM DD, YYYY"
                                  ) +
                                    " " +
                                    moment(detail?.createdAt).format("hh:mm a")}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!detail?.staticQrcode ? (
                      <div className="col-md-6">
                        <div className="class_slide">
                          <div className="list_slide mb-3">
                            <p>Password</p>
                            <div className="form-switch switch_class">
                              <input
                                className="form-check-input border pointer"
                                checked={detail?.setting?.passwordStatus}
                                onChange={(e) =>
                                  handleChangePassword(
                                    "toggle",
                                    e.target.checked
                                  )
                                }
                                type="checkbox"
                                role="switch"
                              />
                              <label
                                className="form-check-label pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#PasswordChangeModal"
                              >
                                Change Password
                              </label>
                            </div>
                          </div>
                          <div className="list_slide mb-3">
                            <p>GPS Tracking</p>
                            <div className="form-switch switch_class">
                              <input
                                className="form-check-input border pointer"
                                checked={
                                  detail?.setting?.exactGPSLocation ||
                                  detail?.setting?.limitScanLocation
                                }
                                onChange={(e) =>
                                  GPStracing(e, "button", e.target.checked)
                                }
                                type="checkbox"
                                role="switch"
                              />
                              <label
                                className="form-check-label  qrCodeName pointer"
                                onClick={(e) => setGps(detail)}
                                data-bs-toggle="modal"
                                data-bs-target="#GPSModal"
                              >
                                GPS Settings
                              </label>
                            </div>
                          </div>
                          {/* <div className="list_slide">
                                                    <p>Alert</p>
                                                    <div className="form-switch switch_class">
                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                        <label className="form-check-label" for="flexSwitchCheckDefault">Alert Settings</label>
                                                    </div>
                                                </div> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {!detail?.staticQrcode ? (
                <div className="card_div mb-3">
                  <div className="list-group-item d-flex justify-content-between">
                    <h1 className="name_heading">Campaign </h1>
                    <div className="btns_blue">
                      <button
                        onClick={(e) =>{ 
                          setExpiry({
                            ...detail,
                            setting: {
                              ...detail.setting,
                              numberOfScans:detail?.totalScans,
                            },
                          })
                      }}
                        // disabled={ActivePlan?.name == "free"}
                        data-bs-toggle="modal"
                        data-bs-target="#Expirymodal"
                        className="btn primary_btn ml-2"
                      >
                        Expiry{" "}
                        <span className="material-icons ml-2">date_range</span>
                      </button>

                      {/* <button className="btn primary_btn ml-2">Retarget <span className="material-icons ml-2">forward</span></button> */}
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row_22 border_22">
                          <div className="">
                            <div className="list_data mb-3">
                              <p className="p_row1">Medium</p>
                              <p
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={(e) => setEditData(detail)}
                                className="qrCodeName pointer"
                              >
                                <span className="blue-text">
                                  {detail?.setting?.flyer || "--"}
                                </span>{" "}
                              </p>{" "}
                            </div>
                            <div className="list_data  mb-3">
                              <p className="p_row1">Print Run</p>
                              <p
                                className="p_row12 qrCodeName pointer"
                                onClick={(e) => setEditData(detail)}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                {detail?.setting?.printRun || "--"}
                              </p>
                            </div>
                            <div className="list_data mb-3">
                              <p className="p_row1 ">Campaign Start</p>
                              <p
                                className="p_row12  qrCodeName pointer"
                                onClick={(e) => setEditData(detail)}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                {detail?.setting?.campaignStart
                                  ? moment(
                                      detail?.setting?.campaignStart
                                    ).format("MMM DD, YYYY")
                                  : "--"}
                              </p>
                            </div>
                            {!detail?.staticQrcode ? (
                              <div className="list_data">
                                <p className="p_row1">Campaign End</p>
                                <p
                                  className="p_row12  qrCodeName pointer"
                                  onClick={(e) => setEditData(detail)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  {detail?.setting?.campaignEnd
                                    ? moment(
                                        detail?.setting?.campaignEnd
                                      ).format("MMM DD, YYYY")
                                    : "--"}
                                </p>
                                {/* <p className="p_row12">Set Date <span className="material-icons ml-2">insert_invitation</span></p> */}
                              </div>
                            ) : null}
                          </div>
                          {!detail?.staticQrcode ? (
                            <div className="border_leftclass">
                              <div className="box_col2 mb-2">
                                <p className="mb-2">Unique Scans</p>
                                <h3 className="mb-0">
                                  {detail?.scanDetail?.length || 0}
                                </h3>
                              </div>
                              <div className="box_col2 box_col3">
                                <p className="mb-2">Total Scans</p>
                                <h3 className="mb-0">
                                  {totalScansCount(detail?.scanDetail)}
                                </h3>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="card_div mb-3">
                <div className="list-group-item d-flex justify-content-between">
                  <div className="active_h1">
                    <h1 className="name_heading">QR Code Data </h1>
                  </div>
                  <div>
                    {!detail?.staticQrcode && <button
                      disabled={detail?.staticQrcode}
                      onClick={(e) => {
                        history.push(
                          `/app/qrcodes/${detail?.type}/${detail?.slug}`
                        );
                      }}
                      className="btn primary_btn ml-2"
                    >
                      Edit QR Code Data{" "}
                      <span className="material-icons ml-2">edit</span>
                    </button>}
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row_22 border_22">
                        <div className="row_qr seturl">
                          <div className="layout_qr mb-3">
                            Type:{" "}
                            <b className="text-uppercase">
                              {FindType(detail?.type)}
                              {/* {detail?.type.replaceAll("-", " ")} */}
                              {}
                            </b>
                          </div>
                          <div className="layout_qr mb-3">
                            {detail?.type == "smart-url" ? (
                              <span className="inline_bx">
                                {" "}
                                URL: <b>{detail?.defaultUrl}</b>
                              </span>
                            ) : (
                              <>
                                <span className="inline_bx"> URL: </span>
                                <b>
                                  {detail?.qrCode_data ? (
                                    detail?.type == "text" ? (
                                      <span className="ellipes">
                                        {detail?.message}
                                      </span>
                                    ) : (
                                      <span className="ellipes">
                                        {detail?.qrCode_data}
                                      </span>
                                    )
                                  ) : (
                                    `${environment?.liveURL}redirecting/${detail?.slug}`
                                  )}
                                </b>
                              </>
                            )}
                          </div>
                          {/* <div className="layout_qr mb-3">
                                                    Rule Type: <b>{detail?.smartURLNumberOfScans?.map((item)=>item?.language?.name).toString()}</b>
                                                    </div> */}

                          {detail?.apple_app_store_ios ? (
                            <div className="layout_qr mb-3">
                              Apple App Store:{" "}
                              <b className="">{detail?.apple_app_store_ios}</b>
                            </div>
                          ) : (
                            ""
                          )}
                          {detail?.google_play_store_android ? (
                            <div className="layout_qr mb-3">
                              Google Play Store:{""}
                              <b>{detail?.google_play_store_android}</b>
                            </div>
                          ) : (
                            ""
                          )}
                          {detail?.apple_app_store_for_iPad_only_ios ? (
                            <div className="layout_qr mb-3">
                              Apple App Store for iPad only:{""}
                              <b>{detail?.apple_app_store_for_iPad_only_ios}</b>
                            </div>
                          ) : (
                            ""
                          )}
                          {detail?.galaxy_store_samsung ? (
                            <div className="layout_qr mb-3">
                              Galaxy Store :{""}
                              <b>{detail?.galaxy_store_samsung}</b>
                            </div>
                          ) : (
                            ""
                          )}
                          {detail?.microsoft_store_window_mobile ? (
                            <div className="layout_qr mb-3">
                              Microsoft Store:{""}
                              <b>{detail?.microsoft_store_window_mobile}</b>
                            </div>
                          ) : (
                            ""
                          )}
                          {detail?.backup_link_for_other_device_browser ? (
                            <div className="layout_qr mb-3">
                              Backup Link:{""}
                              <b>
                                {detail?.backup_link_for_other_device_browser}
                              </b>
                            </div>
                          ) : (
                            ""
                          )}
                          {detail?.smartURLLocation &&
                            detail?.smartURLLocation?.map((itm) => {
                              return (
                                <div className="layout_qr mb-3">
                                  <div className="row">
                                    <div className="">
                                      Rule: <b>{itm?.country?.name}</b>
                                    </div>
                                    <div className="my-1">
                                      Targeted URL: <b>{itm?.url}</b>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {detail?.smartURLNumberOfScans &&
                            detail?.smartURLNumberOfScans?.map((itm) => {
                              return (
                                <div className="layout_qr mb-3">
                                  <div className="row">
                                    <div className="">
                                      Rule: <b>{itm?.totalScans}</b>
                                    </div>
                                    <div className="my-1">
                                      Targeted URL: <b>{itm?.url}</b>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {detail?.smartURLTime &&
                            detail?.smartURLTime?.map((itm) => {
                              return (
                                <div className="layout_qr mb-3">
                                  <div className="row">
                                    <div className="">
                                      Rule:{" "}
                                      <b>
                                        {moment(itm?.startTime).format("HH:mm")}{" "}
                                        - {moment(itm?.endTime).format("HH:mm")}
                                      </b>
                                    </div>
                                    <div className="my-1">
                                      Targeted URL: <b>{itm?.url}</b>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {detail?.smartURLLanguage &&
                            detail?.smartURLLanguage?.map((itm) => {
                              return (
                                <div className="layout_qr mb-3">
                                  <div className="row">
                                    <div className="">
                                      Rule: <b>{itm?.language?.name}</b>
                                    </div>
                                    <div className="my-1">
                                      Targeted URL: <b>{itm?.url}</b>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mb-4">
              <div className="section_background text-center">
                <div className="hr-line">
                  <p className="p_sec text-left">QR Code Design</p>
                </div>
                <Duplicate slug={slug} setform={setform} form={form} frameForm={frameForm} createDuplicate={createDuplicate} setcreateDuplicate={setcreateDuplicate} detail={detail} duplicateQrCode={duplicateQrCode} />
                <img
                  ref={(ref) => {
                    canvasRef.current = ref;
                  }}
                  src={detail?.image}
                  width="100%"
                />
                <div className="form-switch switch_btnline mt-3">
                  <input
                    className="form-check-input"
                    onChange={(e) => {
                      templateImageConvert(e.target.checked);
                    }}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    for="flexSwitchCheckDefault"
                  >
                    Add to your templates
                  </label>
                </div>
                <div className="qr_btn">
                  <div className="">
                    <button
                      disabled={detail?.staticQrcode}
                      onClick={(e) => handleEditDesign()}
                      className="btn primary-button w-100"
                    >
                      Edit QR Code Design
                    </button>
                  </div>
                  <div className="qr_btn">
                    <button
                      onClick={(e) => {
                        setDownloadImg(detail?.image);
                        DownloadQR();
                      }}
                      className="btn btn-primary w-100 text-white"
                    >
                      Download
                      <span className="material-icons ml-2">download</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {!detail?.staticQrcode ? (
              <div className="col-md-12">
                <div className="graf_Section graf_Sec mb-3">
                  <div className="list-group-item d-flex justify-content-between list_an">
                    <div className="active_h1">
                      <h1 className="name_heading">Analytics </h1>
                    </div>
                    <div className="select_layout ml-2 mr-2">
                      <span
                        class="form-select position-relactive pointer "
                        onClick={(e) => sethandleDateFilter(!handleDateFilter)}
                        onBlur={(e) => sethandleDateFilter(false)}
                      >
                        {dateFilter?.[0]?.startDate || dateFilter?.[0]?.endDate
                          ? `${moment(dateFilter?.[0]?.startDate).format(
                              "MMMM DD, YYYY"
                            )} - ${moment(dateFilter?.[0]?.endDate).format(
                              "MMMM DD, YYYY"
                            )}`
                          : "Select Date Range"}
                      </span>
                      <div className="position-absolute">
                        {handleDateFilter ? (
                          <div className="analytics_date_filter analytics_date_filter2">
                            <DateRangePicker
                              onChange={(item) =>
                                setdateFilter([item.selection])
                              }
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={2}
                              ranges={dateFilter}
                              direction="horizontal"
                              startDatePlaceholder="Date"
                              endDatePlaceholder="Date"
                            />
                            <div className="text-right mr-3 mb-1 mt-0">
                              <button
                                className="btn btn-light light_btn mx-2"
                                onClick={(e) => clearAnalyticsDateFilter()}
                              >
                                <span className="d-flex">
                                  Clear{" "}
                                  <span className="material-icons">
                                    clear_all
                                  </span>
                                </span>
                              </button>
                              <button
                                className="btn btn-primary mx-2"
                                onClick={(e) => confirmAnalyticsDateFilter()}
                              >
                                Confirm{" "}
                                <span className="fa fa-check ml-1"></span>
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="btns_blue">
                      <button
                        className="btn primary_btn "
                        onClick={(e) => handleResetScans()}
                      >
                        Reset Scans{" "}
                        <span className="material-icons ml-2">restart_alt</span>
                      </button>
                      <button className="btn primary_btn ">
                        Download CSV{" "}
                        <span className="material-icons ml-2">table_view</span>
                      </button>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="lable_p mb-3">
                          <b>Scans Over Time</b>
                        </label>
                        <div className="chart_div">
                          {ScansOverTime?.length > 0 ? (
                            <Chart
                              chartType="ColumnChart"
                              width="100%"
                              height="400px"
                              data={scansOverTimeData}
                              options={scansOverTimeOption}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <label className="lable_p mb-3">
                          <b>Scans By Time</b>
                        </label>
                        <div className="chart_div">
                          <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="400px"
                            data={scansByTimeData}
                            options={scansByTimeOption}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {!detail?.staticQrcode ? (
          <div className="col-md-12 mb-3 ">
            <h1 className="heading_h1row mx-2">Peak hour scans by day</h1>
            <div className="border_box bg-white">
              <ReactECharts
                className="geochart"
                ref={ref}
                option={peakScansOption}
                style={{ height: "400px", width: "100%" }}
              />
            </div>
          </div>
        ) : null}
        {!detail?.staticQrcode ? (
          <div className="col-md-12">
            <div className="list-group-item">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by device</h1>
                  <div className="border_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th className="heading-th">Device</th>
                          <th className="">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScanByDevices &&
                          ScanByDevices?.map((item) => {
                            return (
                              <tr>
                                <td>{item?.device}</td>
                                <td>
                                  <div className="progress progress_bar">
                                    <span className="span-number">
                                      {item?.total_scans}
                                    </span>

                                    <div
                                      className="progress-bar progress_row "
                                      style={{
                                        width: `${
                                          (item?.percentage / 100) * 100
                                        }%`,
                                      }}
                                      role="progressbar"
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by operating system</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th className="heading-th">OS</th>
                          <th className="">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScanByOS &&
                          ScanByOS?.map((item) => {
                            return (
                              <tr>
                                <td>{item?.os || "Unknown OS"}</td>
                                <td>
                                  <div className="progress progress_bar">
                                    <span className="span-number">
                                      {item?.total_scans}
                                    </span>
                                    <div
                                      className="progress-bar progress_row "
                                      style={{
                                        width: `${
                                          (item?.percentage / 100) * 100
                                        }%`,
                                      }}
                                      role="progressbar"
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <h1 className="heading_h1row">Scans by location</h1>
                  <div className="border_box">
                    <Chart
                      chartType="GeoChart"
                      width="100%"
                      height="400px"
                      data={geoChartData}
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by top countries</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Country</th>
                          <th className="scans_div">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScansByTopCountries &&
                          ScansByTopCountries?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.country || "--"}</td>
                                <td>{item?.total_scans || "0"}</td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by top cities</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>City</th>
                          <th className="scans_div">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScansByTopCities &&
                          ScansByTopCities?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.city || "--"}</td>
                                <td>{item?.total_scans || "0"}</td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Select format Modal */}
        <button
          type="button"
          id="OpenFormatModel"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#FormatQrImageModal"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="FormatQrImageModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setSelectedFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      selectFormat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "svg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("svg")}
                  >
                    SVG
                  </p>
                </div>
                </div>
                <div className="img_qr">
                  <img ref={(ref) => {
                    canvasRef.current = ref;
                  }}
                    src={detail?.image} alt="" />
                </div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseFormatModal"
                  onClick={(e) => setSelectedFormat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    !selectFormat ? "disable" : ""
                  }`}
                  disabled={!selectFormat}
                  onClick={convertHTMLToImage}
                >
                  Confirm <i className="fa fa-check text-light ml-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Move Folder Modal */}
        <button
          type="button"
          id="OpenMoveFolderModal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#moveFolderModal"
        >
          Move Folder
        </button>
        <div
          className="modal fade"
          id="moveFolderModal"
          tabindex="-1"
          aria-labelledby="moveFolderModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="moveFolderModalLabel">
                  Move Folder
                </h1>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Select Folder</label>
                    <select
                      className="form-select"
                      value={selectedFolder?.id}
                      onChange={(e) => handleFolderSelect(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option value="">Select Folder</option>
                      {folder &&
                        folder.map((item, index) => {
                          return (
                            <option value={item?._id}>{item?.name}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseMoveFolderModal"
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={!selectedFolder?.id}
                  onClick={(e) => moveFolder("Save")}
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal For Duplicate */}
        <button
          type="button"
          id="OpenDuplicateModal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#duplicateModal"
        >
          Duplicate
        </button>
        <div
          className="modal fade"
          id="duplicateModal"
          data-bs-backdrop="static"
          tabindex="-1"
          aria-labelledby="duplicateModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1
                  className="modal-title fs-4 text-center"
                  id="duplicateModalLabel"
                >
                  Duplicate QR Code
                </h1>
                <div className="row">
                  <div className="col-md-12 my-4">
                    <label className="form-label">Campaign Name</label>
                    <input
                      type="text"
                      value={duplicateQrCode}
                      onChange={(e) => setduplicateQrCode(e.target.value)}
                      className="form-control"
                      placeholder="Campaign Name"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <button
                    type="button"
                    id="CloseDuplicateModal"
                    className="btn primary_btn"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    disabled={!duplicateQrCode}
                    onClick={(e) => handleDuplicate("create")}
                    className="btn btn-primary"
                  >
                    Duplicate
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal For Password Change */}
        <button
          type="button"
          id="OpenPasswordChangeModal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#PasswordChangeModal"
        >
          Change Password
        </button>
        <div
          className="modal fade"
          id="PasswordChangeModal"
          data-bs-backdrop="static"
          tabindex="-1"
          aria-labelledby="PasswordChangeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1
                  className="modal-title fs-4 text-center"
                  id="PasswordChangeModalLabel"
                >
                  Change Password
                </h1>
                <div className="row">
                  <div className="col-md-12 my-4">
                    <label className="form-label">Password</label>
                    <input
                      minLength={0}
                      maxLength={10}
                      type="text"
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <button
                    type="button"
                    id="ClosePasswordChangeModal"
                    className="btn primary_btn"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    disabled={!password || ActivePlan?.name == "free"}
                    onClick={(e) => handleChangePassword("save")}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Expiry Bootstrap modal */}
        <button
          type="button"
          class="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="Expirymodal"
          data-bs-backdrop="static"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog expiry_modal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Expiry
                </h5>
              </div>
              <div class="modal-body">
                <p>
                  You can set the QR Code to expire after a number of scans or
                  by date. This is useful for scenarios like limiting the number
                  of voucher redemptions.
                </p>
                <label> Number of scans</label>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Expiry?.setting?.numberOfScansStatus}
                        onChange={(e) =>
                          setExpiry({
                            ...Expiry,
                            setting: {
                              ...Expiry.setting,
                              numberOfScansStatus: e.target.checked,
                            },
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="numberOfScans"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="numberOfScans"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="text"
                      value={Expiry?.setting?.numberOfScans
                          ? Expiry?.setting?.numberOfScans
                          : ""}
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          totalScans: e.target.value,
                          setting: {
                            ...Expiry.setting,
                            numberOfScans: e.target.value,
                          },
                        })
                      }
                      disabled={!Expiry?.setting?.numberOfScansStatus}
                      maxLength="5"
                      className="form-control"
                      placeholder="Number of scans"
                    />
                  </div>
                </div>
                <label>Date Range</label>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Expiry?.setting?.dateRangeStatus}
                        onChange={(e) =>
                          setExpiry({
                            ...Expiry,
                            setting: {
                              ...Expiry.setting,
                              dateRangeStatus: e.target.checked,
                            },
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="dateRange"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="dateRange"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    {/* <DatePicker
                  selected={Expiry?.dateRangeEnd}
                  minDate={new Date()}
                  onChange={(date) =>
                    setExpiry({ ...Expiry, dateRangeStart: date })
                  }
                  dateFormat="dd-MMM-yyyy"
                  disabled={!Expiry.dateRangeStatus}
                  className="form-control email-input"
                  placeholderText="Start"
                /> */}
                    <input
                      value={moment(Expiry?.setting?.dateRangeStart).format(
                        "YYYY-MM-DD"
                      )}
                      name="dateRangeStart"
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            dateRangeStart: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      type="date"
                      placeholderText="Start"
                      disabled={!Expiry?.setting?.dateRangeStatus}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    {/* <DatePicker
                  selected={Expiry?.dateRangeEnd}
                  minDate={Expiry.dateRangeStart || new Date()}
                  onChange={(date) =>
                    setExpiry({ ...Expiry, dateRangeEnd: date })
                  }
                  dateFormat="dd-MMM-yyyy"
                  disabled={!Expiry.dateRangeStatus}
                  className="form-control email-input"
                  placeholderText="End" 
                /> */}
                    <input
                      value={moment(Expiry?.setting?.dateRangeEnd).format(
                        "YYYY-MM-DD"
                      )}
                      name="dateRangeEnd"
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            dateRangeEnd: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      type="date"
                      placeholderText="Start"
                      disabled={!Expiry?.setting?.dateRangeStatus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label>Expiry URL</label>
                    <input
                      type="text"
                      value={Expiry?.setting?.expiryURL}
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            expiryURL: e.target.value,
                          },
                        })
                      }
                      disabled={
                        !Expiry?.setting?.numberOfScansStatus &&
                        !Expiry?.setting?.dateRangeStatus
                      }
                      className="form-control"
                      placeholder="https://"
                    />
                    <p className="mt-1">
                      Visitors will be redirected to this URL after the main
                      link expires.
                    </p>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-clear border"
                  id="CloseExpiry"
                  data-bs-dismiss="modal"
                >
                  Back
                </button>
                <button
                  onClick={(e) => UpdateExpiry(e)}
                  type="button"
                  class="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Campaign Modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Campaign
                </h5>
              </div>
              <form onSubmit={(e) => UpdateEditdata(e)}>
                <div class="">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="">
                            <p className="my-2 ml-3">Medium</p>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                name="flyer"
                                type="text"
                                placeholder="e.g. email, banner, bus stop"
                                onChange={(e) => HandleEditchange(e)}
                                value={EditData?.setting?.flyer}
                                required
                              />
                            </div>
                            <p className="my-2 ml-3">Print Run</p>
                            <div className="col-md-12">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                name="printRun"
                                placeholder="e.g. 500"
                                onChange={(e) => HandleEditchange(e)}
                                value={EditData?.setting?.printRun}
                              />
                            </div>
                            <p className="my-2 ml-3">Campaign Start</p>
                            <div className="col-md-12">
                              <input
                                type="date"
                                className="form-control"
                                onChange={(e) => HandleEditchange(e)}
                                name="campaignStart"
                                value={moment(
                                  EditData?.setting?.campaignStart
                                ).format("YYYY-MM-DD")}
                              />
                            </div>

                            <p className="my-2 ml-3">Campaign End</p>
                            <div className="col-md-12">
                              <input
                                type="date"
                                className="form-control"
                                onChange={(e) => HandleEditchange(e)}
                                name="campaignEnd"
                                value={moment(
                                  EditData?.setting?.campaignEnd
                                ).format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer my-2">
                  <button
                    type="button"
                    class="btn btn-clear border"
                    id="btnclose"
                    data-bs-dismiss="modal"
                  >
                    Back
                  </button>
                  <button type="submit" class="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Modal for GPS */}
        <button
          className="d-none"
          data-bs-toggle="modal"
          id="Opengpsmodal"
          data-bs-target="#GPSModal"
        >
          ggg
        </button>

        <div
          class="modal fade"
          id="GPSModal"
          tabindex="-1"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  GPS Tracking
                </h5>
              </div>
              <form onSubmit={(e) => GPStracing(e, "modal")}>
                <div className="row p-3">
                  <div className="col-md-12">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Gps?.setting?.exactGPSLocation}
                        onClick={(e) =>
                          setGps({
                            ...Gps,
                            setting: {
                              ...Gps.setting,
                              exactGPSLocation: e.target.checked,
                            },
                          })
                        }
                        disabled={
                          Gps?.setting?.limitScanLocation ||
                          ActivePlan?.name == "starter" ||
                          ActivePlan?.name == "free"
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="exactGPSLocation"
                      />
                      <label
                        className="form-check-label"
                        for="exactGPSLocation"
                      >
                        Exact GPS Location
                      </label>
                    </div>
                  </div>
                  {/* <div className="d-flex">
                  <div class="col-md-4 mb-3">
                    <label>Longitude</label>
                    <input
                      type="text"
                      value={Gps?.setting?.exactGPSLocationLng}
                      onChange={(e) =>
                        setGps({
                          ...Gps,
                          setting: {
                            ...Gps.setting,
                            exactGPSLocationLng: e.target.value,
                          },
                        })
                      }
                      disabled={!Gps?.setting?.exactGPSLocation}
                      maxLength="15"
                      className="form-control"
                      placeholder="Longitude"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label>Latitude</label>
                    <input
                      type="text"
                      value={Gps?.setting?.exactGPSLocationLat}
                      onChange={(e) =>
                        setGps({
                          ...Gps,
                          setting: {
                            ...Gps.setting,
                            exactGPSLocationLat: e.target.value,
                          },
                        })
                      }
                      disabled={!Gps?.setting?.exactGPSLocation}
                      maxLength="15"
                      className="form-control"
                      placeholder="Latitude"
                    />
                  </div>
                </div> */}
                  <div className="col-md-12">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Gps?.setting?.limitScanLocation}
                        onClick={(e) =>
                          setGps({
                            ...Gps,
                            setting: {
                              ...Gps.setting,
                              limitScanLocation: e.target.checked,
                            },
                          })
                        }
                        disabled={
                          Gps?.setting?.exactGPSLocation ||
                          ActivePlan?.name == "starter" ||
                          ActivePlan?.name == "free"
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="limitScanLocation"
                      />
                      <label
                        className="form-check-label"
                        for="limitScanLocation"
                      >
                        Geo-fencing: Limit scan location
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Longitude</label>
                    <input
                      type="number"
                      required
                      value={Gps?.setting?.longitude}
                      onChange={(e) =>
                        setGps({
                          ...Gps,
                          setting: {
                            ...Gps.setting,
                            longitude: e.target.value,
                          },
                        })
                      }
                      disabled={!Gps?.setting?.limitScanLocation}
                      maxLength="15"
                      className="form-control"
                      placeholder="Longitude"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Latitude</label>
                    <input
                      type="number"
                      required
                      value={Gps?.setting?.latitude}
                      onChange={(e) =>
                        setGps({
                          ...Gps,
                          setting: { ...Gps.setting, latitude: e.target.value },
                        })
                      }
                      disabled={!Gps?.setting?.limitScanLocation}
                      maxLength="15"
                      className="form-control"
                      placeholder="Latitude"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Radius in Km{" "}
                      <span className="label_text"> (1 Km = 0,62 Mile)</span>
                    </label>
                    <input
                      type="number"
                      required
                      value={Gps?.setting?.radiusInKM}
                      onChange={(e) =>
                        setGps({
                          ...Gps,
                          setting: {
                            ...Gps.setting,
                            radiusInKM: e.target.value,
                          },
                        })
                      }
                      disabled={!Gps?.setting?.limitScanLocation}
                      maxLength="10"
                      className="form-control"
                      placeholder="Radius in Km"
                    />
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-clear border"
                    id="GPSclose"
                    data-bs-dismiss="modal"
                  >
                    Back
                  </button>
                  <button
                    // onClick={(e) => GPStracing("modal")}
                    type="submit"
                    class="btn btn-primary"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditQrCodes;
