import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { login_success, logout } from "../../actions/user";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import "./style.scss";
import { CountrySelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import moment from "moment";
import { active_Plan, deactive_Plan } from "../../actions/activePlan";
import Pagination from "react-js-pagination";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const AccountSetting = () => {
  const user = useSelector((state) => state.user);

  const currentPlan = useSelector((state) => state.activePlan);
  const dispatch = useDispatch();
  const history = useHistory();
  // Card Detail
  const [cards, setcards] = useState([]);
  const primaryCardId = useRef();
  const [cardForm, setcardForm] = useState({
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
    country: "",
  });
  // Subscription Details
  const [upgradePlan, setupgradePlan] = useState(false);
  const [button, setbutton] = useState(false);

  // Account Details
  const [AccountForm, setAccountForm] = useState({
    firstName: "",
    lastName: "",
    company_name: "",
    email: "",
    image: "",
  });
  const [changePassword, setchangePassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const [submitted, setSubmitted] = useState(false);

  const formValidation = [
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "newPassword"],
    },
    { key: "currentPassword", minLength: 8 },
    { key: "newPassword", minLength: 8 },
  ];
  const getError = (key) => {
    return methodModel.getError(key, changePassword, formValidation);
  };

  useEffect(() => {
    setAccountForm({
      firstName: user?.firstName,
      lastName: user?.lastName,
      company_name: user?.company_name,
      email: user?.email,
      image: user?.image,
    });
    getAllCards();
  }, []);

  // Card Detail
  const getAllCards = () => {
    ApiClient.get(`card/all?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setcards(res?.data?.data);
        res?.data?.data.map((item) => {
          if (item?.isPrimary) {
            primaryCardId.current = item?.card_id;
          }
        });
      }
    });
  };
  const handleCard = (e) => {
    e.preventDefault();
    if (!cardForm?.country) {
      toast.error("Country is required");
      return;
    }
    let payload = {
      ...cardForm,
      country: cardForm?.country?.iso3,
    };
    ApiClient.post(`card`, payload).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res.message);
        }, 100);
        getAllCards();
        document.getElementById("CloseAddCardModal").click();
      }
    });
  };
  const deleteCard = (id) => {
    if (window.confirm("Do you really want to remove this card")) {
      ApiClient.delete(`card?id=${id}`).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          getAllCards();
        }
      });
    }
  };
  const handleCardStatus = (id) => {
    if (window.confirm("Do you really want to set this card primary")) {
      ApiClient.put(`card/set-primary`, { id: id }).then((res) => {
        if (res.success) {
          toast.success(res.message);
          getAllCards();
        }
      });
    }
  };

  // Subscription Details

  // Account Details
  const handleAccountForm = (e) => {
    e.preventDefault();
    setbutton(true);
    let payload = {
      firstName: AccountForm?.firstName,
      lastName: AccountForm?.lastName,
      company_name: AccountForm?.company_name,
      email: AccountForm?.email,
      image: AccountForm?.image,
      id: user?.id,
    };
    ApiClient.put(`edit/profile`, payload).then((res) => {
      if (res.success) {
        dispatch(login_success(res?.data));
        toast.success(res.message);
        document.getElementById("CloseAccountModal").click();
      }
      setbutton(false);
    });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, changePassword);
    if (invalid) return;
    ApiClient.put(`change/password`, { ...changePassword }).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res.message);
        }, 100);
        dispatch(logout());
        localStorage.removeItem("persist:admin-app");
        localStorage.removeItem("token");
        document.getElementById("CloseChangePasswordModal").click();
        history.push(`/login`);
      }
    });
  };

  const uploadImage = (e) => {
    setAccountForm({ ...AccountForm, baseImg: e.target.value });
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", {
      file: file,
      modelName: "users",
    }).then((res) => {
      if (res.data.fullpath) {
        let image = res.data?.fullpath;
        setAccountForm({ ...AccountForm, image: image, baseImg: "" });
      } else {
        setAccountForm({ ...AccountForm, baseImg: "" });
      }
      loader(false);
    });
  };

  // Plans Section
  const [plans, setplans] = useState([]);
  const [bill, setbill] = useState(1);
  const [activePlan, setactivePlan] = useState();
  const token = localStorage.getItem("token");
  const [cardSection, setcardSection] = useState({ status: false });

  const [filters, SetFilters] = useState({ page: 1, limit: 5 }); //Defined state of filters
  const [billingTotal, setbillintTotal] = useState(0);
  useEffect(() => {
    if (!token) {
      history.push("/login");
    }
    getAllPlans();
    getPurchasePlan();
    getBillData(); //Added function in useEffect
  }, []);

  const [billData, setBillData] = useState([]); //Defined State of billData

  const getBillData = (p = {}) => {
    let filt = { ...filters, ...p, userId: user?.id }; // getBillData will store the data from api
    ApiClient.get(`all/buy/planed`, filt).then((res) => {
      setBillData(res.data);
      setbillintTotal(res.totalDocuments);
    });
  };
  const handlePageChange = (pageNumber) => {
    SetFilters({ ...filters, page: pageNumber });
    getBillData({ page: pageNumber });
  };
  const getPurchasePlan = () => {
    loader(true);
    ApiClient.get(`active-subscription?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        setactivePlan(res?.data);
      } else {
        setactivePlan("");
      }
      loader(false);
    });
  };

  const getAllPlans = () => {
    ApiClient.get(
      `subscription-plan/all?status=active&userId=${user?.id}`
    ).then((res) => {
      if (res.success) {
        setplans(res?.data?.data);
      }
    });
  };
  const planPrice = (item) => {
    let amount = 0;
    item?.pricing.map((itm) => {
      if (itm?.interval_count == bill) {
        amount = itm?.unit_amount;
      }
    });
    if (bill == 12) {
      amount /= 12;
    }
    return parseFloat(amount).toFixed(2);
  };
  const buyPlan = (item) => {
    let stripeId = "";
    item?.pricing.map((item) => {
      if (item?.interval_count == bill) {
        stripeId = item?.stripe_price_id;
      }
    });
    setcardSection({ status: true, planId: item?._id, stripeId: stripeId });
  };
  const cancelPlan = (item) => {
    loader(true);
    ApiClient.put(`cancel-subscription`, {
      subscription_plan_id: activePlan?.subscription_plan_id,
      user_id: user?.id,
    }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        dispatch(deactive_Plan());
        getAllPlans();
        getPurchasePlan();
      }
      loader(false);
    });
  };
  const planActive = (item) => {
    let value = false;
    if (
      (item?._id == activePlan?.subscription_plan_id &&
        activePlan?.interval_count == bill) ||
      (item?._id == activePlan?.subscription_plan_id &&
        activePlan?.interval_count == "14")
    ) {
      value = true;
    }
    return value;
  };
  const borderCheck = (item) => {
    let value = false;
    if (
      (planActive(item) && activePlan?.interval_count == bill) ||
      (planActive(item) && activePlan?.interval_count == "14")
    ) {
      value = true;
    } else if (item?.name == "premium" && activePlan?.interval_count != bill) {
      value = "check";
    } else {
      false;
    }
    return value;
  };
  const handlePayment = () => {
    if (cards?.length == 0) {
      toast.error("Please add card first");
      return;
    } else {
      let payload = {
        id: cardSection?.planId,
        card_id: primaryCardId.current,
        stripe_price_id: cardSection?.stripeId,
        user_id: user?.id,
      };
      if (user?.referral_slug) {
        payload = {
          ...payload,
          referral_slug: user?.referral_slug,
        };
      }
      /////
      loader(true);
      ApiClient.post(`subscribe`, payload).then((res) => {
        if (res.success) {
          dispatch(active_Plan(res.data));
          getAllPlans();
          getPurchasePlan();
          setupgradePlan(false);
          getBillData();
          setcardSection({ ...cardSection, status: false });
          toast.success(res?.message);
        }
        loader(false);
      });
    }
  };

  const checkFreePlan = () => {
    if (activePlan?.stripe_price_obj?.interval_count == 14) {
      return `(${activePlan?.trial_period_days} Days Free Trial)`;
    } else {
      return "";
    }
  };
  const [data, setdata] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
  });
  const Adddata = (e) => {
    e.preventDefault();
    let payload = { id: user?.id, billingAddress: data };
    ApiClient.put("edit/profile", payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
        dispatch(login_success(res?.data));
        document.getElementById("btnclosebilling").click();
      }
    });
  };
  const Handlebillchange = (e) => {
    const value = e.target.value;
    setdata({ ...data, [e.target.name]: value });
  };

  return (
    <Layout>
      <div>
        <div className="container-fluid">
          <ul
            className="nav nav-tabs nav-detail bg-white mb-3"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item w-0" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                Subscription Details
              </button>
            </li>
            <li className="nav-item w-0" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                Account Details
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabindex="0"
            >
              <div className="form-row ">
                <div className="col-md-12 col-lg-6 mb-3">
                  <div className="current-plandiv">
                    <h6 className="fw-bold mb-0 pb-3 account-h6">
                      Current Plan{" "}
                    </h6>
                    {upgradePlan ? (
                      <div className="">
                        <h6 className="fw-bold standard-h6 text-capitalize py-3">
                          {currentPlan?.name} {checkFreePlan()}
                        </h6>
                        <div className="div_plan">
                          <h6 className="t-h6 fw-normal">Renewal Date </h6>
                          <h6 className="t-h6 fw-normal">
                            {" "}
                            <span className="t-span fw-bold">
                              {moment(currentPlan?.valid_upto).format(
                                "MMM DD, YYYY"
                              )}{" "}
                            </span>
                          </h6>
                        </div>
                        <div className="div_plan">
                          <h6 className="t-h6 fw-normal">Auto Renewal</h6>
                          <h6 className="t-h6 fw-normal">
                            {" "}
                            <span className="t-span fw-bold">Enabled</span>
                          </h6>
                        </div>
                        <div className="div_plan">
                          <h6
                            onClick={(e) => {
                              setupgradePlan(false),
                                setcardSection({
                                  ...cardSection,
                                  status: false,
                                });
                            }}
                            className="fw-bold  text-danger cancel-h6 pointer pt-3"
                          >
                            Cancel  
                          </h6>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="flex_details flex_details2">
                          <h6 className="t-h6 fw-normal">Scans Per Month: </h6>
                          <h6 className="t-h6 fw-normal">
                            {" "}
                            <span className="t-span fw-bold">
                              {activePlan?.totalScan}
                            </span>{" "}
                            /{" "}
                            {currentPlan?.scans_per_month
                              ? methodModel.capitalizeFirstLetter(currentPlan.scans_per_month)
                              : "--"}{" "}
                          </h6>
                        </div>

                        <div className="flex_details flex_details2">
                          <h6 className="t-h6 fw-normal">Dynamic QR Codes: </h6>
                          <h6 className="t-h6 fw-normal">
                            {" "}
                            <span className="t-span fw-bold">
                              {activePlan?.dynamicQrcode}
                            </span>{" "}
                            / {currentPlan?.dynamic_qr_code || "--"}{" "}
                          </h6>
                        </div>
                        <div className="flex_details flex_details2">
                          <h6 className="t-h6 fw-normal">Static QR Codes: </h6>
                          <h6 className="t-h6 fw-normal">
                            {" "}
                            <span className="t-span fw-bold">
                              {activePlan?.staticQrcode}
                            </span>{" "}
                            /{" "}
                            {currentPlan?.static_qr_codes
                              ? methodModel.capitalizeFirstLetter(currentPlan.scans_per_month)
                              : "--"}{" "}
                          </h6>
                        </div>
                        {/* <div className="flex_details flex_details2">
                          <h6 className="t-h6 fw-normal">Users</h6>
                          <h6 className="t-h6 fw-normal"> <span className="t-span fw-bold">{activePlan?.totalReferalUser}</span> / --</h6>
                        </div> */}
                        <div className="flex_details flex_details2">
                          <h6 className="t-h6 fw-normal">Plan:</h6>
                          <h6 className="t-h6 fw-normal">
                            {" "}
                            <span className="t-span fw-bold text-capitalize">
                              {currentPlan?.name || "--"}
                            </span>{" "}
                            (
                            {moment(currentPlan?.valid_upto).format(
                              "MMM DD, YYYY"
                            )}
                            )
                          </h6>
                        </div>
                        <div className="flex_details flex_details2 align-items-center">
                          <h6 className="t-h6 fw-normal border-bottom-0">
                            Need more?
                          </h6>
                          <h6 className="">
                            <button
                              onClick={(e) => setupgradePlan(true)}
                              className="btn btn-primary upgrade-btn"
                            >
                              Upgrade Plan
                            </button>
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 mb-3">
                  <div className="billing-div ">
                    <h6 className="fw-bold mb-0 pb-3 account-h6 p-3">
                      Billing History
                    </h6>
                    <div className=" table-responsive">
                      <table className="table table-striped ">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Invoice Number</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          {/*Displayed Data using map */}
                          {billData.map((item) => (
                            <tr>
                              <td>
                                <span className="table_date">
                                  {new Date(item.createdAt).toLocaleDateString(
                                    "en-US",
                                    {
                                      month: "short",
                                      day: "2-digit",
                                      year: "numeric",
                                    }
                                  )}
                                </span>
                              </td>
                              <td>{item.invoice_no || "--"}</td>
                              <td>{item.amount}</td>
                              {/* <td className="text-center"> <img src="../assets/img/download.png" className="img-fluid" alt="..." /></td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="settings_div d-flex justify-content-between p-3">
                      <div className="text_total">
                        <h6 className="fw-normal mb-0 ">
                          {" "}
                          Total Invoices :{billingTotal}
                        </h6>
                      </div>
                      <div className=" pages_number">
                        <div>
                          <Pagination //Pagination
                            activePage={filters.page}
                            itemsCountPerPage={filters.count}
                            totalItemsCount={billingTotal}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 pr-0 pl-1 mb-3">
                  {upgradePlan ? (
                    <>
                      {!cardSection?.status ? (
                        <>
                          {/* Plan Section */}
                          <div className="billing-div mb-3">
                            <div className="pricing">
                              <h1 className="pricing_heading mt-1"> </h1>
                              <div className="tabs_plan">
                                <ul
                                  className="nav nav-pills plans_btn mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li className="nav-item" role="presentation">
                                    <button
                                      onClick={(e) => setbill(1)}
                                      className={`nav-link ${
                                        bill == 1 ? "active" : ""
                                      }`}
                                    >
                                      Bill Monthly
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      onClick={(e) => setbill(12)}
                                      className={`nav-link ${
                                        bill == 12 ? "active" : ""
                                      }`}
                                    >
                                      Bill Yearly
                                    </button>
                                  </li>
                                </ul>
                                <div className="rate_img">
                                  <h3>You save 30%</h3>
                                  <img src="/assets/img/Arrow.png"></img>
                                </div>
                              </div>
                              <div className="tab-pane fade show active">
                                <div className="pricing_table pricing_div">
                                  <div className="table-responsive table_data">
                                    <table className="w-100">
                                      <tr>
                                        <th className="th_main"></th>
                                        {plans &&
                                          plans.map((item, index) => {
                                            return (
                                              <th
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-top border-primary list_popular rounded-top"
                                                    : ""
                                                }`}
                                              >
                                                {borderCheck(item) ==
                                                "check" ? (
                                                  <p className="topular_text">
                                                    Most Popular
                                                  </p>
                                                ) : null}
                                                {borderCheck(item) == true ? (
                                                  <p className="topular_text">
                                                    Valid Upto:{" "}
                                                    {moment(
                                                      activePlan?.valid_upto
                                                    ).format("MMM DD, YYYY")}
                                                  </p>
                                                ) : null}
                                                <h1
                                                  className={`text-capitalize mt-3`}
                                                >
                                                  {item?.name}
                                                </h1>
                                                <h3>
                                                  ${planPrice(item)}{" "}
                                                  <span className="month_text">
                                                    /{" "}
                                                    {bill == 1
                                                      ? "month"
                                                      : "month"}
                                                  </span>
                                                </h3>
                                                <div className="btn_center">
                                                  {planActive(item) ? (
                                                    <button
                                                      className="btn btn-primary light_greenBtn"
                                                      onClick={(e) =>
                                                        cancelPlan(item)
                                                      }
                                                    >
                                                      Cancel Plan
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn btn-primary"
                                                      onClick={(e) =>
                                                        buyPlan(item)
                                                      }
                                                    >
                                                      {activePlan?.interval_count
                                                        ? "Upgrade Plan"
                                                        : "START FREE TRIAL"}
                                                    </button>
                                                  )}
                                                </div>
                                              </th>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td className="fs-5">FEATURES</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              ></td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Static QR Codes</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.static_qr_codes}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Dynamic QR Codes</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.dynamic_qr_code}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Scans per Month</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.scans_per_month}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Tracking Analytics</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.tracking_analytics}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Advertisement</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.advertisement}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Branding</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.branding || "--"}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Affiliate Commission</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.affiliate_commission}%
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Bulk Upload</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.bulk_upload ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Full Design Options</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.full_design_option ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Save Design as Template</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.save_design_as_template ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>QR Code Scanner</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.qr_code_scanner ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Excel Download of Analytics</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.excel_download ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Max File Size</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.max_file_size}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Customer Support</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.customer_support || "--"}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Max Folders</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`text-capitalize ${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.max_folders || "--"}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>
                                          Set Password Protection of QR Codes
                                        </td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.set_password_protection ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Set Expiry of QR Codes by Time</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.set_expiry_of_qr_codes_by_time ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Set Expiry of QR Codes by Scans</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.set_expiry_of_qr_codes_by_scans ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                      <tr>
                                        <td>Set GPS Tracking of QR Codes</td>
                                        {plans &&
                                          plans.map((item) => {
                                            return (
                                              <td
                                                className={`${
                                                  borderCheck(item) == true ||
                                                  borderCheck(item) == "check"
                                                    ? "border-left-right border-bottom border-primary"
                                                    : ""
                                                }`}
                                              >
                                                {item?.set_GPS_tracking_of_qr_codes ? (
                                                  <span className="material-icons text-success">
                                                    done
                                                  </span>
                                                ) : (
                                                  <span className="material-icons text-danger">
                                                    close
                                                  </span>
                                                )}
                                              </td>
                                            );
                                          })}
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Card Detail */}
                          <div className="billing-div mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="fw-bold mb-3 account-h6 px-3 pt-3">
                                Payment Details
                              </h6>
                              <div>
                                <button
                                  onClick={(e) => {
                                    setupgradePlan(false),
                                      setcardSection({
                                        ...cardSection,
                                        status: false,
                                      });
                                  }}
                                  className="btn btn-clear border  mr-2"
                                >
                                  Back
                                </button>
                                <button
                                  className="btn btn-outline-primary mr-2"
                                  onClick={(e) => handlePayment()}
                                >
                                  Pay
                                </button>
                              </div>
                            </div>
                            <div className=" p-3">
                              <h6 className="fw-bold mb-3 account-h6 pt-3">
                                Payment Method{" "}
                                <span
                                  className="text-primary float-right pointer ml-3"
                                  data-bs-toggle="modal"
                                  data-bs-target="#addcard"
                                >
                                  Add New Payment Method +
                                </span>
                              </h6>
                              <div className="table-responsive">
                                <table className="table billing-table ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Card</th>
                                      <th scope="col">Expiration Date</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cards &&
                                      cards.map((item, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <th scope="row">
                                                {" "}
                                                <span class="white_space">
                                                  {" "}
                                                  <img
                                                    src="../assets/img/visa.png"
                                                    className="img-fluid me-2"
                                                    alt="..."
                                                  />
                                                  VISA *
                                                  {item?.card_number?.substr(
                                                    -4
                                                  )}{" "}
                                                  {item?.isPrimary ? (
                                                    <span className="fw-bold ml-2  primary-btn btn_width">
                                                      Primary
                                                    </span>
                                                  ) : null}
                                                </span>
                                              </th>
                                              <td>
                                                {item?.exp_month}/
                                                {item?.exp_year}
                                              </td>
                                              <td className="fw-bold">
                                                <span class="white_space">
                                                  {" "}
                                                  {!item?.isPrimary ? (
                                                    <div className="d-flex">
                                                      <span
                                                        className="text-primary pointer"
                                                        onClick={(e) =>
                                                          handleCardStatus(
                                                            item?.id
                                                          )
                                                        }
                                                      >
                                                        Set as Primary
                                                      </span>
                                                      <div className="dropdown ml-3 pointer">
                                                        <span
                                                          className="material-icons delete_bar"
                                                          data-bs-toggle="dropdown"
                                                          aria-expanded="false"
                                                        >
                                                          more_horiz
                                                        </span>
                                                        <ul className="dropdown-menu">
                                                          <li>
                                                            <a
                                                              className="dropdown-item"
                                                              onClick={(e) =>
                                                                deleteCard(
                                                                  item?.id
                                                                )
                                                              }
                                                            >
                                                              Remove
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <span
                                                      className="text-danger pointer"
                                                      onClick={(e) =>
                                                        deleteCard(item?.id)
                                                      }
                                                    >
                                                      Remove
                                                    </span>
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    {/* <tr>
                              <th > <img src="../assets/img/visa.png" className="img-fluid me-2" alt="..." /> VISA *4567</th>
                              <td className="text-primary fw-bold">Set as Primary <img src="../assets/img/3dots.png" className="ms-3 img-fluid" alt="..." />   </td>
                              <td>05/27</td>
                            </tr> */}
                                  </tbody>
                                </table>
                              </div>
                              <div className="row"></div>
                              <div className="col-12 px-0">
                                <div className="billing-address-btn">
                                  <h6 className="fw-bold mb-0 py-4 account-h6 ">
                                    Billing Address
                                  </h6>
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Street</th>
                                        <th scope="col">City</th>
                                        <th scope="col">State</th>
                                        <th scope="col">Zip Code</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {user?.billingAddress?.street || "--"}
                                        </td>
                                        <td>
                                          {user?.billingAddress?.city || "--"}
                                        </td>
                                        <td>
                                          {user?.billingAddress?.state || "--"}
                                        </td>
                                        <td>
                                          {user?.billingAddress?.zip || "--"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <button
                                    data-bs-toggle="modal"
                                    onClick={(e) =>
                                      setdata({
                                        street: "",
                                        city: "",
                                        zip: "",
                                        state: "",
                                      })
                                    }
                                    data-bs-target="#exampleModal"
                                    type="button"
                                    className="btn btn-outline-primary fw-bold mt-3 ml-1 w-20"
                                  >
                                    {" "}
                                    Billing Address{" "}
                                  </button>

                                  <button
                                    data-bs-toggle="modal"
                                    onClick={(e) =>
                                      user?.billingAddress &&
                                      setdata(user.billingAddress)
                                    }
                                    data-bs-target="#exampleModal"
                                    type="button"
                                    className="btn btn-outline-primary fw-bold mt-3 ml-2 w-20"
                                  >
                                    Update Billing Address{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* Add Card Modal */}
          <div
            className="modal fade"
            id="addcard"
            tabindex="-1"
            aria-labelledby="addCardModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered card-modal">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="addCardModalLabel">
                    Add Card
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleCard}>
                    <div className="row">
                      <div className="col-md-12 col-lg-6 mb-3">
                        <label>
                          Card Number<span className="star">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          minLength={16}
                          maxLength={16}
                          value={cardForm?.card_number}
                          onChange={(e) =>
                            setcardForm({
                              ...cardForm,
                              card_number: methodModel.isNumber(e),
                            })
                          }
                          placeHolder="Card Number"
                          required
                        />
                      </div>
                      <div className="col-md-12 col-lg-6 mb-3">
                        <label>
                          Expire Month<span className="star">*</span>
                        </label>
                        <input
                          type="text"
                          minLength={2}
                          maxLength={2}
                          className="form-control"
                          value={cardForm?.exp_month}
                          onChange={(e) =>
                            setcardForm({
                              ...cardForm,
                              exp_month: methodModel.isNumber(e),
                            })
                          }
                          placeHolder="Expire Month"
                          required
                        />
                      </div>
                      <div className="col-md-12 col-lg-6 mb-3">
                        <label>
                          Expire Year<span className="star">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          minLength={4}
                          maxLength={4}
                          value={cardForm?.exp_year}
                          onChange={(e) =>
                            setcardForm({
                              ...cardForm,
                              exp_year: methodModel.isNumber(e),
                            })
                          }
                          placeHolder="Expire Year"
                          required
                        />
                      </div>
                      <div className="col-md-12 col-lg-6 mb-3">
                        <label>
                          CVC<span className="star">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          minLength={3}
                          maxLength={3}
                          value={cardForm?.cvc}
                          onChange={(e) =>
                            setcardForm({
                              ...cardForm,
                              cvc: methodModel.isNumber(e),
                            })
                          }
                          placeHolder="CVC"
                          required
                        />
                      </div>
                      <div className="col-md-12 col-lg-6 mb-3">
                        <label>
                          Country<span className="star">*</span>
                        </label>
                        <CountrySelect
                          defaultValue={cardForm?.country}
                          onChange={(e) =>
                            setcardForm({ ...cardForm, country: e })
                          }
                          placeHolder="Country"
                        />
                      </div>
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        id="CloseAddCardModal"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Back
                      </button>
                      <button type="submit" className="btn btn-primary ml-2">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade m-md-0"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            <div className="row">
              <div className="col-md-12 col-lg-6 mb-3">
                <div className="current-plandiv">
                  <h6 className="fw-bold mb-0 pb-3 account-h6">
                    Account Details{" "}
                  </h6>

                  <div className="flex_details">
                    <h6 className="tb-first fw-normal">First Name </h6>
                    <h6 className="tb-second fw-bold">
                      {user?.firstName || "--"}{" "}
                    </h6>
                  </div>
                  <div className="flex_details">
                    <h6 className="tb-first fw-normal">Last Name </h6>
                    <h6 className="tb-second fw-bold">
                      {user?.lastName || "--"}{" "}
                    </h6>
                  </div>
                  <div className="flex_details">
                    <h6 className="tb-first fw-normal">Company Name </h6>
                    <h6 className="tb-second fw-bold">
                      {user?.company_name || "--"}{" "}
                    </h6>
                  </div>
                  <div className="flex_details">
                    <h6 className="tb-first fw-normal">Email </h6>
                    <h6 className="tb-second mailto:fw-bold">
                      {user?.email || "--"}
                    </h6>
                  </div>

                  <div className="update-btn d-flex align-items-center gap-3 mt-3">
                    <a
                      className="btn btn-outline-primary w-100"
                      href="#"
                      role="button"
                      data-bs-target="#UpdateAccountDetails"
                      data-bs-toggle="modal"
                    >
                      Update Account Details
                    </a>
                    <a
                      className="btn btn-outline-dark w-100"
                      onClick={(e) => {
                        setchangePassword({
                          currentPassword: "",
                          newPassword: "",
                          confirmPassword: "",
                        });
                      }}
                      href="#"
                      role="button"
                      data-bs-target="#ChagngePassword"
                      data-bs-toggle="modal"
                    >
                      Change Password
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* update account detail button  popup */}
          <div
            className="modal fade"
            id="UpdateAccountDetails"
            aria-hidden="true"
            aria-labelledby="UpdateAccountDetailsLabel"
            tabindex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h6
                    className="modal-title fs-5 fw-bold"
                    id="UpdateAccountDetailsLabel"
                  >
                    Update Account Details
                  </h6>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleAccountForm}>
                    <div className="mb-3">
                      <div className="maininutcls text-center">
                        <label className="profileImageLabel">
                          <img
                            src={methodModel.userImg(
                              AccountForm && AccountForm?.image
                            )}
                            className="profileImage"
                            width="100px"
                          />
                        </label>
                        <div className="profile_btn">
                          <div>
                            <label className="btn btn-primary edit mx-3">
                              <input
                                id="bannerImage"
                                type="file"
                                className="d-none"
                                accept="image/*"
                                value={
                                  AccountForm.baseImg ? AccountForm.baseImg : ""
                                }
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                              />
                              {AccountForm.image ? "Change" : "Upload"} Image
                            </label>
                          </div>
                          <div>
                            {AccountForm.image ? (
                              <label
                                className="btn btn-primary delete mx-2"
                                onClick={(e) =>
                                  setAccountForm({ ...AccountForm, image: "" })
                                }
                              >
                                Remove Image
                              </label>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputName1" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        value={AccountForm?.firstName}
                        onChange={(e) =>
                          setAccountForm({
                            ...AccountForm,
                            firstName: e.target.value,
                          })
                        }
                        placeholder="First Name"
                        className="form-control"
                        id="exampleInputName1"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputlast1" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        value={AccountForm?.lastName}
                        onChange={(e) =>
                          setAccountForm({
                            ...AccountForm,
                            lastName: e.target.value,
                          })
                        }
                        placeholder="Last Name"
                        className="form-control"
                        id="exampleInputlast1"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        value={AccountForm?.company_name}
                        onChange={(e) =>
                          setAccountForm({
                            ...AccountForm,
                            company_name: e.target.value,
                          })
                        }
                        placeholder="Company Name"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        value={AccountForm?.email}
                        onChange={(e) =>
                          setAccountForm({
                            ...AccountForm,
                            email: e.target.value,
                          })
                        }
                        placeholder="Email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        id="CloseAccountModal"
                        className="btn btn-outline-secondary mr-3"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={button}
                        className="btn btn-primary"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Change Password button  popup */}
          <div
            className="modal fade"
            id="ChagngePassword"
            aria-hidden="true"
            data-base="static"
            aria-labelledby="ChagngePassword"
            tabindex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title fs-5 fw-bold" id="ChagngePassword">
                    Change Password
                  </h6>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleChangePassword}>
                    <div className="mt-3 p-rel">
                      <label for="exampleInputName1" className="form-label">
                        Current Password
                      </label>
                      <input
                        type={eyes.currentPassword ? "text" : "password"}
                        value={changePassword?.currentPassword}
                        onChange={(e) =>
                          setchangePassword({
                            ...changePassword,
                            currentPassword: e.target.value,
                          })
                        }
                        placeholder="Current Password"
                        className="form-control"
                        id="exampleInputName1"
                        aria-describedby="emailHelp"
                        required
                      />
                      <i
                        className={
                          eyes.currentPassword
                            ? "fa fa-eye p-ab"
                            : "fa fa-eye-slash p-ab"
                        }
                        onClick={() =>
                          setEyes({
                            ...eyes,
                            currentPassword: !eyes.currentPassword,
                          })
                        }
                      ></i>
                    </div>
                    {submitted && getError("currentPassword").invalid ? (
                      <div className="invalid-feedback d-block">
                        Min Length must be 8 characters long
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="mt-3 p-rel">
                      <label for="exampleInputlast1" className="form-label">
                        New Password
                      </label>
                      <input
                        type={eyes.password ? "text" : "password"}
                        value={changePassword?.newPassword}
                        onChange={(e) =>
                          setchangePassword({
                            ...changePassword,
                            newPassword: e.target.value,
                          })
                        }
                        placeholder="New Password"
                        className="form-control"
                        id="exampleInputlast1"
                        aria-describedby="emailHelp"
                        required
                      />
                      <i
                        className={
                          eyes.password
                            ? "fa fa-eye p-ab"
                            : "fa fa-eye-slash p-ab"
                        }
                        onClick={() =>
                          setEyes({ ...eyes, password: !eyes.password })
                        }
                      ></i>
                    </div>
                    {submitted && getError("newPassword").invalid ? (
                      <div className="invalid-feedback d-block">
                        Min Length must be 8 characters long
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="mt-3 p-rel">
                      <label for="exampleInputEmail1" className="form-label">
                        Confirm New Password
                      </label>
                      <input
                        type={eyes.confirmPassword ? "text" : "password"}
                        value={changePassword?.confirmPassword}
                        onChange={(e) =>
                          setchangePassword({
                            ...changePassword,
                            confirmPassword: e.target.value,
                          })
                        }
                        placeholder="Confirm New Password"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        required
                      />
                      <i
                        className={
                          eyes.confirmPassword
                            ? "fa fa-eye p-ab"
                            : "fa fa-eye-slash p-ab"
                        }
                        onClick={() =>
                          setEyes({
                            ...eyes,
                            confirmPassword: !eyes.confirmPassword,
                          })
                        }
                      ></i>
                    </div>
                    {submitted && getError("confirmPassword").invalid ? (
                      <>
                        {/* {getError('confirmPassword').err.minLength ? <div>Min Length must be 8 characters long</div> : <></>} */}
                        {getError("confirmPassword").err.confirmMatch ? (
                          <div className="invalid-feedback d-block">
                            Comfirm Password is not matched with New Password
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="d-flex justify-content-end mt-4">
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        id="CloseChangePasswordModal"
                        className="btn btn-outline-secondary mr-3"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Billing Address */}
      <button
        type="button"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalLabel">
                Billing Address{" "}
              </h3>
            </div>
            <div class="modal-body">
              <div className="row p-3">
                <div className="col-md-8">
                  <label>Street</label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street Address"
                    name="street"
                    value={data.street}
                    onChange={(e) => Handlebillchange(e)}
                  />
                </div>
                <div className="col-md-8 mt-2">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="City"
                    name="city"
                    value={data.city}
                    onChange={(e) => Handlebillchange(e)}
                  />
                </div>
                <div className="col-md-8 mt-2">
                  <label>State</label>

                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="State"
                    name="state"
                    value={data.state}
                    onChange={(e) => Handlebillchange(e)}
                  />
                </div>
                <div className="col-md-8 mt-2">
                  <label>Zip</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Zip Code"
                    name="zip"
                    value={data.zip}
                    onChange={(e) => Handlebillchange(e)}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                id="btnclosebilling"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={(e) => Adddata(e)}
                class="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AccountSetting;
