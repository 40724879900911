import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/global/header";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import { CountrySelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useDispatch, useSelector } from "react-redux";
import { active_Plan } from "../../actions/activePlan";
import loader from "../../methods/loader";

const Cards = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const { id, stripeId } = useParams()
    const [cards, setcards] = useState([])
    const primaryCardId = useRef()
    const [form, setform] = useState({ card_number: '', exp_month: '', exp_year: '', cvc: '', country: '' })
    const navigate = useHistory()

    useEffect(() => {
        getAllCards()
    }, [])

    const getAllCards = () => {
        loader(true)
        ApiClient.get(`card/all?user_id=${user?.id}`).then(res => {
            if (res.success) {
                setcards(res?.data?.data)
                res?.data?.data.map(item=>{
                    if(item?.isPrimary){
                        primaryCardId.current = item?.card_id
                    }
                })
            }
            loader(false)
        })
    }

    const handleCard = (e) => {
        e.preventDefault()
        if (!form?.country) {
            toast.error('Country is required')
            return
        }
        let payload = {
            ...form,
            country: form?.country?.iso3
        }
        ApiClient.post(`card`,payload).then(res => {
            if (res.success) {
                setTimeout(() => {
                    toast.success(res.message)
                }, 100);
                getAllCards()
                document.getElementById('CloseAddCardModal').click()
            }
        })
    }
    
    const deleteCard=(id)=>{
        if(window.confirm('Do you really want to delete this card')){
            ApiClient.delete(`card?id=${id}`).then(res=>{
                if(res.success){
                    toast.success(res?.message)
                    getAllCards()
                }
            })
        }
    }
    
    const handleCardStatus=(item)=>{
        if(item?.isPrimary){
            return
        }else{
            if(window.confirm('Do you really want to set this card primary')){
                ApiClient.put(`card/set-primary`,{ id: item?.id }).then(res=>{
                    if(res.success){
                        toast.success(res.message)
                        getAllCards()
                    }
                })
            }
        }
    }

    const handlePay=()=>{
        if(cards?.length == 0){
            toast.error('Please add card first')
            return
        }
        else{
            let payload = {
                id: id,
                card_id: primaryCardId.current,
                stripe_price_id: stripeId,
                user_id: user?.id
            }
            if(user?.referral_slug){
                payload = {
                    ...payload, referral_slug: user?.referral_slug
                }
            }
            loader(true)
            ApiClient.post(`subscribe`,payload).then(res=>{
                if(res.success){
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    let redirect = localStorage.getItem('DynamicQrCode')
                    dispatch(active_Plan(res.data));
                    if(redirect) navigate.push(redirect)
                    else navigate.push(`/pricing`)
                }
                loader(false)
            })
        }
    }

    return (
        <>
            <Header />
            <div className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="mb-3">Cards</h1>
                    <button type="button" className="btn btn-primary add_card" data-bs-toggle="modal" data-bs-target="#addcard">Add Card</button>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Last Digits</th>
                            <th scope="col">Expiry</th>
                            <th scope="col">Country</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cards && cards.map((item, index) => {
                            return <tr>
                                <td>{item?.card_number?.substr(-4)}</td>
                                <td>{item?.exp_month}, {item?.exp_year}</td>
                                <td>{item?.country}</td>
                                <td>
                                    <span onClick={e=>handleCardStatus(item)} className={`${item?.isPrimary?'status_primary':'status_danger pointer'}`}>{item?.isPrimary?'Primary':'Set Primary'}</span>
                                </td>
                                <td>
                                    <span className="material-icons ml-3 text-danger pointer" onClick={e=>deleteCard(item?.id)} title="Delete">delete</span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <div className="text-right">
                    <button type="button" onClick={e=>handlePay()} className="btn btn-primary">Pay</button>
                </div>
            </div>

            <div className="modal fade" id="addcard" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog card-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Add Card</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleCard}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label>Card Number<span className="star">*</span></label>
                                        <input type="text" className="form-control" minLength={16} maxLength={16} value={form?.card_number} onChange={e => setform({ ...form, card_number: methodModel.isNumber(e) })} placeHolder="Card Number" required />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Expire Month<span className="star">*</span></label>
                                        <input type="text" minLength={2} maxLength={2} className="form-control" value={form?.exp_month} onChange={e => setform({ ...form, exp_month: methodModel.isNumber(e) })} placeHolder="Expire Month" required />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Expire Year<span className="star">*</span></label>
                                        <input type="text" className="form-control" minLength={4} maxLength={4} value={form?.exp_year} onChange={e => setform({ ...form, exp_year: methodModel.isNumber(e) })} placeHolder="Expire Year" required />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>CVC<span className="star">*</span></label>
                                        <input type="text" className="form-control" minLength={3} maxLength={3} value={form?.cvc} onChange={e => setform({ ...form, cvc: methodModel.isNumber(e) })} placeHolder="CVC" required />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label>Country<span className="star">*</span></label>
                                        <CountrySelect defaultValue={form?.country} onChange={(e) => setform({ ...form, country: e })} placeHolder="Country" />
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button type="button" className="btn btn-secondary mr-2" id="CloseAddCardModal" data-bs-dismiss="modal" aria-label="Close">Back</button>
                                    <button type="submit" className="btn btn-primary ml-2">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards