import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login_success } from "../../actions/user";
import { active_Plan } from "../../actions/activePlan";
import environment from "../../environment";
export default function SignUp() {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const [Freeplan,SetFreeplanId]=useState([])
  const stripeId = localStorage.getItem("stripeId");
  const planId = localStorage.getItem("planId");

  console.log(Freeplan,"hjghjgjhgjhg")

  const handleTrailPlan = (id) => {
    if (stripeId && planId) {
      let payload = {
        id: planId,
        stripe_price_id: stripeId,
        user_id: id,
      };
      ApiClient.post(`subscribe`, payload).then((res) => {
        if (res.success) {
          localStorage.removeItem("stripeId");
          localStorage.removeItem("planId");
        }
      });
    }
  };

  const getAllPlans = () =>{
  ApiClient.get(`subscription-plan/all`).then((res) => {
    if (res.success) {
      console.log(res?.data?.data,"res?.data?.datares?.data?.data")
      const data = res?.data?.data.filter((item)=>item?.on_trial == true)
      SetFreeplanId(data)
    }
  });
  }

  useEffect(()=>{
    getAllPlans()
  },[])

  return (
    <>
      <div className="m_login">
        <div className="container-fluid">
          <div className="row align-items-center  justify-content-center">
            <div className="col-md-5 d-none d-lg-block">
              <div className="sign-div">
                <img
                  src="./assets/img/favicon_2.png"
                  width="150px"
                  className="mb-2"
                />
                {/* <button type="button" className="btn btn-primary px-5 py-3 mb-3"></button> */}
                <h4 className="fw-bold text-start text-dark lh-base track_box">
                  Create, Manage, and Track <br></br>{" "}
                  <a className="" href="#" role="button">
                    all your QR Codes{" "}
                  </a>{" "}
                  in one account
                </h4>
                <a className="" href="#" role="button">
                  <img
                    src="./assets/img/Frame 237.png"
                    className="img-fluid"
                    alt="..."
                  />
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="login-bx bg-white">
                <Formik
                  enableReinitialize
                  initialValues={{ email: "", password: "" }}
                  onSubmit={(values) => {
                    const payload = {
                      email: values.email,
                      password: values.password,
                      role: "user",
                    };
                    loader(true);
                    ApiClient.post(`register`, payload).then((res) => {
                      if (res.success) {
                        ApiClient.post(`user/auto-login`, {
                          id: res?.data?.id,
                        }).then((response) => {
                          if (response.success) {
                            localStorage.setItem(
                              "token",
                              response.data.access_token
                            );
                            dispatch(login_success(response.data));
                            setTimeout(() => {
                              toast.success("Successfully Login");
                            }, 100);
                            let payload = {
                              id: Freeplan?.[0]?.id || Freeplan?.[0]?._id,
                              stripe_price_id:Freeplan?.[0]?.stripe_price_id,
                              user_id: response?.data?.id || response?.data?._id,
                            };
                            ApiClient.post(`subscribe`, payload).then(
                              (result) => {
                                if (res.success) {
                                  dispatch(active_Plan(result?.data));
                                }
                              }
                            );
                            navigate.push(`/app/qrcodes`);
                          }
                        });
                      }
                      loader(false);
                    });
                  }}
                >
                  {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form className="centerLogin" onSubmit={handleSubmit}>
                      <div className="text-center mb-md-4 mb-2">
                        <h5 className="text-left fw-bold text-center text-dark">
                          Get started
                        </h5>
                      </div>
                      <div className="h_features ">
                        <h6 className="mx-0 mb-2 ">
                          Try all features free for 14 days.{" "}
                        </h6>
                        <h6 className="text-uppercase mx-0 mt-2">
                          No credit card required
                        </h6>
                      </div>
                      {/* <div className='text-start'>
                      <label className='form-label'>Email<span className='text-danger'>*</span></label>
                      <input type='email' value={values.email} required name='email' onChange={handleChange} onBlur={handleBlur} placeholder='Email' className='form-control bginput' />
                    </div> */}

                      <div className="text-start">
                        <label className="form-label">
                          Email<span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          value={values.email}
                          required
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Email"
                          className="form-control bginput"
                          pattern="^[a-zA-Z0-9._%+-]+@yopmail\.com$"
                          title="Please enter a YOPmail format email address (e.g., example@yopmail.com)"
                        />
                      </div>

                      <div className="mt-3 text-start position-relative set-pass">
                        <label className="form-label">
                          Password<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control bginput"
                          type={!eyes.password ? "password" : "text"}
                          required
                          value={values.password}
                          name="password"
                          minLength="8"
                          onChange={handleChange}
                          placeholder="Password"
                          onBlur={handleBlur}
                        />
                        <i
                          className={
                            eyes.password
                              ? "fa fa-eye setfa"
                              : "fa fa-eye-slash setfa"
                          }
                          onClick={() =>
                            setEyes({ ...eyes, password: !eyes.password })
                          }
                        ></i>
                      </div>
                      <div className="mt-4 d-flex align-items-baseline">
                        <p>
                          By signing up, you agree to the
                          <a className="m-sign ps-1" href="#" role="button">
                            Terms and Conditions
                          </a>{" "}
                          and
                          <a className=" m-sign ps-1" href="#" role="button">
                            Privacy Policy.
                          </a>
                        </p>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-100 mt-3"
                        >
                          Sign Up Free
                        </button>
                      </div>
                      {/* <div className='borderCls or-bx text-center my-3'>Or</div>
                    <a className='btn btn-outline google_id w-100'>
                      <button type='button' className='google_heading border-1 rounded py-2 bg-transparent w-100'><img src='/assets/img/gogle.png' className='m_img' />Continue with Google</button>
                    </a> */}
                      <p className="accop mb-0">
                        Already have an account?
                        <Link className="sign_up m-sign" to="/login">
                          Sign In
                        </Link>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}
