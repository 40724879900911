import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import UAParser from "ua-parser-js";
import ApiClient from "../methods/api/apiClient";
import loader from "../methods/loader";
import environment from "../environment";
import moment from "moment-timezone";
// import { useDetectAdBlock } from "adblock-detect-react";

const Testing = () => {
  const { slug } = useParams();
  let host = document.location.host;
  let RedirectURL = environment?.liveURL;
  // if (host?.includes("https://qrcode.jcsoftwaresolution.in/")) {
  //   RedirectURL = "https://qrcode.jcsoftwaresolution.in/";
  // } else {
  //   RedirectURL = "http://66.179.251.9:8056/";
  // }
  const [detail, setdetail] = useState();
  const userAgent =
    navigator.userAgent ||
    navigator.vendor ||
    window.opera ||
    window.navigator.userAgent;
  const [ipAddress, setIPAddress] = useState("");
  const deviceLanguage = navigator.language;
  const [UserLocation, setUserLocation] = useState();
  const [Cordinates, setCordinates] = useState({});
  const [form, setform] = useState({ password: "" });
  const [Lat, SetLat] = useState();
  const [Lng, setLng] = useState();
  const parser = new UAParser(userAgent);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [geoipResponse, setGeoipResponse] = useState(null);
  // const adBlockDetected = useDetectAdBlock();

  // useEffect(() => {
  //   if (adBlockDetected) {
  //     alert("Please Remove Your ad block to Continue");
  //   }
  // }, [adBlockDetected]);

  function getDeviceType() {

    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    if (screenWidth < 768) {
        return "Mobile";
    } else if (screenWidth >= 768 && screenWidth < 1024) {
        return "Tablet";
    } else {
        return "Desktop/Laptop";
    }

}

var deviceType = getDeviceType();

  useEffect(() => {
    const timerID = setInterval(() => setCurrentDateTime(new Date()), 1000);

    return () => clearInterval(timerID);
  }, []);

  const formattedDateTime = `${currentDateTime.getFullYear()}-${(
    currentDateTime.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${currentDateTime
      .getDate()
      .toString()
      .padStart(2, "0")}T${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}:${currentDateTime
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${currentDateTime
            .getSeconds()
            .toString()
            .padStart(2, "0")}.${currentDateTime
              .getMilliseconds()
              .toString()
              .padStart(3, "0")}Z`;

  const options = {
    fields: [
      "address_components",
      "geometry",
      "formatted_address",
      "utc_offset_minutes",
    ],
    strictBounds: false,
  };
  const initMap = async (
    fromLat,
    fromlng,
    toLat,
    toLng,
    url,
    QR_Radius,
    detail,
    TargetUrl
  ) => {
    const input = document.getElementById("RADIUSINPUT");
    const options = {
      fields: [
        "address_components",
        "geometry",
        "formatted_address",
        "utc_offset_minutes",
      ],
      strictBounds: false,
    };
    loader(false);
    const autocomplete = new google.maps.places.Autocomplete(input, options);

    const place = autocomplete.getPlace();
    var _kCord = { lat: parseInt(fromLat), lng: parseInt(fromlng) };

    var _pCord = { lat: parseInt(toLat), lng: parseInt(toLng) };

    const { spherical } = await google.maps.importLibrary("geometry");
    const radius = google.maps.geometry.spherical.computeDistanceBetween(
      _kCord,
      _pCord
    );
    const finalR = Math.round(radius / 1000);
    if (!detail?.setting?.numberOfScansStatus) {
      if (finalR < parseInt(QR_Radius)) {
        getIpAddress(detail)
        // let defaultURL = detail?.urlData;
        // if (!defaultURL?.includes("http" || "https")) {
        //   defaultURL = `https://${defaultURL}`;
        // }
        // return window.open(defaultURL, "_self");
      } else {
        let target = TargetUrl;
        if (!target?.includes("http" || "https")) {
          target = `https://${TargetUrl}`;
        }
        return window.open(target, "_self");
      }
    }

    if (detail?.setting?.numberOfScansStatus) {
      if (detail?.setting?.numberOfScans != 0) {
        if (finalR < parseInt(QR_Radius)) {
          getIpAddress(detail);
          // let defaultURL = detail?.urlData;
          // if (!defaultURL?.includes("http" || "https")) {
          //   defaultURL = `https://${defaultURL}`;
          // }
          // return window.open(defaultURL, "_self");
        } else {
          let target = TargetUrl;
          if (!target?.includes("http" || "https")) {
            target = `https://${TargetUrl}`;
          }
          return window.open(target, "_self");
        }
      }
    }
    // } else if (detail?.setting?.numberOfScans == 0) {
      // getIpAddress(detail);
      // let url = detail?.setting?.expiryURL || environment?.liveURL;
      // if (detail?.setting?.expiryURL) {
      //   url = `https://${detail?.setting?.expiryURL}`;

      //   return window.open(url, "_self");
      // }
    // } else {
    //   getIpAddress(detail);
    // }
  };

  useEffect(() => {
    if (slug) {
      ApiClient.get(`qrcode/download?slug=${slug}`).then((res) => {
        if (res.success) {
          setdetail(res?.data);
          if (res?.data?.setting?.passwordStatus) {
            document.getElementById("OpenPasswordModal").click();
            return;
          }
          // loader(true);

          {
            if (res?.data?.setting?.limitScanLocation) {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                  initMap(
                    res?.data?.setting?.latitude,
                    res?.data?.setting?.longitude,
                    position?.coords?.latitude,
                    position?.coords?.longitude,
                    res?.data?.setting?.expiryURL,
                    res?.data?.setting?.radiusInKM || 5,
                    res?.data
                  );
                  return false;
                  if (
                    initMap(
                      res?.data?.setting?.latitude,
                      res?.data?.setting?.longitude,
                      position?.coords?.latitude,
                      position?.coords?.longitude,
                      detail?.setting?.expiryURL,
                      res?.data?.setting?.radiusInKM
                    )
                  ) {
                    loader(false);

                    let url = detail?.setting?.expiryURL;
                    if (
                      !detail?.setting?.expiryURL.includes("http" || "https") &&
                      detail?.setting?.expiryURL
                    ) {
                      url = `https://${detail?.setting?.expiryURL}`;
                      ApiClient.post(`addUniqueScans`, paylaodDetail).then(
                        (res) => { }
                      );
                      return window.open(url);
                    }
                  } else {
                    getIpAddress(res?.data);
                  }
                });
              }
            } else if (res?.data?.setting?.exactGPSLocation) {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                  // if (
                  //   res?.data?.setting?.exactGPSLocationLat?.slice(0, 2) !=
                  //     String(position?.coords?.latitude)?.slice(0, 2) ||
                  //   res?.data?.setting?.exactGPSLocationLng?.slice(0, 2) !=
                  //     String(position?.coords?.longitude)?.slice(0, 2) ||
                  //   res?.data?.setting?.exactGPSLocationLat?.slice(3, 4) !=
                  //     String(position?.coords?.latitude)?.slice(3, 4) ||
                  //   res?.data?.setting?.exactGPSLocationLng?.slice(3, 4) !=
                  //     String(position?.coords?.longitude)?.slice(3, 4)
                  // ) {
                  //   loader(false);

                  //   let url = detail?.setting?.expiryURL;
                  //   if (
                  //     !detail?.setting?.expiryURL.includes("http" || "https") &&
                  //     detail?.setting?.expiryURL
                  //   ) {
                  //     url = `https://${detail?.setting?.expiryURL}`;
                  //     return window.open(url);
                  //   }
                  // }
                  getIpAddress(res?.data, "exactGPSLocation");
                  let ExactLocation =
                    res?.data?.setting?.exactLocationUsers || [];

                  ExactLocation.push({
                    latitude: position?.coords?.latitude,
                    longitude: position?.coords?.longitude,
                  });
                  let Payload = {
                    id: res?.data?.id,
                    setting: {
                      ...res?.data?.setting,
                      exactLocationUsers: ExactLocation,
                    },
                  };

                  ApiClient.put(`qrcode/download?slug=${slug}`, Payload).then(
                    (res) => {
                      // toast.success("Data Updated Successfully");
                    }
                  );

                  return false;
                });
              }
            } else {
              getIpAddress(res?.data);
            }
          }
        } else {
          console.log("Api Error");
        }
      });
    }
  }, []);

  const getIpAddress = (slugData) => {

    const fillInPage = () => {
      if (typeof geoip2 !== 'undefined') {
        geoip2.city(onSuccess, onError);
      } else {
        setGeoipResponse({ error: 'GeoIP2 requests blocked by browser' });
      }
    };

    const onSuccess = (response) => {
      const result = {
        "range": [1742671360, 1742671871],
        "country": response.country.iso_code,
        "region": response.subdivisions[0].iso_code,
        "eu": "0",
        "timezone": response.location.time_zone,
        "city": response.city.names.en,
        "ll": [response.location.latitude, response.location.longitude],
        "metro": 0,
        "area": 50,
        "country_name": response.country.names.en,
        "state": response.subdivisions[0].names.en,
        "ip": response?.traits?.ip_address
      };
      setIPAddress(result?.ip);
      getUserLocation(result?.ip, slugData, result);
      setGeoipResponse(result);
    };

    const onError = (error) => {
      setGeoipResponse({ error: 'Error fetching GeoIP data' });
    };

    fillInPage();
    // ApiClient.get(`ip`).then((res) => {
    //   if (res.success) {
    // alert(res?.data,"resdata")

    // });
    // fetch("https://api.ipify.org?format=json")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setIPAddress(data?.ip);
    //     getUserLocation(data?.ip, slugData);
    //   });
  };

  const getUserLocation = (ip, slugData, geoipResponse) => {
    // ApiClient.post(`ipAddress`, { ipAddress: ip }).then((res) => {
    //   if (res.success) {
        setUserLocation(geoipResponse);
        redirect(slugData, geoipResponse , ip);
    //   }
    // });
  };

  const CheckURl = (string) => {
    let isValid = string.includes("https" || "htpps");
    if (isValid) {
      return window.open(string);
    } else {
      let url = `https://${string}`;
      return window.location.replace(url);
    }
  };

  const getLocationFromCoordinates = async (
    lat,
    lng,
    locationDetail,
    ip,
    data
  ) => {
    console.log("HELLO");
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDXcaa81DeBgUyniq30GpZ5pYI7oDneKlE`
    );
    const res = await response.json();
    console.log(res);
    let country, city;

    // Loop through all results
    for (let result of res?.results) {
      // Check if the result has address_components
      if (result.address_components) {
        // Loop through all address components
        for (let component of result.address_components) {
          // Check if the component has a 'country' type
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          // Check if the component has a 'locality' or 'administrative_area_level_3' type
          if (
            component.types.includes("locality") ||
            component.types.includes("administrative_area_level_3")
          ) {
            city = component.long_name;
          }
        }
      }
    }
    let createdAtTime = moment()
      .tz(locationDetail?.timezone)
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    let paylaodDetail = {
      user_id: data?.user_id,
      ipAddress: ip,
      device: parser?.getOS()?.name == "Android" ? deviceType : (parser?.getOS()?.name == "Windows" || parser?.getOS()?.name == "Linux" || parser?.getOS()?.name == "Ubuntu") ? "Desktop/Laptop" : (parser?.getOS()?.name == "Mac OS" && deviceType == "Tablet") ? "IPad" : deviceType ? findDevice() : "",
      browser: parser?.getBrowser()?.name,
      os: parser?.getOS()?.name,
      slug: slug,
      country: country,
      state: locationDetail?.state,
      city: city,
      lat: lat,
      lng: lng,
      timezone: locationDetail?.timezone,
      createdAt: createdAtTime,
    };

    ApiClient.post(`addUniqueScans`, paylaodDetail).then((res) => {
      // return;
      if (res?.success) {
        if (
          parseInt(data?.setting?.numberOfScans) != 0 &&
          data?.setting?.numberOfScansStatus
        ) {
          let settings = data?.setting;
          settings["numberOfScans"] = (
            Number(data?.setting?.numberOfScans) - 1
          ).toString();
          let payload = {
            id: data?.id,
            setting: settings,
            // totalScans: data?.setting?.numberOfScans
          };
          ApiClient.put(`qrcode/download?slug=${slug}`, payload).then((res) => {
            // toast.success("Data Updated Successfully");
          });
        }
        if (data?.staticQrcode) {
          return CheckURl(data?.qrCode_data);
        } else {
          if (data?.type == "vcard") {
            return (
              (window.location.pathname = `/redirecting/vcard/${data?.slug}`),
              "_self"
            );
          }

          // if (data?.type == "youtube") {
          //   CheckURl(data?.qrCode_data);
          // }
          if (data?.type == "snapchat") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://www.snapchat.com/add${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "instagram") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://www.instagram.com/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "pinterest") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://in.pinterest.com/pin/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "slack") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://join.slack.com/shareDM/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "reddit") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://www.reddit.com/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }

          if (data?.type == "facebook") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://www.facebook.com/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "discord") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://discord.com/servers/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "tiktok") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://www.tiktok.com/@${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "x") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = ` https://twitter.com/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "soundcloud") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://soundcloud.com/${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "spotify") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "apple-music") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "vimeo") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "urls") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "signal") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "play-store") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "app-store") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}?src=serp`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "app-gallery") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "microsoft-store") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "wechat") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "skype") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "viber") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}=vi`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "line") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "messenger") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "telegram") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "zalo") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "docs") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "google-forms") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "youtube") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return setTimeout(() => window.open(url, "_self"), 2000);
          }
          if (data?.type == "sheets") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "zoom") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "paypal") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "sepa") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http", "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "reviews") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "strdavidpe") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }
          if (data?.type == "bitcoin") {
            let url = data?.qrCode_data;
            if (!data?.qrCode_data.includes("http" || "https")) {
              url = `https://${data?.qrCode_data}`;
            }
            return window.open(url, "_self");
          }

          if (data?.type == "events") {
            return window.open(data?.qrCode_data, "_self");
          }
          if (data?.type == "file") {
            return window.open(
              `http://portal.jcsoftwaresolution.in:6056/documents/${data?.qrCode_data}`,
              "_self"
            );
          }
          if (data?.type == "smart-url") {
            if (data?.ruleType == "location") {
              let datacheck = data?.smartURLLocation.find((item) => {
                if (!item?.region?.state_code && !item?.city?.name) {
                  if (item?.country?.iso2 == locationDetail?.country) {
                    return item;
                  }
                } else if (item?.region?.state_code && !item?.city?.name) {
                  if (
                    item?.country?.iso2 == locationDetail?.country &&
                    item?.region?.state_code == locationDetail?.region
                  ) {
                    return item;
                  }
                } else {
                  // if (item?.city) {
                  //   if (navigator.geolocation) {
                  //     navigator.geolocation.getCurrentPosition((position) => {
                  //       return initMap(
                  //         item?.city?.latitude,
                  //         item?.city?.longitude,
                  //         position?.coords?.latitude,
                  //         position?.coords?.longitude,
                  //         data?.defaultUrl,
                  //         100,
                  //         "",
                  //         item?.url
                  //       );
                  //     });
                  //   }
                  // }
                  // return false;

                  // return console.log({
                  //   lat: item?.city?.latitude,
                  //   lng: item?.city?.longitude,
                  //   PoLat: position?.coords?.latitude,
                  //   PoLng: position?.coords?.longitude,
                  //   defaultUrl: item?.defaultUrl,
                  //   radius: 70,
                  //   data: "",
                  //   url: item?.url,
                  // });
                  if (
                    item?.country?.iso2 == locationDetail?.country &&
                    item?.region?.state_code == locationDetail?.region &&
                    item?.city?.name == locationDetail?.city
                  ) {
                    return item;
                  }
                }
              });
              // return false;
              if (datacheck?.url) {
                CheckURl(datacheck?.url);
              } else {
                CheckURl(data?.defaultUrl);
              }
            } else if (data?.ruleType == "number_of_scans") {
              let numberOfScans = data?.smartURLNumberOfScans;
              let datacheck = data?.smartURLNumberOfScans.find(
                (item, index) => {
                  if (parseInt(item?.scans) > 0) {
                    numberOfScans[index]["scans"] = (
                      Number(item?.scans) - 1
                    ).toString();
                    return item;
                  }
                }
              );

              if (datacheck?.length == 0 || !datacheck) {
                return setTimeout(() => CheckURl(data?.defaultUrl), 2000);
              } else {
                let payload = {
                  id: data?.id,
                  smartURLNumberOfScans: numberOfScans,
                };
                ApiClient.put(`qrcode/download`, payload).then((res) => { });
                return setTimeout(() => CheckURl(datacheck?.url), 2000);
              }
            } else if (data?.ruleType == "time") {
              let datacheck = data?.smartURLTime.find((item) => {
                if (
                  item?.timezone?.value == locationDetail?.timezone &&
                  new Date(item?.startTime).getTime() < new Date().getTime() &&
                  new Date(item?.endTime).getTime() > new Date().getTime()
                ) {
                  return item;
                }
              });
              if (datacheck?.length == 0 || !datacheck) {
                let url = data?.defaultUrl;
                if (!data?.defaultUrl.includes("http" || "https")) {
                  url = ` https://${data?.defaultUrl}`;
                }
                return window.open(url, "_self");
              } else {
                let url = datacheck?.url;
                if (!datacheck?.url.includes("http" || "https")) {
                  url = ` https://${datacheck?.url}`;
                }
                return window.open(url, "_self");
              }
            } else {
              let datacheck = data?.smartURLLanguage.find((item) => {
                if (
                  (deviceLanguage == "hi" && item?.language.code == "hi") ||
                  (deviceLanguage == "hi IN" && item?.language.code == "hi")
                ) {
                  return item;
                }
                if (deviceLanguage.match(item?.language.code)) {
                  return item;
                }
              });
              // return;
              if (datacheck?.length == 0 || datacheck == undefined) {
                let url = data?.defaultUrl;
                if (!data?.defaultUrl.includes("http" || "https")) {
                  url = `https://${url}`;
                }
                return setTimeout(() => window.open(url, "_self"), 1000);
              } else {
                let url = datacheck?.url;
                if (!datacheck?.url.includes("http" || "https")) {
                  url = `https://${datacheck?.url}`;
                }
                return setTimeout(() => window.open(url, "_self"), 1000);
              }
            }
          }
          if (data?.type == "multiple-apps") {
            if (/android/i.test(userAgent)) {
              let url = data?.google_play_store_android;
              if (
                !data?.google_play_store_android.includes("http" || "https")
              ) {
                url = `https://play.google.com/store/search?q=${data?.google_play_store_android}`;
              }
              return window.open(url, "_self");
              // return window.open(data?.google_play_store_android);
            } else if (/iPad/.test(userAgent) && !window.MSStream) {
              let url = data?.apple_app_store_for_iPad_only_ios;
              if (
                !data?.apple_app_store_for_iPad_only_ios.includes(
                  "http" || "https"
                )
              ) {
                url = `https://www.apple.com/us/search/${data?.apple_app_store_for_iPad_only_ios}?src=serp`;
              }
              return window.open(url, "_self");
              // return window.open(data?.apple_app_store_for_iPad_only_ios);
            } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
              // return window.open(data?.apple_app_store_ios);
              let url = data?.apple_app_store_ios;
              if (!data?.apple_app_store_ios.includes("http" || "https")) {
                url = `https://www.apple.com/us/search/${data?.apple_app_store_ios}?src=serp`;
              }
              return window.open(url, "_self");
            } else if (
              navigator.userAgent.match(
                /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
              )
            ) {
              return window.open(CheckURl());
            } else if (/huawei|HONOR|HUAWEI/i.test(userAgent)) {
              // return window.open(data?.app_gallery_huawei);
              let url = data?.app_gallery_huawei;
              if (!data?.app_gallery_huawei.includes("http" || "https")) {
                url = `https://appgallery.huawei.com/search/${data?.app_gallery_huawei}`;
              }
              return window.open(url, "_self");
            } else if (/windows phone/i.test(userAgent)) {
              // return window.open(data?.microsoft_store_window_mobile);
              let url = data?.microsoft_store_window_mobile;
              if (
                !data?.microsoft_store_window_mobile.includes("http" || "https")
              ) {
                url = `https://apps.microsoft.com/search?query=${data?.microsoft_store_window_mobile}`;
              }
              return window.open(url, "_self");
            } else {
              // return CheckURl(data?.backup_link_for_other_device_browser);
              let url = data?.backup_link_for_other_device_browser;
              if (
                !data?.backup_link_for_other_device_browser.includes(
                  "http" || "https"
                )
              ) {
                url = `https://www.${data?.backup_link_for_other_device_browser}.com`;
              }
              return window.open(url, "_self");
            }
          } else if (data?.type == "smart-url") {
            if (data?.ruleType == "location") {
              let datacheck = data?.smartURLLocation.find((item) => {
                if (!item?.region?.state_code && !item?.city?.name) {
                  if (item?.country?.iso2 == locationDetail?.country) {
                    return item;
                  }
                } else if (item?.region?.state_code && !item?.city?.name) {
                  if (
                    item?.country?.iso2 == locationDetail?.country &&
                    item?.region?.state_code == locationDetail?.region
                  ) {
                    return item;
                  }
                } else {
                  if (
                    item?.country?.iso2 == locationDetail?.country &&
                    item?.region?.state_code == locationDetail?.region &&
                    item?.city?.name == locationDetail?.city
                  ) {
                    return item;
                  }
                }
              });
              // if (datacheck?.length == 0 || !datacheck) {
              //   CheckURl(data?.defaultUrl);
              // } else {
              //   CheckURl(data?.defaultUrl);
              // }
            } else if (data?.ruleType == "number_of_scans") {
              let numberOfScans = data?.smartURLNumberOfScans;
              let datacheck = data?.smartURLNumberOfScans.find(
                (item, index) => {
                  if (parseInt(item?.scans) > 0) {
                    numberOfScans[index]["scans"] = (
                      Number(item?.scans) - 1
                    ).toString();
                    return item;
                  }
                }
              );
              if (datacheck?.length == 0 || !datacheck) {
                return setTimeout(() => {
                  CheckURl(data?.defaultUrl);
                }, 1000);
              } else {
                let payload = {
                  id: data?.id,
                  smartURLNumberOfScans: numberOfScans,
                };
                ApiClient.put(`qrcode/download`, payload).then((res) => { });
                return setTimeout(() => {
                  CheckURl(data?.defaultUrl);
                }, 1000);
              }
            } else if (data?.ruleType == "time") {
              let datacheck = data?.smartURLTime.find((item) => {
                if (
                  item?.timezone?.value == locationDetail?.timezone &&
                  new Date(item?.startTime).getTime() < new Date().getTime() &&
                  new Date(item?.endTime).getTime() > new Date().getTime()
                ) {
                  return item;
                }
              });

              // if (datacheck?.length == 0 || !datacheck) {
              //   let url = data?.defaultUrl;
              //   if (!data?.defaultUrl.includes("http" || "https")) {
              //     url = `https://${url}`;
              //   }
              //   return setTimeout(() => window.open(url, "_self"), 1000);

              // } else {
              //   let url = datacheck?.url;
              //   if (!datacheck?.url?.iMerge branch 'new_branch' of https://bitbucket.org/nishantp_jc/qr_frontend into developercludes("http" || "https")) {
              //     url = `https://${url}`;
              //   }
              //   return setTimeout(() => {
              //     window.open(url, "_self");
              //   }, 1000);
              // }
            } else {
              let datacheck = data?.smartURLLanguage.find((item) => {
                // if (
                //   (deviceLanguage == "hi" && item?.language.code == "hi") ||
                //   (deviceLanguage == "hi IN" && item?.language.code == "hi")
                // ) {
                //   return item;
                if (deviceLanguage.match(item?.language.code)) {
                  return item;
                }
              });
              if (datacheck?.length == 0 || !datacheck) {
                return setTimeout(
                  () => window.open(data?.defaultUrl, "_self"),
                  1000
                );
              } else {
                return setTimeout(
                  () => window.open(datacheck?.url, "_self"),
                  1000
                );
              }
            }
          } else if (data?.setting?.numberOfScansStatus) {
            if (parseInt(data?.setting?.numberOfScans) != 0) {
              let settings = data?.setting;
              settings["numberOfScans"] = (
                Number(data?.setting?.numberOfScans) - 1
              ).toString();
              let payload = {
                id: data?.id,
                setting: settings,
                // totalScans: data?.setting?.numberOfScans
              };
              if (data?.setting?.dateRangeStatus) {
                if (
                  new Date(data?.setting?.dateRangeStart) > new Date() &&
                  new Date(data?.setting?.dateRangeEnd) < new Date()
                ) {
                  window.open(data?.qrCode_data, "_self");
                  ApiClient.put(`qrcode/download`, payload).then((res) => { });
                  return;
                } else {
                  return window.open(data?.setting?.expiryURL, "_self");
                }
              } else {
                window.open(data?.qrCode_data);
                ApiClient.put(`qrcode/download`, payload).then((res) => { });
                return;
              }
            } else {
              // ApiClient.post(`addUniqueScans`, paylaodDetail).then((res) => {});
              return window.open(data?.setting?.expiryURL, "_self");
            }
          } else if (data?.type == "file") {
            return window.open(
              `http://portal.jcsoftwaresolution.in:6056/documents/${data?.qrCode_data}`,
              "_self"
            );
          } else if (data?.type == "snapchat") {
            return window.open(data?.qrCode_data, "_self");
          }
          //  else if (data?.type == "youtube") {
          //   return window.open(data?.qrCode_data, "_self");
          // }
          else {
            return window.open(data?.qrCode_data, "_self");
          }
        }
      }
    });
    // return { country: country, city: city };
    console.log("Country: " + country);
    console.log("City: " + city);
  };

  const redirect = async (data, locationDetail, ip, key) => {
    // ExactLocation = data?.setting?.exactLocation;
    if(data?.setting?.numberOfScansStatus){
      let createdAtTime = moment()
      .tz(data?.timezone)
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    let paylaodDetail = {
      user_id: data?.user_id,
      ipAddress: ip,
      device: parser?.getOS()?.name == "Android" ? deviceType : (parser?.getOS()?.name == "Windows" || parser?.getOS()?.name == "Linux" || parser?.getOS()?.name == "Ubuntu") ? "Desktop/Laptop" : (parser?.getOS()?.name == "Mac OS" && deviceType == "Tablet") ? "IPad" : deviceType ? findDevice() : "",
      browser: parser?.getBrowser()?.name,
      os: parser?.getOS()?.name,
      slug: slug,
      country: locationDetail?.country_name || locationDetail?.country,
      state: locationDetail?.state,
      city: locationDetail?.city,
      lat: locationDetail?.ll?.[0],
      lng: locationDetail?.ll?.[2],
      timezone: locationDetail?.timezone,
      // createdAt: formattedDateTime,
      createdAt: createdAtTime,
    };
    ApiClient.post(`addUniqueScans`, paylaodDetail).then((res) => {
      if (res?.success) {
        if(data?.setting?.numberOfScans == 0 && data?.setting?.numberOfScansStatus){
          let url = data?.setting?.expiryURL;
          if (!data?.setting?.expiryURL.includes("http" || "https")) {
            url = `https://${data?.setting?.expiryURL}`;
          }
          return window.open(url, "_self");
        }else{
          let settings = data?.setting;
          settings["numberOfScans"] = (
            Number(data?.setting?.numberOfScans) - 1
          ).toString();
          let payload = {
            id: data?.id,
            setting: settings,
          };
          ApiClient.put(`qrcode/download?slug=${slug}`, payload).then(
            (res) => {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return window.open(url, "_self");
            }
          );
        }
      }})
    }else if (data?.setting?.exactGPSLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          return getLocationFromCoordinates(
            position.coords.latitude,
            position.coords.longitude,
            locationDetail,
            ip,
            data
          );
        });
      }
    } else {
      let createdAtTime = moment()
        .tz(data?.timezone)
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let paylaodDetail = {
        user_id: data?.user_id,
        ipAddress: ip,
        device: parser?.getOS()?.name == "Android" ? deviceType : (parser?.getOS()?.name == "Windows" || parser?.getOS()?.name == "Linux" || parser?.getOS()?.name == "Ubuntu") ? "Desktop/Laptop" : (parser?.getOS()?.name == "Mac OS" && deviceType == "Tablet") ? "IPad" : deviceType ? findDevice() : "",
        browser: parser?.getBrowser()?.name,
        os: parser?.getOS()?.name,
        slug: slug,
        country: locationDetail?.country_name || locationDetail?.country,
        state: locationDetail?.state,
        city: locationDetail?.city,
        lat: locationDetail?.ll?.[0],
        lng: locationDetail?.ll?.[2],
        timezone: locationDetail?.timezone,
        // createdAt: formattedDateTime,
        createdAt: createdAtTime,
      };
      ApiClient.post(`addUniqueScans`, paylaodDetail).then((res) => {
        if (res?.success) {
          if(data?.setting?.numberOfScans == 0 && data?.setting?.numberOfScansStatus){
            let url = data?.setting?.expiryURL;
            if (!data?.setting?.expiryURL.includes("http" || "https")) {
              url = `https://${data?.setting?.expiryURL}`;
            }
            return window.open(url, "_self");
          }
          if (
            parseInt(data?.setting?.numberOfScans) != 0 &&
            data?.setting?.numberOfScansStatus
          ) {
            let settings = data?.setting;
            settings["numberOfScans"] = (
              Number(data?.setting?.numberOfScans) - 1
            ).toString();
            let payload = {
              id: data?.id,
              setting:settings,
              // totalScans: data?.setting?.numberOfScans
            };
            ApiClient.put(`qrcode/download?slug=${slug}`, payload).then(
              (res) => {
                // toast.success("Data Updated Successfully");
              }
            );
          }
        
          if (data?.staticQrcode) {
            return setTimeout(() =>CheckURl(data?.qrCode_data),2000);
          } else {
            if (data?.type == "vcard") {
              return setTimeout(() =>(
                (window.location.pathname = `/redirecting/vcard/${data?.slug}`),
                "_self"
              ),2000);
            }

            if (data?.type == "youtube") {
              CheckURl(data?.qrCode_data);
            }
            if (data?.type == "snapchat") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "instagram") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "pinterest") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "slack") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url =`${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000); 
            }
            if (data?.type == "reddit") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "facebook") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "discord") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "tiktok") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "x") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "soundcloud") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "spotify") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "apple-music") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "vimeo") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "urls") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "signal") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "play-store") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "app-store") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}?src=serp`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "app-gallery") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "microsoft-store") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "wechat") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "skype") {
              let url = data?.qrCode_data;
              if (data?.qrCode_data.includes("www")) {
                url = `https://${data?.qrCode_data}`;
              } else if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`
              } else {
                url = `${data?.qrCode_data}`
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "viber") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}=vi`;
              }
              return window.open(url, "_self");
            }
            if (data?.type == "line") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "messenger") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return window.open(url, "_self");
            }
            if (data?.type == "telegram") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "zalo") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "docs") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "google-forms") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "sheets") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "zoom") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "paypal") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "sepa") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http", "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "reviews") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "stripe") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }
            if (data?.type == "bitcoin") {
              let url = data?.qrCode_data;
              if (!data?.qrCode_data.includes("http" || "https")) {
                url = `https://${data?.qrCode_data}`;
              }
              return setTimeout(() =>window.open(url, "_self"),2000);
            }

            if (data?.type == "events") {
              return setTimeout(() =>window.open(data?.qrCode_data, "_self"),2000);
            }
            if (data?.type == "file") {
              return setTimeout(() =>window.open(
                `http://portal.jcsoftwaresolution.in:6056/documents/${data?.qrCode_data}`,
                "_self"
              ),2000);
            }
            if (data?.type == "smart-url") {
              if (data?.ruleType == "location") {
                let datacheck = data?.smartURLLocation.find((item) => {
                  if (!item?.region?.state_code && !item?.city?.name) {
                    if (item?.country?.iso2 == locationDetail?.country) {
                      return item;
                    }
                  } else if (item?.region?.state_code && !item?.city?.name) {
                    if (
                      item?.country?.iso2 == locationDetail?.country &&
                      item?.region?.state_code == locationDetail?.region
                    ) {
                      return item;
                    }
                  } else {
                    // if (item?.city) {
                    //   if (navigator.geolocation) {
                    //     navigator.geolocation.getCurrentPosition((position) => {
                    //       return initMap(
                    //         item?.city?.latitude,
                    //         item?.city?.longitude,
                    //         position?.coords?.latitude,
                    //         position?.coords?.longitude,
                    //         data?.defaultUrl,
                    //         100,
                    //         "",
                    //         item?.url
                    //       );
                    //     });
                    //   }
                    // }
                    // return false;

                    // return console.log({
                    //   lat: item?.city?.latitude,
                    //   lng: item?.city?.longitude,
                    //   PoLat: position?.coords?.latitude,
                    //   PoLng: position?.coords?.longitude,
                    //   defaultUrl: item?.defaultUrl,
                    //   radius: 70,
                    //   data: "",
                    //   url: item?.url,
                    // });
                    if (
                      item?.country?.iso2 == locationDetail?.country &&
                      item?.region?.state_code == locationDetail?.region &&
                      item?.city?.name == locationDetail?.city
                    ) {
                      return item;
                    }
                  }
                });
                // return false;
                if (datacheck?.url) {
                  CheckURl(datacheck?.url);
                } else {
                  CheckURl(data?.defaultUrl);
                }
              } else if (data?.ruleType == "number_of_scans") {
                let numberOfScans = data?.smartURLNumberOfScans;
                let datacheck = data?.smartURLNumberOfScans.find(
                  (item, index) => {
                    if (parseInt(item?.scans) > 0) {
                      numberOfScans[index]["scans"] = (
                        Number(item?.scans) - 1
                      ).toString();
                      return item;
                    }
                  }
                );

                if (datacheck?.length == 0 || !datacheck) {
                  return setTimeout(() => CheckURl(data?.defaultUrl), 2000);
                } else {
                  let payload = {
                    id: data?.id,
                    smartURLNumberOfScans: numberOfScans,
                  };
                  ApiClient.put(`qrcode/download`, payload).then((res) => { });
                  return setTimeout(() => CheckURl(datacheck?.url), 2000);
                }
              } else if (data?.ruleType == "time") {
                let datacheck = data?.smartURLTime.find((item) => {
                  if (
                    item?.timezone?.value == locationDetail?.timezone &&
                    new Date(item?.startTime).getTime() <
                    new Date().getTime() &&
                    new Date(item?.endTime).getTime() > new Date().getTime()
                  ) {
                    return item;
                  }
                });
                if (datacheck?.length == 0 || !datacheck) {
                  let url = data?.defaultUrl;
                  if (!data?.defaultUrl.includes("http" || "https")) {
                    url = ` https://${data?.defaultUrl}`;
                  }
                  return setTimeout(() =>window.open(url, "_self"),2000);
                } else {
                  let url = datacheck?.url;
                  if (!datacheck?.url.includes("http" || "https")) {
                    url = ` https://${datacheck?.url}`;
                  }
                  return setTimeout(() =>window.open(url, "_self"),2000);
                }
              } else {
                let datacheck = data?.smartURLLanguage.find((item) => {
                  if (
                    (deviceLanguage == "hi" && item?.language.code == "hi") ||
                    (deviceLanguage == "hi IN" && item?.language.code == "hi")
                  ) {
                    return item;
                  }
                  if (deviceLanguage.match(item?.language.code)) {
                    return item;
                  }
                });
                // return;
                if (datacheck?.length == 0 || datacheck == undefined) {
                  let url = data?.defaultUrl;
                  if (!data?.defaultUrl.includes("http" || "https")) {
                    url = `https://${url}`;
                  }
                  return setTimeout(() => window.open(url, "_self"), 2000);
                } else {
                  let url = datacheck?.url;
                  if (!datacheck?.url.includes("http" || "https")) {
                    url = `https://${datacheck?.url}`;
                  }
                  return setTimeout(() => window.open(url, "_self"), 2000);
                }
              }
            }
            if (data?.type == "multiple-apps") {
              if (/android/i.test(userAgent)) {
                let url = data?.google_play_store_android;
                if (
                  !data?.google_play_store_android.includes("http" || "https")
                ) {
                  url = `https://${data?.google_play_store_android}`;
                }
                return setTimeout(() =>window.open(url, "_self"),2000);
                // return window.open(data?.google_play_store_android);
              } else if (/iPad/.test(userAgent) && !window.MSStream) {
                let url = data?.apple_app_store_for_iPad_only_ios;
                if (
                  !data?.apple_app_store_for_iPad_only_ios.includes(
                    "http" || "https"
                  )
                ) {
                  url = `https://www.apple.com/us/search/${data?.apple_app_store_for_iPad_only_ios}?src=serp`;
                }
                return setTimeout(() =>window.open(url, "_self"),2000);
                // return window.open(data?.apple_app_store_for_iPad_only_ios);
              } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                // return window.open(data?.apple_app_store_ios);
                let url = data?.apple_app_store_ios;
                if (!data?.apple_app_store_ios.includes("http" || "https")) {
                  url = `https://www.apple.com/us/search/${data?.apple_app_store_ios}?src=serp`;
                }
                return window.open(url, "_self");
              } else if (
                navigator.userAgent.match(
                  /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
                )
              ) {
                return setTimeout(() =>window.open(CheckURl()),2000);
              } else if (/huawei|HONOR|HUAWEI/i.test(userAgent)) {
                // return window.open(data?.app_gallery_huawei);
                let url = data?.app_gallery_huawei;
                if (!data?.app_gallery_huawei.includes("http" || "https")) {
                  url = `https://appgallery.huawei.com/search/${data?.app_gallery_huawei}`;
                }
                return setTimeout(() =>window.open(url, "_self"),2000);
              } else if (/windows phone/i.test(userAgent)) {
                // return window.open(data?.microsoft_store_window_mobile);
                let url = data?.microsoft_store_window_mobile;
                if (
                  !data?.microsoft_store_window_mobile.includes(
                    "http" || "https"
                  )
                ) {
                  url = `https://apps.microsoft.com/search?query=${data?.microsoft_store_window_mobile}`;
                }
                return setTimeout(() =>window.open(url, "_self"),2000);
              } else {
                // return CheckURl(data?.backup_link_for_other_device_browser);
                let url = data?.backup_link_for_other_device_browser;
                if (
                  !data?.backup_link_for_other_device_browser.includes(
                    "http" || "https"
                  )
                ) {
                  url = `https://www.${data?.backup_link_for_other_device_browser}.com`;
                }
                return setTimeout(() =>window.open(url, "_self"),2000);
              }
            } else if (data?.type == "smart-url") {
              if (data?.ruleType == "location") {
                let datacheck = data?.smartURLLocation.find((item) => {
                  if (!item?.region?.state_code && !item?.city?.name) {
                    if (item?.country?.iso2 == locationDetail?.country) {
                      return item;
                    }
                  } else if (item?.region?.state_code && !item?.city?.name) {
                    if (
                      item?.country?.iso2 == locationDetail?.country &&
                      item?.region?.state_code == locationDetail?.region
                    ) {
                      return item;
                    }
                  } else {
                    if (
                      item?.country?.iso2 == locationDetail?.country &&
                      item?.region?.state_code == locationDetail?.region &&
                      item?.city?.name == locationDetail?.city
                    ) {
                      return item;
                    }
                  }
                });
                // if (datacheck?.length == 0 || !datacheck) {
                //   CheckURl(data?.defaultUrl);
                // } else {
                //   CheckURl(data?.defaultUrl);
                // }
              } else if (data?.ruleType == "number_of_scans") {
                let numberOfScans = data?.smartURLNumberOfScans;
                let datacheck = data?.smartURLNumberOfScans.find(
                  (item, index) => {
                    if (parseInt(item?.scans) > 0) {
                      numberOfScans[index]["scans"] = (
                        Number(item?.scans) - 1
                      ).toString();
                      return item;
                    }
                  }
                );
                if (datacheck?.length == 0 || !datacheck) {
                  return setTimeout(() => {
                    CheckURl(data?.defaultUrl);
                  }, 1000);
                } else {
                  let payload = {
                    id: data?.id,
                    smartURLNumberOfScans: numberOfScans,
                  };
                  ApiClient.put(`qrcode/download`, payload).then((res) => { });
                  return setTimeout(() => {
                    CheckURl(data?.defaultUrl);
                  }, 1000);
                }
              } else if (data?.ruleType == "time") {
                let datacheck = data?.smartURLTime.find((item) => {
                  if (
                    item?.timezone?.value == locationDetail?.timezone &&
                    new Date(item?.startTime).getTime() <
                    new Date().getTime() &&
                    new Date(item?.endTime).getTime() > new Date().getTime()
                  ) {
                    return item;
                  }
                });

                // if (datacheck?.length == 0 || !datacheck) {
                //   let url = data?.defaultUrl;
                //   if (!data?.defaultUrl.includes("http" || "https")) {
                //     url = `https://${url}`;
                //   }
                //   return setTimeout(() => window.open(url, "_self"), 1000);

                // } else {
                //   let url = datacheck?.url;
                //   if (!datacheck?.url?.includes("http" || "https")) {
                //     url = `https://${url}`;
                //   }
                //   return setTimeout(() => {
                //     window.open(url, "_self");
                //   }, 1000);
                // }
              } else {
                let datacheck = data?.smartURLLanguage.find((item) => {
                  // if (
                  //   (deviceLanguage == "hi" && item?.language.code == "hi") ||
                  //   (deviceLanguage == "hi IN" && item?.language.code == "hi")
                  // ) {
                  //   return item;
                  if (deviceLanguage.match(item?.language.code)) {
                    return item;
                  }
                });
                if (datacheck?.length == 0 || !datacheck) {
                  return setTimeout(
                    () => window.open(data?.defaultUrl, "_self"),
                    2000
                  );
                } else {
                  return setTimeout(
                    () => window.open(datacheck?.url, "_self"),
                    2000
                  );
                }
              }
            } else if (data?.setting?.numberOfScansStatus) {
              if (parseInt(data?.setting?.numberOfScans) != 0) {
                let settings = data?.setting;
                settings["numberOfScans"] = (
                  Number(data?.setting?.numberOfScans) - 1
                ).toString();
                let payload = {
                  id: data?.id,
                  setting: settings,
                  // totalScans: data?.setting?.numberOfScans
                };
                if (data?.setting?.dateRangeStatus) {
                  if (
                    new Date(data?.setting?.dateRangeStart) > new Date() &&
                    new Date(data?.setting?.dateRangeEnd) < new Date()
                  ) {
                    window.open(data?.qrCode_data, "_self");
                    ApiClient.put(`qrcode/download`, payload).then((res) => { });
                    return;
                  } else {
                    return window.open(data?.setting?.expiryURL, "_self");
                  }
                } else {
                  window.open(data?.qrCode_data);
                  ApiClient.put(`qrcode/download`, payload).then((res) => { });
                  return;
                }
              } else {
                // ApiClient.post(`addUniqueScans`, paylaodDetail).then((res) => {});
                return setTimeout(() =>window.open(data?.setting?.expiryURL, "_self"),2000);
              }
            } else if (data?.type == "file") {
              return window.open(
                `http://portal.jcsoftwaresolution.in:6056/documents/${data?.qrCode_data}`,
                "_self"
              );
            } else if (data?.type == "snapchat") {
              return setTimeout(() =>window.open(data?.qrCode_data, "_self"),2000);
            } else if (data?.type == "youtube") {
              return CheckURl(data?.qrCode_data, "_self");
            } else {
              return setTimeout(() =>window.open(data?.qrCode_data, "_self"),2000);
            }
          }
        }
      });
    }
  };

  const findUserBrowser = () => {
    if (userAgent.indexOf("Firefox") > -1) {
      return "Mozilla Firefox";
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
      return "Samsung Internet";
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      return "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      return "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      return "Microsoft Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      return "Google Chrome or Chromium";
    } else if (userAgent.indexOf("Safari") > -1) {
      return "Apple Safari";
    } else if (userAgent.indexOf("Brave") > -1) {
      return "Brave";
    } else if (userAgent.indexOf("BingPreview") > -1) {
      return "Bing";
    } else {
      return "Unknown Browser";
    }
  };

  const findOS = () => {
    if (userAgent.match(/Android/i)) {
      return "Android OS";
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return "iOS";
    } else if (userAgent.match(/Windows/i)) {
      return "Windows";
    } else if (userAgent.match(/Macintosh|Mac OS X/i)) {
      return "OS X";
    } else if (userAgent.match(/Linux/i)) {
      return "Linux";
    } else if (userAgent.match(/CrOS/i)) {
      return "Chrome OS";
    } else {
      return "Unknown OS";
    }
  };

  const findDevice = () => {
    // const iandroidRegex = /Android/i;
    const iphoneRegex = /iPhone/i;
    const ipadRegex = /iPad/i;
    const samsungRegex = /Samsung/i;
    const motoRegex = /Moto/i;
    const motorolaRegex = /Motorola/i;
    const huaweiRegex = /Huawei/i;
    const realmeRegex = /Realme/i;
    const vivoRegex = /Vivo/i;
    const oppoRegex = /Oppo/i;
    const pocoRegex = /Poco/i;
    const linuxRegex = /Linux/i;
    const windowsRegex = /Windows/i;
    const macRegex = /Macintosh|Mac OS X/i;
     if (iphoneRegex.test(userAgent)) {
      return "iPhone";
    }else if (samsungRegex.test(userAgent)) {
      return "Samsung";
    } else if (ipadRegex.test(userAgent)) {
      return "iPad";
    } else if (motoRegex.test(userAgent) || motorolaRegex.test(userAgent)) {
      return "Motorola";
    } else if (huaweiRegex.test(userAgent)) {
      return "Huawei";
    } else if (realmeRegex.test(userAgent)) {
      return "Realme";
    } else if (vivoRegex.test(userAgent)) {
      return "Vivo";
    } else if (oppoRegex.test(userAgent)) {
      return "Oppo";
    } else if (pocoRegex.test(userAgent)) {
      return "Poco";
    } else if (linuxRegex.test(userAgent)) {
      return "Linux";
    } else if (windowsRegex.test(userAgent)) {
      return "Windows";
    } else if (macRegex.test(userAgent)) {
      return "macOS";
    } else {
      return "Unknown Device";
    }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    // console.log(form?.password, "==FORM PASSWORD");
    console.log(detail, "===detail");
    const payload = {
      "id": detail?.id || detail?._id,
      "password": form?.password
    }

    ApiClient.post('password/compared',payload).then((res)=>{
      if(res.success){
        // if (form?.password == detail?.setting?.password) {
          // loader(true);
          if (
            detail?.setting?.numberOfScansStatus &&
            detail?.setting?.numberOfScans == 0
          ) {
            let url = detail?.setting?.expiryURL;
            if (!detail?.setting?.expiryURL.includes("http" || "https")) {
              url = `https://${detail?.setting?.expiryURL}`;
            }
            // getIpAddress(detail, true);
            // ApiClient.post(`addUniqueScans`, paylaodDetail).then((res) => {});
            // loader(false);
            return window.open(url, "_self");
          }
          if (detail?.setting?.limitScanLocation) {
            console.log(navigator, "==NAVIGATOR");
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {
                initMap(
                  detail?.setting?.latitude,
                  detail?.setting?.longitude,
                  position?.coords?.latitude,
                  position?.coords?.longitude,
                  detail?.setting?.expiryURL,
                  detail?.setting?.radiusInKM,
                  detail
                );
                return false;
                if (
                  detail?.setting?.latitude?.toString()?.slice(0, 2) !=
                  String(position?.coords?.latitude)?.slice(0, 2) ||
                  detail?.setting?.longitude?.slice(0, 2) !=
                  String(position?.coords?.longitude)?.slice(0, 2) ||
                  detail?.setting?.latitude?.slice(3, 5) !=
                  String(position?.coords?.latitude)?.slice(3, 5) ||
                  detail?.setting?.longitude?.slice(3, 5) !=
                  String(position?.coords?.longitude)?.slice(3, 5)
                ) {
                  loader(false);
    
                  let url = detail?.setting?.expiryURL;
                  if (
                    !detail?.setting?.expiryURL.includes("http" || "https") &&
                    detail?.setting?.expiryURL
                  ) {
                    url = `https://${detail?.setting?.expiryURL}`;
                    // getIpAddress(detail, true);
                    return window.open(url, "_self");
                  }
                } else {
                  getIpAddress(detail);
                }
                // setLng(position?.coords?.longitude);
                // SetLat(position?.coords?.latitude);
                // setCordinates({
                //   ...Cordinates,
                //   lat: position?.coords?.latitude,
                //   lng: position?.coords?.longitude,
                // });
              });
            }
          } else if (detail?.setting?.exactGPSLocation) {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {
                if (
                  detail?.setting?.exactGPSLocationLat?.slice(0, 2) !=
                  String(position?.coords?.latitude)?.slice(0, 2) ||
                  detail?.setting?.exactGPSLocationLng?.slice(0, 2) !=
                  String(position?.coords?.longitude)?.slice(0, 2) ||
                  detail?.setting?.exactGPSLocationLat?.slice(3, 5) !=
                  String(position?.coords?.latitude)?.slice(3, 5) ||
                  detail?.setting?.exactGPSLocationLng?.slice(3, 5) !=
                  String(position?.coords?.longitude)?.slice(3, 5)
                ) {
                  // return toast.error(position?.coords);
                  loader(false);
    
                  let url = detail?.setting?.expiryURL;
                  if (
                    !detail?.setting?.expiryURL.includes("http" || "https") &&
                    detail?.setting?.expiryURL
                  ) {
                    url = `https://${detail?.setting?.expiryURL}`;
                    return window.open(url, "_self");
                  }
                } else {
                  getIpAddress(detail);
                }
              });
            }
          } else {
            getIpAddress(detail);
          }
          document.getElementById("ClosePasswordModal").click();
          setform({ ...form, password: "" });
        } else {
          // toast.error("Password is wrong.");
          return;
        }
      // }
    })
    
  };

  return (
    <>
      <input
        type="text"
        name=""
        onChange={initMap}
        style={{ display: "none" }}
        id="RADIUSINPUT"
      />
      {/* <h1>Testing Page</h1> */}
      {/* <h2>{Cordinates?.latitude}</h2>
      <h2>{Cordinates?.longitude}</h2> */}

      {/* Password Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        id="OpenPasswordModal"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handlePassword}>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h1>Password</h1>
                    <input
                      type="text"
                      value={form?.password}
                      onChange={(e) =>
                        setform({ ...form, password: e.target.value })
                      }
                      className="form-control"
                      placeholder="Password"
                      required
                    />
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-secondary d-none"
                    id="ClosePasswordModal"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default Testing;
