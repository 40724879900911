import React from "react";
import Layout from "../../components/global/layout"
import WebScanner from "./WebScanner";

const QrScanner = () => {
    return (
        <>
            <Layout>
                <WebScanner />
            </Layout>
        </>
    )
}

export default QrScanner