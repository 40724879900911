import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import "./addeditfolder.scss";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import loader from "../../methods/loader";
import QRCodeStyling from "styled-qr-code";
import { toJpeg, toPng } from "html-to-image";
import Frames from "./../Frames/index";

const EditFolder = () => {
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  let host = document.location.host;
  let RedirectURL =environment?.liveURL
  // if (host?.includes("https://qrcode.jcsoftwaresolution.in/")) {
  //   RedirectURL = "https://qrcode.jcsoftwaresolution.in/";
  // } else {
  //   RedirectURL = "http://66.179.251.9:8056/";
  // }
  const [filter, setfilter] = useState({
    // user_id: user?.id,
    search: "",
    sortBy: "updatedAt desc",
    status: "",
    type: "",
    qrCodeId: "",
    campaignName: "",
    medium: "",
  });
  const [detail, setdetail] = useState([]);
  const [dataCheck, setdataCheck] = useState([]);
  const [types, settypes] = useState([]);
  const token = localStorage.getItem("token");
  const [Tableformat, setTableformat] = useState("");
  const [downloadimg, setdownloadimg] = useState(null);
  const [Type, setType] = useState([]);
  const ActivePlan = useSelector((state) => state.activePlan);
  const [EditData, setEditData] = useState({
    flyer: "",
    printRun: "",
    campaignStart: "",
    campaignEnd: "",
  });
  const [Expiry, setExpiry] = useState({});
  const [Downloadimageindex, setDownloadimageindex] = useState(null);
  const [DownloadItem, setDownloadItem] = useState({ data: {}, index: 0 });
  const [selectFormat, setSelectedFormat] = useState("");
  const canvasRef2 = useRef();
  const canvasRef = useRef([]);
  const [QRCODES, setQrCodes] = useState([]);
  const [total, settotal] = useState(0);

  const UpdateExpiry = (e) => {
    e.preventDefault();
    let payload = { ...Expiry };
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    loader(true);
    ApiClient.put("qrcode/download", payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getDetail({ sortBy: "updatedAt desc" });
        document.getElementById("CloseExpiry").click();
      }
      loader(false);
    });
  };

  // For Update Campaign Data
  const UpdateEditdata = (e) => {
    e.preventDefault();
    let payload = { ...EditData, id: EditData?._id };
    delete payload?.updatedAt;
    delete payload?.updatedBy;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.deletedBy;
    delete payload?.addedBy;
    loader(true);
    ApiClient.put("qrcode/download", payload).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getDetail({ sortBy: "updatedAt desc" });
        document.getElementById("btnclose").click();
        loader(false);
      }
    });
  };

  // useEffect(()=>{
  //   setExpiry({...Expiry,numberOfScans:Expiry?.totalScans})
  //   },[])

  const HandleEditchange = (e) => {
    const value = e.target.value;
    const data = EditData;
    data["setting"][e.target.name] = value;
    setEditData({ ...data });
  };

  const GetType = () => {
    ApiClient.get("qrtype/all").then((res) => {
      setType(res.data);
    });
  };

  useEffect(() => {
    GetType();
  }, []);

  const FindType = (data) => {
    let value = "";
    if (data == "url") {
      data = "urls";
    }
    value = Type && Type.filter((itm) => itm?.slug == data?.replace("-", " "));
    return value[0]?.type ? value[0]?.type : "--";
  };

  const TableDownload = () => {
    document.getElementById("Tablemodal").click();
  };

  const handleDownloadQrCode = () => {
    if (Tableformat == "svg") {
      ConvertQRtoSVG();
    } else {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var img = new Image();
      var scaleFactor = 2;
      img.onload = function () {
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the image to a different format (e.g., PNG)
        if (Tableformat === "png") {
          var newImage = canvas.toDataURL("image/png", 1.0);
        } else {
          var newImage = canvas.toDataURL("image/jpeg", 1.0);
        }
        // var newImage = canvas.toDataURL("image/jpeg");

        var a = document.createElement("a");
        a.href = newImage;
        if (Tableformat === "png") {
          a.download = `QrCode.png`;
        } else if (Tableformat === "jpeg") {
          a.download = `QrCode.jpeg`;
        }
        // a.download = `QrCode.jpeg`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      img.src = downloadimg;
      document.getElementById("CloseFormatModal").click();
    }
  };

  const DownloadQR = (index, item) => {
    document.getElementById("OpenFormatModel").click();
    setDownloadimageindex(index);
    console.log(index, item);
    setDownloadItem({ data: item, index: index });
  };

  const convertHTMLToImage = (e, index) => {
    if (DownloadItem?.data?.design?.isFrame) {
      return ConvertDiv();
    }
    if (selectFormat == "svg") {
      QRCODES[DownloadItem?.index].download({
        name: "QRCode",
        extension: "svg",
      });
    }
    if (selectFormat == "png") {
      QRCODES[DownloadItem?.index].download({
        name: "QRCode",
        extension: "png",
      });
    }
    if (selectFormat == "jpeg") {
      QRCODES[DownloadItem?.index].download({
        name: "QRCode",
        extension: "jpeg",
      });
    }
    document.getElementById("CloseSingalFormatModal").click();

    e.preventDefault();
  };
  const ConvertDiv = () => {
    if (!canvasRef2.current) {
      return;
    }

    if (selectFormat == "png") {
      toPng(canvasRef2.current, {
        cacheBust: true,
        quality: 1, // Set this value to 1 for the highest quality
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "my-image.png";

          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error("Conversion failed:", err);
        });
    }

    if (selectFormat == "jpeg") {
      toJpeg(canvasRef2.current, {
        cacheBust: true,
        quality: 1, // Set this value to 1 for the highest quality
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "my-image.jpeg";

          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error("Conversion failed:", err);
        });
    }

    if (selectFormat == "svg") {
      ConvertQRtoSVG();
    }
    document.getElementById("CloseSingalFormatModal").click();
  };

  const ConvertQRtoSVG = () => {
    var img = new Image();
    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");

      // Set background color to white
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Convert canvas content to SVG
      var svg =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
        img.width +
        " " +
        img.height +
        '" width="' +
        img.width +
        '" height="' +
        img.height +
        '"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml"><img src="' +
        canvas.toDataURL("image/png") +
        '"/></div></foreignObject></svg>';

      // Download the SVG file
      var blob = new Blob([svg], { type: "image/svg+xml" });
      var url = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "image.svg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    };
    img.src = downloadimg;
    document.getElementById("CloseSingalFormatModal").click();
  };

  useEffect(() => {
    if (id) getDetail();
    getAllTypes();
  }, []);

  const getDetail = (p = {}) => {
    loader(true);
    let filters = { ...filter, ...p, id: id };
    ApiClient.get(`folder`, filters).then((res) => {
      if (res.success) {
        const data = res?.data?.data.map((item) => {
          return { ...item, ...item?.qcodeDownloadDetail };
        });

        setdetail(data);
        settotal(data?.length);
        data?.map(async (itm, index) => {
          const qrCode = new QRCodeStyling({
            width: 500,
            height: 500,
            type: "svg",
            margin: 5,
            data: itm?.dynamicQrcode
              ? `${RedirectURL}redirecting/${itm?.slug}`
              : itm?.qrCode_data || RedirectURL,
            image: itm?.design?.logoimg
              ? `${environment.api}images/logo/${itm?.design?.logoimg}`
              : "",
            crossOrigin: "anonymous",
            qrOptions: {
              typeNumber: 0,
              mode: "Byte",
              errorCorrectionLevel: itm?.design?.errorCorrection,
            },
            dotsOptions: {
              color: itm?.design?.qrBodyPatternColor,
              type: itm?.design?.qrBodyPattern,
              gradient:
                itm?.design?.qrBodyPatternGradientColor1 ||
                itm?.design?.qrBodyPatternGradientColor2
                  ? {
                      type: itm?.design?.qrBodyPatternGradientColorType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.qrBodyPatternGradientColor1 ||
                            "#030202",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.qrBodyPatternGradientColor2 ||
                            "#030202",
                        },
                      ],
                    }
                  : "",
            },
            imageOptions: {
              crossOrigin: "anonymous",
              // hideBackgroundDots: itm?.design.logotoggle || false,
              margin: 0,
              imageSize: `0.${itm?.design?.logosize}`,
            },

            cornersSquareOptions: {
              type: itm?.design?.qrOuterEye,
              color: itm?.design?.qrOuterEyeColor,
              gradient:
                itm?.design?.qrOuterEyeColorGradient1 ||
                itm?.design?.qrOuterEyeColorGradient2
                  ? {
                      type: itm?.design?.qrOuterEyeColorGradientType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.qrOuterEyeColorGradient1 || "#030202",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.qrOuterEyeColorGradient2 || "#030202",
                        },
                      ],
                    }
                  : "",
            },
            cornersDotOptions: {
              type: itm?.design?.qrInnerEye,
              color: itm?.design?.qrInnerEyeColor,
              gradient:
                itm?.design?.qrInnerEyeColorGradient1 ||
                itm?.design?.qrInnerEyeColorGradient2
                  ? {
                      type: itm?.design?.qrInnerEyeColorGradientType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.qrInnerEyeColorGradient1 || "#030202",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.qrInnerEyeColorGradient2 || "#030202",
                        },
                      ],
                    }
                  : "",
            },
            backgroundOptions: {
              color: itm?.design?.backgroundGradientColor1
                ? itm?.design?.backgroundGradientColor1
                : itm?.design?.backgroundColor || "#ffffff",
              gradient:
                itm?.design?.backgroundGradientColor1 ||
                itm?.design?.backgroundGradientColor2
                  ? {
                      type: itm?.design?.backgroundGradientColorType,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            itm?.design?.backgroundGradientColor1 || "#FFFFFF",
                        },
                        {
                          offset: 1,
                          color:
                            itm?.design?.backgroundGradientColor2 || "#FFFFFF",
                        },
                      ],
                    }
                  : "",
            },
          });
          QRCODES.push(qrCode);
          console.log(qrCode, "fjghuig45");
          // console.log(qrCode, "=======================itms==");
          // document.getElementById(`Multicanvas${index}`).innerHTML = "";
          // qrCode.append(document.getElementById(`Multicanvas${index}`));
          // ListQRCodes.push(qrCode);
        });
      }
      loader(false);
    });
  };

  const totalScansCount = (data) => {
    let value = 0;
    if (data?.length != 0) {
      data?.map((item) => {
        value += item?.count;
      });
      return value;
    } else {
      return value;
    }
  };

  const getAllTypes = () => {
    ApiClient.get(`qrtype/all?status=active`).then((res) => {
      if (res.success) {
        settypes(
          res?.data?.map((item) => {
            return { value: item?.slug, label: item?.type };
          })
        );
      }
    });
  };

  const handleStatus = (item) => {
    let payload = {
      id: item?.qcodeDownloadDetail?._id,
      status: item?.status == "pause" ? "active" : "pause",
    };
    if (window.confirm("Do you really want to change this status")) {
      ApiClient.put(`updateStatus`, { data: [payload] }).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          setdataCheck([]);
          getDetail();
        }
      });
    }
  };

  // Checks
  const handleAllCheck = (check) => {
    if (check) {
      let array = [];

      detail?.map((item) => {
        array.push(item?._id);
      });
      setdataCheck(array);
    } else {
      setdataCheck([]);
    }
  };
  const checkAllChecks = () => {
    let value = true;
    detail?.find((item) => {
      if (dataCheck.includes(item?._id)) {
        return;
      } else {
        value = false;
      }
    });

    return value;
  };
  const handleCheck = (check, item) => {
    let array = dataCheck;
    if (check) {
      array.push(item?._id);
    } else {
      array = dataCheck.filter((itm) => itm != item?._id);
    }

    setdataCheck([...array]);
  };

  // Actions

  const DownLoadMutliQR = () => {
    detail?.map((item, index) => {
      if (dataCheck.includes(item?._id)) {
        if (!item?.design?.isFrame) {
          if (Tableformat == "svg") {
            QRCODES[index].download({
              name: "QRCode",
              extension: "svg",
            });
          }
          if (Tableformat == "png") {
            QRCODES[index].download({
              name: "QRCode",
              extension: "png",
            });
          }
          if (Tableformat == "jpeg") {
            QRCODES[index].download({
              name: "QRCode",
              extension: "jpeg",
            });
          }
          // QRCODES[index].download({ name: "QRCODE", extension: "png" })
        } else if (item?.design?.isFrame) {
          if (Tableformat == "svg") {
            var img = new Image();

            img.onload = function () {
              var canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              var ctx = canvas.getContext("2d");

              // Set background color to white
              ctx.fillStyle = "black";
              ctx.fillRect(0, 0, canvas.width, canvas.height);

              // Draw the image on the canvas
              ctx.drawImage(img, 0, 0);

              // Convert canvas content to SVG
              var svg =
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
                img.width +
                " " +
                img.height +
                '" width="' +
                img.width +
                '" height="' +
                img.height +
                '"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml"><img src="' +
                canvas.toDataURL("image/png") +
                '"/></div></foreignObject></svg>';

              // Download the SVG file
              var blob = new Blob([svg], { type: "image/svg+xml" });
              var url = URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = "QrCode.svg";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              URL.revokeObjectURL(url);
            };
            img.src = item?.image;
          } else {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var img = new Image();
            var scaleFactor = 2;
            img.onload = function () {
              canvas.width = img.width * scaleFactor;
              canvas.height = img.height * scaleFactor;
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

              // Convert the image to a different format (e.g., PNG)
              if (Tableformat === "png") {
                var newImage = canvas.toDataURL("image/png", 1.0);
              } else {
                var newImage = canvas.toDataURL("image/jpeg", 1.0);
              }
              // var newImage = canvas.toDataURL("image/jpeg");

              var a = document.createElement("a");
              a.href = newImage;
              if (Tableformat === "png") {
                a.download = `QrCode.png`;
              } else if (Tableformat === "jpeg") {
                a.download = `QrCode.jpeg`;
              }
              // a.download = `QrCode.jpeg`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            };
            img.src = item?.image;
            setdataCheck([]);
          }

          document.getElementById("CloseMultiQRModal").click();
        }
      }
    });
  };
  const downlaodQrCodes = () => {
    DownLoadMutliQR();
    return false;
    if (dataCheck?.length == 0) {
      toast.error("Select QR code for download");
      return;
    }
    let array = [];

    detail?.find((item) => {
      if (dataCheck.includes(item?._id)) {
        array.push(item?.image);
      }
    });
    array &&
      array.map((item, index) => {
        if (Tableformat == "svg") {
          var img = new Image();

          img.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");

            // Set background color to white
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0);

            // Convert canvas content to SVG
            var svg =
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
              img.width +
              " " +
              img.height +
              '" width="' +
              img.width +
              '" height="' +
              img.height +
              '"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml"><img src="' +
              canvas.toDataURL("image/png") +
              '"/></div></foreignObject></svg>';

            // Download the SVG file
            var blob = new Blob([svg], { type: "image/svg+xml" });
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "QrCode.svg";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          };
          img.src = item;
        } else {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          var img = new Image();
          var scaleFactor = 2;
          img.onload = function () {
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert the image to a different format (e.g., PNG)
            if (Tableformat === "png") {
              var newImage = canvas.toDataURL("image/png", 1.0);
            } else {
              var newImage = canvas.toDataURL("image/jpeg", 1.0);
            }
            // var newImage = canvas.toDataURL("image/jpeg");

            var a = document.createElement("a");
            a.href = newImage;
            if (Tableformat === "png") {
              a.download = `QrCode.png`;
            } else if (Tableformat === "jpeg") {
              a.download = `QrCode.jpeg`;
            }
            // a.download = `QrCode.jpeg`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
          img.src = item;
          setdataCheck([]);
        }
      });
    document.getElementById("CloseMultiQRModal").click();
  };
  const pausedQrCode = () => {
    if (dataCheck?.length != 0) {
      let payload =
        dataCheck &&
        dataCheck.map((item) => {
          return { id: item, status: "pause" };
        });
      if (window.confirm("Do you really want to change this status")) {
        ApiClient.put(`updateStatus`, { data: payload }).then((res) => {
          if (res.success) {
            toast.success(res?.message);
            getDetail();
            setdataCheck([]);
          }
        });
      }
    } else {
      toast.error("Qr Code Not Selected");
      return;
    }
  };
  const multipleDelete = () => {
    if (dataCheck?.length != 0) {
      if (window.confirm("Do you really want to this qr codes")) {
        axios
          .delete(`${environment.api}qrcode/download/multiple`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              ids: dataCheck,
            },
          })
          .then((res) => {
            toast.success(res?.data?.message);
            getDetail();
          });
      }
    } else {
      toast.error("Qr Code Not Selected");
      return;
    }
  };

  const handleRedirectURL = (url) => {
    window.open(url);
  };

  const handleFilters = (value, type) => {
    if (type == "sortBy") {
      setfilter({ ...filter, sortBy: value });
      getDetail({ sortBy: value });
    } else {
      getDetail({
        type: filter?.type?.value,
        qrCodeId: filter?.qrCodeId,
        campaignName: filter?.campaignName,
        medium: filter?.medium,
      });
    }
  };

  const handleResetFilters = () => {
    setfilter({
      ...filter,
      status: "",
      type: "",
      qrCodeId: "",
      campaignName: "",
      medium: "",
    });
    getDetail({
      ...filter,
      type: "",
      qrCodeId: "",
      campaignName: "",
      medium: "",
      status: "",
    });
  };

  const handleSearch = (e) => {
    setfilter({ ...filter, search: e });
    getDetail({ search: e }, false);
  };

  return (
    <>
      <Layout>
        <div className="marketing">
          <div className="container-fluid">
            <div className="second_div mb-3">
              <div className="trash_div">
                <div className="search_bar w-50">
                  <input
                    value={filter?.search}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="form-control search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <i className="fa fa-search search_pos"></i>
                </div>
                <div className="dropdown_flex">
                  <div className="select_input ml-md-2 ">
                    <p className="type_text">Sort By:</p>
                    <select
                      value={filter?.sortBy}
                      onChange={(e) => handleFilters(e.target.value, "sortBy")}
                      className="form-select select_url"
                    >
                      <option
                        className="form_select_options"
                        value="updatedAt desc"
                      >
                        Latest
                      </option>
                      <option
                        className="form_select_options"
                        value="updatedAt asc"
                      >
                        Oldest
                      </option>
                    </select>
                  </div>
                  <div className="dropdown drop_it">
                    <button
                      className="btn btn-light light_btn dropdown-toggle "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            {
                              dataCheck?.length == 0
                                ? toast.error("Select QR code for download")
                                : document
                                    .getElementById("MultiQrmodal")
                                    .click();
                            }
                          }}
                        >
                          <span className="material-icons mr-2 text-primary fs-4">
                            download
                          </span>{" "}
                          Download
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => pausedQrCode()}
                        >
                          <span className="material-icons mr-2 fs-4 text-primary">
                            pause_circle_outline
                          </span>{" "}
                          Pause QR Code
                        </a>
                      </li>
                      {/* <li><a className="dropdown-item" onClick={e => moveFolder()}><span className="material-icons mr-2 fs-4 text-primary">folder_open</span> Move Folder</a></li> */}
                      {/* <li><a className="dropdown-item"><span className="material-icons mr-2 fs-4 text-primary">library_add</span> Duplicate</a></li> */}
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={(e) => multipleDelete()}
                        >
                          <span className="material-icons mr-2 fs-4 text-primary">
                            delete_outline
                          </span>{" "}
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="select_dropdown ml-md-2"
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Filter{" "}
                    <span className="material-icons ml-2">filter_list</span>
                  </div>
                  <div
                    className="tabs_icon nav nav-pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <span
                      className="material-icons nav-link active list_alt"
                      id="pills-tab1-tab"
                      data-toggle="pill"
                      data-target="#pills-tab1"
                      type="button"
                      role="tab"
                      aria-controls="pills-tab1"
                      aria-selected="true"
                    >
                      view_stream
                    </span>
                    <span
                      className="material-icons nav-link"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      data-target="#pills-tab2"
                      type="button"
                      role="tab"
                      aria-controls="pills-tab2"
                      aria-selected="false"
                    >
                      list_alt
                    </span>
                  </div>
                </div>
              </div>
              <div className="filter_list collapse" id="collapseExample">
                <div className="filters_row">
                  <h1>Filter</h1>
                  <div className="text_filter">
                    <h3 onClick={(e) => handleResetFilters()} className="mr-3">
                      Reset Filter{" "}
                      <span className="material-icons ml-2">clear_all</span>
                    </h3>
                    <h3
                      onClick={(e) => handleFilters("", "combineFilters")}
                      className="bule_h3"
                    >
                      Apply <span className="material-icons ml-2">done</span>
                    </h3>
                  </div>
                </div>
                <div className="row_name">
                  <div className="row">
                    <div className="col">
                      <label className="fw-bold">Campaign Name</label>
                      <input
                        type="text"
                        value={filter?.campaignName}
                        onChange={(e) =>
                          setfilter({ ...filter, campaignName: e.target.value })
                        }
                        className="form-control"
                        placeholder="Campaign Name"
                      />
                    </div>
                    <div className="col">
                      <label className="fw-bold">QR Code ID</label>
                      <input
                        type="text"
                        value={filter?.qrCodeId}
                        onChange={(e) =>
                          setfilter({ ...filter, qrCodeId: e.target.value })
                        }
                        className="form-control"
                        placeholder="QR Code ID"
                      />
                    </div>
                    <div className="col">
                      <label className="fw-bold">Status</label>
                      <select
                        value={filter?.status}
                        onChange={(e) =>
                          setfilter({ ...filter, status: e.target.value })
                        }
                        className="form-select select-btn"
                        aria-label="Default select example"
                      >
                        <option value="">All Status</option>
                        <option value="active">Active</option>
                        <option value="pause">Paused</option>
                      </select>
                    </div>
                    <div className="col">
                      <label className="fw-bold">Types</label>
                      <Select
                        className=""
                        value={filter?.type}
                        onChange={(e) => setfilter({ ...filter, type: e })}
                        options={types}
                        isClearable={true}
                      />
                    </div>
                    <div className="col">
                      <label className="fw-bold">Medium</label>
                      <input
                        className="form-control"
                        value={filter?.medium}
                        onChange={(e) =>
                          setfilter({ ...filter, medium: e.target.value })
                        }
                        placeholder="Medium"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show active"
                id="pills-tab1"
                role="tabpanel"
                aria-labelledby="pills-tab1-tab"
              >
                <div className="card_row">
                  <div className="my-3 px-4">
                    <input
                      checked={checkAllChecks()}
                      onClick={(e) => handleAllCheck(e.target.checked)}
                      className="form-check-input fs-5"
                      type="checkbox"
                      id="flexCheckChecked"
                    />
                    <span className="selectall">Select All</span>
                  </div>
                  {detail?.map((item, index) => {
                    return (
                      <div className="catd_div mt-3">
                        <div className="list-group-item d-flex justify-content-between">
                          <h1 className="name_heading">
                            <div className="form-check">
                              <input
                                checked={dataCheck.includes(item?._id)}
                                onClick={(e) =>
                                  handleCheck(e.target.checked, {
                                    ...item,
                                    id: item._id,
                                  })
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                            <span
                              onClick={(e) => handleStatus(item)}
                              className={`mx-2 ${
                                item?.staticQrcode
                                  ? "static"
                                  : item?.status == "pause"
                                  ? "paused"
                                  : "status"
                              } text-capitalize pointer`}
                            >
                              {item?.staticQrcode
                                ? "Static"
                                : item?.status == "pause"
                                ? "Paused"
                                : "Active"}
                            </span>
                            <span>{item?.setting?.qrCodeName || "--"}</span>
                          </h1>
                          <div className="btns_blue">
                            <button
                              disabled={ActivePlan?.name == "free"}
                              onClick={(e) => {
                                setExpiry({
                                  ...item,
                                  id: item?._id,
                                  setting: {
                                    ...item.setting,
                                    numberOfScans:item?.totalScans,
                                  },
                                })
                                // setExpiry({ ...item, id: item?._id });
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#Expirymodal"
                              className="btn primary_btn ml-2"
                            >
                              Expiry{" "}
                              <span className="material-icons ml-2">
                                date_range
                              </span>
                            </button>
                            {/* {/ <button className="btn primary_btn ml-2">Retarget <span className="material-icons ml-2">forward</span></button> /}
                                                    {/ <button className="btn primary_btn ml-2">Share <span className="material-icons ml-2">share</span></button> /} */}
                            <button
                              onClick={(e) => {
                                DownloadQR(index, item);
                                setdownloadimg(item?.image);
                              }}
                              download="QroCode"
                              className="btn primary_btn2 ml-2 text-white"
                            >
                              Download QR Code{" "}
                              <span className="material-icons ml-2">
                                download
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="list-group-item">
                          <div className="row">
                            <div className="col-md-6 border-right">
                              <div className="row_22 border_22">
                                <div className="left_list">
                                  <div className="row_qr">
                                    {!item?.staticQrcode ? (
                                      <div className="layout_qr mb-3">
                                        <span className="material-icons ico mr-2">
                                          link
                                        </span>
                                        <span
                                          className="pointer link_btn"
                                          onClick={(e) =>
                                            handleRedirectURL(
                                              `${environment?.liveURL}redirecting/${item?.slug}`
                                            )
                                          }
                                        >{`${environment?.liveURL}redirecting/${item?.slug}`}</span>
                                      </div>
                                    ) : null}
                                    <div className="layout_qr mb-3">
                                      <span className="material-icons ico  mr-2">
                                        send
                                      </span>
                                      <span
                                        className="pointer link_btn"
                                        onClick={(e) =>
                                          handleRedirectURL(
                                            !item?.qrCode_data
                                              ? `${environment?.liveURL}redirecting/${item?.slug}`
                                              : item?.qrCode_data
                                          )
                                        }
                                      >
                                        {!item?.qrCode_data ? (
                                          `${environment?.liveURL}redirecting/${item?.slug}`
                                        ) : (
                                          <span className="ellipes">
                                            {item?.qrCode_data}
                                          </span>
                                        )}
                                      </span>{" "}
                                    </div>
                                    <div className="layout_qr span-flex mb-3">
                                      <span className="mr-3">
                                        <span className="fw-normal">
                                          Created:
                                        </span>{" "}
                                        <b>
                                          {moment(item?.createdAt).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " " +
                                            moment(item?.createdAt).format(
                                              "hh:mm a"
                                            )}
                                        </b>
                                      </span>{" "}
                                      <span>
                                        {" "}
                                        <span className="fw-normal">
                                          Type:
                                        </span>{" "}
                                        <b>
                                          {FindType(item?.type)}
                                          {/* <span className="text-uppercase">
                                            {item?.type.replaceAll("-", " ")}
                                          </span> */}
                                        </b>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="border_leftclass">
                                  <div className="box_col2 mb-2">
                                    <p className="mb-2">Unique Scans </p>
                                    <h3 className="mb-0">
                                      {item?.slugDetails?.length || 0}
                                    </h3>
                                  </div>
                                  <div className="box_col2 box_col3">
                                    <p className="mb-2">Total Scans </p>
                                    <h3 className="mb-0">
                                      {totalScansCount(item?.slugDetails)}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row_qrcode">
                                <div className="qr_ico">
                                  <div className="list_data mb-3">
                                    <p className="p_row1">Medium</p>
                                    <p
                                      className="p_row12 text-capitalize pointer"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={(e) =>
                                        setEditData({ ...item, id: item?._id })
                                      }
                                    >
                                      {item?.setting?.flyer || "--"}
                                    </p>
                                  </div>
                                  <div className="list_data mb-3">
                                    <p className="p_row1">Print Run</p>
                                    <p
                                      className="p_row12 text-capitalize pointer"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={(e) => setEditData(item)}
                                    >
                                      {item?.setting?.printRun || "--"}
                                    </p>
                                  </div>
                                  <div className="list_data mb-3">
                                    <p className="p_row1">Campaign Start</p>
                                    <p
                                      className="p_row12 text-capitalize pointer"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={(e) => setEditData(item)}
                                    >
                                      {item?.setting?.campaignStart
                                        ? moment(
                                            item?.setting?.campaignStart
                                          ).format("MMM DD, YYYY")
                                        : "--"}
                                    </p>
                                  </div>
                                  <div className="list_data mb-3">
                                    <p className="p_row1">Campaign End</p>
                                    <p
                                      className="p_row12 text-capitalize pointer"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={(e) => setEditData(item)}
                                    >
                                      {item?.setting?.campaignEnd
                                        ? moment(
                                            item?.setting?.campaignEnd
                                          ).format("MMM DD, YYYY")
                                        : "--"}
                                    </p>
                                    {/* <p className="p_row12 text-capitalize">Set Date <span className="material-icons ml-2">insert_invitation</span></p> */}
                                  </div>
                                </div>
                                <div
                                  style={{ padding: "5px" }}
                                  ref={(ref) => {
                                    canvasRef2.current[index] = ref;
                                  }}
                                  className="qr_img"
                                >
                                  <img
                                    src={item?.image}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      width: "auto",
                                      height: "auto",
                                    }}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-tab2"
                role="tabpanel"
                aria-labelledby="pills-tab2-tab"
              >
                <div className="table-responsive">
                  <table className="table  ">
                    <thead>
                      <tr>
                        <th scope="col">
                          <span className="table_th">
                            <div className="form-check">
                              <input
                                checked={checkAllChecks()}
                                onClick={(e) =>
                                  handleAllCheck(e.target.checked)
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                            Campaign Name
                          </span>
                        </th>
                        <th scope="col">
                          <span className="table_th">QR Code ID</span>
                        </th>
                        <th scope="col">
                          <span className="table_th">Type</span>
                        </th>
                        <th scope="col">
                          <span className="table_th">Campaign Start</span>
                        </th>
                        <th scope="col">
                          <span className="table_th">Campaign End</span>
                        </th>
                        <th scope="col">
                          <span className="table_th">Unique Scans</span>
                        </th>
                        <th scope="col">
                          <span className="table_th">Total Scans</span>
                        </th>
                        <th scope="col">
                          <span className="table_th">Status</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail?.map((item) => {
                        return (
                          <tr>
                            <td>
                              <span className="table_td">
                                <div className="form-check">
                                  <input
                                    checked={dataCheck.includes(item?._id)}
                                    onClick={(e) =>
                                      handleCheck(e.target.checked, {
                                        ...item,
                                        id: item._id,
                                      })
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </div>
                                {item?.setting?.qrCodeName || "--"}
                              </span>
                            </td>
                            <td>{item?.slug || "--"}</td>
                            <td className="text-capitalize">
                              {item?.type || "--"}
                            </td>
                            <td>
                              {item?.setting?.campaignStart
                                ? moment(item?.setting?.campaignStart).format(
                                    "MMM DD, YYYY"
                                  )
                                : moment(item?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )}
                            </td>
                            <td>
                              {item?.setting?.campaignEnd
                                ? moment(item?.setting?.campaignEnd).format(
                                    "MMM DD, YYYY"
                                  )
                                : "--"}
                            </td>
                            <td>{item?.slugDetails?.length || 0}</td>
                            <td>{totalScansCount(item?.slugDetails)}</td>
                            <td>
                              <span
                                onClick={(e) => handleStatus(item)}
                                className={`mx-2 ${
                                  item?.staticQrcode
                                    ? "static"
                                    : item?.status == "pause"
                                    ? "paused"
                                    : "status"
                                } text-capitalize pointer`}
                              >
                                {item?.staticQrcode
                                  ? "Static"
                                  : item?.status == "pause"
                                  ? "Paused"
                                  : "Active"}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {total > 10 ? (
                <div className="d-flex justify-content-between align-items-center ml-2">
                  <div>
                    <b>{total}</b> Campaigns
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <button
          type="button"
          id="Tablemodal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#TablemodalOpen"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="TablemodalOpen"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setSelectedFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      Tableformat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      Tableformat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      Tableformat == "svg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("svg")}
                  >
                    SVG
                  </p>
                </div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseFormatModal"
                  onClick={(e) => setTableformat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${!Tableformat ? "disable" : ""}`}
                  disabled={!Tableformat}
                  onClick={() => {
                    handleDownloadQrCode();
                  }}
                >
                  Confirm <i className="fa fa-check text-light ml-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* MUTLI DOWNLOAD QR CODE FORMAT MODAL */}

        <button
          type="button"
          id="MultiQrmodal"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#MultiQRmodalOpen"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="MultiQRmodalOpen"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setSelectedFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      Tableformat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      Tableformat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      Tableformat == "svg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setTableformat("svg")}
                  >
                    SVG
                  </p>
                </div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseMultiQRModal"
                  onClick={(e) => setTableformat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${!Tableformat ? "disable" : ""}`}
                  disabled={!Tableformat}
                  onClick={() => {
                    downlaodQrCodes();
                  }}
                >
                  Confirm <i className="fa fa-check text-light ml-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Expiry Bootstrap modal */}
        <button
          type="button"
          class="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Launch demo modal
        </button>

        <div
          class="modal fade"
          id="Expirymodal"
          data-bs-backdrop="static"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog expiry_modal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Expiry
                </h5>
              </div>
              <div class="modal-body">
                <p>
                  You can set the QR Code to expire after a number of scans or
                  by date. This is useful for scenarios like limiting the number
                  of voucher redemptions.
                </p>
                <label> Number of scans</label>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Expiry?.setting?.numberOfScansStatus}
                        onChange={(e) =>
                          setExpiry({
                            ...Expiry,
                            setting: {
                              ...Expiry.setting,
                              numberOfScansStatus: e.target.checked,
                            },
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="numberOfScans"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="numberOfScans"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      type="text"
                      value={
                        Expiry?.setting?.numberOfScans
                          ? Expiry?.setting?.numberOfScans
                          : ""
                      }
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          totalScans:e.target.value,
                          setting: {
                            ...Expiry.setting,
                            numberOfScans: e.target.value,
                          },
                        })
                      }
                      disabled={!Expiry?.setting?.numberOfScansStatus}
                      maxLength="5"
                      className="form-control"
                      placeholder="Number of scans"
                    />
                  </div>
                </div>
                <label>Date Range</label>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-check form-switch switch_button">
                      <input
                        checked={Expiry?.setting?.dateRangeStatus}
                        onChange={(e) =>
                          setExpiry({
                            ...Expiry,
                            setting: {
                              ...Expiry.setting,
                              dateRangeStatus: e.target.checked,
                            },
                          })
                        }
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="dateRange"
                      />
                      <label
                        className="form-check-label label_text"
                        htmlFor="dateRange"
                      >
                        Enabled
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      value={moment(Expiry?.setting?.dateRangeStart).format(
                        "YYYY-MM-DD"
                      )}
                      name="dateRangeStart"
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            dateRangeStart: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      type="date"
                      placeholderText="Start"
                      disabled={!Expiry?.setting?.dateRangeStatus}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <input
                      value={moment(Expiry?.setting?.dateRangeEnd).format(
                        "YYYY-MM-DD"
                      )}
                      name="dateRangeEnd"
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            dateRangeEnd: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      type="date"
                      placeholderText="Start"
                      disabled={!Expiry?.setting?.dateRangeStatus}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label>Expiry URL</label>
                    <input
                      type="text"
                      value={Expiry?.setting?.expiryURL}
                      onChange={(e) =>
                        setExpiry({
                          ...Expiry,
                          setting: {
                            ...Expiry.setting,
                            expiryURL: e.target.value,
                          },
                        })
                      }
                      disabled={
                        !Expiry?.setting?.numberOfScansStatus &&
                        !Expiry?.setting?.dateRangeStatus
                      }
                      className="form-control"
                      placeholder="https://"
                    />
                    <p className="mt-1">
                      Visitors will be redirected to this URL after the main
                      link expires.
                    </p>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-clear border"
                  id="CloseExpiry"
                  data-bs-dismiss="modal"
                >
                  Back
                </button>
                <button
                  onClick={(e) => UpdateExpiry(e)}
                  type="button"
                  class="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Campaign Modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Campaign
                </h5>
              </div>
              <form onSubmit={(e) => UpdateEditdata(e)}>
                <div class="">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="">
                            <p className="my-2 ml-3">Medium</p>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                name="flyer"
                                type="text"
                                placeholder="e.g. email, banner, bus stop"
                                onChange={(e) => HandleEditchange(e)}
                                value={EditData?.setting?.flyer}
                                required
                              />
                            </div>
                            <p className="my-2 ml-3">Print Run</p>
                            <div className="col-md-12">
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                name="printRun"
                                placeholder="e.g. 500"
                                onChange={(e) => HandleEditchange(e)}
                                value={EditData?.setting?.printRun}
                              />
                            </div>
                            <p className="my-2 ml-3">Campaign Start</p>
                            <div className="col-md-12">
                              <input
                                type="date"
                                className="form-control"
                                onChange={(e) => HandleEditchange(e)}
                                name="campaignStart"
                                value={moment(
                                  EditData?.setting?.campaignStart
                                ).format("YYYY-MM-DD")}
                              />
                            </div>

                            <p className="my-2 ml-3">Campaign End</p>
                            <div className="col-md-12">
                              <input
                                type="date"
                                className="form-control"
                                onChange={(e) => HandleEditchange(e)}
                                name="campaignEnd"
                                value={moment(
                                  EditData?.setting?.campaignEnd
                                ).format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer my-2">
                  <button
                    type="button"
                    class="btn btn-clear border"
                    id="btnclose"
                    data-bs-dismiss="modal"
                  >
                    Back
                  </button>
                  <button type="submit" class="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Select format Modal */}
        <button
          type="button"
          id="OpenFormatModel"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#FormatQrImageModal"
        >
          Launch demo modal
        </button>
        <div
          className="modal fade"
          id="FormatQrImageModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Download Format
                </h1>
              </div>
              <div className="modal-body d-flex justify-content-center gap-5 my-2">
                {/* <div><p className={`svg_box pointer ${selectFormat == 'svg' ? 'download_active' : ''}`} onClick={e => setSelectedFormat('svg')}>SVG</p></div> */}
                <div>
                  <p
                    className={`png_box pointer ${
                      selectFormat == "png" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("png")}
                  >
                    PNG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "jpeg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("jpeg")}
                  >
                    JPEG
                  </p>
                </div>
                <div>
                  <p
                    className={`jpeg_box pointer ${
                      selectFormat == "svg" ? "download_active" : ""
                    }`}
                    onClick={(e) => setSelectedFormat("svg")}
                  >
                    SVG
                  </p>
                </div>
                {/* <div><p className={`webp_box pointer ${selectFormat == 'webp' ? 'download_active' : ''}`} onClick={e => setselectFormat('webp')}>WEBP</p></div> */}
              </div>
              <img ref={canvasRef2} src={downloadimg} alt="" />

              {/* <div style={{width:"100%"}} id="Multicanvas2" className="ml-5"></div> */}

              <div className="modal-footer">
                <button
                  type="button"
                  id="CloseSingalFormatModal"
                  onClick={(e) => setSelectedFormat("")}
                  className="btn btn-clear"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    !selectFormat ? "disable" : ""
                  }`}
                  disabled={!selectFormat}
                  onClick={convertHTMLToImage}
                >
                  Confirm <i className="fa fa-check text-light ml-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default EditFolder;
