import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Frames from "../Frames";
import HomeLogo from "../Logo/HomeLogo";
import PatternColors from "../PatternColors";
import TemplateQrCode from "./TemplateQrCode";

const AddEditTemplate = () => {
  const { id } = useParams();
  const [form, setform] = useState({
    templateName: "",
    value: "",
    staticQrCode: true,
    logoimg: "",
    logosize: "4",
    qrOuterEye: "square",
    qrInnerEye: "square",
    qrBodyPattern: "square",
    qrOuterEyeColor: "#030202",
    qrInnerEyeColor: "#030202",
    qrBodyPatternColor: "#030202",
    backgroundColor: "#FFFFFF",
    qrOuterEyeColorGradient1: "",
    qrOuterEyeColorGradient2: "",
    qrOuterEyeColorGradientType: "linear",
    qrInnerEyeColorGradient1: "",
    qrInnerEyeColorGradient2: "",
    qrInnerEyeColorGradientType: "linear",
    qrBodyPatternGradientColor1: "",
    qrBodyPatternGradientColor2: "",
    qrBodyPatternGradientColorType: "linear",
    backgroundGradientColor1: "",
    backgroundGradientColor2: "",
    backgroundGradientColorType: "linear",
    errorCorrection: "M",
  });
  const [frameForm, setframeForm] = useState({
    width: "250",
    height: "250",
    frame: "",
    qrFrameColor: "#030202",
    qrFrameColorGradient1: "",
    qrFrameColorGradient2: "",
    text: "SCAN ME",
    fontStyle: "",
    textColor: "#3c61e4",
  });
  const [radiobtn, setradiobtn] = useState({
    qrOuterEyeType: true,
    qrInnerEyeType: true,
    backgroundColorType: true,
    qrBodyPatternType: true,
    frameColor: true,
  });

  const resetDesign = () => {
    setform({
      ...form,
      logoimg: "",
      logosize: "4",
      qrOuterEye: "square",
      qrInnerEye: "square",
      qrBodyPattern: "square",
      qrOuterEyeColor: "#030202",
      qrInnerEyeColor: "#030202",
      qrBodyPatternColor: "#030202",
      backgroundColor: "#FFFFFF",
      qrOuterEyeColorGradient1: "",
      qrOuterEyeColorGradient2: "",
      qrOuterEyeColorGradientType: "linear",
      qrInnerEyeColorGradient1: "",
      qrInnerEyeColorGradient2: "",
      qrInnerEyeColorGradientType: "linear",
      qrBodyPatternGradientColor1: "",
      qrBodyPatternGradientColor2: "",
      qrBodyPatternGradientColorType: "linear",
      backgroundGradientColor1: "",
      backgroundGradientColor2: "",
      backgroundGradientColorType: "linear",
      errorCorrection: "M",
    });
    setframeForm({
      width: "250",
      height: "250",
      frame: "",
      qrFrameColor: "#030202",
      qrFrameColorGradient1: "",
      qrFrameColorGradient2: "",
      text: "SCAN ME",
      fontStyle: "",
      textColor: "#3c61e4",
    });
    setradiobtn({
      qrOuterEyeType: true,
      qrInnerEyeType: true,
      backgroundColorType: true,
      qrBodyPatternType: true,
      frameColor: true,
    });
  };

  useEffect(() => {
    if (id) getTemplateDetail();
  }, []);

  const getTemplateDetail = () => {
    loader(true);
    ApiClient.get(`template?id=${id}`).then((res) => {
      if (res.success) {
        setform({
          ...form,
          templateName: res?.data?.name,
          logoimg: res?.data?.design?.logoimg,
          logosize: res?.data?.design?.logosize,
          qrOuterEye: res?.data?.design?.qrOuterEye,
          qrInnerEye: res?.data?.design?.qrInnerEye,
          qrBodyPattern: res?.data?.design?.qrBodyPattern,
          qrOuterEyeColor: res?.data?.design?.qrOuterEyeColor,
          qrInnerEyeColor: res?.data?.design?.qrInnerEyeColor,
          qrBodyPatternColor: res?.data?.design?.qrBodyPatternColor,
          backgroundColor: res?.data?.design?.backgroundColor,
          qrOuterEyeColorGradient1: res?.data?.design?.qrOuterEyeColorGradient1,
          qrOuterEyeColorGradient2: res?.data?.design?.qrOuterEyeColorGradient2,
          qrOuterEyeColorGradientType:
            res?.data?.design?.qrOuterEyeColorGradientType,
          qrInnerEyeColorGradient1: res?.data?.design?.qrInnerEyeColorGradient1,
          qrInnerEyeColorGradient2: res?.data?.design?.qrInnerEyeColorGradient2,
          qrInnerEyeColorGradientType:
            res?.data?.design?.qrInnerEyeColorGradientType,
          qrBodyPatternGradientColor1:
            res?.data?.design?.qrBodyPatternGradientColor1,
          qrBodyPatternGradientColor2:
            res?.data?.design?.qrBodyPatternGradientColor2,
          qrBodyPatternGradientColorType:
            res?.data?.design?.qrBodyPatternGradientColorType,
          backgroundGradientColor1: res?.data?.design?.backgroundGradientColor1,
          backgroundGradientColor2: res?.data?.design?.backgroundGradientColor2,
          backgroundGradientColorType:
            res?.data?.design?.backgroundGradientColorType,
          errorCorrection: res?.data?.design?.errorCorrection,
        });
      }
      loader(false);
    });
  };

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <form>
                <div className="bg-white rounded-4 p-4">
                  <h6 className="fw-bold">Step 1: Template Name</h6>
                  <input
                    type="text"
                    value={form?.templateName}
                    onChange={(e) =>
                      setform({ ...form, templateName: e.target.value })
                    }
                    maxLength="50"
                    className="form-control mt-3"
                    placeholder="Template Name"
                  />
                </div>
              </form>
              <div className="bg-white rounded-4 p-4 mt-4">
                <p className="fw-bold">
                  STEP 2: Design your QR Code{" "}
                  <span className="fw-normal"></span>
                  <span
                    className="text-primary fw-bold float-right pointer"
                    onClick={(e) => resetDesign()}
                  >
                    Reset Design{" "}
                    <img
                      src="/assets/img/reset.png"
                      className=" img-fluid ms-3 "
                      alt="..."
                    />{" "}
                  </span>
                </p>
                <ul
                  className="nav nav-pills mb-4 tab-pills pills_row"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      {" "}
                      <div className="d-flex flex-column  gap-3 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_168_400)">
                            <path
                              d="M12.0006 10C8.5306 10 5.6406 12.54 5.0906 15.86C4.9906 16.46 5.4806 17 6.0906 17C6.5806 17 6.9906 16.64 7.0706 16.15C7.4806 13.79 9.5306 12 12.0006 12C14.4706 12 16.5206 13.79 16.9306 16.15C17.0106 16.64 17.4206 17 17.9106 17C18.5206 17 19.0006 16.46 18.9006 15.86C18.3606 12.54 15.4706 10 12.0006 10ZM12.0006 6C6.3006 6 1.6106 10.34 1.0506 15.9C1.0006 16.49 1.4606 17 2.0606 17C2.5706 17 3.0006 16.62 3.0506 16.12C3.4906 11.57 7.3406 8 12.0006 8C16.6606 8 20.5106 11.57 20.9606 16.12C21.0106 16.62 21.4406 17 21.9506 17C22.5406 17 23.0106 16.49 22.9506 15.9C22.3906 10.34 17.7006 6 12.0006 6Z"
                              fill="#212121"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_168_400">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Logo{" "}
                      </div>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      <div className="d-flex flex-column  gap-3 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_168_408)">
                            <path
                              d="M12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C12.83 21 13.5 20.33 13.5 19.5C13.5 19.11 13.35 18.76 13.11 18.49C12.88 18.23 12.73 17.88 12.73 17.5C12.73 16.67 13.4 16 14.23 16H16C18.76 16 21 13.76 21 11C21 6.58 16.97 3 12 3ZM6.5 12C5.67 12 5 11.33 5 10.5C5 9.67 5.67 9 6.5 9C7.33 9 8 9.67 8 10.5C8 11.33 7.33 12 6.5 12ZM9.5 8C8.67 8 8 7.33 8 6.5C8 5.67 8.67 5 9.5 5C10.33 5 11 5.67 11 6.5C11 7.33 10.33 8 9.5 8ZM14.5 8C13.67 8 13 7.33 13 6.5C13 5.67 13.67 5 14.5 5C15.33 5 16 5.67 16 6.5C16 7.33 15.33 8 14.5 8ZM17.5 12C16.67 12 16 11.33 16 10.5C16 9.67 16.67 9 17.5 9C18.33 9 19 9.67 19 10.5C19 11.33 18.33 12 17.5 12Z"
                              fill="#212121"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_168_408">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Pattern & Colors{" "}
                      </div>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      <div className="d-flex flex-column gap-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_168_415)">
                            <path
                              d="M20 3.99998H16L12.71 0.70998C12.32 0.31998 11.69 0.31998 11.3 0.70998L8 3.99998H4C2.9 3.99998 2 4.89998 2 5.99998V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V5.99998C22 4.89998 21.1 3.99998 20 3.99998ZM19 20H5C4.45 20 4 19.55 4 19V6.99998C4 6.44998 4.45 5.99998 5 5.99998H8.52L12.04 2.49998L15.52 5.99998H19C19.55 5.99998 20 6.44998 20 6.99998V19C20 19.55 19.55 20 19 20ZM17 7.99998H7C6.45 7.99998 6 8.44998 6 8.99998V17C6 17.55 6.45 18 7 18H17C17.55 18 18 17.55 18 17V8.99998C18 8.44998 17.55 7.99998 17 7.99998Z"
                              fill="#212121"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_168_415">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Frame{" "}
                      </div>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <HomeLogo setform={setform} form={form} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                    <PatternColors
                      setform={setform}
                      form={form}
                      setradiobtn={setradiobtn}
                      radiobtn={radiobtn}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                    tabindex="0"
                  >
                    <Frames
                      frameForm={frameForm}
                      setframeForm={setframeForm}
                      radiobtn={radiobtn}
                      setradiobtn={setradiobtn}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <TemplateQrCode form={form} id={id} frameForm={frameForm} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddEditTemplate;
