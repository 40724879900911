import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout"
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";

const Templates = () => {
    const [data, setdata] = useState([])
    const [filter, setfilter] = useState({ page: 1, count: 9 })
    const [total, settotal] = useState()
    const history = useHistory()
    const user=useSelector((state)=>state.user)

    useEffect(() => {
        getData()
    }, [])

    const getData = (p = {}) => {
        let filters = { ...filter, ...p ,user_id:user?.id}
        loader(true)
        ApiClient.get(`template/all`, filters).then(res => {
            if (res.success) {
                setdata(res?.data?.data)
                settotal(res?.data?.total)
            }
            loader(false)
        })
    }

    const handleTemplates = (id, type) => {
        if (type == 'delete') {
            if (window.confirm(`Do you really want to delete this template`)) {
                ApiClient.delete(`template?id=${id}`).then(res => {
                    if (res.success) {
                        toast.success(res.message)
                        getData()
                    }
                })
            }
        }
    }

    const handlePagination = (e) => {
        setfilter({ ...filter, page: e })
        getData({ page: e })
    }

    return (
        <>
            <Layout>
                <div className="container-fluid">
                    <div className="row">
                        {data && data.map((item, index) => {
                            return <div className="col-md-4 mb-4">
                                <div class="card w-100">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between border_bottom">
                                            <p className="fw-bold">{item?.name}</p>
                                            <div className="d-flex align-items-center">
                                                <i className="fa fa-pen text-primary mr-3" onClick={e=>{ history.push(`/app/template/edit/${item?.id}`) }} title="Edit"></i>
                                                <div class="dropdown d-flex align-items-center">
                                                <span class='material-icons pointer' data-bs-toggle="dropdown" aria-expanded="false">more_horiz</span>
                                                    <ul class="dropdown-menu">
                                                        <li><a onClick={e => handleTemplates(item?.id, 'delete')} class="dropdown-item">Delete</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item qr_images">
                                            <img src={item?.image} width='100%' />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        })}
                    </div>
                    {filter?.count < total ?
                        <div className="d-flex justify-content-between align-items-center ml-2 mt-3">
                            <div><b>{total}</b> Templates</div>
                            <div className="d-flex justify-content-center">
                                <Pagination
                                    activePage={filter?.page}
                                    itemsCountPerPage={filter?.count}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={1}
                                    onChange={handlePagination}
                                    hideFirstLastPages={true}
                                />
                            </div>
                            <div></div>
                        </div>
                        : null}
                </div>
            </Layout>
        </>
    )
}

export default Templates