import React, { useEffect, useRef, useState } from "react";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import ReactECharts from "echarts-for-react";
import loader from "../../methods/loader";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import environment from "../../environment";
import axios from "axios";
import { toast } from "react-toastify";
const Analytics = () => {
  const user = useSelector((state) => state.user);
  const [overview, setoverview] = useState({
    uniqueScan: 0,
    totalScans: 0,
    qrScannes: 0,
  });
  const [UniqueScans, setUniqueScan] = useState(0);
  const [TotalScans, SetTotalScans] = useState(0);
  const [TotalQRScanns, setTotalQRScanned] = useState({
    previousMonthdata: 0,
    totalQrcodeScan: 0,
  });
  const [ScannhISTORY, sETScannhISTORY] = useState(0);
  const [FinalScanned, setFinalScanned] = useState(0);
  const [TotalPercentageScan, setTotalPercentageScan] = useState(0);
  const [ScansOverTime, setScansOverTime] = useState([]);
  const [peakScansByDay, setpeakScansByDay] = useState([]);
  const [totalScans, setTotalScans] = useState(0);
  const ActivePlan = useSelector((state) => state.activePlan);
  const [TopPerformingQrCodes, setTopPerformingQrCodes] = useState([]);
  const [ScanByDevices, setScanByDevices] = useState([]);
  const [ScanByOS, setScanByOS] = useState([]);
  const [ScansByTopCities, setScansByTopCities] = useState([]);
  const [Arr, setArr] = useState([]);
  const [TotalQR, setTotalQR] = useState(0);
  const [ScansByTopCountries, setScansByTopCountries] = useState([]);
  const ref = useRef(null);
  const [FinalData, setFinalData] = useState([]);
  const [UniquePercent, setUniquePercent] = useState(0);
  const [TotalPercent, setTotalPercent] = useState(0);
  const [AnalyticsData, setAnalyticsdata] = useState({});
  const GetAnalytics = () => {
    ApiClient.get("qrAnalytics", { user_id: user?.id }).then((res) => {
      if (res.success) {
        setAnalyticsdata(res?.data);
        setTotalQR(res?.data?.get_active_count + res?.data?.get_stastic_count);
      }
    });
  };
  const [Filter, setFilter] = useState({
    status: "",
    qrtype: "",
    medium: "",
    start: "",
    end: "",
  });

  const GetTotalQRScanned = () => {
    ApiClient.get(`findScanQrcode?user_id=${user?.id}`).then((res) => {
      if (res.success) {
        let currentCal = res?.data?.totalQrcodeScan ?? 0;
        let prevCal = res?.data?.previousMonthdata ?? 0;
        let calculated = currentCal - prevCal;
        if (currentCal == 0 && prevCal == 0) {
          calculated = 0;
        } else if (currentCal == 0) {
          calculated = -100;
        } else {
          calculated = (calculated / currentCal) * 100;
        }
        setTotalPercentageScan(calculated);
        setTotalQRScanned({
          previousMonthdata: res?.daata?.previousMonthdata,
          totalQrcodeScan: res?.data?.totalQrcodeScan,
        });
      }
    });
  };
  const [types, settypes] = useState([]);
  const [handleDateFilter, sethandleDateFilter] = useState(false);
  const [dateFilter, setdateFilter] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  // const [DateFilter,setDateFilter]=useState() key: 'selection'
  const [selectFormat, setSelectedFormat] = useState("");

  useEffect(() => {
    GetTotalQRScanned();
    GetAnalytics();
    getUniqueAndTotalScans();
    getQrScannedTotal();
    getScansOverTime();
    getPeakScansByDay();
    getTopPerformingQrCodes();
    getScansByDevices();
    getScansByOS();
    getScansByTopCities();
    getScansByTopCountries();
  }, []);

  // Overview Total
  const getUniqueAndTotalScans = (p = {}) => {
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`scanAnalytics`, newfilter).then((res) => {
      if (res.success) {
        setUniqueScan(parseInt(res?.data[1]?.total_previousMonths_uniqueScan));
        SetTotalScans(
          parseInt(res?.data?.[0]?.totalCount) -
            parseInt(res?.data[1]?.total_previousMonths_totalCount)
        );
        let currentCal = res?.data?.[0]?.totalCount ?? 0;
        let prevCal = res?.data[1]?.total_previousMonths_totalCount ?? 0;
        let calcultate = currentCal - prevCal;
        if (currentCal == 0 && prevCal == 0) {
          calcultate = 0;
        } else if (currentCal == 0) {
          calcultate = -100;
        } else {
          calcultate = (calcultate / currentCal) * 100;
        }
        setTotalPercent(calcultate);

        let currentCal2 = res?.data?.[0]?.uniqueScan ?? 0;
        let prevCal2 = res?.data[1]?.total_previousMonths_uniqueScan ?? 0;
        let calculated = currentCal2 - prevCal2;
        if (currentCal2 == 0 && prevCal2 == 0) {
          calculated = 0;
        } else if (currentCal2 == 0) {
          calculated = -100;
        } else {
          calculated = (calculated / currentCal2) * 100;
        }
        setUniquePercent(calculated);
        setoverview((previous) => ({
          ...previous,
          uniqueScan: parseInt(res?.data?.[0]?.uniqueScan),
          totalScans: parseInt(res?.data?.[0]?.totalCount),
        }));
      }
    });
  };
  const getQrScannedTotal = (p = {}) => {
    const newfilter = { ...Filter, ...p, user_id: user?.id };
    ApiClient.get(`qrcode/all`, newfilter).then((res) => {
      if (res.success) {
        let currentCal = res?.data?.total ?? 0;
        let prevCal = res?.data?.total_peviousData ?? 0;
        let calculated = currentCal - prevCal;
        if (currentCal == 0 && prevCal == 0) {
          calculated = 0;
        } else if (currentCal == 0) {
          calculated = -100;
        } else {
          calculated = (calculated / currentCal) * 100;
        }
        setFinalScanned(calculated);

        sETScannhISTORY(
          parseInt(res?.data?.total) - parseInt(res?.data?.total_peviousData)
        );
        setoverview((previous) => ({
          ...previous,
          qrScannes: res?.data?.total,
        }));
      }
    });
  };

  useEffect(() => {}, []);
  // Scan Over Time Chart
  // const scansOverTimeData = [
  //     ["", "Unique Scans", "Total Scans"],
  //     ...(ScansOverTime && ScansOverTime?.map(item => {
  //         return [`${item?.monthName}`, item?.uniqueScan, item?.total_scans]
  //     }) || [
  //         ["Element", "Density", { role: "style" }],
  //         ["Copper", 8.94, "#b87333"], // RGB value
  //         ["Silver", 10.49, "silver"], // English color name
  //         ["Gold", 19.3, "gold"],
  //         ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
  //     ])
  // ];
  const scansOverTimeData = [
    ["", "Unique Scans", "Total Scans"],
    ...((ScansOverTime?.length > 0 &&
      ScansOverTime?.map((item) => {
        return [`${item?.monthName}`, item?.uniqueScan, item?.total_scans];
      })) || [
      ["Element", "Density", { role: "style" }],
      ["Copper", 8.94, "#b87333"], // RGB value
      ["Silver", 10.49, "silver"], // English color name
      ["Gold", 19.3, "gold"],
      ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ]),
  ];
  const scansOverTimeOption = {
    colors: ["#42a2eb", "#4bc0bf"],
    annotations: {
      alwaysOutside: true,
    },
    legend: { position: "top" },
    vAxis: {
      format: "0", // Display integers only on the vertical axis
    },
  };

  const getScansOverTime = (p = {}) => {
    loader(true);
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`analytics/scan/over-time`, newfilter).then((res) => {
      if (res.success) {
        setScansOverTime(res?.data?.data);
      }
      loader(false);
    });
  };
  let arr = [];
  // Peak hour scans by day
  const getPeakScansByDay = (p = {}) => {
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`analytics/scan/by-day`, newfilter).then((res) => {
      if (res.success) {
        setpeakScansByDay(res?.data?.data);

        // Assuming your total scans data is in res.data.total
        setTotalScans(res?.data?.total || 0);
        res?.data?.data?.map((itm) => {
          arr.push([itm?.dayOfWeek, itm?.hour, itm?.total_scans]);
        });
        setArr(arr);
      }
    });
  };
  let array = [];
  peakScansByDay &&
    peakScansByDay?.map((itm) => {
      array.push(itm?.hour, itm?.total_scans, itm?.dayOfWeek);
    });
  let Elements = [];
  peakScansByDay?.map((itm) =>
    Elements?.push([itm?.dayOfWeek, itm?.hour, itm?.total_scans || "-"])
  );

  useEffect(() => {}, []);
  const generateHeatmapData = (xAxisData, yAxisData, minValue, maxValue) => {
    const data = [];

    xAxisData.forEach((xValue, xIndex, value) => {
      yAxisData.forEach((yValue, yIndex) => {
        const value =
          Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
        data.push([xIndex, yIndex, value]);
      });
    });
    return data;
  };

  const data = Arr.map(function (item) {
    return [item[1] > 9 ? `${item[1]}:00` : `0${item[1]}:00`,item[0] == 0 ? 1 : item[0] == 2 ? 6 : item[0] == 1 ? 7 :item[0] == 3 ? 5 :item[0] == 4 ? 4 :item[0] == 5 ? 3 : item[0] == 6 ? 2 : ''  ,item[2] || "-"];
  });

  const peakScansOption = {
    tooltip: {
      position: "top",
      // formatter: params => {
      //     return `Total Scans: ${totalScans}`;
      // },
    },
    xAxis: {
      type: "category",
      data: [
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
      ], // Assuming hours of the day
    },
    yAxis: {
      type: "category",
      data: ["Sun", "Sat", "Fri", "Thu", "Wed", "Tue", "Mon"],
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: 0,
      max: 50,
      calculable: false,
      orient: "horizontal",
      left: "center",
      bottom: "0%",
    },
    series: [
      {
        name: "Total Scans",
        type: "heatmap",
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "#ffffff",
          },
        },
      },
    ],
  };

  // Geo Chart
  const geoChartData = [
    ["Country", "Total Scans"],
    ...(ScansByTopCountries &&
      ScansByTopCountries?.map((item) => {
        return [`${item?.country}`, item?.total_scans];
      })),
  ];

  // Top Performing Qr Codes
  const getTopPerformingQrCodes = (p = {}) => {
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`analytics/scan/by-campaign`, newfilter).then((res) => {
      if (res.success) {
        setTopPerformingQrCodes(res?.data?.data);
      }
    });
  };

  // Get Scans By Top Cities
  const getScansByTopCities = (p = {}) => {
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`analytics/scan/by-city`, newfilter).then((res) => {
      if (res.success) {
        setScansByTopCities(res?.data?.data);
      }
    });
  };

  // Get Scans By Top Countries
  const getScansByTopCountries = (p = {}) => {
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`analytics/scan/by-country`, newfilter).then((res) => {
      if (res.success) {
        setScansByTopCountries(res?.data?.data);
      }
    });
  };

  // Get Scans By Devices
  const getScansByDevices = (p = {}) => {
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`analytics/scan/by-device`, newfilter).then((res) => {
      if (res.success) {
        setScanByDevices(res?.data?.data);
      }
    });
  };

  // Get Scans By OS
  const getScansByOS = (p = {}) => {
    const newfilter = { ...Filter, ...p, userId: user?.id };
    ApiClient.get(`analytics/scan/by-os`, newfilter).then((res) => {
      if (res.success) {
        setScanByOS(res?.data?.data);
      }
    });
  };

  const findDevice = () => {
    const iphoneRegex = /iPhone/i;
    const ipadRegex = /iPad/i;
    const samsungRegex = /Samsung/i;
    const motoRegex = /Moto/i;
    const motorolaRegex = /Motorola/i;
    const huaweiRegex = /Huawei/i;
    const realmeRegex = /Realme/i;
    const vivoRegex = /Vivo/i;
    const oppoRegex = /Oppo/i;
    const pocoRegex = /Poco/i;
    const linuxRegex = /Linux/i;
    const windowsRegex = /Windows/i;
    const macRegex = /Macintosh|Mac OS X/i;

    if (iphoneRegex.test(userAgent)) {
      return "iPhone";
    } else if (samsungRegex.test(userAgent)) {
      return "Samsung";
    } else if (ipadRegex.test(userAgent)) {
      return "iPad";
    } else if (motoRegex.test(userAgent) || motorolaRegex.test(userAgent)) {
      return "Motorola";
    } else if (huaweiRegex.test(userAgent)) {
      return "Huawei";
    } else if (realmeRegex.test(userAgent)) {
      return "Realme";
    } else if (vivoRegex.test(userAgent)) {
      return "Vivo";
    } else if (oppoRegex.test(userAgent)) {
      return "Oppo";
    } else if (pocoRegex.test(userAgent)) {
      return "Poco";
    } else if (linuxRegex.test(userAgent)) {
      return "Linux";
    } else if (windowsRegex.test(userAgent)) {
      return "Windows";
    } else if (macRegex.test(userAgent)) {
      return "macOS";
    } else {
      return "Unknown Device";
    }
  };
  const getAllTypes = () => {
    ApiClient.get(`qrtype/all?status=active`).then((res) => {
      if (res.success) {
        settypes(
          res?.data?.map((item) => {
            return { value: item?.slug, label: item?.type };
          })
        );
      }
    });
  };
  const handleDownloadCSV = async () => {
    // Construct the API URL
    const apiUrl = `analytics/scan/by-campaign?status=&type=&medium=&start=&end=&userId=${user?.id}&exports_to_xls=yes`;
    await axios({
      method: "get",
      url: environment.api + apiUrl,
      responseType: "blob",
      body: {
        token: localStorage.getItem("token"),
      },
    }).then((res) => {
      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "AnalyticsData,setAnalyticsdata. = useState({})xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  const GetTypeValue = (value) => {
    let data = types.find((item) => item.value == value);
    return data || "";
  };

  useEffect(() => {
    getAllTypes();
  }, []);

  const GetAllData = (p = {}) => {
    setFilter({ ...Filter, ...p });
    getUniqueAndTotalScans(p);
    getQrScannedTotal(p);
    getScansOverTime(p);
    getPeakScansByDay(p);
    getTopPerformingQrCodes(p);
    getScansByDevices(p);
    getScansByOS(p);
    getScansByTopCities(p);
    getScansByTopCountries(p);
  };

  const clearAnalyticsDateFilter = () => {
    setdateFilter([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
    setFilter({ ...Filter, start: "", end: "" });
  };

  const confirmAnalyticsDateFilter = () => {
    sethandleDateFilter(false);
    setFilter({
      ...Filter,
      start: moment(dateFilter[0].startDate).format("YYYY-MM-DD"),
      end: moment(dateFilter[0].endDate).format("YYYY-MM-DD"),
    });
  };

  const ResetFilter = () => {
    setFilter({ status: "", qrtype: "", medium: "", start: "", end: "" });
    setdateFilter([
      {
        startDate: "",
        endDate: "",
        key: "selection",
      },
    ]);
    GetAllData({ status: "", qrtype: "", medium: "", start: "", end: "" });
  };

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="graf_Section mb-3">
            <div className="filters_row">
              <h1>Filter</h1>
              <div className="text_filter">
                <h3 className="mr-3" onClick={(e) => ResetFilter()}>
                  Reset Filter{" "}
                  <span className="material-icons ml-2">clear_all</span>
                </h3>
                <h3 className="bule_h3" onClick={(e) => GetAllData()}>
                  Apply <span className="material-icons ml-2">done</span>
                </h3>
              </div>
            </div>
            <div className="row_form2">
              <div className="row">
                <div className="col-md-3">
                  <label>Status</label>
                  <select
                    value={Filter?.status}
                    onChange={(e) =>
                      setFilter({ ...Filter, status: e.target.value })
                    }
                    className="form-select"
                  >
                    <option value="">All Status</option>
                    <option value="staticQrcode">Static</option>
                    <option value="dynamicQrcode">Dynamic </option>
                  </select>
                </div>
                <div className="col-md-3">
                  <label>Type</label>
                  <Select
                    className=""
                    value={GetTypeValue(Filter.qrtype)}
                    onChange={(e) => setFilter({ ...Filter, qrtype: e.value })}
                    options={types}
                  />
                </div>
                {/* <div className="col-md-6 col-lg-3">
                                    <label>Medium</label>
                                    <input placeholder="Medium" value={Filter?.medium} onChange={e=>setFilter({...Filter,medium:e.target.value})}/>

                                </div> */}
                <div className="col-md-3">
                  <label>Medium</label>
                  <input
                    type="text"
                    className="form-control"
                    value={Filter?.medium}
                    onChange={(e) =>
                      setFilter({ ...Filter, medium: e.target.value })
                    }
                    placeholder="e.g. email, banner, bus stop"
                  />
                </div>
                {/* <div className="col-md-6 col-lg-3 mb-3">
                                    <label>Date Range</label>
                                    <select className="form-select">
                                        <option>August 29 - November 28</option>
                                        <option>Andy 6</option>
                                    </select>
                                </div> */}
                <div className="col-md-3">
                  <label>Date</label>
                  <div className="select border position-relactive pointer rounded">
                    <span
                      class="form-select position-relactive pointer date_select"
                      onClick={(e) => sethandleDateFilter(!handleDateFilter)}
                      onBlur={(e) => sethandleDateFilter(false)}
                    >
                      {dateFilter?.[0]?.startDate || dateFilter?.[0]?.endDate
                        ? `${moment(dateFilter?.[0]?.startDate).format(
                            "MMMM DD, YYYY"
                          )} - ${moment(dateFilter?.[0]?.endDate).format(
                            "MMMM DD, YYYY"
                          )}`
                        : "Select Date Range"}
                    </span>
                    <div className="position-absolute">
                      {handleDateFilter ? (
                        <div className="analytics_date_filter">
                          <DateRangePicker
                            onChange={(item) => {
                              setdateFilter([
                                {
                                  startDate: item.selection.startDate,
                                  endDate: item.selection.endDate,
                                  key: "selection",
                                },
                              ]);
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateFilter}
                            direction="horizontal"
                            className="rounded"
                            startDatePlaceholder="Date"
                            endDatePlaceholder="Date"
                          />
                          <div className="text-right mr-3 mb-1 mt-0">
                            <button
                              className="btn btn-light light_btn mx-2"
                              onClick={(e) => clearAnalyticsDateFilter()}
                            >
                              <span className="d-flex">
                                Clear{" "}
                                <span className="material-icons">
                                  clear_all
                                </span>
                              </span>
                            </button>
                            <button
                              className="btn btn-primary mx-2"
                              onClick={(e) => confirmAnalyticsDateFilter()}
                            >
                              Confirm <span className="fa fa-check ml-1"></span>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="graf_Section mb-3">
            <div className="filters_row">
              <h1>Analytics</h1>
              <div className="text_filter">
                <button
                  className="btn primary_btn mr-2"
                  onClick={() => {
                    if (
                      ActivePlan?.name == "starter" ||
                      ActivePlan?.name == "free"
                    ) {
                      toast.error("CSV download not include in your plan");
                    } else {
                      handleDownloadCSV();
                    }
                  }}
                >
                  Download CSV{" "}
                  <span className="material-icons ml-2">text_snippet</span>
                </button>
              </div>
            </div>
            <div className="row_form2">
              <div className="row mx-0">
                <div className="col-md-12 col-lg-8 mb-3">
                  <h1 className="heading_h1row">Overview</h1>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="row_design">
                        <h1>UNIQUE SCANS</h1>
                        <h2 className="color_1">{overview?.uniqueScan || 0}</h2>
                        <p className="mb-0">
                          <span className="material-icons">
                            {UniquePercent <= 0 ? "trending_down" : "moving"}
                          </span>{" "}
                          <span>
                            <span
                              className={`plus_item ${
                                UniquePercent <= 0 ? "red_item" : ""
                              }`}
                            >
                              {parseInt(UniquePercent)}%
                            </span>
                            vs. previous period
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="row_design row_design2">
                        <h1>TOTAL SCANS</h1>
                        <h2 className="color_1 color_2">
                          {overview?.totalScans || 0}
                        </h2>
                        <p className="mb-0">
                          <span className="material-icons">
                            {TotalPercent <= 0 ? "trending_down" : "moving"}
                          </span>{" "}
                          <span>
                            <span
                              className={`plus_item ${
                                TotalPercent <= 0 ? "red_item" : ""
                              }`}
                            >
                              {parseInt(TotalPercent)}%
                            </span>
                            vs. previous period
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="row_design row_design3">
                        <h1>QR CODES SCANNED</h1>
                        <h2 className="color_1 color_3">
                          {TotalQRScanns?.totalQrcodeScan || 0}
                        </h2>
                        <p className="mb-0">
                          <span className="material-icons">
                            {parseInt(TotalPercentageScan) <= 0
                              ? "trending_down"
                              : "moving"}
                          </span>{" "}
                          <span>
                            <span
                              className={`plus_item ${
                                parseInt(TotalPercentageScan) <= 0
                                  ? "red_item"
                                  : ""
                              } `}
                            >
                              {parseInt(TotalPercentageScan)}%
                            </span>
                            vs. previous period
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="design_scan">
                    <h1 className="heading_h1row">Scan over time</h1>
                    <div className="border_box">
                      {ScansOverTime?.length > 0 ? (
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="350px"
                          data={scansOverTimeData}
                          options={scansOverTimeOption} //
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 mb-3">
                  <h1 className="heading_h1row">Top performing QR Codes</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th>Campaign Name</th>
                          <th>Unique</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {TopPerformingQrCodes &&
                          TopPerformingQrCodes.map((item) => {
                            return (
                              <tr>
                                <td>{item?.campaign_name || "--"}</td>
                                <td>{item?.uniqueScan || "0"}</td>
                                <td>{item?.total_scans || "0"}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <h1 className="heading_h1row">Peak hour scans by day</h1>
                  <div className="border_box">
                    <ReactECharts
                      className=""
                      ref={ref}
                      option={peakScansOption}
                      style={{ height: "400px", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by device</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th className="heading-th">Device</th>
                          <th className="">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScanByDevices &&
                          ScanByDevices?.map((item) => {
                            return (
                              <tr>
                                <td>{item?.device}</td>
                                <td>
                                  <div className="progress progress_bar">
                                    <span className="span-number">
                                      {item?.total_scans}
                                    </span>
                                    <div
                                      className="progress-bar progress_row "
                                      style={{
                                        width: `${
                                          (item?.percentage / 100) * 100
                                        }%`,
                                      }}
                                      role="progressbar"
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by operating system</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th className="heading-th">OS</th>
                          <th className="">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScanByOS &&
                          ScanByOS?.map((item) => {
                            return (
                              <tr>
                                <td>{item?.os || "Unknown OS"}</td>
                                <td>
                                  <div className="progress progress_bar">
                                    <span className="span-number">
                                      {item?.total_scans}
                                    </span>
                                    <div
                                      className="progress-bar progress_row "
                                      style={{
                                        width: `${
                                          (item?.percentage / 100) * 100
                                        }%`,
                                      }}
                                      role="progressbar"
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <h1 className="heading_h1row">Scans by location</h1>
                  <div className="border_box">
                    <Chart
                      chartType="GeoChart"
                      width="100%"
                      height="400px"
                      data={geoChartData}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by top countries</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Country</th>
                          <th className="scans_div">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScansByTopCountries &&
                          ScansByTopCountries?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.country || "--"}</td>
                                <td>{item?.total_scans || "0"}</td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h1 className="heading_h1row">Scans by top cities</h1>
                  <div className="table_box">
                    <table className="table_design w-100">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>City</th>
                          <th className="scans_div">Scans</th>
                          <th className="td_table">%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ScansByTopCities &&
                          ScansByTopCities?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.city || "--"}</td>
                                <td>{item?.total_scans || "0"}</td>
                                <td>
                                  {parseFloat(item?.percentage).toFixed(2)}%
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Analytics;
