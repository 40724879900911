import React, { useEffect, useState } from "react";
import "./blogstyle.scss";
import Header from "../../components/global/header";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import environment from "../../environment";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";

import Footer from "../../components/global/Footer";

const Blogs = () => {
  const [BlogList, setBlogList] = useState([]);
  const Navigate = useHistory();
  const [BlogFilters, setBlogFilters] = useState({
    page: 1,
    count: 10,
    search: "",
    status: "active",
  });
  const [total, settotal] = useState(0);
  const [searchBlog, setsearchBlog] = useState();

  const GetAllList = (p = {}) => {
    let fil = { ...BlogFilters, ...p };
    loader(true);
    ApiClient.get(`blog/all`, fil).then((res) => {
      if (res.success) {
        setBlogList(res?.data);
        settotal(res?.total);
      }
      loader(false);
    });
  };

  useEffect(() => {
    GetAllList();
  }, []);

  const BlogImageHandler = (image) => {
    return image == ""
      ? "/assets/img/qr-images/blog.jpeg"
      : environment.api + "images/blog/" + image;
  };

  const handleBlogSearch = (e) => {
    e.preventDefault();
    setBlogFilters({ ...BlogFilters, search: searchBlog });
    GetAllList({ search: searchBlog });
  };

  const handlePagination = (e) => {
    setBlogFilters({ ...BlogFilters, page: e });
    GetAllList({ page: e });
  };

  return (
    <>
      <Header />
      <section className="blogs-content">
        {/* CTA Section */}
        <div className="cta-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 blog-blue">
                <div className="container-fluid h-100">
                  <div className="blogs-left-content">
                    <h2 className="second-head">QR Bubble Blog</h2>
                    <h3 className="third-head four-head">
                      Unlock the full potential of QR{" "}
                    </h3>
                    <h3 className="third-head four-head">
                      Codes with the latest tips and news
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 blog-black">
                <div className="container-fluid h-100">
                  <div className="blogs-right-content">
                    <h6 className="sixth-head">Subscribe now</h6>
                    <p className="common-parah">
                      Join our mailing list to receive the latest news, special
                      offers, and QR tips{" "}
                    </p>
                    <div className="form-wrapper">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your email"
                        className="form-control"
                      />
                      <button type="submit" className="btn   blue-btn">
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Blogs Tabs  */}
        <div className="blog-detail-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <nav class="navbar navbar-expand-lg p-0">
                  <i
                    class="fa fa-bars navbar-toggler bars_blog"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarBlog"
                    aria-controls="navbarBlog"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    aria-hidden="true"
                  ></i>
                  <div
                    class="nav_dropdown collapse navbar-collapse"
                    id="navbarBlog"
                  >
                    <div className="sidebar-tabs">
                      <div className="d-flex align-items-start flex-column">
                        <form
                          onSubmit={handleBlogSearch}
                          className="btns_wrap d-flex mb-4"
                        >
                          <div className="searchRow">
                            <input
                              value={searchBlog}
                              onChange={(e) => setsearchBlog(e.target.value)}
                              className="form-control"
                              placeholder="Search.."
                            />
                            {searchBlog && (
                              <span
                                class="material-icons"
                                onClick={() => {
                                  setsearchBlog("");
                                }}
                              >
                                close
                              </span>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Search
                          </button>
                        </form>
                        <div
                          className="nav flex-column nav-pills me-3"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <button
                            className="nav-link active"
                            id="v-pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-home"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-home"
                            aria-selected="true"
                          >
                            All Articles
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-profile"
                            aria-selected="false"
                          >
                            Use Cases
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-messages-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-messages"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-messages"
                            aria-selected="false"
                          >
                            Guides
                          </button>
                          <button
                            className="nav-link"
                            id="v-pills-settings-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-settings"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-settings"
                            aria-selected="false"
                          >
                            Industry Trends
                          </button>
                        </div>
                        <div className="qr-code-generate">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="free-qr-code">
                                <h6 className="sixth-head">
                                  Create static QR Codes for FREE with QR BUBBLE
                                </h6>
                                <a href="#" className="btn light-btn">
                                  Generate QR Code
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-lg-9">
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="form-row">
                    {BlogList.map((item, index) => (
                      <div
                        className={`col-md-${index == 0 ? "12" : "4"} mb-3`}
                        onClick={(e) =>
                          Navigate.push(`/blog/detail/${item.id}`)
                        }
                      >
                        <div
                          className={
                            index == 0 ? "main-blog ma" : "small-blog ma"
                          }
                        >
                          <img
                            src={BlogImageHandler(item.image)}
                            className={index == 0 ? "big_image" : "small_image"}
                          />
                          <div className="main-blog-info  blog-info">
                            <h3 className="third-head four-head">
                              {item.heading}
                            </h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                            <div
                              className={
                                index == 0
                                  ? "main-author blog-author"
                                  : "blog-author"
                              }
                            >
                              <span className="common-span">{item.author}</span>
                              <span className="common-span">
                                {" "}
                                {moment(item.createdAt).format("MMM Do, YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {BlogFilters?.count < total ? (
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={BlogFilters?.page}
                      itemsCountPerPage={BlogFilters?.count}
                      totalItemsCount={total}
                      pageRangeDisplayed={1}
                      onChange={handlePagination}
                      hideFirstLastPages={true}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Blogs;
