import React, { useEffect } from "react";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import configureStore from "./config";
import { createBrowserHistory } from "history";
import Login from "./pages/Login";
import "./scss/main.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import SignUp from "./pages/Signup";
import Forgotpassword from "./pages/Forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import { Auth } from "./methods/auth";
export const history = createBrowserHistory();
/************ store configration *********/
import Logo from "./pages/Logo/Logo";
import AccountSetting from "./pages/AccountSetting";
import Blogs from "./pages/Blog/Blogs";
import BlogDetail from "./pages/Blog/BlogDetail";
import FAQ from "./pages/FAQ";
import Contactus from "./pages/ContactUs";
import FolderLisitng from "./pages/Folder/FolderListing";
import BulkQrCode from "./pages/BulkQrCode";
import QrCodeScanner from "./pages/QrCodeScanner";
import QrCodes from "./pages/QrCode/QrCodes";
import QrScanner from "./pages/QrCodeScanner/QrScanner";
import QrCodeListing from "./pages/QrCode/QrCodeListing";
import Trash from "./pages/Trash";
import AddFolder from "./pages/Folder/AddFolder";
import EditFolder from "./pages/Folder/EditFolder";
import Templates from "./pages/Templates";
import AddEditTemplate from "./pages/Templates/AddEditTemplate";
import EditQrCodes from "./pages/QrCode/EditQrCodes";
import Testing from "./pages/Testing";
import Plans from "./pages/Plans";
import UserBulkQrCode from "./pages/BulkQrCode/UserBulkQrCode";
import Cards from "./pages/Card";
import Analytics from "./pages/Analytics";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import ReferralAffiliate from "./pages/ReferralAffiliate";
import VcardLandingPage from "./pages/VcardLandingPage";

const { persistor, store } = configureStore(history);

export default () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Router>
              <Switch>
                <Route
                  exact={true}
                  store={store}
                  path="/login"
                  component={Login}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/signup"
                  component={SignUp}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/forgotpassword"
                  component={Forgotpassword}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/resetpassword"
                  component={Resetpassword}
                />

                {/* Account Setting */}
                <Route
                  exact={true}
                  store={store}
                  path="/app/accountsetting"
                  component={AccountSetting}
                />

                {/* Blog */}
                <Route
                  exact={true}
                  store={store}
                  path="/blog"
                  component={Blogs}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/blog/detail/:id"
                  component={BlogDetail}
                />

                {/* FAQ */}
                <Route exact={true} store={store} path="/faq" component={FAQ} />

                {/* Contact Us */}
                <Route
                  exact={true}
                  store={store}
                  path="/contact"
                  component={Contactus}
                />

                {/* Card */}
                <Route
                  exact={true}
                  store={store}
                  path="/cards/:id/:stripeId"
                  component={Cards}
                />

                {/* Folder */}
                <Route
                  exact={true}
                  store={store}
                  path="/app/folder"
                  component={FolderLisitng}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/folder/add"
                  component={AddFolder}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/folder/edit/:id"
                  component={EditFolder}
                />

                {/* Bulk Qr Code */}
                <Route
                  exact={true}
                  store={store}
                  path="/bulk-qr-code"
                  component={BulkQrCode}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/bulkqr"
                  component={UserBulkQrCode}
                />

                {/* Qr Code Scanner */}
                <Route
                  exact={true}
                  store={store}
                  path="/qr-code-scanner"
                  component={QrCodeScanner}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/qrscanner"
                  component={QrScanner}
                />

                {/* Qr Codes with Types */}
                <Route
                  exact={true}
                  store={store}
                  path="/app/qrcodes"
                  component={QrCodes}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/qrcodes/:slug"
                  component={QrCodes}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/qrcodes/:slug/:qrCodeSlug"
                  component={QrCodes}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/qrcodelisting"
                  component={QrCodeListing}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/qrcode/edit/:slug"
                  component={EditQrCodes}
                />

                {/* Trash Qr Codes */}
                <Route
                  exact={true}
                  store={store}
                  path="/app/trash"
                  component={Trash}
                />

                {/* Templates */}
                <Route
                  exact={true}
                  store={store}
                  path="/app/template"
                  component={Templates}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/template/add"
                  component={AddEditTemplate}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/app/template/edit/:id"
                  component={AddEditTemplate}
                />

                {/* Plans */}
                <Route
                  exact={true}
                  store={store}
                  path="/pricing"
                  component={Plans}
                />

                {/* Analytics */}
                <Route
                  exact={true}
                  store={store}
                  path="/app/analytics"
                  component={Analytics}
                />

                {/* Terms Of Use */}
                <Route
                  exact={true}
                  store={store}
                  path="/terms"
                  component={TermsOfUse}
                />

                {/* Privacy Policy */}
                <Route
                  exact={true}
                  store={store}
                  path="/privacy"
                  component={PrivacyPolicy}
                />

                {/* Cookie Policy */}
                <Route
                  exact={true}
                  store={store}
                  path="/cookie-policy"
                  component={CookiePolicy}
                />

                {/* Referral & Affiliate */}
                <Route
                  exact={true}
                  store={store}
                  path="/app/referral"
                  component={ReferralAffiliate}
                />

                {/* Testing URL */}
                <Route
                  exact={true}
                  store={store}
                  path="/redirecting/:slug"
                  component={Testing}
                />
                {/* Testing URL FOR VCARD */}
                <Route
                  exact={true}
                  store={store}
                  path="/redirecting/vcard/:slug"
                  component={VcardLandingPage}
                />

                {/* Logo */}
                <Route exact={true} store={store} path="/" component={Logo} />
                <Route
                  exact={true}
                  store={store}
                  path="/:slug"
                  component={Logo}
                />

                <Route exact path="/">
                  {/* <Redirect to="/urls" /> */}
                </Route>
              </Switch>
            </Router>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastsContainer
        position={ToastsContainerPosition.TOP_RIGHT}
        store={ToastsStore}
      />
    </>
  );
};
