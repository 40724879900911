import moment from "moment";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Select from "react-select";
import environment from "../../environment";
import { User } from "../../methods/auth";
import { useSelector } from "react-redux";

const Trash = () => {
  const [data, setdata] = useState([]);
  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    isDeleted: true,
    search: "",
    sortBy: "updatedAt desc",
    type: "",
    qrCodeId: "",
    campaignName: "",
    medium: "",
  });
  const [total, settotal] = useState();
  const [types, settypes] = useState([]);
  const user = useSelector((state) => state.user);
  const [Type, setType] = useState([]);

  const GetType = () => {
    ApiClient.get("qrtype/all").then((res) => {
      setType(res.data);
    });
  };

  useEffect(() => {
    GetType();
  }, []);

  const FindType = (data) => {
    let value = "";
    if (data == "url") {
      data = "urls";
    }
    value = Type && Type.filter((itm) => itm?.slug == data?.replace("-", " "));
    return value[0]?.type ? value[0]?.type : "--";
  };

  useEffect(() => {
    getData();
    getAllTypes();
  }, []);

  const getAllTypes = () => {
    ApiClient.get(`qrtype/all?status=active`).then((res) => {
      if (res.success) {
        settypes(
          res?.data?.map((item) => {
            return { value: item?.slug, label: item?.type };
          })
        );
      }
    });
  };

  const getData = (p = {}) => {
    let filters = { ...filter, ...p, user_id: user?.id };
    loader(true);
    ApiClient.get(`qrcode/download/all`, filters).then((res) => {
      if (res.success) {
        setdata(res?.data?.data);
        settotal(res?.data?.total);
      }
      loader(false);
    });
  };

  const handleActions = (id, type) => {
    if (type == "restore") {
      if (window.confirm(`Do you want to restore this qr code`)) {
        ApiClient.put(`qrcode/download/restore?id=${id}`).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getData();
          }
        });
      }
    } else {
      if (window.confirm(`Do you want to delete this qr code permanently`)) {
        ApiClient.delete(`qrcode/download/destroy?id=${id}`).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getData();
          }
        });
      }
    }
  };

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getData({ page: e });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setfilter({ ...filter, [e.target.name]: value });
    getData({ [e.target.name]: value })
  };

  const handleFilters = (data, type) => {
    if (type == "sortBy") {
      setfilter({ ...filter, sortBy: data });
      getData({ sortBy: data });
    } else {
      getData({
        type: filter?.type?.value,
        qrCodeId: filter?.qrCodeId,
        campaignName: filter?.campaignName,
        medium: filter?.medium,
      });
    }
  };

  const handleResetFilters = () => {
    setfilter({
      ...filter,
      type: "",
      qrCodeId: "",
      campaignName: "",
      medium: "",
    });
    getData({
      ...filter,
      type: "",
      qrCodeId: "",
      campaignName: "",
      medium: "",
    });
  };

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="first_div">
            <p className="mb-0">
              <i className="fa fa-info-circle"></i> QR Codes will be permanently
              deleted from Trash 60 days from the date of deletion
            </p>
          </div>
          <div className="second_div mb-3 mt-3">
            <div className="trash_div mb-3">
              <div className="search_trash_div">
                {/* <form onSubmit={(e) => handleSearch(e)}> */}
                <input
                  type="search"
                  name="search"
                  value={filter?.search}
                  onChange={(e) => {
                    handleSearch(e)
                  }}
                  className="form-control"
                  placeholder="Search..."
                />
                <i className="fa fa-search search_pos"></i>

                {/* <button  className="d-none"></button> */}
                {/* </form> */}
              </div>
              <div className="dropdown_flex">
                <div className="select_input ml-md-2">
                  <p className="type_text">Sort By:</p>
                  <select
                    value={filter?.sortBy}
                    onChange={(e) => handleFilters(e.target.value, "sortBy")}
                    className="form-select select_url"
                  >
                    <option
                      className="form_select_options"
                      value="updatedAt desc"
                    >
                      Latest
                    </option>
                    <option
                      className="form_select_options"
                      value="updatedAt asc"
                    >
                      Oldest
                    </option>
                  </select>
                </div>
                <div
                  className="select_dropdown ml-md-2 "
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Filter <span class="material-icons ml-2">filter_list</span>
                </div>
              </div>
            </div>

            <div className="filter_list collapse" id="collapseExample">
              <div className="filters_row">
                <h1>Filter</h1>
                <div className="text_filter">
                  <h3 className="mr-3" onClick={(e) => handleResetFilters()}>
                    Reset Filter{" "}
                    <span class="material-icons ml-2">clear_all</span>
                  </h3>
                  <h3
                    className="bule_h3"
                    onClick={(e) => handleFilters("", "combineFilter")}
                  >
                    Apply <span class="material-icons ml-2">done</span>
                  </h3>
                </div>
              </div>
              <div className="row_name">
                <div className="row ">
                  <div className="col-md-3">
                    <div class="mb-3">
                      <label>Campaign Name</label>
                      <input
                        type="text"
                        value={filter?.campaignName}
                        onChange={(e) =>
                          setfilter({ ...filter, campaignName: e.target.value })
                        }
                        class="form-control"
                        placeholder="Campaign Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="mb-3">
                      <label>QR Code ID</label>
                      <input
                        type="text"
                        value={filter?.qrCodeId}
                        onChange={(e) =>
                          setfilter({ ...filter, qrCodeId: e.target.value })
                        }
                        class="form-control"
                        placeholder="QR Code ID"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Type</label>
                    <Select
                      className="text-capitalize"
                      value={filter?.type}
                      onChange={(e) => setfilter({ ...filter, type: e })}
                      options={types}
                      isClearable={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Medium</label>
                    <input
                      type="text"
                      className="form-control"
                      value={filter?.medium}
                      onChange={(e) =>
                        setfilter({ ...filter, medium: e.target.value })
                      }
                      placeholder="e.g. email, banner, bus stop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card_row">
              {data &&
                data.map((item, index) => {
                  return (
                    <div className="card_div mt-3">
                      <div class="card w-100 ">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item d-flex justify-content-between">
                            <h1 className="name_heading">
                              {item?.setting?.qrCodeName || "--"}
                            </h1>
                            <div className="btn_restore">
                              <button
                                onClick={(e) =>
                                  handleActions(item?.id, "restore")
                                }
                                className="btn primary_btn mr-2"
                              >
                                Restore{" "}
                                <i className="material-icons ml-2 text-primary">
                                  restore
                                </i>
                              </button>
                              <button
                                onClick={(e) =>
                                  handleActions(item?.id, "delete")
                                }
                                className="btn danger_btn ml-2"
                              >
                                Delete{" "}
                                <i className="material-icons ml-2 text-danger">
                                  delete_forever
                                </i>
                              </button>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div className="row_22">
                              <div className="left_list">
                                <div className="row_qr">
                                  {!item?.staticQrcode ? (
                                    <div className="layout_qr mb-3">
                                      <span class="material-icons ico mr-2">
                                        folder
                                      </span>{" "}
                                      {item?.setting?.folderName || "--"}
                                    </div>
                                  ) : null}
                                  {!item?.staticQrcode ? (
                                    <div className="layout_qr mb-3">
                                      <span class="material-icons ico mr-2">
                                        link
                                      </span>{" "}
                                      {`${environment?.liveURL}redirecting/${item?.slug}`}
                                    </div>
                                  ) : null}
                                  <div className="layout_qr mb-3">
                                    <span class="material-icons ico  mr-2">
                                      send
                                    </span>{" "}
                                    {!item?.qrCode_data ? (
                                      `${environment?.liveURL}redirecting/${item?.slug}`
                                    ) : (
                                      <span className="ellipes">
                                        {item?.qrCode_data}
                                      </span>
                                    )}
                                  </div>
                                  <div className="list_rowqr">
                                    <div className="layout_qr">
                                      Created:{" "}
                                      <b>
                                        {item?.setting?.campaignStart
                                          ? moment(
                                            item?.setting?.campaignStart
                                          ).format("MMM DD,YYYY HH:MM")
                                          : moment(item?.createdAt).format(
                                            "MMM DD,YYYY"
                                          ) + " " + moment(item?.createdAt).format("hh:mm a")}
                                      </b>
                                    </div>
                                    <div className="layout_qr">
                                      Date Deleted:{" "}
                                      <b>
                                        {moment(item?.deletedAt).format(
                                          "MMM DD,YYYY"
                                        ) + " " + moment(item?.deletedAt).format("hh:mm a")}
                                      </b>
                                    </div>
                                    <div className="layout_qr text-capitalize">
                                      Type: <b>{FindType(item?.type)}</b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="right_list">
                                <img src={item?.image} width="100%" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              {filter?.count < total ? (
                <div className="d-flex justify-content-between align-items-center ml-2 mt-3">
                  <div>
                    <b>{total}</b> Trashs
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={filter?.page}
                      itemsCountPerPage={filter?.count}
                      totalItemsCount={total}
                      pageRangeDisplayed={1}
                      onChange={handlePagination}
                      hideFirstLastPages={true}
                    />
                  </div>
                  <div></div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {data.length === 0 ? <h2 className="text-center">No Data</h2> : null}
      </Layout>
    </>
  );
};

export default Trash;
