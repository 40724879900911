import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/global/header";
import "./style.scss";
import ApiClient from "../../methods/api/apiClient";
import environment from "../../environment";
import QrCode from "../QrCode";
import PatternColors from "../PatternColors";
import HomeLogo from "./HomeLogo";
import { Link, useHistory, useParams } from "react-router-dom";
import Forms from "../QrCode/Forms";
import TemplatesDesign from "../Templates/TemplatesDesign";
import HomeDetail from "./HomeDetail";
import { useDispatch, useSelector } from "react-redux";
import { active_Plan, deactive_Plan } from "../../actions/activePlan";
import methodModel from "../../methods/methods";
import Frames from "../Frames";

const Logo = () => {
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.user);
  const activePlan = useSelector((state) => state.activePlan);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const code = methodModel.getPrams("code");
  const urlPath = window.location.pathname;
  const history = useHistory();
  const [slugData, setslugData] = useState();
  const [slugDataImage,setSlugDataImage] = useState('')
  const [form, setform] = useState({
    value: "",
    staticQrCode: true,
    logoimg: "",
    logosize: "4",
    qrOuterEye: "square",
    qrInnerEye: "square",
    qrBodyPattern: "square",
    qrOuterEyeColor: "#030202",
    qrInnerEyeColor: "#030202",
    qrBodyPatternColor: "#030202",
    backgroundColor: "#FFFFFF",
    qrOuterEyeColorGradient1: "",
    qrOuterEyeColorGradient2: "",
    qrOuterEyeColorGradientType: "linear",
    qrInnerEyeColorGradient1: "",
    qrInnerEyeColorGradient2: "",
    qrInnerEyeColorGradientType: "linear",
    qrBodyPatternGradientColor1: "",
    qrBodyPatternGradientColor2: "",
    qrBodyPatternGradientColorType: "linear",
    backgroundGradientColor1: "",
    backgroundGradientColor2: "",
    backgroundGradientColorType: "linear",
    errorCorrection: "Q",
  });
  const [qrCodeImage, setqrCodeImage] = useState();
  const [filter, setfilter] = useState({
    page: 1,
    count: 12,
    sortBy: "srNumber asc",
    status: "active",
  });
  const [radiobtn, setradiobtn] = useState({
    qrOuterEyeType: true,
    qrInnerEyeType: true,
    backgroundColorType: true,
    qrBodyPatternType: true,
    frameColor: true,
  });
  const [qrTypes, setqrTypes] = useState();
  const [common, setcommon] = useState({
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    value6: "",
    value7: "",
    value8: "",
  });
  const [selectSlug, setselectSlug] = useState();
  const [qrTypesViewMore, setqrTypesViewMore] = useState();
  const [searchViewMore, setsearchViewMore] = useState();
  const [ViewMoreSuggestions, setViewMoreSuggestions] = useState();
  const [selectFormat, setselectFormat] = useState("");
  const [downloadConfirm, setdownloadConfirm] = useState(false);
  // For Settings tab
  const [settingForm, setsettingForm] = useState({
    qrCodeName: "",
    folderName: "",
    folderId: "",
    flyer: "",
    printRun: "",
    campaignStart: "",
    campaignEnd: "",
    numberOfScansStatus: false,
    numberOfScans: "",
    dateRangeStatus: false,
    dateRangeStart: "",
    dateRangeEnd: "",
    expiryURL: "",
    externalTrackerFacebook: false,
    externalTrackerGoogle: false,
    passwordStatus: false,
    password: "",
    exactGPSLocation: false,
    limitScanLocation: false,
    longitude: "",
    latitude: "",
    radiusInKM: "",
  });
  // For vCard
  const [vcard, setvcard] = useState();
  //Add More
  const [smartURL, setsmartURL] = useState({
    ruleType: "location",
    defaultUrl: "",
  });
  const [location, setlocation] = useState([
    { country: "", region: "", city: "", url: "" },
  ]);
  const [numberOfScans, setnumberOfScans] = useState([{ scans: "", url: "" }]);
  const [time, settime] = useState([
    { timezone: "", startTime: "", endTime: "", url: "" },
  ]);
  const [language, setlanguage] = useState([{ language: "", url: "" }]);
  // For Frames
  const [frameForm, setframeForm] = useState({
    width: "250",
    height: "250",
    frame: "",
    qrFrameColor: "#030202",
    qrFrameColorGradient1: "",
    qrFrameColorGradient2: "",
    text: "SCAN ME",
    fontStyle: "",
    textColor: "#3c61e4",
  });

  useEffect(() => {
    if (!slug) {
      if (code) {
        history.push(`/urls?code=${code}`);
      } else {
        history.push(`/urls`);
      }
    }
    if (token) {
      history.push("/app/qrcodes");
    }
    getSlugQrCodeDetail();
    getQrCodeTypes();
    getViewMoreQrCodeTypes();
    getActivePlanDetail();
    if (slug == "multiple-apps" || slug == "smart-url" || slug == "file") {
      setform({ ...form, staticQrCode: false });
    }
    if (slug == "paynow") {
      setcommon({
        value1: true,
        value2: "",
        value3: "",
        value4: true,
        value5: "",
        value6: true,
        value7: "",
        value8: false,
        value9: "",
      });
    }
    if (slug == "sepa") {
      setcommon({
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: true,
        value6: "",
        value7: "",
        value8: "",
      });
    }
    // if (!activePlan?.planActive) {
    //   setform({ ...form, staticQrCode: true })
    // }
    let formData = JSON.parse(localStorage.getItem("QrCodeDesign"));
    if (formData) {
      setform(formData);
      localStorage.removeItem("QrCodeDesign");
    }
    let commonData = JSON.parse(localStorage.getItem("commonState"));
    if (commonData) {
      setcommon(commonData);
      localStorage.removeItem("commonState");
    }
    localStorage.removeItem("AfterLogin");
    localStorage.removeItem("DynamicQrCode");
  }, []);

  const getActivePlanDetail = () => {
    if (user?.id && token) {
      ApiClient.get(`active-subscription?user_id=${user?.id}`).then((res) => {
        if (res.success) {
          dispatch(active_Plan(res?.data));
        } else {
          dispatch(deactive_Plan());
        }
      });
    } else {
      dispatch(deactive_Plan());
    }
  };

  const getSlugQrCodeDetail = () => {
    if (slug || urlPath == "/urls") {
      ApiClient.get(
        `qrtype?id=${slug ? slug.replaceAll("-", " ") : "urls"}`
      ).then((res) => {
        if (res.success) {
          if ((slug && slug != 'events' && slug != 'email' && slug != 'text' && slug != 'call' && slug != 'sms' && slug != "urls" && slug != "wifi" && slug != "smart-url" && slug != "file" && slug != "vcard" && slug != "multiple-apps")) {
              setSlugDataImage(res?.data?.image)
          }else{
            setSlugDataImage('')
          }
          if(slug == "wifi" ){
            setform({...form,staticQrCode:true})
          }
          if(slug == "vcard" ){
            setform({...form,staticQrCode:false})
          }
          setslugData(res?.data);
        }
      });
    }
  };

  useEffect(() => {
    getSlugQrCodeDetail();
  }, [slug]);
  const getQrCodeTypes = (p = {}) => {
    let fil = { ...filter, ...p };
    ApiClient.get(`qrtype/all`, fil).then((res) => {
      if (res.success) {
        setqrTypes(res.data);
      }
    });
  };

  const handleCount = () => {
    if (filter?.count == 12) {
      setfilter({ ...filter, count: 100 });
      getQrCodeTypes({ count: 100 });
    } else {
      setfilter({ ...filter, count: 12 });
      getQrCodeTypes({ count: 12 });
    }
  };

  const resetDesign = () => {
    setform({
      ...form,
      logoimg: "",
      logosize: "4",
      qrOuterEye: "square",
      qrInnerEye: "square",
      qrBodyPattern: "square",
      qrOuterEyeColor: "#030202",
      qrInnerEyeColor: "#030202",
      qrBodyPatternColor: "#030202",
      backgroundColor: "#FFFFFF",
      qrOuterEyeColorGradient1: "",
      qrOuterEyeColorGradient2: "",
      qrOuterEyeColorGradientType: "linear",
      qrInnerEyeColorGradient1: "",
      qrInnerEyeColorGradient2: "",
      qrInnerEyeColorGradientType: "linear",
      qrBodyPatternGradientColor1: "",
      qrBodyPatternGradientColor2: "",
      qrBodyPatternGradientColorType: "linear",
      backgroundGradientColor1: "",
      backgroundGradientColor2: "",
      backgroundGradientColorType: "linear",
      errorCorrection: "M",
    });
    setframeForm({
      width: "250",
      height: "250",
      frame: "",
      qrFrameColor: "#030202",
      qrFrameColorGradient1: "",
      qrFrameColorGradient2: "",
      text: "SCAN ME",
      fontStyle: "",
      textColor: "#3c61e4",
    });
    setradiobtn({
      qrOuterEyeType: true,
      qrInnerEyeType: true,
      backgroundColorType: true,
      qrBodyPatternType: true,
    });
  };

  const handleQrType = (slugType, type) => {
    if (
      slugType == "multiple apps" ||
      slugType == "smart url" ||
      slugType == "file"
    ) {
      if (type == "direct") {
        window.open(`/${slugType.replace(" ", "-")}`);
      } else if (type == "viewMore") {
        setselectSlug(slugType);
      } else {
        handleQrCode(false, "No");
      }
    } else {
      if (
        slugType == slug?.replace("-", " ") ||
        urlPath.replace("/", "") == slugType
      ) {
        return;
      } else if (type == "viewMore") {
        setselectSlug(slugType);
      } else {
        window.open(`/${slugType.replace(" ", "-")}`);
      }
    }
  };

  const urlsQrCodeOnly = () => {
    if (
      qrTypesViewMore?.find((item) => item?.slug == slug.replaceAll("-", " "))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const headerText = () => {
    if (slug == "url" || urlPath == "/urls") {
      return "Free QR Code Generator with Logo and Brand Colors";
    } else if (slug == "whatsapp") {
      return "WhatsApp QR Code Generator";
    } else if (slug == "google-forms") {
      return "Google Forms QR Code Generator";
    } else if (slug == "sheets") {
      return "Google Sheets QR Code Generator";
    } else if (slug == "docs") {
      return "Google Docs QR Code Generator";
    } else if (slug == "wifi") {
      return "Free WiFi QR Code Generator";
    } else if (slug == "events") {
      return "Calendar QR Code Generator";
    } else if (slug == "call") {
      return "Phone Number QR Code Generator";
    } else if (slug == "sms") {
      return "SMS QR Code Generator";
    } else if (slug == "location" || slug == "maps") {
      return "Location QR Code Generator";
    } else if (slug == "vcard") {
      return "vCard QR Code Generator";
    } else if (slug == "paynow") {
      return "PayNow QR Code Generator";
    } else if (slug == "sepa") {
      return "SEPA QR Code Generator";
    } else if (urlsQrCodeOnly()) {
      return (
        <span className="text-capitalize">
          {slug.replaceAll("-", " ")} QR Code Generator
        </span>
      );
    } else {
      return "";
    }
  };

  // View More Search Functions
  const getViewMoreQrCodeTypes = (p = {}) => {
    let payload = { ...p, status: "active" };
    ApiClient.get(`qrtype/all`, payload).then((res) => {
      if (res.success) {
        setqrTypesViewMore(res.data);
      }
    });
  };

  const handleViewMoreSearch = (e) => {
    setsearchViewMore(e);
    ApiClient.get(`qrtype/all?search=${e || "zzzz"}&status=active`).then(
      (res) => {
        if (res.success) {
          setViewMoreSuggestions(res?.data);
        }
      }
    );
    if (!e) getViewMoreQrCodeTypes({});
  };

  const selectViewMoreSearch = (type) => {
    getViewMoreQrCodeTypes({ search: type });
    setViewMoreSuggestions([]);
  };

  const ViewMoreModal = () => {
    setsearchViewMore("");
    setViewMoreSuggestions([]);
    getViewMoreQrCodeTypes({});
    setselectSlug("");
  };

  const staticQrCode = () => {
    if (
      slug == "sepa" ||
      slug == "paynow" ||
      slug == "wifi" ||
      slug == "call" ||
      slug == "text" ||
      slug == "sms" ||
      slug == "email" ||
      slug == "location" ||
      slug == "maps" ||
      slug == "vcard"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleQrCode = (check, modal) => {
    if (!check) {
      if (modal == "No") {
        if (activePlan?.planActive) {
          setform({ ...form, staticQrCode: check });
        } else {
          if (user?.id) {
            document.getElementById("OpenDynamicQrCode").click();
          } else {
            document.getElementById("OpenSignUpModal").click();
          }
        }
      } else {
        setform({ ...form, staticQrCode: check });
        localStorage.setItem(
          "QrCodeDesign",
          JSON.stringify({ ...form, staticQrCode: check })
        );
        localStorage.setItem("commonState", JSON.stringify(common));
        localStorage.setItem("DynamicQrCode", urlPath);
        history.push(`/pricing`);
        document.getElementById("ClodeDynamicQrCode").click();
      }
    } else {
      setform({ ...form, staticQrCode: check });
    }
  };
  return (
    <>
      <Header />
      <div className="bgback back-gradient py-4">
        {/* section 2 */}
        <div className="steps-content mx-3 ">
          <div className="container bg-white rounded-4 pt-2 pb-2">
            <div className="row">
              {qrTypes &&
                qrTypes.map((item) => {
                  return (
                    <div className="linkcol col-md-2 col-lg-1 col-4">
                      <div className="scanner-options text-capitalize">
                        <p
                          className={`link-box p-1 pointer ${
                            slug?.replace("-", " ") == item?.slug ||
                            urlPath.replace("/", "") == item?.slug
                              ? "scanner-active py-2"
                              : ""
                          }`}
                          onClick={(e) => handleQrType(item?.slug, "direct")}
                        >
                          <img
                            src={`${environment.api}images/qrcodetypes/${item?.image}`}
                            className="setimg mx-auto"
                            alt="..."
                          />
                          <span className="text-center">{item?.type}</span>
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <p
              onClick={(e) => ViewMoreModal()}
              className="view_more"
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
            >
              View {filter?.count == 12 ? "More" : "Less"}
              <i className="material-icons mr-2">
                {filter?.count == 12 ? "arrow_drop_down" : "arrow_drop_up"}
              </i>
            </p>
          </div>
        </div>

        {/* section 3 */}
        <div className="text-detail my-3">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h4 className="fw-bold home_q_header">{headerText()}</h4>
                <h6 className="text-primary fw-normal home_q_desc">
                  {" "}
                  No ads, no hidden fees. Track scans of your QR Codes.
                </h6>
              </div>
              <div className="col-4 text-md-end">
                <img
                  src="./assets/img/gdpr-lock.png"
                  className=" gdpr-btn mr-3 mb-2"
                  alt="..."
                />
                <img
                  src="./assets/img/gdpr-check.png"
                  className=" gdpr-btn mb-2"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>

        {/* section 4 */}
        <div className=" enter-url-detail">
          <div className="container position-relative top-0">
            <div className="row">
              <div className="col-md-12 col-lg-8 pr-md-1 mb-3">
                <div className="bg-white p-3 rounded-4">
                  <Forms
                    qrTypesViewMore={qrTypesViewMore}
                    slug={slug}
                    slugDataImage={slugDataImage}
                    setform={setform}
                    form={form}
                    common={common}
                    setcommon={setcommon}
                    vcard={vcard}
                    setvcard={setvcard}
                    slugData={slugData}
                    smartURL={smartURL}
                    setsmartURL={setsmartURL}
                    location={location}
                    setlocation={setlocation}
                    numberOfScans={numberOfScans}
                    setnumberOfScans={setnumberOfScans}
                    time={time}
                    settime={settime}
                    language={language}
                    setlanguage={setlanguage}
                  />
                  {staticQrCode() ? null : (
                    <div className="row">
                      <div className="col-sm-12 col-md-5 mb-3">
                        <label
                          className={`form-check-label fw-bold w-100 ${
                            slug == "multiple-apps" ||
                            slug == "smart-url" ||
                            slug == "file"
                              ? "disable-box"
                              : "pointer"
                          }`}
                        >
                          <div
                            className={`${
                              form?.staticQrCode ? "activeurl" : "urlbtn"
                            } height_box rounded-3 p-3`}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                disabled={
                                  slug == "multiple-apps" ||
                                  slug == "smart-url" ||
                                  slug == "file"
                                }
                                type="radio"
                                checked={form?.staticQrCode}
                                onClick={(e) => handleQrCode(true, "No")}
                                name="flexRadioDefault4"
                              />
                              <h6 className="fw-bold stat_heading">
                                Static QR Code
                              </h6>
                              <ul className="list-group list-gp-btn">
                                <li className="list-group-item fw-normal li-green">
                                  100% free forever
                                </li>
                                <li className="list-group-item fw-normal li-close">
                                  Cannot edit data once created
                                </li>
                                <li className="list-group-item fw-normal li-close">
                                  {" "}
                                  Cannot track scans
                                </li>
                              </ul>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-7 mb-3">
                        <label className="form-check-label fw-bold w-100">
                          <div
                            className={`${
                              !form?.staticQrCode ? "activeurl" : "urlbtn"
                            } height_box rounded-3 p-3 pointer`}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                checked={!form?.staticQrCode}
                                onClick={(e) => handleQrCode(false, "No")}
                                name="flexRadioDefault4"
                              />
                              <h6 className="fw-bold stat_heading">
                                Dynamic QR Code
                              </h6>
                              <ul className="list-group list-gp-btn mt-2">
                                <li className="list-group-item fw-normal li-green">
                                  Edit data or change QR type even after its
                                  printed
                                </li>
                                <li className="list-group-item fw-normal li-green">
                                  Track analytics; number of scans, time,
                                  location, device and more
                                </li>
                                <li className="list-group-item fw-normal li-green">
                                  {" "}
                                  Redirect users based on your rules
                                </li>
                              </ul>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="bg-white p-3 mt-md-4 mt-2 rounded-4">
                  <div className="row ">
                    <div className="col-12">
                      <div className="bg-white rounded-4  ">
                        <p className="fw-bold">
                          STEP 2: Design your QR Code{" "}
                          <span className="fw-normal">(Optional)</span>
                          <span
                            className="text-primary fw-bold float-right pointer"
                            onClick={(e) => resetDesign()}
                          >
                            Reset Design{" "}
                            <img
                              src="/assets/img/reset.png"
                              className=" img-fluid ms-3 "
                              alt="..."
                            />{" "}
                          </span>
                        </p>
                        {/* tabs-section */}
                        <ul
                          className="nav nav-pills mb-4 tab-pills pills_row"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              {" "}
                              <div className="d-flex flex-column  gap-3 ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_168_400)">
                                    <path
                                      d="M12.0006 10C8.5306 10 5.6406 12.54 5.0906 15.86C4.9906 16.46 5.4806 17 6.0906 17C6.5806 17 6.9906 16.64 7.0706 16.15C7.4806 13.79 9.5306 12 12.0006 12C14.4706 12 16.5206 13.79 16.9306 16.15C17.0106 16.64 17.4206 17 17.9106 17C18.5206 17 19.0006 16.46 18.9006 15.86C18.3606 12.54 15.4706 10 12.0006 10ZM12.0006 6C6.3006 6 1.6106 10.34 1.0506 15.9C1.0006 16.49 1.4606 17 2.0606 17C2.5706 17 3.0006 16.62 3.0506 16.12C3.4906 11.57 7.3406 8 12.0006 8C16.6606 8 20.5106 11.57 20.9606 16.12C21.0106 16.62 21.4406 17 21.9506 17C22.5406 17 23.0106 16.49 22.9506 15.9C22.3906 10.34 17.7006 6 12.0006 6Z"
                                      fill="#212121"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_168_400">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Logo{" "}
                              </div>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <div className="d-flex flex-column  gap-3 ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_168_408)">
                                    <path
                                      d="M12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C12.83 21 13.5 20.33 13.5 19.5C13.5 19.11 13.35 18.76 13.11 18.49C12.88 18.23 12.73 17.88 12.73 17.5C12.73 16.67 13.4 16 14.23 16H16C18.76 16 21 13.76 21 11C21 6.58 16.97 3 12 3ZM6.5 12C5.67 12 5 11.33 5 10.5C5 9.67 5.67 9 6.5 9C7.33 9 8 9.67 8 10.5C8 11.33 7.33 12 6.5 12ZM9.5 8C8.67 8 8 7.33 8 6.5C8 5.67 8.67 5 9.5 5C10.33 5 11 5.67 11 6.5C11 7.33 10.33 8 9.5 8ZM14.5 8C13.67 8 13 7.33 13 6.5C13 5.67 13.67 5 14.5 5C15.33 5 16 5.67 16 6.5C16 7.33 15.33 8 14.5 8ZM17.5 12C16.67 12 16 11.33 16 10.5C16 9.67 16.67 9 17.5 9C18.33 9 19 9.67 19 10.5C19 11.33 18.33 12 17.5 12Z"
                                      fill="#212121"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_168_408">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Pattern & Colors{" "}
                              </div>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-contact-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-contact"
                              type="button"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              <div className="d-flex flex-column gap-3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_168_415)">
                                    <path
                                      d="M20 3.99998H16L12.71 0.70998C12.32 0.31998 11.69 0.31998 11.3 0.70998L8 3.99998H4C2.9 3.99998 2 4.89998 2 5.99998V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V5.99998C22 4.89998 21.1 3.99998 20 3.99998ZM19 20H5C4.45 20 4 19.55 4 19V6.99998C4 6.44998 4.45 5.99998 5 5.99998H8.52L12.04 2.49998L15.52 5.99998H19C19.55 5.99998 20 6.44998 20 6.99998V19C20 19.55 19.55 20 19 20ZM17 7.99998H7C6.45 7.99998 6 8.44998 6 8.99998V17C6 17.55 6.45 18 7 18H17C17.55 18 18 17.55 18 17V8.99998C18 8.44998 17.55 7.99998 17 7.99998Z"
                                      fill="#212121"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_168_415">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Frame{" "}
                              </div>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-disabled-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-disabled"
                              type="button"
                              role="tab"
                              aria-controls="pills-disabled"
                              aria-selected="false"
                            >
                              <div className="d-flex flex-column  gap-3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_168_422)">
                                    <path
                                      d="M19.5803 3.08L3.15031 19.51C3.24031 19.85 3.42031 20.16 3.66031 20.41C3.91031 20.65 4.22031 20.83 4.56031 20.92L21.0003 4.49C20.8103 3.8 20.2703 3.26 19.5803 3.08ZM11.9503 3L3.07031 11.88V14.71L14.7803 3H11.9503ZM5.07031 3C3.97031 3 3.07031 3.9 3.07031 5V7L7.07031 3H5.07031ZM19.0703 21C19.6203 21 20.1203 20.78 20.4803 20.41C20.8503 20.05 21.0703 19.55 21.0703 19V17L17.0703 21H19.0703ZM9.36031 21H12.1903L21.0703 12.12V9.29L9.36031 21Z"
                                      fill="#212121"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_168_422">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Template{" "}
                              </div>
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabindex="0"
                          >
                            <HomeLogo setform={setform} form={form} />
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabindex="0"
                          >
                            <PatternColors
                              setform={setform}
                              form={form}
                              setradiobtn={setradiobtn}
                              radiobtn={radiobtn}
                            />
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-contact"
                            role="tabpanel"
                            aria-labelledby="pills-contact-tab"
                            tabindex="0"
                          >
                            <Frames
                              frameForm={frameForm}
                              setframeForm={setframeForm}
                              radiobtn={radiobtn}
                              setradiobtn={setradiobtn}
                            />
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-disabled"
                            role="tabpanel"
                            aria-labelledby="pills-disabled-tab"
                            tabindex="0"
                          >
                            <TemplatesDesign
                              setform={setform}
                              form={form}
                              setframeForm={setframeForm}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 mt-md-0 mt-2 mb-3">
                <div className="bg-white rounded-4 p-4 position-sticky top-0">
                  <p className="text-center">
                    Scan your QR code to confirm that it works
                  </p>
                  <QrCode
                    qrTypesViewMore={qrTypesViewMore}
                    frameForm={frameForm}
                    slugDataImage={slugDataImage}
                    settingForm={settingForm}
                    smartURL={smartURL}
                    location={location}
                    slugData={slugData}
                    numberOfScans={numberOfScans}
                    time={time}
                    language={language}
                    qrCodeImage={qrCodeImage}
                    setqrCodeImage={setqrCodeImage}
                    form={form}
                    setform={setform}
                    common={common}
                    urlPath={urlPath}
                    selectFormat={selectFormat}
                    downloadConfirm={downloadConfirm}
                    setdownloadConfirm={setdownloadConfirm}
                    setselectFormat={setselectFormat}
                    slug={slug}
                    vcard={vcard}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade qr-modal-code"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body qr-code-body p-0 rounded-3 ">
              <div className="p-3 border-bottom">
                <div className="search-or-code bg-white  p-0">
                  <div className="row">
                    <div className="col-9">
                      <div className="search-qr-code position-relative">
                        <input
                          value={searchViewMore}
                          onChange={(e) => handleViewMoreSearch(e.target.value)}
                          className="form-control me-2"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                        />
                        <i
                          className="fa fa-search search-qr-svg"
                          width="24"
                        ></i>
                        <div
                          className={`bg-white ${
                            ViewMoreSuggestions?.length != 0
                              ? "search_barlist"
                              : ""
                          }`}
                        >
                          {ViewMoreSuggestions &&
                            ViewMoreSuggestions.map((item) => {
                              return (
                                <div className="white_bx_bg">
                                  <div className="search_view_more">
                                    <p
                                      onClick={(e) =>
                                        selectViewMoreSearch(item?.type)
                                      }
                                      className="pointer p-2 mb-0"
                                    >
                                      <img
                                        src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                        className="setimg"
                                      />{" "}
                                      <span className="mb-0 textp text-capitalize ms-2">
                                        {" "}
                                        {item?.type}{" "}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className={`col-3 ${!selectSlug ? "disable" : ""}`}>
                      <button
                        onClick={(e) => {
                          window.open(`/${selectSlug.replace(" ", "-")}`);
                        }}
                        disabled={!selectSlug}
                        className="btn btn-primary w-100"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Proceed{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_317_5936)">
                            <path
                              d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_317_5936">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="padding_box">
                {/* <!-- popular  and app-stories --> */}
                <div className="popular-box pt-3 pl-md-3">
                  <div className="row">
                    {qrTypesViewMore &&
                    qrTypesViewMore.find(
                      (item) => item?.category_details?.name == "popular"
                    ) ? (
                      <div className="col-6">
                        <p className="mb-2 headp">Popular</p>
                        <div className="row mb-0">
                          {qrTypesViewMore &&
                            qrTypesViewMore.map((item) => {
                              if (item?.category_details?.name == "popular") {
                                return (
                                  <div
                                    className={`col-lg-2 p-2 mx-1 ${
                                      selectSlug == item?.slug
                                        ? "scanner-active py-2"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      onClick={(e) =>
                                        handleQrType(item?.slug, "viewMore") &
                                        localStorage.removeItem("qr")
                                      }
                                      className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                    >
                                      <img
                                        src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                        className="setimg mx-auto"
                                        alt="..."
                                      />
                                      <p className="mb-0 textp text-capitalize">
                                        {item?.type}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    ) : null}
                    {qrTypesViewMore &&
                    qrTypesViewMore.find(
                      (item) => item?.category_details?.name == "app stores"
                    ) ? (
                      <div className="col-6">
                        <p className="mb-2 headp">App Stores</p>
                        <div className="row">
                          {qrTypesViewMore &&
                            qrTypesViewMore.map((item) => {
                              if (
                                item?.category_details?.name == "app stores"
                              ) {
                                return (
                                  <div
                                    className={`col-lg-2 p-2 mx-1 ${
                                      selectSlug == item?.slug
                                        ? "scanner-active "
                                        : ""
                                    }`}
                                  >
                                    <div
                                      onClick={(e) =>
                                        handleQrType(item?.slug, "viewMore")
                                      }
                                      className={`link-item d-flex flex-column text-center align-items-center gap-2`}
                                    >
                                      <img
                                        src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                        className="setimg mx-auto"
                                        alt="..."
                                      />
                                      <p className="mb-0 textp text-capitalize">
                                        {item?.type}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <!-- Social Media and Chat Apps --> */}
                <div className="social pt-3 pl-3">
                  <div className="row">
                    {qrTypesViewMore &&
                    qrTypesViewMore.find(
                      (item) => item?.category_details?.name == "social media"
                    ) ? (
                      <div className="col-6">
                        <div className="row">
                          <div className="col-10">
                            <div className="social-app">
                              <p className="mb-2 headp">Social Media</p>
                              <div className="row">
                                {qrTypesViewMore &&
                                  qrTypesViewMore.map((item) => {
                                    if (
                                      item?.category_details?.name ==
                                      "social media"
                                    ) {
                                      return (
                                        <div
                                          className={`col-lg-2 p-2 mx-1 ${
                                            selectSlug == item?.slug
                                              ? "scanner-active "
                                              : ""
                                          }`}
                                        >
                                          <div
                                            onClick={(e) =>
                                              handleQrType(
                                                item?.slug,
                                                "viewMore"
                                              )
                                            }
                                            className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                          >
                                            <img
                                              src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                              className="setimg mx-auto"
                                              alt="..."
                                            />
                                            <p className="mb-0 textp text-capitalize">
                                              {item?.type}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="col-2"></div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-6">
                      <div className="row">
                        {qrTypesViewMore &&
                        qrTypesViewMore.find(
                          (item) => item?.category_details?.name == "chat apps"
                        ) ? (
                          <>
                            <div className="col-10">
                              <div className="social-app">
                                <p className="mb-2 headp">Chat Apps</p>
                                <div className="row">
                                  {qrTypesViewMore &&
                                    qrTypesViewMore.map((item) => {
                                      if (
                                        item?.category_details?.name ==
                                        "chat apps"
                                      ) {
                                        return (
                                          <div
                                            className={`col-lg-2 p-2 mx-1 ${
                                              selectSlug == item?.slug
                                                ? "scanner-active py-2"
                                                : ""
                                            }`}
                                          >
                                            <div
                                              onClick={(e) =>
                                                handleQrType(
                                                  item?.slug,
                                                  "viewMore"
                                                )
                                              }
                                              className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                            >
                                              <img
                                                src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                                className="setimg mx-auto"
                                                alt="..."
                                              />
                                              <p className="mb-0 textp text-capitalize">
                                                {item?.type}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              </div>
                            </div>
                            <div className="col-2"></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Media and Google  --> */}
                <div className="social pt-3 pl-3">
                  <div className="row">
                    {qrTypesViewMore &&
                    qrTypesViewMore.find(
                      (item) => item?.category_details?.name == "media"
                    ) ? (
                      <div className="col-6">
                        <div className="row">
                          <div className="col-10">
                            <div className="social-app">
                              <p className="mb-2 headp">Media</p>
                              <div className="row">
                                {qrTypesViewMore &&
                                  qrTypesViewMore.map((item) => {
                                    if (
                                      item?.category_details?.name == "media"
                                    ) {
                                      return (
                                        <div
                                          className={`col-lg-2 p-2 mx-1 ${
                                            selectSlug == item?.slug
                                              ? "scanner-active "
                                              : ""
                                          }`}
                                        >
                                          <div
                                            onClick={(e) =>
                                              handleQrType(
                                                item?.slug,
                                                "viewMore"
                                              )
                                            }
                                            className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                          >
                                            <img
                                              src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                              className="setimg mx-auto"
                                              alt="..."
                                            />
                                            <p className="mb-0 textp text-capitalize">
                                              {item?.type}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="col-2"></div>
                        </div>
                      </div>
                    ) : null}
                    {qrTypesViewMore &&
                    qrTypesViewMore.find(
                      (item) => item?.category_details?.name == "google"
                    ) ? (
                      <div className="col-6">
                        <div className="row">
                          <div className="col-10">
                            <div className="social-app">
                              <p className="mb-2 headp">Google</p>
                              <div className="row">
                                {qrTypesViewMore &&
                                  qrTypesViewMore.map((item) => {
                                    if (
                                      item?.category_details?.name == "google"
                                    ) {
                                      return (
                                        <div
                                          className={`col-lg-2 p-2 mx-1 ${
                                            selectSlug == item?.slug
                                              ? "scanner-active "
                                              : ""
                                          }`}
                                        >
                                          <div
                                            onClick={(e) =>
                                              handleQrType(
                                                item?.slug,
                                                "viewMore"
                                              )
                                            }
                                            className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                          >
                                            <img
                                              src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                              className="setimg mx-auto"
                                              alt="..."
                                            />
                                            <p className="mb-0 textp text-capitalize">
                                              {item?.type}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="col-2"></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <!-- Phone and Payment --> */}
                <div className="social pt-3 pl-3">
                  <div className="row">
                    {qrTypesViewMore &&
                    qrTypesViewMore.find(
                      (item) => item?.category_details?.name == "phone"
                    ) ? (
                      <div className="col-6">
                        <div className="row">
                          <div className="col-10">
                            <div className="social-app">
                              <p className="mb-2 headp">Phone</p>
                              <div className="row">
                                {qrTypesViewMore &&
                                  qrTypesViewMore.map((item) => {
                                    if (
                                      item?.category_details?.name == "phone"
                                    ) {
                                      return (
                                        <div
                                          className={`col-lg-2 p-2 mx-1 ${
                                            selectSlug == item?.slug
                                              ? "scanner-active py-2"
                                              : ""
                                          }`}
                                        >
                                          <div
                                            onClick={(e) =>
                                              handleQrType(
                                                item?.slug,
                                                "viewMore"
                                              )
                                            }
                                            className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                          >
                                            <img
                                              src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                              className="setimg mx-auto"
                                              alt="..."
                                            />
                                            <p className="mb-0 textp text-capitalize">
                                              {item?.type}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="col-2"></div>
                        </div>
                      </div>
                    ) : null}
                    {qrTypesViewMore &&
                    qrTypesViewMore.find(
                      (item) => item?.category_details?.name == "payment"
                    ) ? (
                      <div className="col-6">
                        <div className="row">
                          <div className="col-10">
                            <div className="social-app">
                              <p className="mb-2 headp">Payment</p>
                              <div className="row">
                                {qrTypesViewMore &&
                                  qrTypesViewMore.map((item) => {
                                    if (
                                      item?.category_details?.name == "payment"
                                    ) {
                                      return (
                                        <div
                                          className={`col-lg-2 p-2 mx-1 ${
                                            selectSlug == item?.slug
                                              ? "scanner-active py-2"
                                              : ""
                                          }`}
                                        >
                                          <div
                                            onClick={(e) =>
                                              handleQrType(
                                                item?.slug,
                                                "viewMore"
                                              )
                                            }
                                            className={`link-item d-flex pointer flex-column text-center align-items-center gap-2`}
                                          >
                                            <img
                                              src={`${environment.api}images/qrcodetypes/${item?.image}`}
                                              className="setimg mx-auto"
                                              alt="..."
                                            />
                                            <p className="mb-0 textp text-capitalize">
                                              {item?.type}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="col-2"></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Select download extension modal */}
      <button
        type="button"
        id="OpenDownloadModal"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Select Download Format
              </h1>
            </div>
            <div className="modal-body d-flex justify-content-center gap-5 my-2">
              <div>
                <p
                  className={`svg_box pointer ${
                    selectFormat == "svg" ? "download_active" : ""
                  }`}
                  onClick={(e) => setselectFormat("svg")}
                >
                  SVG
                </p>
              </div>
              <div>
                <p
                  className={`png_box pointer ${
                    selectFormat == "png" ? "download_active" : ""
                  }`}
                  onClick={(e) => setselectFormat("png")}
                >
                  PNG
                </p>
              </div>
              <div>
                <p
                  className={`jpeg_box pointer ${
                    selectFormat == "jpeg" ? "download_active" : ""
                  }`}
                  onClick={(e) => setselectFormat("jpeg")}
                >
                  JPEG
                </p>
              </div>
              <div>
                <p
                  className={`webp_box pointer ${
                    selectFormat == "webp" ? "download_active" : ""
                  }`}
                  onClick={(e) => setselectFormat("webp")}
                >
                  WEBP
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="CloseDownloadModal"
                onClick={(e) => setselectFormat("")}
                className="btn btn-clear"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn btn-primary ${!selectFormat ? "disable" : ""}`}
                disabled={!selectFormat}
                onClick={(e) => setdownloadConfirm(true)}
              >
                Confirm <i className="fa fa-check text-light ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Dynamic Qr Code Modal */}
      <button
        type="button"
        className="btn btn-primary d-none"
        id="OpenDynamicQrCode"
        data-bs-toggle="modal"
        data-bs-target="#OpenModalForDynamicQrCode"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="OpenModalForDynamicQrCode"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dynamic_modal">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <h4>For Dynamic Qr Code you need to purchase plan</h4>
              </div>
              <div className="mt-4 text-center">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  id="ClodeDynamicQrCode"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={(e) => handleQrCode(false, "Yes")}
                  className="btn btn-primary ml-2"
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeDetail />
    </>
  );
};
export default Logo;
