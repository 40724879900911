import React, { useEffect, useRef, useState } from "react";
import QrReader from "react-qr-scanner";
import jsQR from "jsqr";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import environment from "../../environment";
import { useSelector } from "react-redux";
import "./style.scss";
import moment from "moment";
import Pagination from "react-js-pagination";
import methodModel from "../../methods/methods";

const WebScanner = () => {
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const [data, setdata] = useState({ result: "", show: true });
  const qrResult = useRef();
  const [found, setfound] = useState(true);
  const delay = 100;
  const [webcam, setwebcam] = useState(false);
  const [qrimage, setqrimage] = useState();
  const [qrScansHistory, setqrScansHistory] = useState();
  const [webCamImage, setwebCamImage] = useState(null);
  const [filter, setfilter] = useState({ page: 1, count: 10 });
  const [total, settotal] = useState();

  useEffect(() => {
    getQrScansHistory();
  }, []);
  const getQrScansHistory = (p = {}) => {
    let payload = { ...filter, ...p, user_id: user?.id };
    if (token) {
      ApiClient.get(`qrcode/all`, payload).then((res) => {
        setqrScansHistory(res?.data?.data);
        settotal(res?.data?.total);
      });
    }
  };
  const handleWebCam = () => {
    async function checkWebcam() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        // Camera is available and permission granted
        setwebcam(true);
      } catch (error) {
        // Handle permission denial or camera unavailability
        setwebcam(false);
      }
    }
    checkWebcam();
  };
  const previewStyle = {
    height: 280,
    width: 500,
  };
  const handleScan = (data) => {
    if (data?.text) {
      qrResult.current = data?.text;
      const video = document.querySelector("video");
      if (video) {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL("image/png");
        setwebCamImage(image);
        saveQrScan(image);
      }

      setdata({ result: data?.text, show: false });
    }
  };
  const handleError = (err) => {
    console.error(err, "Handle Error");
  };
  const decodeQRCodeFromImage = (img) => {
    try {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        setfound(true);
        return code.data;
      } else {
        return setfound(false);
      }
    } catch (error) {
      console.error("Error decoding QR code:", error);
      return null;
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleImageScan(file);
  };
  const handleImageScan = (uploadedImage) => {
    uploadImage(uploadedImage);
    if (!uploadedImage) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageUrl = e.target.result;
      const img = new Image();
      img.onload = () => {
        const qrData = decodeQRCodeFromImage(img);
        qrResult.current = qrData;
      };
      img.src = imageUrl;
    };
    reader.readAsDataURL(uploadedImage);
  };
  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    toast.success("Copy to clipboard");
  };
  const uploadImage = (e) => {
    loader(true);
    ApiClient.postFormData("upload/image?modelName=qrcodetypes", {
      file: e,
      modelName: "qrcodetypes",
    }).then((res) => {
      if (res.data.fullpath) {
        let image = res.data?.fullpath;
        setqrimage({ ...qrimage, image: image, baseImg: "" });
      } else {
        setqrimage({ ...qrimage, baseImg: "" });
      }
      if (localStorage.getItem("token")) {
        saveQrScan(res?.data?.fullpath);
      }
      loader(false);
    });
  };
  // Drop Image Function
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    const file = files[0];
    handleImageScan(file);
  };
  const qrImageFun = () => {
    return `${environment?.api}images/qrcodetypes/${qrimage?.image}`;
  };
  // Save Qr Scan
  const saveQrScan = (img) => {
    let payload = {
      user_id: user?.id,
      qrcode: qrResult.current,
      image: img || "",
    };
    ApiClient.post(`qrcode`, payload).then((res) => {
    });
    getQrScansHistory();
  };

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getQrScansHistory({ page: e });
  };

  return (
    <div className="data-box">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="bg-white  p-3 border border-1 rounded-3 set-bg-box">
              <ul
                className="nav nav-pills scanner-pills mb-4"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Upload
                    <img
                      src="./assets/img/upload-csv.png"
                      className="data-img"
                      alt=""
                    />
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    onClick={(e) => handleWebCam()}
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Scan{" "}
                    <img
                      src="./assets/img/scanner.png"
                      className="data-img"
                      alt=""
                    />
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    {" "}
                    History{" "}
                    <img
                      src="./assets/img/history.png"
                      className="data-img"
                      alt=""
                    />
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div className="upload_div_btn">
                    {/* {token ? */}
                    <>
                      <div className="row">
                        <div
                          className={`col-md-${qrimage?.image ? "9" : "12"}`}
                        >
                          <label
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            className="d-flex flex-column text-center add_div justify-content-center pointer"
                          >
                            <p className="">Drag and drop to upload or</p>
                            <p className="fw-bold">
                              Browse{" "}
                              <img
                                src="./assets/img/lightbulb_.png"
                                className="img-fluid"
                                alt=""
                              />
                              &nbsp;
                            </p>
                            <input
                              type="file"
                              className="d-none"
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                          </label>
                        </div>
                        {qrimage?.image ? (
                          <div className="col-md-3">
                            <img
                              src={qrImageFun()}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </div>
                        ) : null}
                      </div>
                      {found && qrimage?.image ? (
                        <>
                          <h6 className="fw-normal">
                            Type:<span className="fw-bold ms-3">URL</span>
                          </h6>
                          <h6 className="fw-normal">
                            Data:
                            <span
                              onClick={(e) => {
                                window.open(qrResult.current, "_blank");
                              }}
                              className="fw-bold ms-3 text-dark pointer"
                              id="qrData"
                            >
                              {qrResult.current}{" "}
                            </span>
                            {qrResult.current ? (
                              <img
                                src="./assets/img/link-download.png"
                                alt=""
                                onClick={(e) =>
                                  copyToClipboard(qrResult.current)
                                }
                                className="ms-2 pointer"
                              />
                            ) : null}
                          </h6>
                        </>
                      ) : !found && qrimage?.image ? (
                        <p className="fs-5">
                          Error:{" "}
                          <span className="text-danger">Qr code not found</span>
                        </p>
                      ) : null}
                    </>
                    {/* :
                                            <div className="scan-detail-dv text-center">
                                                <h6 className="fw-bold">Please <Link to="/login">log in</Link> to upload your qr code </h6>
                                            </div> */}
                    {/* } */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  <div className="scan-detail-dv text-center">
                    {/* {token ? */}
                    <>
                      {webcam && data?.show ? (
                        <QrReader
                          delay={delay}
                          style={previewStyle}
                          onError={handleError}
                          onScan={handleScan}
                        />
                      ) : null}
                      {data?.result ? (
                        <>
                          <h6>
                            Data:
                            <span
                              onClick={(e) => {
                                window.open(data?.result, "_blank");
                              }}
                              className="fw-bold ms-3 text-dark pointer"
                              id="qrData"
                            >
                              {data?.result}{" "}
                            </span>
                            {data?.result ? (
                              <img
                                src="./assets/img/link-download.png"
                                alt=""
                                onClick={(e) => copyToClipboard(data?.result)}
                                className="ms-2 pointer"
                              />
                            ) : null}
                          </h6>
                          {/* <img src={webCamImage || ''} width='300px' /> */}
                        </>
                      ) : !webcam ? (
                        <h6>
                          Please allow qrcode-bubble.com to use the camera.
                          <br></br>If it doesn't work, try another browser.
                        </h6>
                      ) : null}
                    </>
                    {/* :
                                            
                                            <h6 className="fw-bold">Please <Link to="/login">log in</Link> to scan your qr code </h6> */}
                    {/* } */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                  tabindex="0"
                >
                  <div className="scan-detail-dv text-center ">
                    {token ? (
                      <>
                        <div className="table-responsive">
                          <table class="table table-striped ">
                            <thead>
                              <tr>
                                <th scope="col">Data</th>
                                <th scope="col">Date/Time</th>
                                <th scope="col">Image</th>
                              </tr>
                            </thead>
                            <tbody>
                              {qrScansHistory &&
                                qrScansHistory.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        {item?.qrcode || (
                                          <span className="text-danger">
                                            Qr code not found
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {moment(item?.createdAt).format(
                                          "MMM DD, YYYY"
                                        )}{"  "}{moment(item?.createdAt).format("hh:mm a")}
                                      </td>
                                      <td>
                                        <img
                                          src={
                                            methodModel.QrCodeImage(item?.image)
                                              ? `${item?.image}`
                                              : `${environment?.api}images/qrcodetypes/${item?.image}`
                                          }
                                          width="150px"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h6 className="fw-bold">
                        Please <Link to="/login">log in</Link> to view your scan
                        history{" "}
                      </h6>
                    )}
                  </div>
                  {filter?.count < total ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <b>{total}</b> Scans
                      </div>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          activePage={filter?.page}
                          itemsCountPerPage={filter?.count}
                          totalItemsCount={total}
                          pageRangeDisplayed={1}
                          onChange={handlePagination}
                          hideFirstLastPages={true}
                        />
                      </div>
                      <div></div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebScanner;
