import moment from "moment";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./style.scss";

const FolderLisitng = () => {
  const user = useSelector((state) => state.user);

  const [data, setdata] = useState();
  const [filter, setfilter] = useState({ page: 1, count: 10, search: "",sortBy: "", });
  const [total, settotal] = useState();
  const history = useHistory();

  useEffect(() => {
    getData(filter, true);
  }, []);

  const getData = (p = {}, loading) => {
    let filters = { ...filter, ...p, user_id: user?.id };
    if (loading) loader(true);
    ApiClient.get(`folder/all`, filters).then((res) => {
      if (res.success) {
        setdata(res?.data?.data);
        settotal(res?.data?.total);
      }
      if (loading) loader(false);
    });
  };

  const deleteFolder = (id) => {
    if (window.confirm("Do you really want to delete this folder")) {
      loader(true);
      ApiClient.delete(`folder?id=${id}`).then((res) => {
        if (res.success) {
          toast.success(res.message);
          getData();
        }
        loader(false);
      });
    }
  };

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getData({ page: e });
  };

  const handleSearch = (e) => {
    setfilter({ ...filter, search: e });
    getData({ search: e }, false);
  };

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="main-table">
            <div className="search_bar">
              <input
                value={filter?.search}
                onChange={(e) => handleSearch(e.target.value)}
                className="form-control search me-2"
                // type="search"
                placeholder="Search"
              />
              <i className="fa fa-search search_pos"></i>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className="th-folder">
                      Folder
                    </th>
                    <th scope="col" className="th-codes">
                      QR Codes
                    </th>
                    <th scope="col">Date Created</th>
                    <th scope="col" className="th-date"></th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row" className="text-capitalize">
                            <span class="folder_table">
                              <span class="img_folder">
                                <img
                                  src="/assets/img/files.png"
                                  alt="..."
                                ></img>{" "}
                                {item?.name}
                              </span>{" "}
                              <span
                                onClick={(e) => {
                                  history.push(`/app/folder/edit/${item?._id}`);
                                }}
                                className="float-right pointer"
                              >
                                <img src="/assets/img/edit.png" alt="..."></img>
                              </span>
                            </span>
                          </th>
                          <td>{item?.qcodeDownload_idDetails?.length}</td>
                          <td>
                            {moment(item?.createdAt).format("MMM DD, YYYY") + //createdAt
                              " " +
                              moment(item?.createdAt).format("hh:mm a")}      
                          </td>
                          <td>
                            <img
                              src="/assets/img/delete_forever.png"
                              onClick={(e) => deleteFolder(item?._id)}
                              className="delete-img pointer"
                              alt="..."
                            ></img>
                          </td>
                        </tr>
                      );
                    })}
                  <tr></tr>
                </tbody>
              </table>
            </div>

            {filter?.count < total ? (
              <div className="d-flex justify-content-between align-items-center ml-2 mt-3">
                <div>
                  <b>{total}</b> Folders
                </div>
                <div className="d-flex justify-content-center">
                  <Pagination
                    activePage={filter?.page}
                    itemsCountPerPage={filter?.count}
                    totalItemsCount={total}
                    pageRangeDisplayed={1}
                    onChange={handlePagination}
                    hideFirstLastPages={true}
                  />
                </div>
                <div></div>
              </div>
            ) : null}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FolderLisitng;
