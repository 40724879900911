import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/global/layout";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import moment from "moment";
import Pagination from "react-js-pagination";
import environment from "../../environment";

const ReferralAffiliate = () => {
  const activePlan = useSelector((state) => state.activePlan);
  const user = useSelector((state) => state.user);
  let referralURL = useRef();
  let title = useRef("Hi use this link to register youself");
  const [filter, setfilter] = useState({ page: 1, count: 10 });
  const [total, settotal] = useState(0);
  const [form, setform] = useState({ paypalURL: "", withdrawAmount: "" });
  const [data, setdata] = useState();
  const [listing, setlisting] = useState([]);
  let host = document.location.host;
  let RedirectURL = environment?.liveURL;
  // if (host?.includes("https://qrcode.jcsoftwaresolution.in/")) {
  //   RedirectURL = "https://qrcode.jcsoftwaresolution.in/";
  // } else {
  //   RedirectURL = "http://66.179.251.9:8056/";
  // }
  const copyReferralURL = () => {
    let text = document.getElementById("URLCopy").value;
    navigator.clipboard.writeText(text);
    toast.success("URL Copy to Clipboard.");
  };

  useEffect(() => {
    referralURL.current = `${RedirectURL}?code=${user?.my_slug}`;
    getData();
    getRequestListing();
  }, []);

  const getData = () => {
    ApiClient.get(`commission?id=${user?.id}`).then((res) => {
      if (res.success) {
        setdata(res?.data);
      }
    });
  };

  const getRequestListing = (p = {}) => {
    let fil = { ...filter, ...p, user_id: user?.id };
    loader(true);
    ApiClient.get(`withdrawEarning`, fil).then((res) => {
      if (res.success) {
        setlisting(res?.data?.data);
        settotal(res?.data?.total);
      }
      loader(false);
    });
  };

  const handleWithdrawForm = (e) => {
    e.preventDefault();
    // if (form?.withdrawAmount < 100) {
    //     toast.error('Minimum Withdraw Amount is $100')
    //     return
    // }
    if (form?.withdrawAmount > data?.available_balance) {
      toast.error(
        "Withdraw Amount can not be greater then to available balance"
      );
      return;
    } else {
      let payload = {
        url: form?.paypalURL,
        user_id: user?.id,
        withdraw_amount: form?.withdrawAmount,
      };
      loader(true);
      ApiClient.post(`withdrawEarning`, payload).then((res) => {
        if (res.success) {
          toast.success(res.message);
          getData();
          getRequestListing();
        }
        loader(false);
      });
    }
  };

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getRequestListing({ page: e });
  };

  return (
    <>
      <Layout>
        <div className="container-fluid">
          <div className="layout_NEW">
            <div className="row">
              <div className="col-md-6 mt-2">
                <div className="details_col">
                  <h1 className="detail_heading">Program Details</h1>
                  <div className="box_detail">
                    <div className="back_ground">
                      <h3>LIFETIME EARNINGS</h3>
                      <h1>$ {data?.total_commission || 0}</h1>
                    </div>
                    <p>
                      Earn
                      <span className="green_textLine">
                        {" "}
                        {activePlan?.affiliate_commission}%{" "}
                      </span>{" "}
                      in commissions from every sale generated from your link
                    </p>
                    <p>
                      Users that sign up via your link gets <b>a free month</b>{" "}
                      of the premium plan.{" "}
                    </p>
                    <p>Everyone wins!</p>
                    <div className="mt-3">
                      <label>Share your referral link:</label>
                      <div className="group_div form-group">
                        <div className="w-100">
                          <input
                            type="text"
                            id="URLCopy"
                            className="form-control"
                            value={`${referralURL.current}`}
                          />
                        </div>
                        <button
                          className="primary_btn1 ml-2"
                          onClick={(e) => copyReferralURL()}
                        >
                          Copy{" "}
                          <span className="material-icons ml-2">
                            content_copy
                          </span>
                        </button>
                      </div>
                    </div>
                    <div>
                      <label>Or share directly via these buttons:</label>
                      <div className="icon_images">
                        <WhatsappShareButton
                          url={referralURL.current}
                          title={title.current}
                          separator=":: "
                          className="Demo__some-network__share-button"
                        >
                          <img src="/assets/img/Social Icons1.png"></img>
                        </WhatsappShareButton>
                        <FacebookShareButton
                          url={referralURL.current}
                          quote={title.current}
                          className="Demo__some-network__share-button"
                        >
                          <img src="/assets/img/Social Icons2.png"></img>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={referralURL.current}
                          title={title.current}
                          className="Demo__some-network__share-button"
                        >
                          <img src="/assets/img/Social Icons3.png"></img>
                        </TwitterShareButton>
                        <FacebookMessengerShareButton
                          url={referralURL.current}
                          appId="521270401588372"
                          className="Demo__some-network__share-button"
                        >
                          <img src="/assets/img/Social Icons4.png"></img>
                        </FacebookMessengerShareButton>
                        <TelegramShareButton
                          url={referralURL.current}
                          title={title.current}
                          className="share-button"
                        >
                          <img src="/assets/img/Social Icons5.png"></img>
                        </TelegramShareButton>
                        <EmailShareButton
                          url={referralURL.current}
                          subject={"Share URL"}
                          body={`${title.current}:`}
                          className="Demo__some-network__share-button"
                        >
                          <img src="/assets/img/Social Icons6.png"></img>
                        </EmailShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="details_col">
                  <h1 className="detail_heading">Withdraw Earnings</h1>
                  <div className="box_detail">
                    <form onSubmit={handleWithdrawForm}>
                      <div className="back_ground back_ground2">
                        <h3>Available Balance</h3>
                        <h1>$ {data?.available_balance || 0}</h1>
                      </div>
                      <div className="form-group mb-3">
                        <label>PayPal URL</label>
                        <input
                          type="text"
                          value={form?.paypalURL}
                          onChange={(e) =>
                            setform({ ...form, paypalURL: e.target.value })
                          }
                          className="form-control"
                          placeholder="https://paypal.me"
                          required
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label>
                          Withdrawal Amount{" "}
                          <span className="label_text">
                            (minimum of USD 100)
                          </span>
                        </label>
                        <input
                          type="text"
                          value={form?.withdrawAmount}
                          onChange={(e) =>
                            setform({
                              ...form,
                              withdrawAmount: methodModel.isNumber(e),
                            })
                          }
                          maxLength="8"
                          className="form-control"
                          placeholder="USD"
                          required
                        />
                      </div>
                      <div className="form-group mb-3">
                        <p className="bulb_row">
                          <span className="material-icons lightbulb_icon">
                            lightbulb
                          </span>
                          Contact us if you prefer to receive your earnings via
                          bank transfers instead
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="btn primary_btn2 text-center form-control text-white"
                      >
                        Submit Withdrawal{" "}
                        <span className="material-icons ml-2">done</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="details_col mt-3">
            <h1 className="detail_heading">Withdrawal History</h1>
            <div className="px-3">
              <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Details</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listing &&
                      listing.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <span className="white_space">
                                {moment(item?.createdAt).format(
                                  "MMM DD, YYYY hh:MM A"
                                )}
                              </span>
                            </td>
                            <td className="text-capitalize">{item?.status}</td>
                            <td>{item?.url}</td>
                            <td>$ {item?.withdraw_amount}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {filter?.count < total ? (
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <b>{total}</b> Withdrawals
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={filter?.page}
                      itemsCountPerPage={filter?.count}
                      totalItemsCount={total}
                      pageRangeDisplayed={1}
                      onChange={handlePagination}
                      hideFirstLastPages={true}
                    />
                  </div>
                  <div></div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ReferralAffiliate;
